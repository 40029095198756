import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {applicationsBrand} from "../../../store/applications/applications-select/application-brand/applicationBrandSlice";
import ApplicationImportItemModel from "./application-import-item-components/application-import-item-model";
import {applicationsModel} from "../../../store/applications/applications-select/application-model/applicationModelSlice";
import ApplicationImportImageUploadModal
    from "./application-import-item-components/application-import-image-upload-modal";
import {getDataFromExcelFile} from "../../../store/applications/application-import/applicationImportSlice";
import {parkingsData} from "../../../store/parkings/parkingsSlice";



function ApplicationImportItem(
    {
        massImportData,
        deleteCurrentRow,
        applicationWithoutAttach,
        setApplicationWithoutAttach,
        setLastApplicationVersionAfterChanging,
        lastApplicationVersionAfterChanging
    }

) {
    const dispatch = useDispatch()
    const { parkings } = useSelector((state) => state.parkings);
    const [uniqueCarMarkResponses, setUniqueCarMarkResponses] = useState([]);
    const [currentModalMarkId, setCurrentModalMarkId] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentCarId, setCurrentCarId] = useState(0);
    const [currentCarAttach, setCurrentCarAttach] = useState([]);
    const [currentApplicationsIndex, setCurrentApplicationsIndex] = useState(0);

    const closeModal = () => {
        setIsModalOpen(false)
    }
    const openModal = (id, index) => {
        setCurrentCarId(id)
        setCurrentApplicationsIndex(index)
        setIsModalOpen(true)
        dispatch(getDataFromExcelFile()).then((response) => {
        })
    }

    const handleInputChange = (event, id) => {
        const {name, value} = event.target ? event.target : event;

        let newValue = value;
        let fieldName = ''
        const selectedText = event.target.options !== undefined ?
        event.target.options[event.target.selectedIndex].text : ''
        if (name === 'external_id' && value === ''){
            newValue = null
        }

        if (name === 'dataCatTypes') {
            fieldName = 'car_type_id'
            dispatch(applicationsBrand(+newValue)).then((response) => {
                const updatedItems = applicationWithoutAttach.applications.map(item => {
                    if (item.id === id) {
                        return {
                            ...item,
                            [fieldName]: +newValue,
                            ['car_mark_id']: 0,
                            ['car_mark_name']: 'выбрать',
                            ['car_model_id']: 0,
                            ['car_model_name']: 'выбрать'
                        };
                    }
                    return item;
                });
                // Update the state, keeping other fields intact
                setApplicationWithoutAttach(prevState => ({
                    ...prevState,
                    applications: updatedItems,
                }));
            })
        }
        if (name === 'dataCarMarks') {
            fieldName = 'car_mark_id'
            dispatch(applicationsModel(+newValue)).then((response) => {
                const updatedItems = applicationWithoutAttach.applications.map(item => {
                    if (item.id === id) {
                        return {
                            ...item,
                            [fieldName]: +newValue,
                            ['car_mark_name']: selectedText,
                            ['car_model_id']: 0,
                            ['car_model_name']: 'выбрать'
                        };
                    }
                    return item;
                });
                // Update the state, keeping other fields intact
                setApplicationWithoutAttach(prevState => ({
                    ...prevState,
                    applications: updatedItems,
                }));
            })
        }
        if (name === 'dataCarModal') {
            fieldName = 'car_model_id'
            const updatedItems = applicationWithoutAttach.applications.map(item => {
                if (item.id === id) {
                    return {
                        ...item,
                        [fieldName]: +newValue,
                        ['car_model_name']: selectedText
                    };
                }
                return item;
            });
            // Update the state, keeping other fields intact
            setApplicationWithoutAttach(prevState => ({
                ...prevState,
                applications: updatedItems,
            }));
        }
        if (name === 'parkings_id') {
            fieldName = 'parking_id'
            const updatedItems = applicationWithoutAttach.applications.map(item => {
                if (item.id === id) {
                    return {
                        ...item,
                        [fieldName]: +newValue,
                    };
                }
                return item;
            });
            setApplicationWithoutAttach(prevState => ({
                ...prevState,
                applications: updatedItems,
            }));
        }
        else {
            const updatedItems = lastApplicationVersionAfterChanging.map(item => {
                if (item.id === id) {
                    return {
                        ...item,
                        [name]: newValue,
                    };
                }
                return item;
            });
            setLastApplicationVersionAfterChanging(updatedItems)
        }
    };
    // useEffect(()=>{
    //     carTypesArray = selectArrayCarTypes(applicationWithoutAttach.car_types,'car_type_id')
    // },[dispatch])
    //update attachments list for current application
    useEffect(() => {
        setCurrentCarAttach(massImportData?.applications[currentApplicationsIndex]?.attachments)
    }, [massImportData])
    //Extract and store unique car_type_id values from massImportData
    useEffect(() => {
        if (applicationWithoutAttach && applicationWithoutAttach.applications) {
            const uniqueCarTypeIds = Array.from(new Set(applicationWithoutAttach.applications.map(item => item.car_type_id)));
            if (uniqueCarTypeIds.length > 0) {
                const responses = uniqueCarTypeIds.map(id => {
                    return dispatch(applicationsBrand(id))
                        .then(response => ({[id]: response.payload}));
                });
                Promise.all(responses).then(setUniqueCarMarkResponses);
            }
        }
    }, [applicationWithoutAttach, dispatch]);
    //get modals list
    useEffect(() => {
        if (applicationWithoutAttach?.applications?.length > 0) {
            const marArr = Array.from(new Set(applicationWithoutAttach.applications.map(item => item.car_mark_id)));
            const marks = marArr.map((id) => {
                return dispatch(applicationsModel(id))
                    .then(response => ({[id]: response.payload}))
            })
            Promise.all(marks).then(setCurrentModalMarkId);

        }
    }, [applicationWithoutAttach])

    useEffect(() => {
        dispatch(parkingsData())
    }, [dispatch]);



    return (
        applicationWithoutAttach && applicationWithoutAttach?.applications?.map((item, index) => {
            return (
                <>
                    <tr>
                        {/*№*/}
                        <td key={index + 1}>{index}</td>
                        {/*Фото*/}
                        <td
                            key={index + 2}
                            className={'cursor-pointer'}
                            onClick={() => {
                                openModal(item.id, index)
                            }}
                        >
                            {item?.attachments?.length > 0 ? item?.attachments?.length + ' фото +' : 'Добавить +'}
                        </td>
                        {/*Тип авто*/}
                        <td
                            key={index + 3}
                        >

                            {applicationWithoutAttach.car_types.length > 0 ?
                                // <Select
                                //     styles={selectStyle}
                                //     options={carTypesArray}
                                //     name="dataCatTypes"
                                //     isMulti
                                //     onChange={(event) => {
                                //         handleInputChange(event, item.id)
                                //     }}
                                // />
                                <select
                                    name="dataCatTypes"
                                    id="dataCatTypes"
                                    className={'massImportSelect'}
                                    onChange={(event) => {
                                        handleInputChange(event, item.id)
                                    }}
                                >
                                    {/*<option defaultValue={item?.car_type_id}>{item?.car_type_id}</option>*/}
                                    {massImportData && massImportData?.car_types?.map((car_type) => {

                                        return (
                                            <option
                                                key={car_type.id}
                                                value={car_type.id}
                                                selected={car_type.id === item?.car_type_id ? true : false}
                                            >
                                                {car_type.name}
                                            </option>
                                        )
                                    })}
                                </select>
                                : item?.car_type_id}
                        </td>
                        {/*Марка*/}
                        <td
                            key={index + 4}
                        >
                            {uniqueCarMarkResponses.length > 0 ?
                                <select
                                    name="dataCarMarks"
                                    id="dataCarMarks"
                                    className={'massImportSelect'}
                                    onChange={(event) => {
                                        handleInputChange(event, item.id)
                                    }}
                                >
                                    <option selected={true} value={item?.car_mark_id}>{item?.car_mark_name}</option>
                                    {
                                        uniqueCarMarkResponses && uniqueCarMarkResponses?.map((carMarks, index) => {
                                            return carMarks && item?.car_type_id ? carMarks[item?.car_type_id] && carMarks[item?.car_type_id]?.map((itm) => {
                                                return (
                                                    <option
                                                        key={itm.id}
                                                        value={itm.id}
                                                    >
                                                        {itm.name}
                                                    </option>
                                                )
                                            }) : ''
                                        })
                                    }
                                </select>
                                : item?.car_mark_name}
                        </td>
                        {/*Модель*/}
                        <ApplicationImportItemModel
                            currentModalMarkId={currentModalMarkId}
                            modelName={item?.car_model_name}
                            item={item}
                            handleInputChange={handleInputChange}
                        />
                        {/*Vin*/}
                        <td
                            key={item?.vin}
                        >
                            <input
                                type="text"
                                name={'vin'}
                                defaultValue={item?.vin}
                                onChange={(event) => {
                                    handleInputChange(event, item.id)
                                }}
                            />
                        </td>
                        {/*Гос номер*/}
                        <td
                            key={item?.external_id + index}
                        >
                            <input
                                type="text"
                                name={'external_id'}
                                defaultValue={item?.external_id !== null ? item?.external_id : 'не указан'}
                                onChange={(event) => {
                                    handleInputChange(event, item.id)
                                }}
                            />
                            </td>
                        {/*Наим.собственника*/}
                        <td
                            key={item?.courier_fullname + index + 5}
                        >
                            <input
                                type="text"
                                name={'courier_fullname'}
                                defaultValue= {item?.courier_fullname}
                                onChange={(event) => {
                                    handleInputChange(event, item.id)
                                }}
                            />
                        </td>
                        {/*Тел.собственника*/}
                        <td  key={item?.courier_phone + index + 6}>
                            <input
                                type="text"
                                name={'courier_phone'}
                                defaultValue= {item?.courier_phone}
                                onChange={(event) => {
                                    handleInputChange(event, item.id)
                                }}
                            />

                        </td>
                        {/*№ убытка/договор*/}
                        <td  key={item?.license_plate + index + 7}>
                            <input
                                type="text"
                                name={'courier_phone'}
                                defaultValue= {item?.courier_phone}
                                onChange={(event) => {
                                    handleInputChange(event, item.id)
                                }}
                            />
                        </td>
                        {/*Предполагаемая дата постановки	*/}
                        <td  key={item?.arriving_at + index + 8}>
                            <input
                                type="text"
                                name={'arriving_at'}
                                defaultValue=  {item?.arriving_at}
                                onChange={(event) => {
                                    handleInputChange(event, item.id)
                                }}
                            />

                        </td>
                        {/*Город/Адрес стоянки*/}
                        <td  key={item?.parking_id + index + 9}>
                            {parkings.length > 0 ?
                                <select
                                    name="parkings_id"
                                    id="parkings_id"
                                    className={'massImportSelect'}
                                    onChange={(event) => {
                                        handleInputChange(event, item.id)
                                    }}

                                >
                                    {
                                        parkings?.map((parking, index) => {
                                                return (
                                                    <option
                                                        key={parking.id}
                                                        value={parking.id}
                                                        selected={parking.id === item?.parking_id ? true : false}
                                                    >
                                                        {parking.title}
                                                    </option>
                                                )
                                        })
                                    }
                                </select>
                                : item?.parking_id}


                        </td>
                        {/*удаление*/}
                        {applicationWithoutAttach?.applications.length > 1 ?
                            <td>
                                        <span
                                            style={{cursor: 'pointer'}}
                                            onClick={() => {
                                                deleteCurrentRow(item.id)
                                            }}
                                        >
                                            <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <g opacity="0.6">
                                                    <path
                                                        d="M11.6667 3.50002H9.33333V2.91669C9.33333 2.45256 9.14896 2.00744 8.82077 1.67925C8.49258 1.35106 8.04746 1.16669 7.58333 1.16669H6.41667C5.95254 1.16669 5.50742 1.35106 5.17923 1.67925C4.85104 2.00744 4.66667 2.45256 4.66667 2.91669V3.50002H2.33333C2.17862 3.50002 2.03025 3.56148 1.92085 3.67087C1.81146 3.78027 1.75 3.92864 1.75 4.08335C1.75 4.23806 1.81146 4.38644 1.92085 4.49583C2.03025 4.60523 2.17862 4.66669 2.33333 4.66669H2.91667V11.0834C2.91667 11.5475 3.10104 11.9926 3.42923 12.3208C3.75742 12.649 4.20254 12.8334 4.66667 12.8334H9.33333C9.79746 12.8334 10.2426 12.649 10.5708 12.3208C10.899 11.9926 11.0833 11.5475 11.0833 11.0834V4.66669H11.6667C11.8214 4.66669 11.9697 4.60523 12.0791 4.49583C12.1885 4.38644 12.25 4.23806 12.25 4.08335C12.25 3.92864 12.1885 3.78027 12.0791 3.67087C11.9697 3.56148 11.8214 3.50002 11.6667 3.50002ZM5.83333 2.91669C5.83333 2.76198 5.89479 2.6136 6.00419 2.50421C6.11358 2.39481 6.26196 2.33335 6.41667 2.33335H7.58333C7.73804 2.33335 7.88642 2.39481 7.99581 2.50421C8.10521 2.6136 8.16667 2.76198 8.16667 2.91669V3.50002H5.83333V2.91669ZM9.91667 11.0834C9.91667 11.2381 9.85521 11.3864 9.74581 11.4958C9.63642 11.6052 9.48804 11.6667 9.33333 11.6667H4.66667C4.51196 11.6667 4.36358 11.6052 4.25419 11.4958C4.14479 11.3864 4.08333 11.2381 4.08333 11.0834V4.66669H9.91667V11.0834Z"
                                                        fill="#EB5757"/>
                                                </g>
                                            </svg>
                                        </span>
                            </td>
                            : ''
                        }
                    </tr>
                    <ApplicationImportImageUploadModal
                        carId={currentCarId}
                        currentCarAttach={currentCarAttach}
                        isOpen={isModalOpen}
                        closeModal={closeModal}
                    />
                </>

            )
        })


    );
}

export default ApplicationImportItem;