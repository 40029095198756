import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  reportsEmployeeData,
  reportsByEmployeeLink,
} from "../../../store/reports/reportsEmployeeSlice/reportsEmployeeSlice";
import ReportEmployeeForm from "./report-by-employee-form/report-by-employee-form";
import ReportsEmployeeItem from "./report-by-employee-item/report-by-employee-item";

function ReportEmployee() {
  const { employeeReports, link } = useSelector(
    (state) => state.reportsEmployee
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reportsEmployeeData());
    dispatch(reportsByEmployeeLink());
  }, [dispatch]);

  const handleChangeEmployeeData = (data) => {
    dispatch(reportsEmployeeData(data));
    dispatch(reportsByEmployeeLink(data));
  };
  return (
    <>
      {employeeReports && employeeReports.length !== 0 && (
        <>
          <ReportEmployeeForm
            employeeReports={employeeReports}
            handleChangeEmployeeData={handleChangeEmployeeData}
            link={link}
          />
          <div className="container">
            <div className="inner-page">
              <table className="table fs-13">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Сотрудник</th>
                    <th>Принял</th>
                    <th>Показал</th>
                    <th>Выдал</th>
                  </tr>
                </thead>
                <tbody>
                  {employeeReports.data.data.map((item, index) => {
                    return (
                      <ReportsEmployeeItem
                        item={item}
                        index={index}
                        key={index}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ReportEmployee;
