import React, {useEffect, useState} from 'react';
import styles from "../../permission.module.css";

function PermissionByRole({permissions, permissionName, togglePermission, role}) {
    const [isEnabled,setIsEnabled] = useState(false)



    useEffect(()=>{
        if (permissions){
            setIsEnabled(permissions.includes(permissionName))
        }
    },[permissionName,permissions])
    return (
        <td className={styles.chekboxes}>
            <label htmlFor="">
                <input
                    type="checkbox"
                    checked={isEnabled}
                    onChange={()=>{togglePermission(role, permissionName)}}
                />
            </label>
        </td>
    );
}

export default PermissionByRole;