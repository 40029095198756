import React, {useEffect, useState} from 'react';

function ServiceItem(
    {
        allServices,
        handleDelete,
        allCategories,
        renameServiceItem,
        carTypes
    }) {

    const [editMode, setEditMode] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState(0)
    const [newServiceName, setNewServiceName] = useState("")
    const [newServiceCarType, setNewServiceCarType] = useState("")
    const [newServiceCarSubType, setNewServiceCarSubType] = useState("")

    const handleSelect = (e) => {
        if (e.target.name === "serviceCategory"){
            setSelectedCategory(parseInt(e.target.value))
        }
        if (e.target.name === "carType" && parseInt(e.target.value) === 4){
            setNewServiceCarType(null)
            const carSubtypeId = e.target.selectedOptions[0].getAttribute('data-car-subtype-id');
            setNewServiceCarSubType(parseInt(carSubtypeId))
        }
    }
    const changeEditMode = () => {
        setEditMode(prevState => !prevState)
    }
    const changeServiceName = (e) =>{
        setNewServiceName(e.target.value)
    }
    const saveChangedData = (parkingservice_id) => {
        renameServiceItem(parkingservice_id, newServiceName, selectedCategory,newServiceCarType,newServiceCarSubType)
        changeEditMode()
    }
    const defaultValuesOfSelectedService = (cat_id, name, car_type_id) => {
        setSelectedCategory(cat_id)
        setNewServiceName(name)
        setNewServiceCarType(car_type_id)
        changeEditMode()
    }
    useEffect(()=>{
        setSelectedCategory(allServices?.service_category?.id)
    },[])

    return (
        <>
            {/* service name */}
            {!editMode ?
                <td>
                    <div className="first-info d-flex align-items-center mw-100">
                        <span className="status-dot status-success">&bull;</span>
                        <span>{allServices.name}</span>
                    </div>
                </td> :
                <td>
                    <input
                        type="text"
                        name={'service'}
                        defaultValue={allServices.name}
                        onChange={(e)=>{changeServiceName(e)}}
                    />
                </td>
            }

            {/*category name*/}
            {!editMode ?
                <td>
                    {allServices?.service_category?.name}

                </td> :
                <td>
                    <label className="field-style w-auto mb-0">
                        <select name="serviceCategory" id="" className="page-select"
                                onChange={handleSelect}>
                            <option key={'unknown'} selected={true} disabled={true}>Не указано</option>
                            {allCategories.map((item, index) => {
                                return (
                                    <option
                                        key={index}
                                        value={item.id}
                                        selected={item.id === allServices?.service_category?.id ? true : false}
                                    >{item.name}
                                    </option>
                                )
                            })}
                        </select>
                    </label>
                </td>
            }
            {/*car type name*/}
            {editMode && selectedCategory === 1 ?
                <td>
                    <label className="field-style w-auto mb-0">
                        <select name="carType" id="" className="page-select"
                                onChange={handleSelect}>
                            <option key={'noSelected'} selected={true} disabled={true}>Не указано</option>
                            {carTypes.map((item, index) => {
                                return (
                                    <option
                                        value={item.id}
                                        key={index}
                                        data-car-subtype-id={item.car_subtype_id !== null ? item.car_subtype_id : ''}
                                        selected={item.id === allServices?.car_type_id ? true : false}
                                    >
                                        {item.name}
                                    </option>
                                )
                            })}
                        </select>
                    </label>
                </td> :
                <td>
                    {allServices?.car_type_name !== null ? allServices.car_type_name : 'Не указано' }
                </td>
            }

            {editMode ?
                <td>

                    <a href='#'
                       onClick={()=>{saveChangedData(allServices.id)}}
                      >
                        <svg width="19" height="19" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"
                             className="ml-2">
                            <path
                                d="M0.25 1.75C0.25 0.921573 0.921573 0.25 1.75 0.25H4.75H9.25H10.4393C10.8372 0.25 11.2187 0.408035 11.5 0.68934L13.5303 2.71967C13.671 2.86032 13.75 3.05109 13.75 3.25V12.25C13.75 13.0784 13.0784 13.75 12.25 13.75H9.25H4.75H1.75C0.921573 13.75 0.25 13.0784 0.25 12.25V1.75ZM4.75 12.25H9.25V7.75H4.75V12.25ZM10.75 12.25H12.25V3.56066L10.75 2.06066V3.25C10.75 4.07843 10.0784 4.75 9.25 4.75H4.75C3.92157 4.75 3.25 4.07843 3.25 3.25V1.75H1.75V12.25H3.25V7.75C3.25 6.92157 3.92157 6.25 4.75 6.25H9.25C10.0784 6.25 10.75 6.92157 10.75 7.75V12.25ZM4.75 1.75V3.25H9.25V1.75H4.75Z"
                                fill="#536E9B"></path>
                        </svg>
                    </a>
                </td> : <td>
                    <a href='#'
                       onClick={()=>{defaultValuesOfSelectedService(allServices?.service_category?.id,allServices.name, allServices?.car_type_id)}}
                    >
                        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M13.2929 0.292893C13.6834 -0.0976311 14.3166 -0.0976311 14.7071 0.292893L18.7071 4.29289C19.0976 4.68342 19.0976 5.31658 18.7071 5.70711L5.70711 18.7071C5.51957 18.8946 5.26522 19 5 19H1C0.447715 19 0 18.5523 0 18V14C0 13.7348 0.105357 13.4804 0.292893 13.2929L10.2927 3.2931L13.2929 0.292893ZM11 5.41421L2 14.4142V17H4.58579L13.5858 8L11 5.41421ZM15 6.58579L16.5858 5L14 2.41421L12.4142 4L15 6.58579Z"
                                fill="#536E9B"></path>
                        </svg>
                    </a>
                </td>
            }
            <td className={'col-1'}>
                <a href="#" onClick={() => {
                    handleDelete(allServices.id)
                }}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.6">
                            <path
                                d="M7 4C7 2.89543 7.89543 2 9 2H15C16.1046 2 17 2.89543 17 4V6H18.9897C18.9959 5.99994 19.0021 5.99994 19.0083 6H21C21.5523 6 22 6.44772 22 7C22 7.55228 21.5523 8 21 8H19.9311L19.0638 20.1425C18.989 21.1891 18.1182 22 17.0689 22H6.93112C5.88184 22 5.01096 21.1891 4.9362 20.1425L4.06888 8H3C2.44772 8 2 7.55228 2 7C2 6.44772 2.44772 6 3 6H4.99174C4.99795 5.99994 5.00414 5.99994 5.01032 6H7V4ZM9 6H15V4H9V6ZM6.07398 8L6.93112 20H17.0689L17.926 8H6.07398ZM10 10C10.5523 10 11 10.4477 11 11V17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17V11C9 10.4477 9.44772 10 10 10ZM14 10C14.5523 10 15 10.4477 15 11V17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17V11C13 10.4477 13.4477 10 14 10Z"
                                fill="#EB5757">

                            </path>
                        </g>
                    </svg>
                </a>
            </td>
            {/*</tr>*/}
        </>
    );
}

export default ServiceItem;