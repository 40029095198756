import axios from "axios";


const API = '/chats'

const sendMessageToRoomChat = async ({chatRoom,content}) => {
    const message = {
        content: content
    }
    const response = await axios.post(`${API}/${chatRoom}/messages`, message);
    return response.data;
};

const sendMessageAndCreateRoom = async ({model, modelID, message}) => {
    const messageFormat = {
        content: message
    }
    const response = await axios.post(`/${model}/${modelID}/messages`, messageFormat);
    return response.status;
};

const getDataFromChat = async ({applicationId}) => {
    const response = await axios.get(`/v1/applications/get-model-content-app-chat/${applicationId}`);
    return response.data;
};

const chatServices = {
    sendMessageAndCreateRoom,
    sendMessageToRoomChat,
    getDataFromChat
}

export default chatServices