import React from "react";

function ApplicationAboutCar({ single }) {
  return (
    <div className="row no-gutters">
      <div className="col-4">
        <div className="info-item">
          <span>ПТС</span>
          {single.application?.pts && single.application?.pts_type && single.application.pts_provided ?
              <span>
                    {single.application?.pts} {single.application?.pts_type}
                    <br /> {single.application.pts_provided && "(Принят на хранение)"}
              </span>
              : '_'
          }

        </div>
        <div className="info-item">
              <span>Кол-во владельцев</span>
              {single.application?.owner_number ? single.application?.owner_number : '_'}
          </div>
      </div>
      <div className="col-4">
        <div className="info-item">
              <span>СТС</span>
              {single.application?.sts && single.application.sts_provided ?
                  <span>
                {single.application?.sts}
                      <br /> {single.application.sts_provided && "(Принят на хранение)"}
              </span>
                  : '_'
              }

          </div>
        <div className="info-item">
          <span>Кол-во ключей</span>
          {single.application?.car_key_quantity
            ? single.application?.car_key_quantity
            : 0}
        </div>
      </div>
      <div className="col-4">
          <div className="info-item">
              <span>Пробег, км</span>
              {single.application?.milage ?
                  <span>{single.application?.milage}км</span>  : '_'
              }
          </div>
      </div>
    </div>
  );
}

export default ApplicationAboutCar;
