import { createAsyncThunk } from "@reduxjs/toolkit";
import applicationModeratorService from "./applicationModeratorService";

// Approve
export const moderatorApprove = createAsyncThunk(
  "applications/moderatorApprove",
  async (data, thunkAPI) => {
    try {
      return await applicationModeratorService.applicationModeratrorApprove(
        data
      );
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Reject
export const moderatorReject = createAsyncThunk(
  "applications/moderatorReject",
  async ({ data, id }, thunkAPI) => {
    try {
      return await applicationModeratorService.applicationModeratrorReject(
        data,
        id
      );
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
