import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  issueRequestCreate,
  issueRequestPost,
} from "../../../store/issueRequest/issue-request-create/IssueRequestSlice";
import { useParams, useNavigate } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import selectStyle from "../../../utils/select-styles";
import { selectArrayInterval } from "../../../utils/select-array";
import { useState } from "react";
import swal from "sweetalert";
import { Russian } from "flatpickr/dist/l10n/ru.js";

import { issuanceUpdate } from "../../../store/issueRequest/issuance/issuanceSlice";
import ImageUploaderEdit from "../../../components/image-upload/image-edit-upload";
import ImageUploader from "../../../components/image-upload/image-upload";
import IssueImageUploader from "../../../components/image-upload/issue-image-upload/issue-image-upload";
import FileUploadFormEdit from "../../../components/file-upload/file-edit-upload";
import IssueFileUpload from "../../../components/file-upload/issue-file-upload/issue-file-upload";

function IssueRequestCreate() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const [issueCreate, setIssueCreate] = useState({});
  const { issueCreateGet } = useSelector((state) => state.issueRequestCreate);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(issueRequestCreate(id));
  }, [dispatch]);

  // Собираем данные и отправляем их
  const onCreateIssue = (e) => {
    e.preventDefault();
    if (user.role === "Manager") {
      dispatch(
        issuanceUpdate({
          id: id,
          data: issueCreate,
        })
      ).then((response) => {
        if (response.payload.message === "Issued.") {
          swal("Машина успешно обновлена", {
            icon: "success",
          }).then(() => {
            navigate("/applications");
          });
        } else {
          let errorMessage = "Ошибка!";
          if (response.payload && response.payload.errors) {
            const errorMessages = Object.values(response.payload.errors).reduce(
              (acc, val) => acc.concat(val),
              []
            );
            errorMessage = errorMessages.join("\n");
          }
          swal(errorMessage, {
            icon: "error",
          });
        }
      });
    } else {
      dispatch(issueRequestPost({ id: id, data: issueCreate })).then(
        (response) => {
          if (response.payload.success === "Сохранено.") {
            swal("Машина успешно отправлена на выдачу!", {
              icon: "success",
            }).then(() => {
              navigate("/applications");
            });
          } else {
            let errorMessage = "Ошибка!";
            if (response.payload && response.payload.errors) {
              const errorMessages = Object.values(
                response.payload.errors
              ).reduce((acc, val) => acc.concat(val), []);
              errorMessage = errorMessages.join("\n");
            }
            swal(errorMessage, {
              icon: "error",
            });
          }
        }
      );
    }
  };
  const handleChangeDate = (e) => {
    if (Array.isArray(e)) {
      let issueDate = e;
      issueDate = issueDate
        .map((item) =>
          new Date(item)
            .toLocaleDateString("ru-RU", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
            })
            .replace(/\./g, "-")
        )
        .join("");
      if (user.role === "Manager") {
        setIssueCreate({ ...issueCreate, issued_at: issueDate });
      } else {
        setIssueCreate({ ...issueCreate, arriving_at: issueDate });
      }
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target ? e.target : e;
    setIssueCreate({ ...issueCreate, [name]: value });
  };
  //
  return (
    issueCreateGet &&
    issueCreateGet.length !== 0 && (
      <form onSubmit={onCreateIssue}>
        <div className="container page-head-wrap">
          <div className="page-head">
            <div className="page-head__top d-flex align-items-center">
              <h1>{issueCreateGet.title}</h1>
              <div className="ml-auto d-flex">
                <button className="btn btn-white">
                  {user.role === "Manager" ? "Выдать авто" : "Создать заявку"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="inner-page">
            <div className="row no-gutters">
              <div className="col-md-12">
                {/* Информация об авто */}
                <div className="inner-page__item">
                  <div className="inner-item-title">Информация об авто</div>
                  <div className="row no-gutters">
                    <div className="col-6">
                      <div className="info-item">
                        <span>VIN</span>
                        {issueCreateGet.application
                          ? issueCreateGet.application.vin
                          : "не указан"}
                      </div>
                      <div className="info-item">
                        <span>Гос. номер</span>
                        {issueCreateGet.application
                          ? issueCreateGet.application.license_plate
                          : "не указан"}
                      </div>
                      <div className="info-item">
                        <span>ФИО собственника</span>
                        {issueCreateGet.application
                          ? issueCreateGet.application.courier_fullname
                          : "не указан"}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="info-item">
                        <span>Заказчик</span>
                        {issueCreateGet.application
                          ? issueCreateGet.application.partner.name
                          : "не указан"}
                      </div>
                      <div className="info-item">
                        <span>Стоянка</span>
                        {issueCreateGet.application
                          ? issueCreateGet.application.parking.title
                          : "не указан"}
                      </div>
                      <div className="info-item">
                        <span>Номер убытка / договора</span>
                        {issueCreateGet.application
                          ? issueCreateGet.application.external_id
                          : "не указан"}
                      </div>
                    </div>
                  </div>
                </div>
                {/*  */}
                <div className="inner-page__item">
                  <div className="inner-item-title">Дата</div>
                  <div className="row">
                    <div className="col-6">
                      <label className="field-style invalid">
                        <span>Дата выдачи</span>
                        <Flatpickr
                          options={{
                            dateFormat: "d-m-Y",
                            locale: {
                              ...Russian,
                              months: {
                                ...Russian.months,
                                longhand: [
                                  "Января",
                                  "Февраля",
                                  "Марта",
                                  "Апреля",
                                  "Мая",
                                  "Июня",
                                  "Июля",
                                  "Августа",
                                  "Сентября",
                                  "Октября",
                                  "Ноября",
                                  "Декабря",
                                ],
                              },
                            },
                          }}
                          onChange={handleChangeDate}
                          name="arriving_at"
                        />
                      </label>
                    </div>
                    {user.role === "Manager" ? (
                      ""
                    ) : (
                      <div className="col-6">
                        <label className="field-style select-react__style invalid">
                          <span>Промежуток времени</span>
                          <Select
                            defaultValue={{
                              value: 0,
                              label: "Выберите интервал времени",
                              name: "arriving_interval",
                            }}
                            options={selectArrayInterval}
                            styles={selectStyle}
                            onChange={handleChange}
                          />
                        </label>
                      </div>
                    )}
                  </div>
                </div>

                <div className="inner-page__item">
                  <div className="inner-item-title">Информация о выдаче</div>
                  <div className="row">
                    <div className="col-6 mt-2 mb-5">
                      <label className="switch-radio-wrap">
                        <input
                          type="radio"
                          name="legal_type_id"
                          defaultValue={1}
                          onChange={handleChange}
                        />
                        <span className="switcher-radio"></span>
                        <span>
                          {issueCreateGet.individualLegalOptions["1"]}
                        </span>
                      </label>
                    </div>
                    <div className="col-6 mt-2 mb-5">
                      <label className="switch-radio-wrap">
                        <input
                          type="radio"
                          name="legal_type_id"
                          defaultValue={2}
                          onChange={handleChange}
                        />
                        <span className="switcher-radio"></span>

                        <span>
                          {issueCreateGet.individualLegalOptions["2"]}
                        </span>
                      </label>
                    </div>
                    <div className="col-6">
                      <label className="field-style">
                        <span>Название организации</span>
                        <input
                          type="text"
                          defaultValue=""
                          name="organization_name"
                          placeholder="Не указан"
                          onChange={handleChange}
                        />
                      </label>
                    </div>
                    <div className="col-6">
                      <label className="field-style">
                        <span>ИНН</span>
                        <input
                          type="text"
                          defaultValue=""
                          name="inn"
                          placeholder="Не указан"
                          onChange={handleChange}
                        />
                      </label>
                    </div>
                    <div className="col-6 mt-3">
                      <label className="field-style">
                        <span>ФИО доверенного лица</span>
                        <input
                          type="text"
                          defaultValue=""
                          name="fio"
                          placeholder="Не указан"
                          onChange={handleChange}
                        />
                      </label>
                    </div>
                    <div className="col-6 mt-3">
                      <label className="field-style">
                        <span>Телефон доверенного лица</span>
                        <input
                          type="text"
                          defaultValue=""
                          name="phone"
                          placeholder="Не указан"
                          onChange={handleChange}
                        />
                        {/* <InputMask
                          mask="+7(999)999-99-99"
                          maskChar="_"
                          name="phone"
                          onChange={handleChange}
                          placeholder="+7 (___) ___-__-__"
                        /> */}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="inner-page__item">
                  <div className="inner-item-title">Документы</div>
                  <div className="inner-page__item">
                    <IssueFileUpload
                        applicationId={id}
                        docs={issueCreateGet.docs}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  );
}

export default IssueRequestCreate;
