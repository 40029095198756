import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Logo from "./header-logo/header-logo";
import Navigation from "./header-navigation/header-navigation";
import RightMenu from "./header-right-menu/header-right-menu";

const Header = () => {
  const { user } = useSelector((state) => state.auth);

  return (
    <div className="header" id="app">
      <div className="container header__wrap">
        <Logo />

        {user ? (
          <>
            <Navigation /> <RightMenu />
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Header;
