import React from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Logout from "../../../logout/logout";

const HeaderProfile = () => {
  const { user } = useSelector((state) => state.auth);
  return user ? (
    <>
      <div className="header__user-icon">
        <svg
          width="32"
          height="33"
          viewBox="0 0 32 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 5.83366C13.0545 5.83366 10.6667 8.22147 10.6667 11.167C10.6667 14.1125 13.0545 16.5003 16 16.5003C18.9455 16.5003 21.3333 14.1125 21.3333 11.167C21.3333 8.22147 18.9455 5.83366 16 5.83366ZM8 11.167C8 6.74871 11.5817 3.16699 16 3.16699C20.4183 3.16699 24 6.74871 24 11.167C24 15.5853 20.4183 19.167 16 19.167C11.5817 19.167 8 15.5853 8 11.167ZM10.6667 24.5003C8.45753 24.5003 6.66667 26.2912 6.66667 28.5003C6.66667 29.2367 6.06971 29.8337 5.33333 29.8337C4.59695 29.8337 4 29.2367 4 28.5003C4 24.8184 6.98477 21.8337 10.6667 21.8337H21.3333C25.0152 21.8337 28 24.8184 28 28.5003C28 29.2367 27.403 29.8337 26.6667 29.8337C25.9303 29.8337 25.3333 29.2367 25.3333 28.5003C25.3333 26.2912 23.5425 24.5003 21.3333 24.5003H10.6667Z"
            fill="#011A3F"
          />
        </svg>
      </div>
      <div className="header__user-info">
        <div className="header__user-pos">{user.role}</div>
        <div className="header__user-name">
          {user.name} <span className="icon icon-arrow-d"></span>
          <div className="header__user-dd">
            <div>
              <Link to="/profile">Профиль</Link>
            </div>
            <Logout>
              <div>Выйти</div>
            </Logout>
          </div>
        </div>
      </div>
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default HeaderProfile;
