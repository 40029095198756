import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import applicationCreatePostService from "./applicationCreatePostService";
import { setFormDataReset } from "../application-create-collect-data/application-create-collect-data";

// Создание авто , отправки данных через POST запрос

const initialState = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const appliationResultPost = createAsyncThunk(
  "appliation/resultPost",
  async (data, thunkAPI) => {
    try {
      const result = await applicationCreatePostService.applicationCreateData(
        data
      );
      await thunkAPI.dispatch(setFormDataReset());
      return result;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const applicationCreatePostSlice = createSlice({
  name: "appliationResultPost",
  initialState,
  reducers: {
    appReset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(appliationResultPost.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(appliationResultPost.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
      })
      .addCase(appliationResultPost.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});
export const { appReset } = applicationCreatePostSlice.actions;
export default applicationCreatePostSlice.reducer;

//
