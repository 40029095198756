import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  reportsByPartnerData,
  reportsByPartnerLink,
} from "../../../store/reports/reportsByPartnerSlice/reportsByPartnerSlice";
import ReportByPartnerForm from "./report-by-partner-form/report-by-partner-form";
import ReportsByPatnerItem from "./reports-by-partner-item/reports-by-parnter-item";
import { useState } from "react";

function ReportPartner() {
  const { reportsByPartner, link } = useSelector(
    (state) => state.reportsByPartner
  );
  const [statusReports, setStatusReports] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reportsByPartnerData());
  }, [dispatch]);

  const handleChangePartnerData = (data) => {
    dispatch(reportsByPartnerData(data));
    dispatch(reportsByPartnerLink(data));
  };
  return (
    <>
      {reportsByPartner && reportsByPartner.length !== 0 && (
        <>
          <ReportByPartnerForm
            reportsByPartner={reportsByPartner}
            link={link}
            handleChangePartnerData={handleChangePartnerData}
            setStatusReports={setStatusReports}
          />

          <div className="container">
            <div className="inner-page">
              <table className="table fs-13 sortable horizontal-scrollable vertical-scrollable">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>ID</th>
                    <th>заказчик</th>
                    <th>Стоянка</th>
                    <th>Ном. убытка</th>
                    <th>Марка</th>
                    <th>Модель</th>
                    <th>Год</th>
                    <th>Тип авто</th>
                    <th>VIN</th>
                    <th>Гос. номер</th>
                    <th>Статус</th>
                    <th>Постановка</th>
                    <th>Выдано</th>
                    <th>Кол-во дней</th>
                    <th>Сумма</th>
                    {statusReports === "" ||
                      (statusReports !== "instorage" && (
                        <>
                          <th>Кол-во дней в период</th>
                          <th>Сумма в период</th>
                        </>
                      ))}
                    <th>Комментарий</th>
                  </tr>
                </thead>
                <tbody>
                  {reportsByPartner.data.data.map((item, index) => {
                    return (
                      <ReportsByPatnerItem
                        item={item}
                        index={index}
                        key={index}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ReportPartner;
