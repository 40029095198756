import React from "react";
import { Link, useLocation } from "react-router-dom";

function ApplicationsHeader({ title, total }) {
  const location = useLocation();
  const statusPaths = {
    "/applications": "all",
    "/applications/1": "draft",
    "/applications/2": "storage",
    "/applications/7": "pending",
    "/applications/11": "issue_requests",
    "/applications/9": "moderation",
    "/applications/3": "issued",
    "/applications/6": "cancelled-by-us",
    "/applications/8": "deleted",
  };

  const status = statusPaths[location.pathname] || "all";
  const count = total ? total[status] : 0;

  return (
    <div className="container page-head-wrap">
      <div className="page-head">
        <div className="page-head__top d-flex align-items-center">
          <h1>
            {title} ({count})
          </h1>
          <Link to="/applications/create" className="btn ms-auto btn-white">
            Добавить авто
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ApplicationsHeader;
