import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

function ApplicationStatus({ total }) {
  const { id } = useParams();
  const { user } = useSelector((state) => state.auth);

  return (
    <>
      <ul className="page-nav">
        <li
          className={`notification page-nav__item  ${
            Number(id) === 2 ? "active" : ""
          }`}
        >
          <Link to="/applications/2" className="page-nav__link">
            Хранение
          </Link>
          <div
              className={`notification__count mk ${Number(total.storage) > 0  ? "" : "d-none"}`}
          >
            {total ? total.storage : 0}
          </div>
        </li>

        <li
          className={`notification page-nav__item  ${
            Number(id) === 1 ? "active" : ""
          }`}
        >
          <Link to="/applications/1" className="page-nav__link">
            Черновики
          </Link>
          <div className={`notification__count mk ${Number(total.draft) > 0  ? "" : "d-none"}`}>
            {total ? total.draft : 0}
          </div>
        </li>

        <li
          className={`notification page-nav__item  ${
            Number(id) === 7 ? "active" : ""
          }`}
        >
          <Link to="/applications/7" className="page-nav__link">
            Постановка
          </Link>
          <div className={`notification__count mk ${Number(total.pending) > 0  ? "" : "d-none"}`}>
            {total ? total.pending : 0}
          </div>
        </li>

        <li
          className={`notification page-nav__item  ${
            Number(id) === 11 ? "active" : ""
          }`}
        >
          <Link to="/applications/11" className="page-nav__link">
            На выдачу
          </Link>
          <div className={`notification__count mk ${Number(total.issue_requests) > 0  ? "" : "d-none"}`}>
            {total ? total.issue_requests : 0}
          </div>
        </li>

        <li
          className={`notification page-nav__item  ${
            Number(id) === 9 ? "active" : ""
          }`}
        >
          <Link to="/applications/9" className="page-nav__link">
            Модерация
          </Link>
          <div className={`notification__count mk ${Number(total.moderation) > 0  ? "" : "d-none"}`}>
            {total ? total.moderation : 0}
          </div>
        </li>

        {user.role !== "Operator" ? (
          <li
            className={`notification page-nav__item  ${
              Number(id) === 3 ? "active" : ""
            }`}
          >
            <Link to="/applications/3" className="page-nav__link">
              Выдано
            </Link>
            <div className={`notification__count mk ${Number(total.issued) > 0  ? "" : "d-none"}`}>
              {total ? total.issued : 0}
            </div>
          </li>
        ) : (
          <></>
        )}

        <li
          className={`notification page-nav__item  ${
            Number(id) === 6 ? "active" : ""
          }`}
        >
          <Link to="/applications/6" className="page-nav__link">
            Отклонено
          </Link>
          <div className={`notification__count mk ${Number(total["cancelled-by-us"]) > 0  ? "" : "d-none"}`}>
            {total ? total["cancelled-by-us"] : 0}
          </div>
        </li>

        <li className={`notification page-nav__item  ${id ? "" : "active"}`}>
          <Link to="/applications" className="page-nav__link active">
            Все
          </Link>
          <div className={`notification__count mk ${Number(total.all) > 0  ? "" : "d-none"}`}>
            {total ? total.all : 0}</div>
        </li>
      </ul>
    </>
  );
}

export default ApplicationStatus;
