import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editLegals,
  legalsEditPut,
} from "../../../store/legal/legal-edit-slice/legalEditSlice";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

function LegalEditPage() {
  const { legalsEdit } = useSelector((state) => state.legalEdit);

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [disableKpp, setDisableKpp] = useState(false)
  const [disableOgrn, setDisableOgrn] = useState(false)
  const [disableOgrnip, setDisableOgrnip] = useState(false)

  const [legalEditForm, setlegalEditForm] = useState({});

  useEffect(() => {
    if (legalsEdit.length !== 0) {
      setlegalEditForm((prevForm) => ({
        ...prevForm,
        name: legalsEdit.legal.name,
        reg_number: legalsEdit.legal.reg_number,
        inn: legalsEdit.legal.inn,
        kpp: legalsEdit.legal.kpp,
        status: legalsEdit.legal.status,
        ogrnip: legalsEdit.legal.ogrnip,
      }));

      if (legalsEdit?.legal.kpp === 0 || legalsEdit.legal.kpp === '' || legalsEdit.legal.kpp === null) {
        setDisableKpp(true)
      }
      if (legalsEdit?.legal.reg_number === 0 || legalsEdit.legal.reg_number === '' || legalsEdit.legal.reg_number === null) {
        setDisableOgrn(true)
      }
      if (legalsEdit?.legal.ogrnip === 0 || legalsEdit.legal.ogrnip === '' || legalsEdit.legal.ogrnip === null) {
        setDisableOgrnip(true)
      }
    }
  }, [legalsEdit]);

  useEffect(() => {
    dispatch(editLegals(id));
  }, [dispatch, navigate]);

  const disableKppHandler = () => {
    setDisableKpp(prevState => !prevState)
    setlegalEditForm(prevForm => ({
      ...prevForm,
      kpp: ""
    }));
  }
  const disableOgrnHandler = () => {
    setDisableOgrn(prevState => !prevState)

    setlegalEditForm(prevForm => ({
      ...prevForm,
      reg_number: ""
    }));
  }
  const disableOgrnipHandler = () => {
    setDisableOgrnip(prevState => !prevState)

    setlegalEditForm(prevForm => ({
      ...prevForm,
      ogrnip: ""
    }));
  }

  const handleChange = (e) => {
    if (e.target) {
      const { name, value, type, checked } = e.target;
      let newValue = value;
      if (type === "checkbox") {
        newValue = checked ? 1 : 0;
      }
      setlegalEditForm({ ...legalEditForm, [name]: newValue });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Вы уверены что хотите обновить данные?",
      icon: "warning",
      buttons: ["Отменить", "Да"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(legalsEditPut({ id: id, data: legalEditForm })).then(
          (response) => {
            if (response.payload === 200) {
              swal("Юридическое лицо успешно обновлено!", {
                icon: "success",
              }).then(() => {
                dispatch(editLegals(id));
                navigate("/legals");
              });
            } else {
              let errorMessage = "Ошибка при редактирование юр.лица!";
              if (response.payload.data && response.payload.data.errors) {
                const errorMessages = Object.values(
                  response.payload.data.errors
                ).reduce((acc, val) => acc.concat(val), []);
                errorMessage = errorMessages.join("\n");
              }
              swal(errorMessage, {
                icon: "error",
              });
            }
          }
        );
      }
    });
  };

  return (
    <>
      {legalsEdit.length !== 0 && (
        <form onSubmit={handleSubmit}>
          <div className="container page-head-wrap">
            <div className="page-head">
              <div className="page-head__top d-flex align-items-center">
                <h1>{legalsEdit.title}</h1>
                <div className="ml-auto d-flex">
                  <button type="submit" className="btn btn-white">
                    Обновить
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="inner-page">
              <div className="row no-gutters">
                <div className="col-md-12">
                  <div className="inner-page__item">
                    <div className="inner-item-title">О юр. лице</div>
                    <div className="row">
                      <div className="col-6 mt-3">
                        <label className="field-style">
                          <span>Название</span>
                          <input
                            type="text"
                            defaultValue={legalEditForm?.name}
                            name="name"
                            required
                            placeholder="Не указан"
                            onChange={handleChange}
                          />
                        </label>
                      </div>
                      <div className="col-6">
                        <label className="switch-radio-wrap mt-11px">
                          <input
                            type="checkbox"
                            name="status"
                            defaultChecked={legalEditForm.status}
                            onChange={handleChange}
                          />
                          <span className="switcher-radio"></span>
                          <span>Активен</span>
                        </label>
                      </div>
                      <div className="col-6 mt-3">
                        {/*<div className="inner-item-title">Юр. данные</div>*/}
                        <label className="field-style">
                          <span>ИНН</span>
                          <input
                            type="text"
                            defaultValue={legalEditForm?.inn}
                            name="inn"
                            required
                            placeholder="Не указан"
                            onChange={handleChange}
                          />
                        </label>
                      </div>
                      <div className="col-6 mt-3 d-flex justify-content-start">
                        <label  className={`field-style ${disableKpp ? 'disabled' : ''}`}>
                          <span>КПП</span>
                          <input
                            type="text"
                            defaultValue={legalEditForm?.kpp}
                            name="kpp"
                            placeholder="Не указан"
                            onChange={handleChange}
                            disabled={disableKpp}
                          />
                        </label>
                        <label className="switch-radio-wrap mt-11px">
                          <input
                              type="checkbox"
                              name="status"
                              value={disableKpp}
                              checked={disableKpp}
                              onChange={disableKppHandler}
                          />
                          <span className="switcher-radio"></span>
                          <span>Неизвестно</span>
                        </label>
                      </div>
                      <div className="col-6 mt-3 d-flex justify-content-start">
                        <label className={`field-style ${disableOgrnip ? 'disabled' : ''}`}>
                          <span>ОГРНИП</span>
                          <input
                              id="ogrnip"
                              type="text"
                              value={legalEditForm.ogrnip}
                              name="ogrnip"
                              placeholder="Не указан"
                              onChange={handleChange}
                          />
                        </label>
                        <label className="switch-radio-wrap mt-11px">
                          <input
                              type="checkbox"
                              name="status"
                              value={disableOgrnip}
                              onChange={disableOgrnipHandler}
                              checked={disableOgrnip}
                          />
                          <span className="switcher-radio"></span>
                          <span>Неизвестно</span>
                        </label>
                      </div>
                      <div className="col-6 mt-3 d-flex justify-content-start">
                        <label className={`field-style ${disableOgrn ? 'disabled' : ''}`}>
                          <span>ОГРН</span>
                          <input
                            type="text"
                            defaultValue={legalEditForm?.reg_number}
                            name="reg_number"
                            placeholder="Не указан"
                            onChange={handleChange}
                            disabled={disableOgrn}
                          />
                        </label>
                        <label className="switch-radio-wrap mt-11px">
                          <input
                              type="checkbox"
                              name="status"
                              value={legalEditForm.status}
                              onChange={disableOgrnHandler}
                              checked={disableOgrn}
                          />
                          <span className="switcher-radio"></span>
                          <span>Неизвестно</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
}

export default LegalEditPage;
