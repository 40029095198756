import React, {useState} from "react";
import Preloader from "../../../preloader/preloader";
import ApplicationItem from "./applications-item/applications-item";
import { ToastContainer } from "react-toastify";

function ApplicationsList({ applications, setFavorite }) {
  return (
    <section className="car-col">
      <div className="container">
        <div className="row">
          {applications && applications.length !== 0 ? (
            Object.keys(applications).length !== 0 &&
            applications?.applications.map((item) => {
              return (
                <ApplicationItem
                  key={item.id}
                  item={item}
                  setFavorite={setFavorite}
                />
              );
            })
          ) : (
            <Preloader />
          )}
        </div>
        {/* <Pagination countpage={applications.last_page} /> */}
      </div>
    </section>
  );
}
export default ApplicationsList;
