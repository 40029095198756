import React from "react";
import { useSelector } from "react-redux";
import ApplicationFooterModeratorModal from "./applications-footer-moderator-modal";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { moderatorReject } from "../../../../../../../store/applications/application-moderator/applicationModeratorSlice";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function ApplicaitonFooterModerator({ single }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rejectData, setRejectData] = useState({
    moderator: true,
    type: "storage",
    message: "",
  });
  const { user } = useSelector((state) => state.auth);
  const isModerator = user.role === "Moderator";
  const isStatus9 = single.application.status.id === 9;

  const isModeratorAndStatus9 = isModerator && isStatus9;
  const isEditable = isModeratorAndStatus9;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  // Выводим модальные окна в зависимости от Select
  const handleChange = (e) => {
    if (e.target.value === "approved") {
      setIsModalOpen(true);
    } else if (e.target.value === "reject") {
      swal({
        title: single && single.application.car_title,
        icon: "warning",
        content: {
          element: "textarea",
          attributes: {
            placeholder: "Причина...",
          },
        },
        buttons: ["Отменить", "Да"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          let valueTextArea = document.querySelector(
            ".swal-content__textarea"
          ).value;
          setRejectData({ ...rejectData, message: valueTextArea });
        }
      });
    }
  };

  useEffect(() => {
    if (rejectData.message !== "") {
      dispatch(
        moderatorReject({ data: rejectData, id: single.application.id })
      );
      swal("Сообщение был отправлено!", {
        icon: "success",
      }).then(() => {
        navigate("/applications");
      });
    }
  }, [rejectData]);

  return (
    <>
      {isEditable && (
        <div className="d-flex">
          <select
            className="theme-back-white"
            onChange={(e) => handleChange(e)}
          >
            <option>Опции для Модератора</option>
            <option value="approved">Одобрено</option>
            <option value="reject">Вернуть с Текстом уведомления</option>
          </select>
        </div>
      )}
      <ApplicationFooterModeratorModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        id={single.application.id}
      />
    </>
  );
}

export default ApplicaitonFooterModerator;
