import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {createCoverage, getAllCoverage} from "../../store/parkings/create-coverage/coverageCreateSlice";
import swal from "sweetalert";
import GuideItem from "../../components/guide/guide-Item";
import {deleteCoverage} from "../../store/parkings/delete-coverage/deleteCoverageSlice";
import {editCoverage} from "../../store/parkings/edit-coverage/editCoverageSlice";
import Sidebar from "../../components/guide/sidebar/sidebar";

function Coverage() {
    const dispatch = useDispatch();
    const allCoverage = useSelector((state) => state.parkingCoverage.allCoverage);
    const [coverageName, setCoverageName] = useState('name')

    const changeCoverageName = (e) => {
        setCoverageName(e.target.value)
    }

    const handleDeleteCoverage = (id) => {
        dispatch(deleteCoverage(id)).then((response) => {

            if (response.payload === 200) {
                dispatch(getAllCoverage())
                swal("Покрытие удалено успешно", {
                    icon: "success",
                })
            } else {
                swal("Ошибка при удалении покрытия", {
                    icon: "error",
                });
            }
        })
    }
    useEffect(() => {
        dispatch(getAllCoverage())
    }, [dispatch])

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(createCoverage(coverageName)).then((response) => {
            if (response.payload.status === 201) {
                dispatch(getAllCoverage())
                swal("Покрытие создано успешно", {
                    icon: "success",
                })
            } else {
                swal("Ошибка при создании покрытия", {
                    icon: "error",
                });
            }
        })

    }
    return (
        <div className={'container '}>
            <div className="inner-page">
                <div className="page-head-wrap">
                    <div className="page-head">
                        <div className="page-head__top d-flex align-items-center">
                            <h1>Покрытия</h1>
                        </div>
                    </div>
                </div>
                <div className="d-flex">
                    <Sidebar/>
                    <div className="col-md-8 p-3 border-left">
                        <form onSubmit={handleSubmit}>
                            <div className="inner-item-title">Создать покрытие</div>
                            <div className=" mt-3 d-flex  justify-content-start">
                                <label className="field-style">
                                    <span>Тип покрытия</span>
                                    <input id="title"
                                           type="text"
                                           name="title"
                                           required=""
                                           className={'w-100'}
                                           placeholder="Асфальтовое покрытие"
                                           onChange={changeCoverageName}
                                    />
                                </label>
                                <button
                                    className={'btn btn-dark-blue'}
                                    type={"submit"}
                                    style={{maxHeight: '45px'}}
                                >
                                    Добавить
                                </button>
                            </div>
                        </form>
                        <br/>
                        <br/>
                        <br/>
                        <div>
                            <h3>Список имеющихся покрытий</h3>
                            <br/>
                            <div className="inner-page">

                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th>id</th>
                                        <th>Название</th>
                                        <th>Редактировать</th>
                                        <th>Удаить</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {allCoverage?.map((item, index) => {
                                        return (
                                            <GuideItem
                                                key={item.id}
                                                item={item}
                                                handleDelete={handleDeleteCoverage}
                                                editItem={editCoverage}
                                                getAll={getAllCoverage}
                                            />
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
);
}

export default Coverage;