import axios from "axios";

let API_URL = "/v1/applications/";

const approveApplicationOrderServiceByManager = async ({currentApplicationId,currentApplicationOrderId,data}) => {
    const response = await axios.put(API_URL + `${currentApplicationId}/orders/${currentApplicationOrderId}`, data );
    return response.status;
};
const sendApplicationOrderServiceToWork = async ({currentApplicationId,currentApplicationOrderId}) => {
    const response = await axios.get(API_URL + `${currentApplicationId}/orders/${currentApplicationOrderId}/start`);
    return response.status;
};
const toggleCompleteApplicationService = async ({currentApplicationId,currentApplicationOrderId,currentApplicationOrderServiceId}) =>{
    const response = await axios.get(API_URL + `${currentApplicationId}/orders/${currentApplicationOrderId}/toggle-complete/${currentApplicationOrderServiceId}`)
    return response.status
}
const changePriceInServiceByManager = async ({currentApplicationId, currentApplicationOrderId, currentApplicationOrderServiceId, data}) =>{
    const response = await axios.put(API_URL + `${currentApplicationId}/orders/${currentApplicationOrderId}/update-price/${currentApplicationOrderServiceId}`, data)
    return response.status
}
const applicationServiceOrderMangerApproveService = {
    approveApplicationOrderServiceByManager,
    toggleCompleteApplicationService,
    changePriceInServiceByManager,
    sendApplicationOrderServiceToWork
}
export default applicationServiceOrderMangerApproveService
