import axios from "axios";

const API_CHOOSE_PARKING = '/v1/choose-parkings'

const addParkingListToPartner = async (data) =>{
    const response = await axios.put(API_CHOOSE_PARKING, data)
    return response.status
}
const addParkingListToPartnerService = {
    addParkingListToPartner
}
export default addParkingListToPartnerService