import axios from "axios";

// Application All LiST
let API_URL = "/v1/application/favorite/";

const applicationFavority = async (id) => {
  const response = await axios.get(API_URL + id);
  return { data: response.data, status: response.status };
};

const applicationFavorityService = {
  applicationFavority,
};

export default applicationFavorityService;
