import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import partnersService from "../partnersService";

const initialState = {
  partnerModal: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//  получаем данные о partner используя api v1/partner/get-modal-users-content
export const partnerModalInfo = createAsyncThunk(
  "partners/partnerModalInfo",
  async ( id ,thunkAPI) => {
    try {
      const response = await partnersService.partnerGetInfo(id);
      if(!response){
        throw new Error ('Server error!');
      }
      return response;
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

const partnerModalInfoSlice = createSlice({
  name: "partnerModalInfo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(partnerModalInfo.pending, (state) => {
        state.isLoading = true;
        state.message = 'pending'
      })
      .addCase(partnerModalInfo.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.partnerModal = action.payload;
      })
      .addCase(partnerModalInfo.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.partnerModal = null;
      });
  },
});

export default partnerModalInfoSlice.reducer;
