import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import applicationEditService from "../applicationsEditService";

const initialState = {
  applicationsData: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Application edit list
export const applicationsEditGet = createAsyncThunk(
  "applications/applicationsEditGet",
  async (id, thunkAPI) => {
    try {
      return await applicationEditService.applicationEditGet(id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Application put list
export const applicationsEditPut = createAsyncThunk(
  "applications/applicationsEditPut",
  async ({ id, data, role }, thunkAPI) => {
    try {
      return await applicationEditService.applicationEditPut(id, data, role);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const applicationsEditDataSlice = createSlice({
  name: "applicationsEditGet",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(applicationsEditGet.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(applicationsEditGet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.applicationsEdit = action.payload;
      })
      .addCase(applicationsEditGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.applicationsEdit = null;
      });
  },
});

export default applicationsEditDataSlice.reducer;
