import axios from "axios";

let API_URL = '/v1/logs'

const getAllLogs = async (paginationPage)=>{
    const response = await  axios.get(`${API_URL}?page=${paginationPage}`)
    return{data:response.data[0], status:response.status, pagination:response.data[1]}
}
const logsService = {
    getAllLogs
}
export default logsService