import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { partnerResultAccepted } from "../../../store/partners/partner-offer-accepted/partner-offer-accepted";

function OfferAcceptBtn() {
  const user = JSON.parse(localStorage.getItem("web_vinz"));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    dispatch(partnerResultAccepted(user.id)).then((response) => {
      if (response.payload.status == 200) {
        // Получаем объект из localStorage
        const authDataString = localStorage.getItem("web_vinz");
        const authData = JSON.parse(authDataString);

        // Вносим изменения в объекте (например, замена элемента)
        authData.terms_accepted = 1;

        // Сохраняем обновленный объект обратно в localStorage
        localStorage.setItem("web_vinz", JSON.stringify(authData));

        window.location.replace("/applications");
      }
    });
  };

  return (
    <div onClick={handleClick} class="btn btn-success">
      Принимаю
    </div>
  );
}

export default OfferAcceptBtn;
