import AuthForm from "../../components/auth/auth-form";

const Register = () => {
  return (
    <AuthForm title="Регистрация">
      <div className="form-group row mb-4">
        <label className="col-md-4 col-form-label text-md-right">Имя</label>

        <div className="col-md-6">
          <input
            id="text"
            type="text"
            className="form-control "
            name="text"
            value=""
            required
            autocomplete="text"
            autofocus
          />

          <span className="invalid-feedback" role="alert">
            <strong>Ошибка</strong>
          </span>
        </div>
      </div>
      <div className="form-group row mb-4">
        <label className="col-md-4 col-form-label text-md-right">Email</label>

        <div className="col-md-6">
          <input
            id="email"
            type="email"
            className="form-control "
            name="email"
            value=""
            required
            autocomplete="email"
            autofocus
          />

          <span className="invalid-feedback" role="alert">
            <strong>Ошибка</strong>
          </span>
        </div>
      </div>

      <div className="form-group row mb-4">
        <label className="col-md-4 col-form-label text-md-right">Пароль</label>

        <div className="col-md-6">
          <input
            id="password"
            type="password"
            className="form-control @error('password') is-invalid @enderror"
            name="password"
            required
            autocomplete="current-password"
          />

          <span className="invalid-feedback" role="alert">
            <strong>Ошибка</strong>
          </span>
        </div>
      </div>

      <div className="form-group row mb-4">
        <label className="col-md-4 col-form-label text-md-right">
          Подтверждение пароля
        </label>

        <div className="col-md-6">
          <input
            id="password"
            type="password"
            className="form-control @error('password') is-invalid @enderror"
            name="password"
            required
            autocomplete="current-password"
          />

          <span className="invalid-feedback" role="alert">
            <strong>Ошибка</strong>
          </span>
        </div>
      </div>

      <div className="form-group row mb-4">
        <div className="col-md-6 offset-md-4">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              name="remember"
              id="remember"
            />

            <label className="form-check-label" for="remember">
              Запомнить меня
            </label>
          </div>
        </div>
      </div>

      <div className="form-group row mb-0">
        <div className="col-md-8 offset-md-4">
          <button type="submit" className="btn btn-primary">
            Войти
          </button>

          <a className="btn btn-link" href="{{ route('password.request') }}">
            Забыли пароль
          </a>
        </div>
      </div>
    </AuthForm>
  );
};

export default Register;
