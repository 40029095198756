import React, { useEffect } from "react";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { selectArray } from "../../../../utils/select-array";
import { setApplicationDataSlice } from "../../../../store/applications/applications-create/application-create-collect-data/application-create-collect-data";
import { useSelector } from "react-redux";
import {Link} from "react-router-dom";

function ApplicatonCrtUptHeader({
  title,
  tab,
  tabfunc,
  status,
  styles,
  token,
  disabledBtn,
}) {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    let defaultStatusToken = { status_id: 2, _token: token };
    if (user.role === "Operator" || user.role === "Partner") {
      defaultStatusToken = { status_id: 7, _token: token };
    }
    dispatch(setApplicationDataSlice(defaultStatusToken));
  }, [token, tab]);

  const statusSelect = selectArray(status, "status_id");
  const handleInputChange = (event) => {
    const { name, value } = event.target ? event.target : event;
    dispatch(setApplicationDataSlice({ [name]: value }));
  };

  // Статус по умолчанию в зависимости от роли пользователя
  let statusDefaultCreateAuto = "";
  if (user.role === "Operator") {
    statusDefaultCreateAuto = statusSelect[0];
  } else if (user.role === "Manager") {
    statusDefaultCreateAuto = statusSelect[6];
  } else if (user.role === "SuperAdmin") {
    statusDefaultCreateAuto = statusSelect[7];
  } else if (user.role === "Partner"){
    statusDefaultCreateAuto = statusSelect[0];
  } else {
    statusDefaultCreateAuto = statusSelect[7];
  }
  return (
    <div className="container page-head-wrap">
      <div className="page-head">
        <div className="page-head__top d-flex align-items-center">
          <h1>{title}</h1>
          <div className="ms-auto d-flex">
            {user.role !== "Partner" && tab === 0 ?
            <Link to='/applications/import' className={'import-link'}>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 9.33337C13.8232 9.33337 13.6536 9.40361 13.5286 9.52864C13.4036 9.65366 13.3333 9.82323 13.3333 10V12.6667C13.3333 12.8435 13.2631 13.0131 13.1381 13.1381C13.013 13.2631 12.8435 13.3334 12.6667 13.3334H3.33333C3.15652 13.3334 2.98695 13.2631 2.86193 13.1381C2.73691 13.0131 2.66667 12.8435 2.66667 12.6667V10C2.66667 9.82323 2.59643 9.65366 2.47141 9.52864C2.34638 9.40361 2.17681 9.33337 2 9.33337C1.82319 9.33337 1.65362 9.40361 1.5286 9.52864C1.40357 9.65366 1.33333 9.82323 1.33333 10V12.6667C1.33333 13.1971 1.54405 13.7058 1.91912 14.0809C2.29419 14.456 2.8029 14.6667 3.33333 14.6667H12.6667C13.1971 14.6667 13.7058 14.456 14.0809 14.0809C14.456 13.7058 14.6667 13.1971 14.6667 12.6667V10C14.6667 9.82323 14.5964 9.65366 14.4714 9.52864C14.3464 9.40361 14.1768 9.33337 14 9.33337ZM7.52667 10.4734C7.59007 10.5341 7.66483 10.5816 7.74667 10.6134C7.82647 10.6486 7.91275 10.6669 8 10.6669C8.08725 10.6669 8.17353 10.6486 8.25333 10.6134C8.33517 10.5816 8.40993 10.5341 8.47333 10.4734L11.14 7.80671C11.2655 7.68117 11.3361 7.51091 11.3361 7.33337C11.3361 7.15584 11.2655 6.98558 11.14 6.86004C11.0145 6.7345 10.8442 6.66398 10.6667 6.66398C10.4891 6.66398 10.3189 6.7345 10.1933 6.86004L8.66667 8.39337V2.00004C8.66667 1.82323 8.59643 1.65366 8.4714 1.52864C8.34638 1.40361 8.17681 1.33337 8 1.33337C7.82319 1.33337 7.65362 1.40361 7.5286 1.52864C7.40357 1.65366 7.33333 1.82323 7.33333 2.00004V8.39337L5.80667 6.86004C5.74451 6.79788 5.67071 6.74857 5.5895 6.71493C5.50829 6.68129 5.42124 6.66398 5.33333 6.66398C5.24543 6.66398 5.15838 6.68129 5.07717 6.71493C4.99595 6.74857 4.92216 6.79788 4.86 6.86004C4.79784 6.9222 4.74853 6.99599 4.71489 7.07721C4.68125 7.15842 4.66394 7.24547 4.66394 7.33337C4.66394 7.42128 4.68125 7.50833 4.71489 7.58954C4.74853 7.67075 4.79784 7.74455 4.86 7.80671L7.52667 10.4734Z" fill="white"/>
              </svg>
              Массовая загрузка
            </Link> : ''
            }
            <label className="field-style select-react__style">
              <span className="field-style-title">Статус</span>
              {status && (
                <Select
                  defaultValue={statusDefaultCreateAuto}
                  options={statusSelect}
                  styles={styles}
                  onChange={handleInputChange}
                />
              )}
            </label>

            {tab === 1 && (
              <>
                <button
                  className="btn btn-white mr-2"
                  type="button"
                  id="tabPrev"
                  onClick={() => tabfunc((prev) => prev - 1)}
                >
                  Назад
                </button>

                <button
                  type="submit"
                  className="btn btn-white"
                  disabled={disabledBtn}
                >
                  Создать заявку
                </button>
              </>
            )}

            {tab === 0 && (
              <button
                className="btn btn-white"
                type="button"
                id="tabNext"
                disabled={tab === 1}
                onClick={() => tabfunc((prev) => prev + 1)}
              >
                Далее
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplicatonCrtUptHeader;
