const AuthForm = ({ title, children, onSubmit }) => {
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-5">
          <div className="card ">
            <div className="card-header">{title}</div>
            <div className="card-body">
              <form method="POST" onSubmit={onSubmit}>
                {children}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthForm;
