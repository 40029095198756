import React from "react";

function ParnterAllItem({ item, index }) {
  return (
    <>
      <tr>
        <td className="tr-id">{index + 1}</td>
        <td>{item.partner.shortname}</td>
        <td>{item.parking.title}</td>
        <td>{item.arrived}</td>
        <td>{item.issued}</td>
        <td>{item.total_days}</td>
      </tr>
    </>
  );
}

export default ParnterAllItem;
