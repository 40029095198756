import React from "react";
import MyModal from "../modal/modal";
import { useSelector } from "react-redux";
import OfferText from "./offer-text/offer-text";
import Logout from "../logout/logout";
import OfferAcceptBtn from "./accept-btn/offer-accept-btn";

function ModalOfferPartner() {
  const { user } = useSelector((state) => state.auth);
  // Условие для вывода модального окна
  const adminPartneRoles =
    user && (user.role === "Partner" || user.role === "Admin");
  const termsAccepted = user?.terms_accepted === 0;
  const enableModal = adminPartneRoles && termsAccepted;

  return (
    <>
      {enableModal && (
        <MyModal isOpen={enableModal ? "false" : "true"}>
          <OfferText />
          <div class="modal-footer d-flex justify-content-between">
            <Logout>
              <div class="btn btn-danger">Не принимать</div>
            </Logout>
            <OfferAcceptBtn />
          </div>
        </MyModal>
      )}
    </>
  );
}

export default ModalOfferPartner;
