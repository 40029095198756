import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import deleteParkingFromPartnerService from "./deleteParkingFromPartnerService";

const initialState = {
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};

// add Parking list
export const removeParkingListFromPartner = createAsyncThunk("removeParkingsToPartner/removeParkingList", async (data,thunkAPI) => {
    try {
        return await deleteParkingFromPartnerService.removeParkingListFromPartner(data);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data) ||
            error.message ||
            error.toSrting();
        return thunkAPI.rejectWithValue(message);
    }
});
//
const removeParkingListSlice = createSlice({
    name: "removeParkingsList",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(removeParkingListFromPartner.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(removeParkingListFromPartner.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.message = action.payload;
            })
            .addCase(removeParkingListFromPartner.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
    },
});

export default removeParkingListSlice.reducer;