import axios from "axios";

// Users All LiST
let API_URL = "/v1/users";

// Список USer/
const users = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};
//

// Создание пользователя Users
const usersCreate = async () => {
  const response = await axios.get(API_URL + "/create");
  return response.data;
};

const usersCreateData = async (data) => {
  const response = await axios.post(API_URL, data);
  return response.data;
};
//

// Редактирование данных USERS
const usersEditData = async (id) => {
  const response = await axios.get(API_URL + "/" + id + "/edit");
  return response.data;
};

const usersEditPut = async (id, data) => {
  const response = await axios.put(API_URL + "/" + id, data);
  return response.data;
};
//

// Удаление пользователей USERS
const usersDelete = async (id) => {
  const response = await axios.delete(API_URL + "/" + id);
  if (response) {
    return id;
  } else {
    return { data: response.data, status: response.status };
  }
};
//

// Привязанные парковки к пользователям
const usersParking = async (id) => {
  const response = await axios.get(API_URL + "/" + id + "/parking/all");
  return response.data;
};
//

// Редактирование подменеджеров
const userChildEdit = async ({ id, childId }) => {
  const response = await axios.get(
    API_URL + "/" + id + "/children/" + childId + "/edit"
  );
  return response.data;
};
//
//Редактирование подменеджеров PUT

const usersChildEditPut = async ({ id: id, childId: child, data: data }) => {

  const response = await axios.put( API_URL + "/" + id + "/children/" + child,  data.child);
  return response.data;
};

//
const usersService = {
  users,
  usersCreate,
  usersCreateData,
  usersEditData,
  usersDelete,
  usersParking,
  usersEditPut,
  userChildEdit,
  usersChildEditPut,
};

export default usersService;


