import axios from "axios";

const API_URL = "/v1/applications";
// Загрузка изображение при создание машины
const uploadFile = async (id, data) => {
  const response = await axios.post(API_URL + "/upload/" + id, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};
//

const uploadFileService = {
  uploadFile,
};

export default uploadFileService;
