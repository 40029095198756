import React, { useEffect } from "react";
import PartnerAllForm from "./form/form";
import ParnterAllItem from "./item/item";
import { useDispatch, useSelector } from "react-redux";
import {
  reportsAllPartnerData,
  reportsByPartnerAllLink,
} from "../../../store/reports/reportByPartnerSliceAll/reportsByPartnerSliceAll";

function ReportPartnerAll() {
  const { reportsAllPartner, link } = useSelector(
    (state) => state.reportsPartnersAll
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reportsAllPartnerData());
    dispatch(reportsByPartnerAllLink());
  }, [dispatch]);

  const handleChangeAllPartnerData = (data) => {
    dispatch(reportsAllPartnerData(data));
    dispatch(reportsByPartnerAllLink(data));
  };

  return (
    <>
      {reportsAllPartner && reportsAllPartner.length !== 0 && (
        <>
          <PartnerAllForm
            link={link}
            reportsAllPartner={reportsAllPartner}
            handleChangeAllPartnerData={handleChangeAllPartnerData}
          />

          <div className="container">
            <div className="inner-page">
              <table className="table fs-13">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Заказчик</th>
                    <th>Стоянка</th>
                    <th>Хранится</th>
                    <th>Выдано</th>
                    <th>Всего дней</th>
                  </tr>
                </thead>
                <tbody>
                  {reportsAllPartner.data.data.map((item, index) => {
                    return (
                      <ParnterAllItem item={item} index={index} key={index} />
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default ReportPartnerAll;
