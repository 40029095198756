import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import chatServices from "./chatServices";

const initialState = {
    chatMessage : '',
    isError : false,
    isLoading : false,
    isSuccess : false,
    responseMessage : ''
}
export const sendMessageToRoomChat = createAsyncThunk (
    'chatRoom/send',
    async ({chatRoom,content}, thunkAPI)=>{
    try {
        return await chatServices.sendMessageToRoomChat({chatRoom,content})
    }catch (error){
        const message =
            (error.response && error.response.data && error.response.data) ||
            error.message ||
            error.toSrting();
        return thunkAPI.rejectWithValue(message);
    }
})

export const sendMessageAndCreateRoomChat = createAsyncThunk (
    'chatCreateRoom/send',
    async ({model, modelID, message}, thunkAPI)=>{
        try {
            return await chatServices.sendMessageAndCreateRoom({model, modelID, message})
        }catch (error){
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
})
export const getMessagesFromChat = createAsyncThunk (
    'chatCreateRoom/send',
    async ({applicationId}, thunkAPI)=>{
        try {
            return await chatServices.getDataFromChat({applicationId})
        }catch (error){
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
})

const chatSlice = createSlice({
    name :'chat',
    initialState,
    reducers : {},
    extraReducers : (builder) =>{
        builder
            .addCase(sendMessageToRoomChat.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(sendMessageToRoomChat.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.chatMessage = action.payload.message;
                state.responseMessage = action.payload;
            })
            .addCase(sendMessageToRoomChat.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.responseMessage = action.payload;
            })
    }

})

export default chatSlice.reducer


