import axios from "axios";

const API_URL = "/v1/applications/";

// single application

const destroy = async (id, text) => {
  const response = await axios.delete(API_URL + id, text);
  return response.data;
};

const applicationDeleteService = {
  destroy,
};

export default applicationDeleteService;
