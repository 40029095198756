import React from "react";

function ReportsEmployeeItem({ item, index }) {
  return (
    <>
      <tr>
        <td>{index + 1}</td>
        <td>{item.name}</td>
        <td>{item.accepted_number}</td>
        <td>{item.reviewed_number}</td>
        <td>{item.issued_number}</td>
      </tr>
    </>
  );
}

export default ReportsEmployeeItem;
