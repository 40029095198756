import React from 'react';

function OrderItem({item, index}) {
    return (
        <>
            <tr className="car-show-modal">
                <td>{item.numeration}</td>
                <td>{item.partner}</td>
                <td>{item.parking}</td>
                <td>{item.car_mark}</td>
                <td>{item.car_model}</td>
                <td>{item.vin}</td>
                <td>{item.license_plate}</td>
                <td>{item.order_number}</td>
                <td>{item.order_date}</td>
                <td>{item.status}</td>
                <td>{item.service}</td>
                <td>{item.completed_at}</td>
                <td>{item.price}</td>

            </tr>
        </>
    );
}

export default OrderItem;