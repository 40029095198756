import axios from "axios";

const API_URL = "/v1/applications/";

// Application All LiST

const application = async () => {
  const response = await axios.get(API_URL + "create");
  return response.data;
};

const getApplicationCarTypes = async (data) => {
  const response = await axios.post(API_URL + "choose-cartypes", data);
  return response.data;
};
const applicationCreateService = {
  application,
  getApplicationCarTypes
};

export default applicationCreateService;
