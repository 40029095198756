import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import partnersService from "../partnersService";

const initialState = {
  partnersEdit: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// partners Info
export const editPartners = createAsyncThunk(
  "partners/partnersEdit",
  async (id, thunkAPI) => {
    try {
      return await partnersService.partnerEditData(id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

// partners EDIT Post Data
export const partnersEditPut = createAsyncThunk(
  "partners/partnersEditPut",
  async ({ id, data }, thunkAPI) => {

    try {
      return await partnersService.partnerEditPut(id, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

// partners Add in general list
export const partnerAdd = createAsyncThunk(
  "partners/partnerAdd",
  async ({ id, user_id }, thunkAPI) => {
    try {
      return await partnersService.partnerAdd(id, user_id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

// partners remove in general list
export const partnerRemove = createAsyncThunk(
  "partners/partnerRemove",
  async ({ id, user_id }, thunkAPI) => {
    try {
      return await partnersService.partnerRemove(id, user_id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

const partnersEditSlice = createSlice({
  name: "partnersEditSlice",
  initialState,
  reducers: {
    editPartnerReset: (state) => {
      state.partnersEdit = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editPartners.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editPartners.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.partnersEdit = action.payload;
      })
      .addCase(editPartners.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.partnersEdit = null;
      });
  },
});
export const { editPartnerReset } = partnersEditSlice.actions;

export default partnersEditSlice.reducer;
