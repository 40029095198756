import React from "react";
import { useSelector } from "react-redux";

function ModerationButton({ handleChange, moderationChecked = "" }) {
  const { user } = useSelector((state) => state.auth);
  const isSuperAdminRole = user.role === "SuperAdmin";
  return (
    isSuperAdminRole && (
      <div className="col-6">
        <label className="switch-radio-wrap mt-11px">
          <input
            type="checkbox"
            name="moderation"
            defaultChecked={moderationChecked}
            onChange={handleChange}
          />
          <span className="switcher-radio"></span>
          <span>Модерация</span>
        </label>
      </div>
    )
  );
}

export default ModerationButton;
