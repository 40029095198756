import axios from "axios";

const API_ALL_PARKINGS = '/v1/all-parkings'


const getAllParkings = async () => {
    const response = await axios.get(API_ALL_PARKINGS  );
    return response.data;
};

const allParkings = {
    getAllParkings
}

export default allParkings