import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import partnersService from "../partnersService";

const initialState = {
    partnerActiveInfo: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};

export const partnerToggleActive = createAsyncThunk(
    'partners/partnerToggleActive',
    async ({partnerId, userId}, thunkAPI) =>{
        try {
            return await partnersService.partnerToggleActiveStatus( partnerId, userId)
        }catch (error){
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
)

const partnerToggleActiveSlice = createSlice({
    name: "partnerToggleActive",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(partnerToggleActive.pending, (state) => {
                state.isLoading = true;
                state.message = 'pending'
            })
            .addCase(partnerToggleActive.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.partnerActiveInfo = action.payload;
            })
            .addCase(partnerToggleActive.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.partnerActiveInfo = null;
            });
    },
});

export default partnerToggleActiveSlice.reducer;