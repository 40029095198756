import React, {useEffect, useState} from 'react';
import MyModal from "../modal/modal";
import {Clusterer, Map, ObjectManager, Placemark, YMaps} from "@pbe/react-yandex-maps";
import {Accordion} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {parkingById, parkingsData} from "../../store/parkings/parkingsSlice";
import {useLocation} from "react-router-dom";
import {addAllParkingListToPartner} from "../../store/parkings/add-parking-list-to-partner/addParkingListToPartnerSlice";
import swal from "sweetalert";
import {getAllParkingsData} from "../../store/parkings/get-all-parkings/getAllParkingsSlice";
import ApplicationSliderImage
    from "../applications/applications-index/applications-list/application-slider-image/applications-slider-image";
import ModalImg from "../modal-img/modalImg";
import ImageApplicationShow from "../image-upload/image-application-show/image-application-show";


function ModalParkingMap(
    {
        isModalOpen,
        closeModal,
        allParkingsList,
        handleInputChange,
        selectOptionById,
        openServicesModal,
        closeServicesModal,
        setFilteredData
    }) {

    const parking = useSelector((state) => state.parkings);
    const location = useLocation();
    const dispatch = useDispatch();
    const [selectOneParking, setSelectOneParking] = useState(false)
    const mapState = {
        center: [55.75, 37.57],
        zoom: 5,
    };
    const [filteredArray, setFilteredArray] = useState([]);
    const [addedParkingList, setAddedParkingList] = useState([]);

    const handlePlacemarkClick = (event) => {
        const objectId = event.get('objectId');
        const clickedFeature = objectManagerFeatures.find(feature => feature.id === objectId);
        if (clickedFeature) {
            setSelectOneParking(true)
            const clickedProperties = clickedFeature.properties;
            dispatch(parkingById(clickedProperties.id))
            handleButtonClickOpen(clickedProperties.id)
        }
    };
    const displayAllParkings = () => {
        selectOneParking ? setSelectOneParking(false) : setSelectOneParking(true)
    }
    const handleSelectParking = (data) =>{
        handleInputChange(data);
        selectOptionById(data.value)
        closeModal();
    }
    const addParkingToPartner = (parkId) =>{
        if (!addedParkingList.includes(parkId)) {
            setAddedParkingList([...addedParkingList, parkId]);
        } else {
            // Value already exists in the array, handle accordingly
            console.log("Parking ID already exists in the list.");
        }
    }
    const removeParkingFromPartner = (parkId) => {
        const updatedList = addedParkingList.filter(id => id !== parkId);
        setAddedParkingList(updatedList);
    }
    useEffect(()=>{
        const result = allParkingsList.parkings.filter(obj => obj.status === 1);
        setFilteredArray(result);
    },[allParkingsList.parkings])
    const placemarksData = filteredArray.map((parking, index) => {
        const coordinates = parking.coordinates?.split(',').map(coord => parseFloat(coord.trim()));
        const title = parking.title;
        return {
            geometry: coordinates  ? coordinates : '55.75, 37.57',
            properties: {
                iconContent: title,
                id: parking.id
            }
        };
    });
    const [activeKey, setActiveKey] = useState(null);
    const handleButtonClick = (eventKey) => {
        setActiveKey(null);
    };
    const handleButtonClickOpen = (eventKey) => {
        setActiveKey(eventKey);
    };
    const handleButtonClickToggle = (eventKey) => {
        activeKey === null ? setActiveKey(eventKey) :setActiveKey(null) ;
    };
    const addToPartnerList = () =>{
        const data = {parking_ids : addedParkingList};
        dispatch(addAllParkingListToPartner(data)).then((response)=>{
           if (response.payload === 200){
               swal("Парковка добавлена успешно", {
                   icon: "success",
               })
               dispatch(parkingsData()).then((response)=>{
                   setFilteredData(response.payload.parkings)
               });
               setAddedParkingList([])
               closeModal()
           }else{
               swal("Ошибка при добавлении парковок", {
                   icon: "error",
               })
           }

        })
    }
    const objectManagerFeatures = placemarksData.map((placemark, index) => ({
        type: 'Feature',
        id: index,
        geometry: {
            type: 'Point',
            coordinates: placemark.geometry,
        },
        properties: {
            // iconContent: selectedTitle === placemark.properties.iconContent ? placemark.properties.iconContent : '',
            balloonContent: placemark.properties.iconContent,
            hintContent: placemark.properties.iconContent,
            id: placemark.properties.id
        },
        options: {
            preset: 'islands#greenDotIcon',
        },
    }));
    const saveButtonDivStyles = {
        display:'flex',
        position:'fixed',
        bottom: 0,
        justifyContent:'center',
        alignItems:'center',
        width:'100%',
        maxWidth:'370px',
        zIndex:5
    }
    const parkingSearchHandler = (e) =>{
        const value = e.target.value.toLowerCase()

        if (value){
            const filteredDataArr = filteredArray.filter((item) => item.title.toLowerCase().includes(value))
            setFilteredArray(filteredDataArr)
        }else{
            const result = allParkingsList.parkings.filter(obj => obj.status === 1);
            setFilteredArray(result);
        }
    }
    return (
        <MyModal isOpen={isModalOpen} onClose={closeModal} ariaHideApp={false}>
            <div
                // style={{width: '1100px',transform:'translate(-10%, -2px)'}}
                 className="fade bd-example-modal-lg modal-custom show map-modal__overlay"
                 id="exampleModalCenter" tabIndex="-1"
                 role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div
                    // style={{width: '1100px'}}
                    className="modal-dialog modal-dialog-centered modal-lg" role="document"
                     // style={{margin: '0 auto'}}
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Список стоянок</h5>
                            <button type="button" onClick={closeModal} className="close" id="destroyButton"
                                    data-dismiss="modal"
                                    aria-label="Close">
                                <svg width="23" height="22" viewBox="0 0 23 22" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <g id="&#208;&#152;&#208;&#186;&#208;&#190;&#208;&#189;&#208;&#186;&#208;&#176;">
                                        <path id="Icon"
                                              d="M5.07219 4.85247C5.44645 4.49449 6.05323 4.49449 6.42748 4.85247L11.4998 9.70429L16.5722 4.85247C16.9464 4.49449 17.5532 4.49449 17.9275 4.85247C18.3017 5.21045 18.3017 5.79085 17.9275 6.14883L12.8551 11.0007L17.9275 15.8525C18.3017 16.2105 18.3017 16.7909 17.9275 17.1488C17.5532 17.5068 16.9464 17.5068 16.5722 17.1488L11.4998 12.297L6.42748 17.1488C6.05323 17.5068 5.44645 17.5068 5.07219 17.1488C4.69794 16.7909 4.69794 16.2105 5.07219 15.8525L10.1445 11.0007L5.07219 6.14883C4.69794 5.79085 4.69794 5.21045 5.07219 4.85247Z"
                                              fill="#D0D0D0"/>
                                    </g>
                                </svg>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="d-flex">
                                <div className="modal__map">
                                    <YMaps>
                                        <div>
                                            <Map
                                                defaultState={mapState}
                                                style={{
                                                    width: '100%',
                                                    maxWidth: '700px',
                                                    height: '700px',
                                                    borderRadius: '0'
                                                }}
                                            >
                                                <ObjectManager
                                                    options={{
                                                        clusterize: true,
                                                        gridSize: 32,
                                                    }}
                                                    objects={{
                                                        openBalloonOnClick: true,
                                                    }}
                                                    clusters={{
                                                        preset: 'islands#redClusterIcons',
                                                    }}
                                                    defaultFeatures={objectManagerFeatures}
                                                    modules={[
                                                        'objectManager.addon.objectsBalloon',
                                                        'objectManager.addon.objectsHint',
                                                    ]}
                                                    onClick={(event) => handlePlacemarkClick(event)}
                                                />
                                            </Map>
                                        </div>
                                    </YMaps>
                                </div>
                                <div className="modal__sidebar" style={{minWidth: '400px'}}>
                                    {addedParkingList.length > 0 ?
                                        <div className="save-parking-list"
                                             style={saveButtonDivStyles}
                                        >
                                            <button
                                                className={'hover-color'}
                                                onClick={
                                                    ()=>{addToPartnerList()}
                                                }
                                                style={
                                                    {
                                                        background:'#008000bf',
                                                        padding:'10px 15px',
                                                        color:'#fff',
                                                        border:'none',
                                                        width:'100%'
                                                    }
                                                }
                                            >
                                                сохранить стоянки ({addedParkingList.length})
                                            </button>
                                        </div> : ''
                                    }
                                    {!selectOneParking && <input type="text" defaultValue={''} onChange={parkingSearchHandler} className={'map-modal__search'} placeholder={'Поиск'}/> }
                                    <Accordion
                                        activeKey={activeKey}
                                    >
                                        {!selectOneParking ?
                                            filteredArray?.map((item, index) => {
                                                return (
                                                    <Accordion.Item
                                                        className="modal__card" id="point-1"
                                                        eventKey={index}
                                                        key={index}
                                                        onHide={() => handleButtonClick(index)}>
                                                        <Accordion.Header
                                                            className="modal__item d-flex"
                                                            onClick={() => {
                                                                handleButtonClickToggle(index)
                                                            }}

                                                        >
                                                            {item.attachments ?
                                                                < img src={item.attachments[0]?.url} alt=""/>
                                                                :
                                                                < img src='img' alt=""/>
                                                            }
                                                            <div className="modal__info d-flex flex-column">
                                                                <h3>{item.title}</h3>
                                                                <span
                                                                    className="modal__info-subtitle">{item.address}</span>
                                                                <div className="d-flex justify-content-between mt-auto">
                                                                    <span className="modal__info-price">{item.passenger_car_storage_price.cost === null ? "не указана" : `${item.passenger_car_storage_price.cost} руб./сутки`}</span>
                                                                    <button className="btn-link collapsed"
                                                                            onClick={() => {
                                                                                handleButtonClickOpen(index)
                                                                            }}>
                                                                        подробнее
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="modal__item-dd">
                                                                <div className="modal__col">Кол-во машиномест
                                                                    <p>{item.qty_parking === null ? 0 : item.qty_parking}</p>
                                                                </div>
                                                                <div className="d-flex flex-wrap">
                                                                    <div className="modal__col">Крытые места
                                                                        <p>{item.covered_parking === null || item.covered_parking === 0 ? 'Нет' : 'Есть'} </p>
                                                                    </div>
                                                                    <div className="modal__col">Отапливаемые места
                                                                        <p>{item.heated_parking === null || item.heated_parking === 0 ? 'Нет' : 'Есть'}</p>
                                                                    </div>
                                                                    <div className="modal__col">Покрытие
                                                                        <p>{item.parking_surface === null ? 'Не указано' : item.parking_surface.name}</p>
                                                                    </div>
                                                                    <div className="modal__col">Ограждение
                                                                        <p>{item.parking_fence === null ? 'Не указано' : item.parking_fence?.name}</p>
                                                                    </div>
                                                                    <div className="modal__col">Видеонаблюдение
                                                                        <p>{item.cctv === null || item.cctv === 0 ? 'Нет' : 'Есть'}</p>
                                                                    </div>
                                                                    <div className="modal__col">Клиентская зона
                                                                        <p>{item.client_zones === null || item.client_zones === 0 ? 'Нет' : 'Есть'}</p>
                                                                    </div>
                                                                </div>
                                                                <div className="modal__col">Схема проезда, общественный
                                                                    транспорт и
                                                                    пр.
                                                                    <p>{item.nav_to_trans === null ? 'Нет' : item.nav_to_trans}</p>
                                                                </div>
                                                                <div className="modal__img-gal d-flex flex-wrap">
                                                                    {item.attachments && item.attachments.length !== 0 ?
                                                                        <ImageApplicationShow oldimage={item.attachments}/> : ''
                                                                    }
                                                                </div>
                                                                <div className="d-flex justify-content-between mt-4">
                                                                    {
                                                                        location.pathname === '/parkings' ?

                                                                            !addedParkingList.includes(item.id) ?
                                                                                <button
                                                                                    className="btn btn-success set-parging"
                                                                                    onClick={()=>{
                                                                                        addParkingToPartner(item.id)
                                                                                    }}
                                                                                >
                                                                                    Выбрать стоянку
                                                                                </button>
                                                                                :
                                                                                <span
                                                                                    onClick={()=>{
                                                                                        removeParkingFromPartner(item.id)
                                                                                    }
                                                                                    }
                                                                                    style={
                                                                                        {
                                                                                            color:'red',
                                                                                            display:'block',
                                                                                            margin:'0 auto',
                                                                                            paddingTop:'6px',
                                                                                            cursor:'pointer'
                                                                                        }}
                                                                                >
                                                                                    Удалить из списка
                                                                                </span>

                                                                         :
                                                                            <button type="button"
                                                                                    className="btn btn-success set-parging"
                                                                                    onClick={()=>{
                                                                                        handleSelectParking({label: item.title, name: "parking_id", value: item.id})
                                                                                    }
                                                                                    }
                                                                                    data-name="Лужнецкая ТТК 1">Выбрать стоянку
                                                                            </button>
                                                                    }

                                                                    <button data-toggle="modal"
                                                                            data-target="#priceModal"
                                                                            onClick={() => openServicesModal(item.id, item.title)}
                                                                            className="btn btn-warning">Прайс-лист
                                                                    </button>
                                                                </div>
                                                                <button className="btn-link"
                                                                        onClick={() => handleButtonClick("0")}
                                                                >
                                                                    Скрыть подробности
                                                                </button>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                )
                                            }) :
                                            <Accordion.Item
                                                className="modal__card" id="point-1"
                                                eventKey={parking.parking.id}
                                                onHide={() => handleButtonClick(parking.parking.id)}>
                                                <Accordion.Header className="modal__item d-flex"
                                                    onClick={() => {
                                                        handleButtonClickToggle(parking.parking.id)
                                                    }}
                                                >
                                                    {
                                                        parking.parking.attachments?
                                                            <img src={parking.parking?.attachments[0]?.url} alt=""/> :
                                                            <img src={"parking.parking?.attachments[0]?.url"} alt=""/>
                                                    }

                                                    <div className="modal__info d-flex flex-column">
                                                        <h3>{parking.parking.title}</h3>
                                                        <span
                                                            className="modal__info-subtitle">{parking.parking.address}</span>
                                                        <div className="d-flex justify-content-between mt-auto">
                                                            {/*<span className="modal__info-price">От 500 руб./сутки</span>*/}
                                                            <button className="btn-link collapsed" onClick={() => {
                                                                handleButtonClickOpen(parking.parking.id)
                                                            }}>
                                                                подробнее
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="modal__item-dd">
                                                        <div className="modal__col">Кол-во машиномест
                                                            <p>{parking.parking.qty_parking === null ? 0 : parking.parking.qty_parking}</p>
                                                        </div>
                                                        <div className="d-flex flex-wrap">
                                                            <div className="modal__col">Крытые места
                                                                <p>{parking.parking.covered_parking === null  || parking.parking.covered_parking === 0? 'Нет' : 'Есть'} </p>
                                                            </div>
                                                            <div className="modal__col">Отапливаемые места
                                                                <p>{parking.parking.heated_parking === null || parking.parking.heated_parking === 0 ? 'Нет' : 'Есть'}</p>
                                                            </div>
                                                            <div className="modal__col">Покрытие
                                                                <p>{parking.parking.parking_surface === null ? 'Не указано' : parking.parking.parking_surface?.name}</p>
                                                            </div>
                                                            <div className="modal__col">Ограждение
                                                                <p>{parking.parking.parking_fence === null ? 'Не указано' : parking.parking.parking_fence?.name}</p>
                                                            </div>
                                                            <div className="modal__col">Видеонаблюдение
                                                                <p>{parking.parking.cctv === null || parking.parking.cctv === 0 ? 'Нет' : 'Есть'}</p>
                                                            </div>
                                                            <div className="modal__col">Клиентская зона
                                                                <p>{parking.parking.client_zones === null || parking.parking.client_zones === 0 ? 'Нет' : 'Есть'}</p>
                                                            </div>
                                                        </div>
                                                        <div className="modal__col">Схема проезда, общественный
                                                            транспорт и
                                                            пр.
                                                            <p>{parking.parking.nav_to_trans === null ? 'Нет' : parking.parking.nav_to_trans}</p>
                                                        </div>
                                                        <div className="modal__img-gal d-flex flex-wrap">
                                                            { parking.parking?.attachments && parking.parking?.attachments.length !== 0 ?
                                                                <ImageApplicationShow oldimage={parking.parking?.attachments}/> : ''
                                                            }
                                                        </div>
                                                        <div className="d-flex justify-content-between mt-4">

                                                            {
                                                                location.pathname === '/parkings' ?
                                                                    !addedParkingList.includes(parking.parking.id) ?
                                                                        <button
                                                                            className="btn btn-success set-parging"
                                                                            onClick={()=>{
                                                                                addParkingToPartner(parking.parking.id)
                                                                            }}
                                                                        >
                                                                            Выбрать стоянку
                                                                        </button>
                                                                        :
                                                                        <span
                                                                            onClick={()=>{
                                                                                removeParkingFromPartner(parking.parking.id)
                                                                            }
                                                                            }
                                                                            style={
                                                                                {
                                                                                    color:'red',
                                                                                    display:'block',
                                                                                    margin:'0 auto',
                                                                                    paddingTop:'6px',
                                                                                    cursor:'pointer'
                                                                                }}
                                                                        >
                                                                                    Удалить из списка
                                                                                </span>

                                                                     :
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-success set-parging"
                                                                        onClick={()=>{
                                                                            handleSelectParking({label: parking.parking.title, name: "parking_id", value: parking.parking.id})
                                                                        }
                                                                        }
                                                                    >
                                                                        Выбрать стоянку
                                                                    </button>
                                                            }


                                                            <button
                                                                data-toggle="modal"
                                                                data-target="#priceModal"
                                                                className="btn btn-warning"
                                                                onClick={() => openServicesModal(parking.parking.id, parking.parking.title)}
                                                            >
                                                                Прайс-лист
                                                            </button>
                                                        </div>
                                                        <button className="btn-link"
                                                                onClick={() => handleButtonClick(parking.parking.id)}
                                                        >
                                                            Скрыть подробности
                                                        </button>
                                                    </div>
                                                </Accordion.Body>

                                            </Accordion.Item>
                                        }
                                        {selectOneParking &&
                                        <span
                                            className="modal__info-subtitle"
                                            style={{
                                                cursor:'pointer',
                                                display:'flex',
                                                width:'100%',
                                                justifyContent:'center'
                                            }}
                                            onClick={()=>{
                                                displayAllParkings()
                                            }}
                                        >
                                            Показать все
                                        </span>
                                        }

                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </MyModal>
    );
}

export default ModalParkingMap;