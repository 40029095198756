import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import parkingsService from "../parkings/parkingsService";
import {parkingById, parkingDelete, parkingsData} from "../parkings/parkingsSlice";
import headerNotificationService from "./headerNotificationService";

const initialState = {
    notifications: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};


export const notificationData = createAsyncThunk(
    "notification/all", async (thunkAPI) => {
    try {
        return await headerNotificationService.headerNotification();
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data) ||
            error.message ||
            error.toSrting();
        return thunkAPI.rejectWithValue(message);
    }
});
export const notificationReade = createAsyncThunk(
    "notification/read", async (id,thunkAPI) => {
    try {
        return await headerNotificationService.headerNotificationRead(id);
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data) ||
            error.message ||
            error.toSrting();
        return thunkAPI.rejectWithValue(message);
    }
});
export const notificationReadeAll = createAsyncThunk(
    "notification/readAll", async ({chat},thunkAPI) => {
    try {
        return await headerNotificationService.headerNotificationReadAll({chat});
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data) ||
            error.message ||
            error.toSrting();
        return thunkAPI.rejectWithValue(message);
    }
});
//


const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(notificationData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(notificationData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.notifications = action.payload;
            })
            .addCase(notificationData.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })

            .addCase(notificationReade.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(notificationReade.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.message = action.payload;
            })
            .addCase(notificationReade.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
    },
});

export default notificationsSlice.reducer