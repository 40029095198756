import axios from "axios";

// Application All LiST
let API_URL = "/v1/application-filter";

const applicationFilter = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

const applicationFilterService = {
  applicationFilter,
};

export default applicationFilterService;
