import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {parkingsData} from "../../../store/parkings/parkingsSlice";
import ParkingsItem from "../../../components/parkings/parkings-item";
import ModalParkingMap from "../../../components/modal-parking-map/modal-parking-map";
import {getAllParkingsData} from "../../../store/parkings/get-all-parkings/getAllParkingsSlice";
import swal from "sweetalert";
import ModalParkingServices from "../../../components/modal-parking-services/modal-parking-services";
import {editParkingService} from "../../../store/parkings/parking-edit-services-slice/parkingEditServicesSlice";

function ParkingsIndex() {
  const { parkings, title } = useSelector((state) => state.parkings);
  const allParkingsList= useSelector((state) => state.parkingAll);
  const { user } = useSelector((state) => state.auth);
  const { single } = useSelector((state) => state.applicationSingle);
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const [isServicesModalOpen, setIsServicesModalOpen] = useState(false);
  const [currentServicesName, setCurrentServicesName] = useState('');
  const parkingServices = useSelector((state)=> state.parkingServices.parkingServices?.parking_services)
  const [filteredData, setFilteredData]= useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(parkingsData()).then((response)=>{
      setFilteredData(response.payload.parkings)
    });
  }, [dispatch]);

  useEffect(()=>{
    if (user.role === 'Partner') {
      dispatch(getAllParkingsData()).then((resp) => {
        if (resp.meta.requestStatus === "fulfilled") {
          const tempParkingList = {parkings: resp.payload, title: "availabeParkings"}
        }
      })
    }
    else{

    }
  },[dispatch])
  const openMapModal = () =>{
    dispatch(getAllParkingsData()).then((resp)=>{
    })
    setIsMapModalOpen(true);
  }
  const closeMapModal = () => {
    setIsMapModalOpen(false);
  };

  const openServicesModal = (id, name) =>{

    let partner_id = user.id
    dispatch(editParkingService({id, partner_id}));
    setCurrentServicesName(name)
    setIsServicesModalOpen(true);
  }
  const closeServicesModal = () => {
    setIsServicesModalOpen(false);
  };

  const searchParking =(e) => {
    const searchQuery = e.target.value;
    const filteredDataArr = searchFilter(searchQuery);
    setFilteredData(filteredDataArr)
  }
  const searchFilter = (query) => {
    return parkings?.filter((item) => item.title.toLowerCase().includes(query.toLowerCase()));
  };

  return (
    <>
      <div className="container page-head-wrap">
        <div className="page-head">
          <div className="page-head__top d-flex align-items-center">
            <h1>{title}</h1>
            <label className="field-style blue">
              <span>Поиск</span>
              <input
                  type="text"
                  id="keyword"
                  placeholder="Поиск по столбцам"
                  onChange={(e)=>{searchParking(e)}}
              />
            </label>
            <div className="ml-auto d-flex">
              {
                user.role === 'Partner' ?
                    <a onClick={()=>{
                      openMapModal()
                    }} className="btn btn-white"
                    style={{minWidth:'200px'}}
                    >
                      Добавить стоянку в мой список
                    </a>
                    :
              <Link to="/parkings/create" className="btn btn-white">
                Добавить
              </Link>
              }
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="inner-page">
          <table className="table" id="searchable">
            <thead>
              <tr>
                <th></th>
                <th>Название</th>
                <th>Регион</th>
                <th>Адрес</th>
                <th>Юр. лица</th>
                <th>Цены</th>
                {/*<th>Заказчики</th>*/}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {filteredData.length === 0 ?
                  parkings.map((item, index) => (
                  <ParkingsItem item={item} key={index} index={index} setFilteredData={setFilteredData} openServicesModal={openServicesModal} />
                )):
                  filteredData.map((item, index) => (
                      <ParkingsItem item={item} key={index} index={index} setFilteredData={setFilteredData} openServicesModal={openServicesModal} />
                  ))
              }
            </tbody>
          </table>
        </div>
      </div>

      <ModalParkingMap
          single={single}
          isModalOpen={isMapModalOpen}
          closeModal={closeMapModal}
          allParkingsList={allParkingsList}
          openServicesModal={openServicesModal}
          closeServicesModal={closeServicesModal}
          setFilteredData={setFilteredData}
      />
      <ModalParkingServices
          isModalOpen={isServicesModalOpen}
          closeModal={closeServicesModal}
          parkingServices={parkingServices}
          currentServicesName={currentServicesName}
      />
    </>
  );
}

export default ParkingsIndex;
