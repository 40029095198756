import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import applicationEditService from "../applicationsEditService";

const initialState = {
  applicationsEdit: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Login user
export const applicationsEditGet = createAsyncThunk(
  "applications/applicationsEditGet",
  async (id, thunkAPI) => {
    try {
      return await applicationEditService.applicationEditGet(id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const applicationsEditDataSlice = createSlice({
  name: "applicationsEditGet",
  initialState,
  reducers: {
    editApplicatioionReset: (state) => {
      state.applicationsEdit = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(applicationsEditGet.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(applicationsEditGet.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.applicationsEdit = action.payload;
      })
      .addCase(applicationsEditGet.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.applicationsEdit = null;
      });
  },
});
export const { editApplicatioionReset } = applicationsEditDataSlice.actions;

export default applicationsEditDataSlice.reducer;
