import { Outlet, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const PrivateRoutes = ({ role }) => {
  const { user } = useSelector((state) => state.auth);
  const isRole = user ? role.includes(user.role) : false;

  return user && isRole ? <Outlet /> : <Navigate to="/login" replace={true} />;
};

export default PrivateRoutes;
