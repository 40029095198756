import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import {partnerSearch} from "../../store/partners/partners-search-slice/partnerSearchSlice";
import dbImg from "../../images/db.png"
import {partnerModalInfo} from "../../store/partners/partner-get-modal-content-slice/partnersGetModalContentSlice";

function PartnersItem({ item, index, setIsModalOpen, setCurrentUser,handleDeletePartner }) {
  const [uSettingActive, setuSettingActive] = useState(false);
  const {absentUsers, partnerUsers} = useSelector((state) => state.partnerModalInfo.partnerModal);
  const dispatch = useDispatch();

  const handlePartnerClick = () => {
    setuSettingActive((current) => !current);
  };

  const openModal = (item) => {
    dispatch(partnerModalInfo(item.id));
    setCurrentUser(item)
    setIsModalOpen(true);
  };
  // Выводить добавление пользователей в зависимости от роли
  const { user } = useSelector((state) => state.auth);

  const isSuperAdminRole = user.role === "SuperAdmin";
  //

  return (
    <>
      <tr>
        <td className="tr-id">{index + 1}</td>
        <td>
          <div className="first-info d-flex align-items-center">
            <span className="status-dot status-success">&bull;</span>
            <span>{item.shortname}</span>
          </div>
          <div className="company-type">{item.name}</div>
        </td>
        <td>{item.partner_type.name}</td>
        <td>{item.inn}</td>
        {/*<td>{item.kpp}</td>*/}
        <td>
          {item.base_type === "user" ? (
            <>
              {/*<div>Пользовательская</div>*/}
              <div type="button" className="text-grey partner-users-show-modal">
                <Link to={`/users/${item.user?.id}/edit`} className="link">
                {item.user?.email}
                </Link>
              </div>
            </>
          ) : (
            <>
              {/*<div>общая</div>*/}
              {isSuperAdminRole && (
                <div
                  // onClick={() => openModal(item)}
                  className="text-grey partner-users-show-modal"
                >
                  <Link to={`/users/${item.user?.id}/edit`} className="link">
                  {/*Организация: {item.users_count}*/}
                  {item.user?.email}
                  </Link>
                </div>
              )}
            </>
          )}
        </td>
        <td>
          <div
            className={uSettingActive ? "car-dd active" : "car-dd "}
            onClick={handlePartnerClick}
          >
            <div
              className={
                uSettingActive ? "car-close-dd active" : "car-close-dd"
              }
            ></div>
            <div className="car-dd-body">
              <a
                  href={"#"}
                  className={'data-base'}
                  onClick={() => openModal(item)}
              >
                <img src={dbImg} alt=""/>
              </a>
              <a href="#" className={'custom-size'} onClick={() => {
                handleDeletePartner(item.id)
              }}>
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <g opacity="0.6">
                    <path
                        d="M7 4C7 2.89543 7.89543 2 9 2H15C16.1046 2 17 2.89543 17 4V6H18.9897C18.9959 5.99994 19.0021 5.99994 19.0083 6H21C21.5523 6 22 6.44772 22 7C22 7.55228 21.5523 8 21 8H19.9311L19.0638 20.1425C18.989 21.1891 18.1182 22 17.0689 22H6.93112C5.88184 22 5.01096 21.1891 4.9362 20.1425L4.06888 8H3C2.44772 8 2 7.55228 2 7C2 6.44772 2.44772 6 3 6H4.99174C4.99795 5.99994 5.00414 5.99994 5.01032 6H7V4ZM9 6H15V4H9V6ZM6.07398 8L6.93112 20H17.0689L17.926 8H6.07398ZM10 10C10.5523 10 11 10.4477 11 11V17C11 17.5523 10.5523 18 10 18C9.44772 18 9 17.5523 9 17V11C9 10.4477 9.44772 10 10 10ZM14 10C14.5523 10 15 10.4477 15 11V17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17V11C13 10.4477 13.4477 10 14 10Z"
                        fill="#EB5757">

                    </path>
                  </g>
                </svg>
              </a>
              {/* Редактирование заказчика */}
              <Link to={`/partners/${item.id}/edit`} className="link">
                <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
              </Link>
              {/*  */}

              {isSuperAdminRole &&
                (item.user ? (

                  // Редактирование существующего юзера привязанного заказчика
                  <Link
                    className="link"
                    to={`/partner-users/${item?.user?.id}/partner/${item.id}/edit`}
                  >
                    <i className="fa fa-user-times" aria-hidden="true"></i>
                  </Link>
                ) : (
                  //
                  // Создание юзера для заказчика
                  <Link
                    className="link"
                    to={`/partner-users/partner/${item.id}/create`}
                  >
                    <i className="fa fa-user-plus" aria-hidden="true"></i>
                  </Link>
                  //
                ))}
            </div>
          </div>
        </td>
      </tr>
    </>
  );
}

export default PartnersItem;
