import React from "react";
import { Link, useNavigate } from "react-router-dom";

function ApplicationAcceptBtn({ item, role }) {
  //   Когда будет отображаться кнопка
  const isOperator = role === "Operator";
  const isStatus7 = item.status.id === 7;

  const isNotOperatorAndStatus7 = !isOperator && isStatus7;

  const isEditable = isNotOperatorAndStatus7;
  return (
    isEditable && (
      <Link to={`/applications/${item.id}/edit`} className="btn btn-success">
        Принять
      </Link>
    )
  );
}

export default ApplicationAcceptBtn;
