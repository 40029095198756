import React from 'react';
import {Link} from "react-router-dom";

function PermissionsHeader({submitForm}) {
    return (
        <div className="container page-head-wrap">
            <div className="page-head">
                <div className="page-head__top d-flex align-items-center">
                    <h1>{'Разрешения'}</h1>
                    <label className="field-style blue">
                        <span>Поиск</span>
                        <input
                            type="text"
                            id="keyword"
                            // onChange={filterLegals}
                            defaultValue={''}
                            placeholder="Поиск по столбцам" />
                    </label>
                    <div className="ml-auto d-flex">
                        <button onClick={submitForm} className="btn btn-white">
                            Сохранить
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PermissionsHeader;