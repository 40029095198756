import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import parkignsService from "../parkingsService";

const initialState =  {
    allFence : [],
    isError : false,
    isSuccess : false,
    isLoading : false,
    message : '',
}

export const createFence = createAsyncThunk(
    'fence/fenceCreate',
    async (name, thunkAPI) =>{
        try {
            return await parkignsService.fenceCreate(name)
        } catch (error){
            const message = (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
)
export const getAllFence = createAsyncThunk(
    'fence/fenceGetAll',
    async (thunkAPI)=>{
        try {
            return await parkignsService.getAllFence()
        } catch (error){
            const message = (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
)

const fenceCreateSlice = createSlice({
    name : 'fenceCreate',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createFence.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createFence.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.createNewFence = action.payload;
            })
            .addCase(createFence.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.createNewFence = null;
            })

            .addCase(getAllFence.pending, (state) => {
                    state.isLoading = true;
                })
            .addCase(getAllFence.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.allFence = action.payload;
            })
            .addCase(getAllFence.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.allFence = null;
            });
    },
})
export  default fenceCreateSlice.reducer