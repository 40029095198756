import React, {useEffect} from "react";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import InputMask from "react-input-mask";

import {
  selectArrayParking,
  selectArrayInterval,
  selectArray,
} from "../../../../utils/select-array";

import selectStyle from "../../../../utils/select-styles";
import { setApplicationDataSlice } from "../../../../store/applications/applications-create/application-create-collect-data/application-create-collect-data";
import { useState } from "react";
import {
  serialNumberReset,
  vinDublicateData,
} from "../../../../store/applications/application-vin-dublicate/applicationVinDublicateSlice";
import { colorConfomity } from "../../../../utils/status-conformity";
import { Link } from "react-router-dom";
import moment from "moment";
import { license_plateDublicateData } from "../../../../store/applications/application-license-plate-dublicate/applicationLicenseDublicateSlice";
import ApplicationItemModal
  from "../../applications-index/applications-list/applications-item-modal/applications-item-modal";
import {applicationsSingle} from "../../../../store/applications/applications-single/applicationsSingleSlice";
import ModalParkingMap from "../../../modal-parking-map/modal-parking-map";
import {parkingsData} from "../../../../store/parkings/parkingsSlice";
import ModalParkingServices from "../../../modal-parking-services/modal-parking-services";
import {editParkingService} from "../../../../store/parkings/parking-edit-services-slice/parkingEditServicesSlice";

function ApplicationCrtUptStep1({ partners, parkings, setSelectedOption, selectedOption,setSelectedPartner }) {
  const partnerSelect = selectArray(partners, "partner_id");
  const parkingsSelect = selectArrayParking(parkings, "parking_id");
  const intervalSelect = selectArrayInterval;
  const formTab1 = useSelector((state) => state.applicationCreateData);
  const allParkingsList= useSelector((state) => state.parkings);
  const parkingServices = useSelector((state)=> state.parkingServices.parkingServices?.parking_services)
  const dispatch = useDispatch();


  // Записываем данные в State
  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target ? event.target : event;
    let newValue = value;
    if(name === 'parking_id'){
      setSelectedOption(
          {
            value: value,
            label: event.label,
            name: 'parking_id'
          }
      )
    }
    if(name === 'partner_id'){
      setSelectedPartner(
          {
            value: value,
            label: event.label,
            name: 'partner_id'
          }
      )
    }

    if (type === "checkbox") {
      newValue = checked ? 1 : 0;
    }
    dispatch(
      setApplicationDataSlice({
        ...formTab1,
        [name]: isNaN(value) ? newValue : newValue,
      })
    );
  };
  //

  // Чекбокс
  const [vinInput, setVinInput] = useState({
    vinClass: "",
    vinDisable: false,
  });

  const vinCheckbox = (e) => {
    const { name, value } = e.target;
    dispatch(serialNumberReset());
    if (e.target.checked) {
      setVinInput({
        vinClass: "disabled",
        vinDisable: true,
      });
      dispatch(
        setApplicationDataSlice({
          ...formTab1,
          vin_array: "",
        })
      );
      dispatch(
        setApplicationDataSlice({
          ...formTab1,
          [name]: 1,
        })
      );
    } else {
      setVinInput({
        vinClass: "",
        vinDisable: false,
      });

      dispatch(
        setApplicationDataSlice({
          ...formTab1,
          [name]: 0,
        })
      );
    }
  };
  //

  const [externalInput, setExternalInput] = useState({
    externalClass: "",
    externalDisable: false,
  });
  const externalCheckbox = (e)=>{
    if (e.target.checked) {
      setExternalInput({
        externalClass: "disabled",
        externalDisable: true,
      });
    }else{
      setExternalInput({
        externalClass: "",
        externalDisable: false,
      });
    }
  }

  // Чекбокс гос.номер
  const [licenseInput, setLicenseInput] = useState({
    licenseClass: "",
    licenseDisable: false,
  });

  const licenseCheckbox = (e) => {
    const { name, value } = e.target;

    if (e.target.checked) {
      setLicenseInput({
        licenseClass: "disabled",
        licenseDisable: true,
      });
      dispatch(
        setApplicationDataSlice({
          ...formTab1,
          license_plate: "",
        })
      );
      dispatch(
        setApplicationDataSlice({
          ...formTab1,
          [name]: 1,
        })
      );
    } else {
      setLicenseInput({
        licenseClass: "",
        licenseDisable: false,
      });

      dispatch(
        setApplicationDataSlice({
          ...formTab1,
          [name]: 0,
        })
      );
    }
  };
  //

  // Проверка на дубликаты VIN
  const { serial_number } = useSelector(
    (state) => state.applicationVinDublicate
  );

  const handleVinDublicate = (e) => {
    const { value } = e.target;
    dispatch(vinDublicateData(value));
  };
  //

  // Проверяем дубликаты гос.номера
  const { license_plate_dublicate } = useSelector(
    (state) => state.applicationLicenseDublicate
  );

  const handleLicencePlateDublicate = (e) => {
    const { value } = e.target;
    dispatch(license_plateDublicateData(value));
  };

  // Минимальная дата для оператора
  const minDefaultDate = moment(new Date(), "DD.MM.YYYY").format("YYYY-MM-DD");
  const { single } = useSelector((state) => state.applicationSingle);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const [isServicesModalOpen, setIsServicesModalOpen] = useState(false);
  const [currentServicesName, setCurrentServicesName] = useState('');
  const openModal = (id) => {
    setIsModalOpen(true);
    dispatch(applicationsSingle(id));
  };
  const openMapModal = () =>{
    setIsMapModalOpen(true);
      dispatch(parkingsData()).then((resp)=>{
    })
  }
  useEffect(()=>{
    dispatch(parkingsData()).then((resp)=>{
    })
  },[])

  const openServicesModal = (id, name) =>{
    setIsServicesModalOpen(true);
    setCurrentServicesName(name)
    dispatch(editParkingService({id}));
  }

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const closeMapModal = () => {
    setIsMapModalOpen(false);
  };

  const closeServicesModal = () => {
    setIsServicesModalOpen(false);
  };
  //

  //выбор стоянки из списка , если выбрана стоянка на крте
  const selectOptionById = (id) => {
    const selected = parkingsSelect.find(option => option.value === id);
    if (selected) {
      setSelectedOption(selected);
    }
  };

  // Для получение роли
  const { user } = useSelector((state) => state.auth);
  //
  // Проверяем на повторное размещение
  let shouldDisplayBlock = false;
  let shouldLicencePlate = false;

  if (serial_number) {
    if (serial_number.length !== 0) {
      shouldDisplayBlock =
        serial_number.vins.every((vin) => vin.status.id === 3) &&
        !serial_number.vins.some((vin) => vin.status.id !== 3);
    }
  }

  if (license_plate_dublicate) {
    if (license_plate_dublicate.length !== 0) {
      shouldLicencePlate =
        license_plate_dublicate.license_plates.every(
          (vin) => vin.status.id === 3
        ) &&
        !license_plate_dublicate.license_plates.some(
          (vin) => vin.status.id !== 3
        );
    }
  }

  return (
    <div className="row no-gutters tab-pane fade show active">
      <div className="col-md-12 main-col">
        {/* VIN LICENZE PLATE  */}
        <div className="inner-page__item">
          <div className="inner-item-title">Административная информация</div>
          <div className="row">
            {/* VIN */}
            <div className="col-6">
              <label
                className={`field-style ${vinInput.vinClass} ${
                  formTab1.vin_array && formTab1.vin_array !== ""
                    ? ""
                    : "invalid"
                } `}
              >
                <span>VIN</span>
                <input
                  type="text"
                  className="vin"
                  name="vin_array"
                  placeholder="Не указан"
                  value={vinInput.vinDisable ? "" : formTab1.vin_array}
                  onChange={(e) => {
                    handleInputChange(e);
                    handleVinDublicate(e);
                  }}
                  disabled={vinInput.vinDisable}
                />
              </label>
              {/* Неправильный vin номер */}
              {serial_number && serial_number.error && (
                <span className="vin__error">{serial_number.error}</span>
              )}
              {/*  */}

              <label className="switch-radio-wrap mt-2">
                <input
                  className="checkbox-unknown cvin"
                  type="checkbox"
                  name="vin_status"
                  onChange={(e) => {
                    handleInputChange(e);
                    vinCheckbox(e);
                  }}
                />
                <span className="switcher-radio"></span>
                <span>неизвестно</span>
              </label>
            </div>
            {/*  */}
            {/* LICENZE PLATE */}
            <div className="col-6">
              <label
                className={`field-style ${licenseInput.licenseClass} ${
                  formTab1.license_plate && formTab1.license_plate !== ""
                    ? ""
                    : "invalid"
                } `}
              >
                <span>Гос. номер</span>
                <input
                  type="text"
                  className="license_plate"
                  name="license_plate"
                  value={
                    vinInput.license_plate_status === "1"
                      ? ""
                      : formTab1.license_plate
                  }
                  placeholder="Не указан"
                  onChange={(e) => {
                    handleInputChange(e);
                    handleLicencePlateDublicate(e);
                  }}
                  disabled={licenseInput.licenseDisable}
                />
              </label>
              <label className="switch-radio-wrap mt-2">
                <input
                  className="checkbox-unknown clicense"
                  type="checkbox"
                  data-for="license_plate"
                  name="license_plate_status"
                  value="1"
                  onChange={(e) => {
                    handleInputChange(e);
                    licenseCheckbox(e);
                  }}
                />
                <span className="switcher-radio"></span>
                <span>неизвестно</span>
              </label>
            </div>
            {/*  */}
            {/* DUBLICATE VIN*/}
            {serial_number && serial_number.length !== 0 ? (
              <div className="col-12 mt-3">
                <div id="allDuplicates" className="conformity">
                  {serial_number.vins.map((item, index) => {
                    return (
                      <Link
                        key={index}
                        to={
                          item.status.id === 2 || item.status.id === 7
                            ? `/applications/${item.id}/edit`
                            : ``
                        }
                        className="conformity-link"
                      >
                        <span className="conformity__info">{item.vin}</span>
                        <span
                          className={`${colorConfomity(
                            item.status.id
                          )} conformity__icon`}
                          onClick={() => openModal(item.id)}
                        >
                          {item.status.code}
                        </span>
                      </Link>
                    );
                  })}
                </div>
              </div>
            ) : (
              ""
            )}
            {/* DUBLICATE LICENZE PLATE*/}
            {license_plate_dublicate && license_plate_dublicate.length !== 0 ? (
              <div className="col-12 mt-3">
                <div className="conformity">
                  {license_plate_dublicate?.license_plates.map(
                    (item, index) => {
                      return (
                        <Link
                          key={index}
                          to={
                            item.status.id === 2 || item.status.id === 7
                              ? `/applications/${item.id}/edit`
                              : ``
                          }
                          className="conformity-link"
                        >
                          <span className="conformity__info">{item.vin}</span>
                          <span
                            className={`${colorConfomity(
                              item.status.id
                            )} conformity__icon`}
                          >
                            {item.status.code}
                          </span>
                        </Link>
                      );
                    }
                  )}
                </div>
              </div>
            ) : (
              ""
            )}

            {serial_number &&
              serial_number.vins &&
              serial_number.vins.length !== 0 &&
              shouldDisplayBlock && (
                <div className="col-12 mt-3">
                  <label className="switch-radio-wrap  repeat-checkbox">
                    <input
                      type="checkbox"
                      name="returned"
                      value="1"
                      onChange={handleInputChange}
                    />
                    <span className="switcher-radio"></span>
                    <span>повторное размещение</span>
                  </label>
                </div>
              )}

            {license_plate_dublicate &&
              license_plate_dublicate.license_plates &&
              license_plate_dublicate.license_plates.length !== 0 &&
              shouldLicencePlate && (
                <div className="col-12 mt-3">
                  <label className="switch-radio-wrap  repeat-checkbox">
                    <input
                      type="checkbox"
                      name="returned"
                      value="1"
                      onChange={handleInputChange}
                    />
                    <span className="switcher-radio"></span>
                    <span>повторное размещение</span>
                  </label>
                </div>
              )}
          </div>
        </div>
        {/*  */}

        {/* О собствениика */}
        <div className="inner-page__item">
          <div className="inner-item-title">О собственнике</div>
          <div className="row">
            <div className="col-6">
              <label className="field-style">
                <span>ФИО собственника</span>
                <input
                  type="text"
                  name="courier_fullname"
                  placeholder="Не указан"
                  onChange={handleInputChange}
                />
              </label>
            </div>
            <div className="col-6">
              <label className="field-style ">
                <span>Телефон собствениика</span>
                <InputMask
                  mask="+7 (999) 999-99-99"
                  maskChar="_"
                  name="courier_phone"
                  onChange={handleInputChange}
                  placeholder="+7 (___) ___-__-__"
                />
              </label>
            </div>
          </div>
        </div>
        {/*  */}

        {/* Системная информация */}
        <div className="inner-page__item">
          <div className="inner-item-title">Системная информация</div>
          <div className="row">
            <input type="hidden" name="app_data[partner_id]" />
            {user.role !== 'Partner' && (
                <div className="col-3">
                  <label
                      className={`field-style select-react__style ${
                          formTab1.partner_id && formTab1.partner_id !== ""
                              ? ""
                              : "invalid"
                      } `}
                  >
                    <span className="field-style-title">Заказчик*</span>
                    <Select
                        defaultValue={{
                          value: 0,
                          label: "Выберите заказчика",
                          name: "partner_id",
                        }}
                        name="partner_id"
                        options={partnerSelect}
                        styles={selectStyle}
                        onChange={handleInputChange}
                    />
                  </label>
                </div>
            )}


            <div className="col-3">
              <label
                className={`field-style select-react__style ${
                  formTab1.parking_id && formTab1.parking_id !== ""
                    ? ""
                    : "invalid"
                } `}
              >
                <span className="field-style-title">Стоянка*</span>
                <Select
                  value={selectedOption}
                  options={parkingsSelect}
                  styles={selectStyle}
                  onChange={handleInputChange}
                />
              </label>
            </div>
            <div className="col-3 d-flex justify-content-start align-items-center pb-3">
              <span
                  style={{cursor:'pointer', marginRight:'40px'}}
                  onClick={() => openMapModal()}
              >
                выбрать <br/> на карте
              </span>
              {selectedOption.value !== 0 &&
              <span
                  style={{cursor: 'pointer'}}
                  onClick={() => openServicesModal(selectedOption.value, selectedOption.label)}
              > <span>  </span>
                      Прайс
                    </span>
              }
            </div>
            <div className="col-12 mt-3">
              <label
                className={`field-style w-100 ${externalInput.externalClass} `}
              >
                <span>Номер убытка или лизингового договора*</span>
                <input
                    className={'mb-2'}
                  type="text"
                  id="external_id"
                  name="external_id"
                  placeholder="Не указан"
                  disabled={externalInput.externalDisable}
                  onChange={handleInputChange}
                />
              </label>
              <label className="switch-radio-wrap mt-2">
                <input
                    className="checkbox-unknown cvin"
                    type="checkbox"
                    name="external_id"
                    onChange={(e) => {
                      handleInputChange(e);
                      externalCheckbox(e);
                    }}
                />
                <span className="switcher-radio"></span>
                <span>неизвестно</span>
              </label>
            </div>
          </div>
        </div>
        {/*  */}
        {/* Дата */}
        <div className="inner-page__item">
          <div className="inner-item-title">Дата</div>
          <div className="row">
            <div className="col-6">
              <label className="field-style">
                <span>Дата поставки</span>
                <input
                  type="date"
                  className="date-manager"
                  name="arriving_at"
                  placeholder="Не указан"
                  onChange={handleInputChange}
                  defaultValue={moment().format("YYYY-MM-DD")}
                  min={user.role === "Operator" ? minDefaultDate : ""}
                />
              </label>
            </div>

            {user.role === "Operator" && (
              <div className="col-4">
                <label
                  className={`field-style select-react__style ${
                    formTab1.arriving_interval &&
                    formTab1.arriving_interval !== ""
                      ? ""
                      : "invalid"
                  } `}
                >
                  <span className="field-style-title">Промежуток времени</span>
                  <Select
                    defaultValue={{
                      value: 0,
                      label: "Выберите интервал времени",
                      name: "arriving_interval",
                    }}
                    options={intervalSelect}
                    styles={selectStyle}
                    onChange={handleInputChange}
                  />
                </label>
              </div>
            )}
          </div>
        </div>
        {/*  */}
      </div>
      <ApplicationItemModal
          single={single}
          isModalOpen={isModalOpen}
          closeModal={closeModal}
      />
      <ModalParkingMap
          single={single}
          isModalOpen={isMapModalOpen}
          closeModal={closeMapModal}
          allParkingsList={allParkingsList}
          handleInputChange={handleInputChange}
          selectOptionById={selectOptionById}
          openServicesModal={openServicesModal}
          closeServicesModal={closeServicesModal}
      />
      <ModalParkingServices
          isModalOpen={isServicesModalOpen}
          closeModal={closeServicesModal}
          parkingServices={parkingServices}
          currentServicesName={currentServicesName}
      />
    </div>
  );
}

export default ApplicationCrtUptStep1;
