import axios from "axios";
const API_URL = '/v1/notifications'

const headerNotification = async () => {
    const response =  await axios.get(API_URL);
    return response.data;
};
const headerNotificationRead = async (id) => {
    const response =  await axios.post('/v1/read-notification', id);
    return response.status;
}
const headerNotificationReadAll = async ({chat}) => {
    let readApi = '/v1/read-all-notifications'
    if (chat){
        readApi = '/v1/read-all-notifications?type=chat'
    }
    const response =  await axios.get(readApi);
    return response.status;
}

const headerNotificationService = {
    headerNotification,
    headerNotificationRead,
    headerNotificationReadAll
}

export default headerNotificationService