import React from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { applicationsDeny } from "../../../../../../../store/applications/application-deny/applicationDenySlice";

function ApplicationAcceptDenyButton({ item, role }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Функция отвечает за статут отклонить
  const onClickDeny = (e) => {
    e.preventDefault();
    swal({
      title: "Вы уверены что хотите отклонить?",
      icon: "warning",
      content: {
        element: "textarea",
        attributes: {
          placeholder: "Введите причину отклонения...",
        },
      },
      buttons: ["Отменить", "Да"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        const valueTextArea = document.querySelector(
          ".swal-content__textarea"
        ).value;

        dispatch(applicationsDeny({ id: item.id, text: valueTextArea }));
        swal("Удален!", {
          icon: "success",
        }).then(() => {
          navigate("/applications");
        });
      }
    });
  };

  //   Когда будет отображаться кнопка
  const isOperator = role === "Operator";
  const isStatus7 = item.status.id === 7;

  const isNotOperatorAndStatus7 = !isOperator && isStatus7;

  const isEditable = isNotOperatorAndStatus7;

  return (
    isEditable && (
      <div className="d-flex">
        <Link to={`/applications/${item.id}/edit`} className="text-success btn">
          Принять
        </Link>
        <div onClick={onClickDeny} className="text-danger deny btn">
          Отклонить
        </div>
      </div>
    )
  );
}

export default ApplicationAcceptDenyButton;
