import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import parkingsService from "../parkingsService";
import {editFence} from "../edit-fence/editFenceSlice";

const initialState = {
    parkingCategories: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};

export const createParkingCategories = createAsyncThunk(
    "parkings/createCategory",
    async ( name, thunkAPI) => {
        try {
            return await parkingsService.createParkingServiceCategory(name);
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const editParkingCategories = createAsyncThunk(
    "parkings/editCategory",
    async ({id, name}, thunkAPI) => {
        try {
            return await parkingsService.parkingServiceCategoryEdit({id,name});
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

const parkingCategoryCreateSlice = createSlice({
    name : 'categoryCreate',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createParkingCategories.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createParkingCategories.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.parkingCategories = action.payload;
            })
            .addCase(createParkingCategories.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.parkingCategories = action.payload;
            });
    },
})
export  default parkingCategoryCreateSlice.reducer
