export const colorStatus = (id) => {
  let statusColor = "";

  switch (id) {
    case 1:
      statusColor = "status-primary";
      break;
    case 2:
      statusColor = "status-success";
      break;
    case 3:
      statusColor = "status-danger";
      break;
    case 4:
      statusColor = "status-danger";
      break;
    case 5:
      statusColor = "status-danger";
      break;
    case 6:
      statusColor = "status-danger";
      break;
    case 7:
      statusColor = "status-danger";
      break;
    case 8:
      statusColor = "status-danger";
      break;
    case 9:
      statusColor = "status-danger";
      break;
  }
  return statusColor;
};
