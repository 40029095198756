import React from 'react';

function ApplicationImportItemModel({modelName, currentModalMarkId, item,handleInputChange}) {
    return (
        <td>
            {currentModalMarkId.length > 0 && item?.car_mark_id ?
                <select
                    name="dataCarModal"
                    id="dataCarModal"
                    className={'massImportSelect'}
                    onChange={(event)=>{handleInputChange(event, item.id)}}
                >
                    <option selected={true} value={modelName}>{modelName}</option>
                    {
                        currentModalMarkId?.map((carModal, index)=>{
                            return carModal[item?.car_mark_id]?.map((itm)=>{
                                return modelName !== itm.name ?(
                                    <option
                                        key={itm.id}
                                        value={itm.id}
                                    >
                                        {itm.name}
                                    </option>
                                ) : ''
                            })
                        })
                    }
                </select>
                : modelName}
        </td>
    );
}

export default ApplicationImportItemModel;