import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import parkingsService from "./parkingsService";

const initialState = {
  parkings: [],
  parking:{},
  title: "",
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// all Parkings for current user
export const parkingsData = createAsyncThunk("parkings/all", async (thunkAPI) => {
    try {
      return await parkingsService.parkigns();
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  });
  //

  // Удаление Parking
export const parkingDelete = createAsyncThunk(
  "parking/parkingDelete",
  async (id, thunkAPI) => {
    try {
      return await parkingsService.parkignDelete(id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

// get 1 parking by id
export const parkingById = createAsyncThunk("parkings/byId", async (id,thunkAPI) => {
  try {
    return await parkingsService.getParkingById(id);
  } catch (error) {
    const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
    return thunkAPI.rejectWithValue(message);
  }
});
//


const parkingsSlice = createSlice({
  name: "parkings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(parkingsData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(parkingsData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.parkings = action.payload.parkings;
        state.title = action.payload.title;
      })
      .addCase(parkingsData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })

        .addCase(parkingById.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(parkingById.fulfilled, (state, action) => {
          state.isLoading = false;
          state.isSuccess = true;
          state.parking = action.payload.parking;
          state.title = action.payload.title;
        })
        .addCase(parkingById.rejected, (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.message = action.payload;
        })

      .addCase(parkingDelete.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(parkingDelete.fulfilled, (state, action) => {
        state.parkings = state.parkings.filter((item) => item.id !== action.payload);
      })
      .addCase(parkingDelete.rejected, (state, action) => {
        state.message = action.payload;
      });
  },
});

export default parkingsSlice.reducer;