import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer>
      <div className="d-flex justify-content-between container mb-2">
        {/*<Link to={"/offer"} className="left-link" target="_blank">*/}
        {/*  Исполнитель*/}
        {/*</Link>*/}
        {/*<Link to={"/clientoffer"} className="right-link" target="_blank">*/}
        {/*  Заказчик - клиент*/}
        {/*</Link>*/}
      </div>
    </footer>
  );
}

export default Footer;
