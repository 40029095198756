import axios from "axios";

let API_URL = "/v1/permissions"

const permissions = async () => {
    const response = await axios.get(API_URL)
    return response.data
}
const permissionsUpdate = async ({data}) => {
    const response = await axios.post(`${API_URL}/sync`, data)
    return response.data
}

const permissionsData = {
    permissions,
    permissionsUpdate
}
export default permissionsData