import ApplicationsHeader from "./applications-header/applications-header";
import ApplicationsList from "./applications-list/applications-list";
import ApplicationStatus from "./applications-status/applications-status";
import { useDispatch, useSelector } from "react-redux";

import {
  applicationsAll,
  applicationsDublicateAll,
} from "../../../store/applications/applicationsSlice";
import { useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import ApplicationSearch from "./applications-search/applications-search";

const ApplicationComponent = () => {
  const { id } = useParams();
  const location = useLocation();
  const [currentPage, setcurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [filterHome, setFilterHome] = useState([]);
  const [favorite, setFavorite] = useState(0);

  const sessionFilter = localStorage.getItem("filter");
  const applications = useSelector((state) => state.application.applications);

  const title = applications?.title || "";
  const total = applications?.amounts || "";
  const dispatch = useDispatch();

  // Для получение роли
  const { user } = useSelector((state) => state.auth);

  // Функция для загрузки данных
  const loadData = (page) => {
    dispatch(
      applicationsAll({
        id: id,
        paginate: page,
        query: sessionFilter ? JSON.parse(sessionFilter) : filterHome,
      })
    );
  };

  useEffect(() => {
    if (location.pathname === "/applications/dublicate") {
      dispatch(applicationsDublicateAll(currentPage));
    } else {
      loadData(currentPage);
    }
  }, [location, favorite, sessionFilter, filterHome]);

  useEffect(() => {
    setPageCount(applications?.last_page);
  }, [applications]);

  const handlePageChange = (selectedObject) => {
    loadData(selectedObject.selected + 1);
    // Прокрутить страницу в начало
    window.scrollTo(0, 0); // Или document.documentElement.scrollTop = 0;
  };

  return (
    <>
      <ApplicationsHeader title={title} total={total} />

      <div className="container">
        <ApplicationStatus total={total} />
        <ApplicationSearch
          applications={applications}
          currentPage={currentPage}
          setFilterHome={setFilterHome}
          filterHome={filterHome}
          sessionFilter={sessionFilter}
        />
      </div>

      <ApplicationsList applications={applications} setFavorite={setFavorite} />
      {applications && applications.length !== 0 && (
        <div className="container">
          {/* <div className="applications-show__more"> Показать еще</div> */}
          <ReactPaginate
            pageCount={pageCount}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            previousLinkClassName={"page-link"}
            breakClassName={"page-item"}
            nextLinkClassName={"page-link"}
            nextLabel={`>`}
            previousLabel={`<`}
            pageClassName={"page-item"}
            disabledClassNae={"disabled"}
            activeClassName={"active"}
          />
        </div>
      )}
    </>
  );
};

export default ApplicationComponent;
