import React from "react";
import style from "../../parking.module.css";
import ParkingEditServiceItem from "./parking-edit-service-item/parkingEditServiceItem";

function ParkingEditService(
    {
        parkingServicesEdit,
        handleServiceChange,
        parkingPartners,
        handleSelectChange,
        selectedOption,
        inputDisable
    }
) {

    return (
        <div className="row no-gutters">
            <div className="col-md-12">
                <div className="inner-page__item">
                    <div className="inner-item-title">Прайс-лист</div>
                    <div className="d-flex align-items-center">
                        <label className="field-style mt-3">
                            <span>Шаблон прайс-листов</span>
                            <select
                                name=""
                                id=""
                                className="page-select"
                                onChange={handleSelectChange}
                                value={selectedOption}
                            >
                                <option value={0} key={'base_price'}>Базовые цены</option>
                                {parkingPartners && parkingPartners.map((item, index) => {
                                    return (
                                        <option
                                            key={item.id}
                                            value={item.id}
                                        >
                                            {item.name}
                                        </option>
                                    )
                                })}

                            </select>
                        </label>
                    </div>
                </div>
            </div>
            <div className="d-flex w-100 mt-5">
                <table className={`${style['price-list-table-tab-3']} price-list-table price-table vertical-scrollable`}>
                    <thead>
                    <tr>
                        <th key={'parking'} className="price-list-head  vertical-align-top">Парковка</th>
                        <th key={'active'} className="text-center vertical-align-top">Активна</th>
                        <th key={'price+for_all'} className={'vertical-align-top text-center'}>Цена для <br/> всех</th>
                        <th key={'price_for_us'} className={'vertical-align-top text-center'}>Цена для <br/> нас</th>
                        <th key={'contacts'} colSpan={2} className={'vertical-align-top text-center'}>Контакты</th>
                        <th key={'comments'} className={'vertical-align-top'}>Комментарий</th>
                    </tr>
                    </thead>
                    <tbody>
                        {parkingServicesEdit?.map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <tr key={index}>
                                        <td key={'td01'} colSpan={7}><b>{item.category}</b></td>
                                    </tr>
                                    {item.services.map(service => (
                                       <ParkingEditServiceItem
                                           key={`${service.id }${service.parking_service_id}`}
                                           service={service}
                                           handleServiceChange={handleServiceChange}
                                           item={item}
                                           inputDisable={inputDisable}
                                       />
                                    ))}
                                </React.Fragment>
                            )})
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}
export default ParkingEditService;
