import AuthForm from "../../components/auth/auth-form";

const PasswordReset = () => {
  return (
    <AuthForm title="Сбросить пароль">
      <div className="form-group row mb-4">
        <label className="col-md-4 col-form-label text-md-right">Email</label>

        <div className="col-md-6">
          <input
            id="email"
            type="email"
            className="form-control "
            name="email"
            value=""
            required
            autocomplete="email"
            autofocus
          />

          <span className="invalid-feedback" role="alert">
            <strong>Ошибка</strong>
          </span>
        </div>
      </div>

      <div className="form-group row mb-0">
        <div className="col-md-8 offset-md-4">
          <button type="submit" className="btn btn-primary">
            Сбросить пароль
          </button>
        </div>
      </div>
    </AuthForm>
  );
};

export default PasswordReset;
