import React, {useState} from 'react';
import {useSelector} from "react-redux";

function ApplicationAddCustomService(
    {
        addRow,
        handleInputChange,
        deleteRow,
        rows,
    }) {
    const {user} = useSelector((state) => state.auth);


    return (
        <div>
            <table className="price-list-table table-services">
                <thead style={{borderBottom: '1px solid #ededed'}}>
                    <th colSpan={2}>
                        <span>Наименование</span>
                    </th>
                    <th className={user.role === 'Partner' ? 'text-center' : ''}>
                        <span> {user.role !== 'Partner' ? 'Цена для заказчика' : 'Цена'} </span>
                    </th>
                    {user.role !== 'Partner' &&
                        <th>
                            <span>Наша цена</span>
                        </th>
                    }
                    <th></th>
                </thead>
                <tbody className={'custom-service-input'}>
                {/* Render rows dynamically */}
                {rows.map((row, index) => (
                    <tr key={index}>
                        <td colSpan={2}>
                            <input
                                type="text"
                                name="custom_service"
                                value={row.custom_service }
                                onChange={(e) => handleInputChange(index, e)}
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                name="price"
                                value={row.price}
                                onChange={(e) => handleInputChange(index, e)}
                            />
                        </td>
                        <td>
                            <input
                                type="text"
                                name="price_for_us"
                                value={row.price_for_us}
                                onChange={(e) => handleInputChange(index, e)}
                            />
                        </td>
                        <td>
                            {/* Delete button */}
                            <span onClick={() => deleteRow(index)}><svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.529247 1.02729C0.789596 0.766944 1.21171 0.766944 1.47206 1.02729L5.00065 4.55589L8.52925 1.02729C8.7896 0.766944 9.21171 0.766944 9.47205 1.02729C9.7324 1.28764 9.7324 1.70975 9.47205 1.9701L5.94346 5.4987L9.47205 9.02729C9.7324 9.28764 9.7324 9.70975 9.47205 9.9701C9.21171 10.2305 8.7896 10.2305 8.52925 9.9701L5.00065 6.44151L1.47206 9.9701C1.21171 10.2305 0.789596 10.2305 0.529247 9.9701C0.268897 9.70975 0.268897 9.28764 0.529247 9.02729L4.05784 5.4987L0.529247 1.9701C0.268897 1.70975 0.268897 1.28764 0.529247 1.02729Z" fill="#EB5757"/>
                            </svg>
                            </span>
                        </td>
                    </tr>
                ))}
                </tbody>
                <button className={'add-custom-service__btn'}
                        onClick={addRow}
                >
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M7.00065 0.332031C7.46089 0.332031 7.83398 0.705127 7.83398 1.16536V6.16537H12.834C13.2942 6.16537 13.6673 6.53846 13.6673 6.9987C13.6673 7.45894 13.2942 7.83203 12.834 7.83203H7.83398V12.832C7.83398 13.2923 7.46089 13.6654 7.00065 13.6654C6.54041 13.6654 6.16732 13.2923 6.16732 12.832V7.83203H1.16732C0.70708 7.83203 0.333984 7.45894 0.333984 6.9987C0.333984 6.53846 0.70708 6.16537 1.16732 6.16537H6.16732V1.16536C6.16732 0.705127 6.54041 0.332031 7.00065 0.332031Z" fill="#536E9B"/>
                    </svg>

                    Добавить услугу
                </button>

            </table>
        </div>
    );
}

export default ApplicationAddCustomService;