import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import parkingsService from "../parkingsService";

const initialState = {
  parkingsEdit: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// parkings Info
export const editParkings = createAsyncThunk(
  "parkings/parkingsEdit",
  async (id, thunkAPI) => {
    try {
      return await parkingsService.parkignEditData(id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

// parkings EDIT Post Data
export const parkingsEditPut = createAsyncThunk(
  "parkings/parkingsEditPut",
  async ({ id, data }, thunkAPI) => {
    try {
      return await parkingsService.parkignEditPut(id, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

const parkingsEditSlice = createSlice({
  name: "parkingsEditSlice",
  initialState,
  reducers: {
    editParkingReset: (state) => {
      state.parkingsEdit = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(editParkings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editParkings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.parkingsEdit = action.payload;
      })
      .addCase(editParkings.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.parkingsEdit = null;
      });
  },
});
export const { editParkingReset } = parkingsEditSlice.actions;

export default parkingsEditSlice.reducer;
