import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import parkingsService from "../parkingsService";

const initialState={
    parkingPrice: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
}
export const editParkingPrice = createAsyncThunk(
    'editParking/editParkingPrice',
    async ({id,partner_id},thunkAPI)=>{
        try {
            return await parkingsService.parkingEditPrices(id,partner_id);
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
)



export const updateParkingPrices = createAsyncThunk (
    'editParking/UpdatePrices',
    async ({id,pricings,partner_id}, thunkAPI)=>{
        try {
            return await parkingsService.parkingUpdatePrices(id, pricings,partner_id);
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
)
const ParkingEditPricesSlice = createSlice({
    name: 'PerkingEditPricesSlice',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder
            .addCase(editParkingPrice.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(editParkingPrice.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.parkingPrice = action.payload;
            })
            .addCase(editParkingPrice.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.parkingPrice = null;
            });
    },
})

export default ParkingEditPricesSlice.reducer