import axios from "axios";

const API_REMOVE_PARKING = '/v1/remove-parkings'

const removeParkingListFromPartner = async (data) =>{
    const response = await axios.put(API_REMOVE_PARKING, data)

    return response.status
}
const deleteParkingFromPartnerService = {
    removeParkingListFromPartner
}
export default deleteParkingFromPartnerService