import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import parkignsService from "../parkingsService";

const initialState =  {
    isError : false,
    isSuccess : false,
    isLoading : false,
    message : '',
}

export const deleteFence = createAsyncThunk(
    'fence/fenceDelete',
    async (id, thunkAPI) =>{
        try {
            return await parkignsService.deleteFence(id)
        } catch (error){
            const message = (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
)

const fenceDeleteSlice = createSlice({
    name : 'fenceDelete',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(deleteFence.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteFence.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.message = action.payload;
            })
            .addCase(deleteFence.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            });
    },
})
export  default fenceDeleteSlice.reducer