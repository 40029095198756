import { createAsyncThunk } from "@reduxjs/toolkit";
import uploadImageService from "../uploadImageService";

export const uploadImageEditSlice = createAsyncThunk(
  "application/uploadImageEditSlice",
  async ({ id, data }, thunkAPI) => {
    try {
      return await uploadImageService.uploadApiEdit(id, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
