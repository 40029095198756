import React from "react";

function LogsItem(item){
    return (
        <>
            <tr className={'logs__item'}>
                <td className={'logs__item-id'} >  {item.item.id} </td>
                <td className={'logs__item-date'}>{item.item.date}</td>
                <td className={'logs__item-action'}>{item.item.action}</td>
            </tr>
        </>
    )
}
export default LogsItem