import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import { appDelete } from "../../../../../../../store/applications/applications-delete/applicationsSingleSlice";

function ApplicationDeleteButton({ item, role }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Удалить application
  const handleClickDelete = (e, id) => {
    e.preventDefault();
    swal({
      title: "Вы уверены что хотите отклонить?",
      icon: "warning",
      content: {
        element: "textarea",
        attributes: {
          placeholder: "Введите причину удаление...",
        },
      },
      buttons: ["Отменить", "Да"],
      dangerMode: true,
    }).then((willDelete, response) => {
      if (willDelete) {
        let valueTextArea = document.querySelector(
          ".swal-content__textarea"
        ).value;
        dispatch(appDelete({ id: id, text: valueTextArea }))
            .then((response)=>{
              if (response.meta?.requestStatus !== "fulfilled"){
                swal("Ошибка при удалении", {
                  icon: "error",
                }).then(() => {
                  navigate("/applications");
                });
              }else{
                swal("Удалено", {
                  icon: "success",
                }).then(() => {
                  navigate("/applications");
                });
              }

            });


      }
    });
  };
  //

  const isAdmin = role === "Admin";
  const isSuperAdmin = role === "SuperAdmin";
  const isOperator = role === "Operator";

  const isStatus2 = item.status.id === 2;
  const isStatus7 = item.status.id === 7;

  const isAdminAndSuperAdminStatus2 = isStatus2 && (isAdmin || isSuperAdmin);
  const isOperatorStatus7 = isStatus7 && isOperator;

  const isEditable = isAdminAndSuperAdminStatus2 || isOperatorStatus7;

  return (
      isSuperAdmin && (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        onClick={(e) => handleClickDelete(e, item.id)}
        className="cursor-pointer"
      >
        <g opacity="0.6">
          <path
            d="M5.83366 3.33317C5.83366 2.4127 6.57985 1.6665 7.50033 1.6665H12.5003C13.4208 1.6665 14.167 2.4127 14.167 3.33317V4.99984H15.8251C15.8302 4.99979 15.8354 4.99979 15.8405 4.99984H17.5003C17.9606 4.99984 18.3337 5.37293 18.3337 5.83317C18.3337 6.29341 17.9606 6.6665 17.5003 6.6665H16.6096L15.8868 16.7852C15.8245 17.6574 15.0988 18.3332 14.2244 18.3332H5.77626C4.90186 18.3332 4.17613 17.6574 4.11383 16.7852L3.39106 6.6665H2.50033C2.04009 6.6665 1.66699 6.29341 1.66699 5.83317C1.66699 5.37293 2.04009 4.99984 2.50033 4.99984H4.16011C4.16528 4.99979 4.17044 4.99979 4.17559 4.99984H5.83366V3.33317ZM7.50033 4.99984H12.5003V3.33317H7.50033V4.99984ZM5.06197 6.6665L5.77626 16.6665H14.2244L14.9387 6.6665H5.06197ZM8.33366 8.33317C8.7939 8.33317 9.16699 8.70627 9.16699 9.1665V14.1665C9.16699 14.6267 8.7939 14.9998 8.33366 14.9998C7.87342 14.9998 7.50033 14.6267 7.50033 14.1665V9.1665C7.50033 8.70627 7.87342 8.33317 8.33366 8.33317ZM11.667 8.33317C12.1272 8.33317 12.5003 8.70627 12.5003 9.1665V14.1665C12.5003 14.6267 12.1272 14.9998 11.667 14.9998C11.2068 14.9998 10.8337 14.6267 10.8337 14.1665V9.1665C10.8337 8.70627 11.2068 8.33317 11.667 8.33317Z"
            fill="#EB5757"
          ></path>
        </g>
      </svg>
    )
  );
}

export default ApplicationDeleteButton;
