import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import partnersService from "../partnersService";

//  создание новой partner
export const partnerUserCreatePost = createAsyncThunk(
  "partners/partnerUserCreatePost",
  async ({ id, data }, thunkAPI) => {
    try {
      return await partnersService.partnerUserCreate(id, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//
