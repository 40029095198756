import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import issueRequestService from "../issueRequestService";

const initialState = {
  issueEdit: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// issueRequest edit get data
export const issueEditData = createAsyncThunk(
  "application/issueEditData",
  async (id, thunkAPI) => {
    try {
      return await issueRequestService.issueRequestEdit(id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

// issueRequest update data
export const issueRequestUpdate = createAsyncThunk(
  "application/issueRequestUpdate",
  async ({ id, data }, thunkAPI) => {
    try {
      return await issueRequestService.issueRequestUpdate(id, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

const issueRequestEditSlice = createSlice({
  name: "issueRequestEditSlice",
  initialState,
  reducers: {
    editIssueReset: (state) => {
      state.issueEdit = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(issueEditData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(issueEditData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.issueEdit = action.payload;
      })
      .addCase(issueEditData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.issueEdit = null;
      });
  },
});
export const { editIssueReset } = issueRequestEditSlice.actions;

export default issueRequestEditSlice.reducer;
