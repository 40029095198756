import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import allParkings from "./getAllParkingsService";


const initialState = {
    parkings: [],
    title: "",
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};

// all available Parkings
export const getAllParkingsData = createAsyncThunk("availableParkings/all", async (thunkAPI) => {
    try {
        return await allParkings.getAllParkings();
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data) ||
            error.message ||
            error.toSrting();
        return thunkAPI.rejectWithValue(message);
    }
});
//

const allParkingsSlice = createSlice({
    name: "parkingsAll",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllParkingsData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllParkingsData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.parkings = action.payload;
                state.title = action.payload.title;
            })
            .addCase(getAllParkingsData.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
    },
});

export default allParkingsSlice.reducer