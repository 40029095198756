import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import partnersService from "./partnersService";

const initialState = {
  partners: [],
  title: "",
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// all partners
export const partnersData = createAsyncThunk(
  "partners/all",
  async ({ paginate, query, search }, thunkAPI) => {
    try {
      return await partnersService.partners(paginate, query, search);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const partnersSlice = createSlice({
  name: "partners",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(partnersData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(partnersData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.partners = action.payload.data.partners;
        state.title = action.payload.data.title;
      })
      .addCase(partnersData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
  },
});

export default partnersSlice.reducer;
