import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PartnerSearchPage from "../search/search";
import Select from "react-select";
import selectStyle from "../../../utils/select-styles";
import { basePartner, selectArray } from "../../../utils/select-array";
import {
  editPartnerReset,
  editPartners,
  partnerAdd,
  partnerRemove,
} from "../../../store/partners/partners-edit-slice/partnersEditSlice";
import { useNavigate, useParams } from "react-router-dom";
import ModerationButton from "../../../components/partners/partner-moderation-button/partner-moderation-button";
import swal from "sweetalert";

function PartnerCreatePage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { partnersEdit } = useSelector((state) => state.partnersEdit);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(editPartnerReset());
    dispatch(editPartners(id));
  }, [dispatch, navigate]);

  const [partnerEditForm, setPartnerEditForm] = useState({});
  useEffect(() => {
    setPartnerEditForm({
      beingAdded: "frompublic",
      _method: "PUT",
      update: null,
      superadmin: null,
      name: partnersEdit.length !== 0 ? partnersEdit.partner.name : "",
      shortname:
        partnersEdit.length !== 0 ? partnersEdit.partner.shortname : "",
      status: partnersEdit.length !== 0 ? partnersEdit.partner.status : "",
      inn: partnersEdit.length !== 0 ? partnersEdit.partner.inn : "",
      kpp: partnersEdit.length !== 0 ? partnersEdit.partner.kpp : "",

      partner_type:
        partnersEdit.length !== 0 ? partnersEdit.partner.partner_type_id : [],
      base: partnersEdit.length !== 0 ? partnersEdit.partner.base_type : [],
    });
  }, [partnersEdit]);

  // Собираем данные для select
  const partnerType = selectArray(partnersEdit.partner_types, "partner_type");
  // Собираем в Select по умолчанию
  let defaultSelectPartnerType = "";
  let defaultSelectBasePartner = "";
  // ЗНачение по умолчанию в Типах заказчика
  for (let i = 0; i < partnerType.length; i++) {
    if (partnerType[i].value === partnersEdit.partner.partner_type_id) {
      defaultSelectPartnerType = partnerType[i];
    }
  }
  //
  // ЗНачение по умолчанию в Базах
  for (let i = 0; i < basePartner.length; i++) {
    if (basePartner[i].value === partnersEdit?.partner?.base_type) {
      defaultSelectBasePartner = basePartner[i];
    }
  }
  //
  //

  // Функция для отправки формы
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target ? e.target : e;
    let newValue = value;
    if (type === "checkbox") {
      newValue = checked ? 1 : 0;
    }
    setPartnerEditForm({ ...partnerEditForm, [name]: newValue });
  };
  //

  const handleClick = () => {
    if (partnersEdit.logic === "remove") {
      dispatch(partnerRemove({ id: id, user_id: user?.id })).then(
        (response) => {
          if (response.payload === 200) {
            swal("Добавлено!", {
              icon: "success",
            });
            dispatch(editPartners(id));
          }
        }
      );
    } else {
      dispatch(partnerAdd({ id: id, user_id: user?.id })).then((response) => {
        if (response.payload === 200) {
          swal("Удалено!", {
            icon: "success",
          });
          dispatch(editPartners(id));
        }
      });
    }
  };
  return (
    <>
      {partnersEdit.length !== 0 && (
        <PartnerSearchPage
          data={partnerEditForm}
          title={partnersEdit.title}
          disabledButton={partnersEdit.disabled}
        >
          {/* Создание нового заказчика */}
          <div className="inner-page__item">
            <div className="inner-item-title">О Заказчике</div>
            <div className="row">
              {/* Полное название */}
              <div className="col-6">
                <label
                  className={`field-style ${
                    partnersEdit.disabled && "disabled"
                  }`}
                >
                  <span>Полное название</span>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    defaultValue={partnersEdit?.partner?.name}
                    required
                    placeholder="Не указан"
                    onChange={handleChange}
                    disabled={partnersEdit.disabled}
                  />
                </label>
              </div>
              {/*  */}

              {/* Активен */}
              <div className="col-6">
                <label
                  className={`switch-radio-wrap mt-11px ${
                    partnersEdit.disabled && "disabled"
                  }`}
                >
                  <input
                    type="checkbox"
                    name="status"
                    defaultChecked={partnersEdit?.partner?.status}
                    onChange={handleChange}
                    disabled={partnersEdit.disabled}
                  />
                  <span className="switcher-radio"></span>
                  <span>Активен</span>
                </label>
              </div>
              {/*  */}

              {/* Короткое название */}
              <div className="col-6 mt-3">
                <label
                  className={`field-style ${
                    partnersEdit.disabled && "disabled"
                  }`}
                >
                  <span>Короткое название</span>

                  <input
                    type="text"
                    className="form-control "
                    name="shortname"
                    defaultValue={partnersEdit?.partner?.shortname}
                    required
                    onChange={handleChange}
                    placeholder="Не указан"
                    disabled={partnersEdit.disabled}
                  />
                </label>
              </div>
              {/*  */}

              {/* ИНН */}
              <div className="col-6 mt-3">
                <label
                  className={`field-style ${
                    partnersEdit.disabled && "disabled"
                  }`}
                >
                  <span>ИНН</span>
                  <input
                    type="text"
                    defaultValue={partnersEdit?.partner?.inn}
                    name="inn"
                    onChange={handleChange}
                    disabled={partnersEdit.disabled}
                    placeholder="Не указан"
                  />
                </label>
              </div>
              {/*  */}
              {/* КПП */}
              <div className="col-6 mt-3">
                <label
                  className={`field-style ${
                    partnersEdit.disabled && "disabled"
                  }`}
                >
                  <span>КПП</span>
                  <input
                    type="text"
                    name="kpp"
                    defaultValue={partnersEdit?.partner?.kpp}
                    required
                    onChange={handleChange}
                    disabled={partnersEdit.disabled}
                    placeholder="Не указан"
                  />
                </label>
              </div>
              {/*  */}

              {/* Тип заказчика */}
              <div className="col-6 mt-3">
                <label className="field-style select-react__style  ">
                  <span>Тип Заказчика</span>
                  {defaultSelectPartnerType !== "" && (
                    <Select
                      defaultValue={defaultSelectPartnerType}
                      options={partnerType}
                      styles={selectStyle}
                      isDisabled={partnersEdit.disabled}
                      onChange={handleChange}
                    />
                  )}
                </label>
              </div>
              {/*  */}

              {/* База */}
              <div className="col-6 mt-3">
                <label className="field-style select-react__style">
                  <span>База</span>
                  {defaultSelectBasePartner !== "" && (
                    <Select
                      defaultValue={defaultSelectBasePartner}
                      options={basePartner}
                      styles={selectStyle}
                      name="base"
                      isDisabled={partnersEdit.disabled}
                      onChange={handleChange}
                    />
                  )}
                </label>
              </div>
              {/*  */}
              {/* Модерация */}
              <ModerationButton
                handleChange={handleChange}
                moderationChecked={partnersEdit?.partner?.moderation}
              />
              {/*  */}
              <div className="col-6 mt-3">
                <div className="btn btn-primary" onClick={handleClick}>
                  {partnersEdit.logic === "remove" ? "Удалить" : "Добавить"}
                </div>
              </div>
            </div>
          </div>
        </PartnerSearchPage>
      )}
    </>
  );
}

export default PartnerCreatePage;
