import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import reportsService from "../reportService";

const initialState = {
  employeeReports: [],
  link: "",
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Получить данные по отчету сотрудника
export const reportsEmployeeData = createAsyncThunk(
  "application/reportsEmployeeData",
  async (data, thunkAPI) => {
    try {
      return await reportsService.reportEmployee(data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

// Ссылка по отчету сотрудника

export const reportsByEmployeeLink = createAsyncThunk(
  "application/reportByEmployeeLink",
  async (data, thunkAPI) => {
    try {
      return await reportsService.reportByEmployeeLink(data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

const reportsEmployeeSlice = createSlice({
  name: "reportsEmployeeSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(reportsEmployeeData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(reportsEmployeeData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.employeeReports = action.payload;
      })
      .addCase(reportsEmployeeData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.employeeReports = null;
      })
      .addCase(reportsByEmployeeLink.fulfilled, (state, action) => {
        state.link = action.payload;
      });
  },
});

export default reportsEmployeeSlice.reducer;
