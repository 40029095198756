export const colorConfomity = (id) => {
  let statusColor = "";

  switch (id) {
    case 2:
      statusColor = "conformity-success";
      break;
    case 7:
      statusColor = "conformity-primary";
      break;
    case 3:
      statusColor = "conformity-dark";
      break;
    case 6:
      statusColor = "conformity-red";
      break;
  }
  return statusColor;
};
