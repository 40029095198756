import Select from "react-select";
import selectStyle from "../../../../utils/select-styles";
import ImageUploader from "../../../image-upload/image-upload";
import ParkingImageUploader from "../../../image-upload/parking/image-upload-parking";

function ParkingApplication({
  parkingCreateForm,
  handleChange,
  individualsOptions,
  usersOptions,
  constractorsOptions,
  fenceList,
  coverageList,
  updateToken
})

{


  // форматируем данные ключ=>значение где ключ это id ф значение name, для того что бы при выводе select выводить id и название

  const fenceNames = fenceList.reduce((acc, fence) => {
    acc[fence.value] = fence.label;
    return acc;
  }, {});
  const fenceIds = Object.keys(fenceNames);

  const coverageNames = coverageList.reduce((acc, coverage) => {
    acc[coverage.value] = coverage.label;
    return acc;
  }, {});
  const coverageIds = Object.keys(coverageNames);

  return (
    <div className="row no-gutters">
      <div className="col-md-12">
        {/* Административная информация
         */}
        <div className="inner-page__item">
          <div className="inner-item-title">Административная информация</div>
          <div className="row">
            {/* Название */}
            <div className="col-6 mt-3">
              <label className="field-style">
                <span>Название</span>
                <input
                  id="title"
                  type="text"
                  value={parkingCreateForm.title}
                  name="title"
                  required
                  placeholder="Не указан"
                  onChange={handleChange}
                />
              </label>
            </div>
            {/*  */}

            {/* Активен */}
            <div className="col-6">
              <label className="switch-radio-wrap mt-11px">
                <input
                  type="checkbox"
                  name="status"
                  value={parkingCreateForm.status}
                  onChange={handleChange}
                />
                <span className="switcher-radio"></span>
                <span>Активен</span>
              </label>
            </div>
            {/*  */}

            {/* Регион */}
            <div className="col-6 mt-3">
              <label className="field-style">
                <span>Регион</span>
                <input
                  id="code"
                  type="text"
                  value={parkingCreateForm.code}
                  name="code"
                  required
                  placeholder="Не указан"
                  onChange={handleChange}
                />
              </label>
            </div>
            {/*  */}

            {/* Адрес */}
            <div className="col-6 mt-3">
              <label className="field-style">
                <span>Адрес</span>
                <input
                  id="address"
                  type="text"
                  value={parkingCreateForm.address}
                  name="address"
                  required
                  placeholder="Не указан"
                  onChange={handleChange}
                />
              </label>
            </div>
            {/*  */}

            {/* Юр.лица */}
            <div className="col-6 mt-3">
              <label className="field-style select-react__style" style={{minWidth:'255px'}}>
                <span>Юр.лица</span>
                <Select
                  styles={selectStyle}
                  options={individualsOptions}
                  name="legals"
                  isMulti
                  onChange={handleChange}
                />
              </label>
            </div>
            {/*  */}

            {/* Пользователи */}
            <div className="col-6 mt-3">
              <label className="field-style select-react__style" style={{minWidth:'255px'}}>
                <span>Менеджеры</span>
                <Select
                  isMulti
                  options={usersOptions}
                  name="users"
                  styles={selectStyle}
                  onChange={handleChange}
                  id="status-selection"
                />
              </label>
            </div>
            {/*  */}

            {/* Констрактор */}
            <div className="col-6 mt-3">
              <label className="field-style select-react__style" style={{minWidth:'255px'}}>
                <span>Исполнители</span>
                <Select
                  isMulti
                  options={constractorsOptions}
                  name="contractors"
                  styles={selectStyle}
                  onChange={handleChange}
                />
              </label>
            </div>
            {/*  */}
          </div>
        </div>
        {/*  */}

        {/* Контактная информация */}
         <div class="inner-page__item">
          <div class="inner-item-title">Контактная информация</div>
          <div class="row">
            {/*<div class="col-6">*/}
            {/*  <label class="field-style">*/}
            {/*    <span>Адрес</span>*/}
            {/*    <input type="text" placeholder="Не указано" />*/}
            {/*  </label>*/}
            {/*</div>*/}
            <div className="col-6">
              <label className="field-style">
                <span>Координаты на карте</span>
                <input
                    type="text"
                    name="coordinates"
                    placeholder="Не указано"
                    value={parkingCreateForm.coordinates}
                    onChange={handleChange}
                />
              </label>
            </div>
            <div className="col-6 mt-3">
              <label className="field-style">
                <span>Ссылка на навигатор</span>
                <input
                    type="text w-100"
                    name="map_url"
                    placeholder="Не указано"
                    value={parkingCreateForm.map_url}
                    onChange={handleChange}
                />
              </label>
            </div>
            <div className="col-6 mt-3">
              <label className="field-style">
                <span>Время работы</span>
                <input
                    type="text"
                    name="working_hours"
                    placeholder="Не указано"
                    value={parkingCreateForm.working_hours}
                    onChange={handleChange}
                />
              </label>
            </div>
            <div className="field-style col-12 mt-3">
              <span>Схема проезда, общественный транспорт и пр.</span>
              <textarea
                name="nav_to_trans"
                id="car_additional"
                placeholder="Не указан"
                className="mw-100"
                value={parkingCreateForm.nav_to_trans}
                onChange={handleChange}
              ></textarea>
            </div>
          </div>
        </div>
        {/*  */}

        {/* Фотографии */}

         <div className="inner-page__item">
           <ParkingImageUploader updateToken={updateToken} />
        </div>
        {/*  */}

        {/* Параметры стоянки */}

         <div class="inner-page__item">
          <div class="inner-item-title">Параметры стоянки</div>
          <div class="row">
            <div class="col-6">
              <label class="field-style">
                <span>Кол-во машиномест</span>
                <input
                    name="qty_parking"
                    type="text"
                    placeholder="Не указано"
                    value={parkingCreateForm.qty_parking}
                    onChange={handleChange}
                />
              </label>
            </div>
            <div className="col-6">
              <label className="field-style">
                <span>Покрытие стоянки</span>
                <select
                    name="parking_surface"
                    id=""
                    className="page-select"
                    onChange={handleChange}
                >
                  <option value={0} disabled={true} selected={true}>Выберете покрытие</option>
                  {coverageIds.map((coverageId) => (
                      <option key={coverageId} value={coverageId}>
                        {coverageNames[coverageId]}
                      </option>
                  ))}
                </select>
              </label>
            </div>
            <div className="col-6">
              <label className="switch-radio-wrap d-flex mt-3">
                <input
                  type="checkbox"
                  name="covered_parking"
                  value={parkingCreateForm.covered_parking}
                  onChange={handleChange}
                />
                <span className="switcher-radio ml-auto"></span>
                <span className="check-box-text">Наличие крытых машиномест</span>
              </label>
              <label className="switch-radio-wrap d-flex mt-3">
                <input
                  type="checkbox"
                  name="heated_parking"
                  value={parkingCreateForm.heated_parking}
                  onChange={handleChange}
                />
                <span className="switcher-radio "></span>
                <span className="check-box-text">
                  Наличие отапливаемых машиномест
                </span>
              </label>
            </div>
            <div className="col-6">
              <label className="field-style mt-3">
                <span>Ограждение</span>
                <select
                    name="fencing"
                    id=""
                    className="page-select"
                    onChange={handleChange}
                >
                  <option value={0} disabled={true} selected={true}>Выберете ограждения</option>
                  {fenceIds.map((fenceId) => (
                      <option key={fenceId} value={fenceId}>
                        {fenceNames[fenceId]}
                      </option>
                  ))}
                </select>
              </label>
            </div>
            <div className="col-12">
              <div className="d-flex mt-4">
                <label className="switch-radio-wrap bold mr-5">
                  <input
                    type="checkbox"
                    name="cctv"
                    className="chech-dd"
                    value={parkingCreateForm.cctv}
                    onChange={handleChange}
                  />
                  <span className="switcher-radio"></span>
                  <span>Видеонаблюдение</span>
                </label>
                <label className="switch-radio-wrap bold mr-5">
                  <input
                    type="checkbox"
                    name="client_zones"
                    className="chech-dd"
                    value={parkingCreateForm.client_zones}
                    onChange={handleChange}
                  />
                  <span className="switcher-radio"></span>
                  <span>Клиентская зона (кулер, Wi-Fi и пр.)</span>
                </label>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
      </div>
    </div>
  );
}

export default ParkingApplication;
