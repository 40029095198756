import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  editUserReset,
  userEdit,
  userEditPut,
} from "../../../store/users/user-edit-slice/userEditSlice";
import { selectArrayRoles } from "../../../utils/select-array";
import selectStyle from "../../../utils/select-styles";
import InputMask from "react-input-mask";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";

function UserEditPage() {
  const { usersEdit } = useSelector((state) => state.userEdit);

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [password, setPassword] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState(true);
  const roles = selectArrayRoles(usersEdit.roles);
  const [userDataEdit, setUserDataEdit] = useState({});

  useEffect(() => {
    setUserDataEdit({
      name: usersEdit.length !== 0 ? usersEdit.user.name : "",
      status: usersEdit.length !== 0 ? usersEdit.user.status : "",
      email: usersEdit.length !== 0 ? usersEdit.user.email : "",
      phone: usersEdit.length !== 0 ? usersEdit.user.phone : "",
      role:
        usersEdit.length !== 0 && usersEdit.user.roles.length > 0
          ? usersEdit.user.roles[0].name
          : "",
    });
  }, [usersEdit]);

  useEffect(() => {
    dispatch(editUserReset());
    dispatch(userEdit(id));
  }, [dispatch]);

  const handlePasswordVisible = () => {
    setPassword(!password);
  };

  const handleConfirmPasswordVisible = () => {
    setConfirmPassword(!confirmPassword);
  };

  // Функция для отправки формы
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target ? e.target : e;
    let newValue = value;
    if (type === "checkbox") {
      newValue = checked ? 1 : 0;
    }
    setUserDataEdit({ ...userDataEdit, [name]: newValue });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    swal({
      title: "Вы уверены что хотите обновить данные?",
      icon: "warning",
      buttons: ["Отменить", "Да"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(userEditPut({ id: id, data: userDataEdit })).then(
          (response) => {
            if (response.payload.message === "Обновлено") {
              swal("Пользователь успешно создан!", {
                icon: "success",
              }).then(() => {
                dispatch(userEdit(id));
                navigate("/users");
              });
            } else {
              let errorMessage = "Ошибка при редактирование пользователя!";
              if (response.payload && response.payload.errors) {
                const errorMessages = Object.values(
                  response.payload.errors
                ).reduce((acc, val) => acc.concat(val), []);
                errorMessage = errorMessages.join("\n");
              }
              swal(errorMessage, {
                icon: "error",
              });
            }
          }
        );
      }
    });
  };
  //

  return (
    usersEdit.length !== 0 && (
      <form onSubmit={handleSubmit}>
        <div className="container page-head-wrap">
          <div className="page-head">
            <div className="page-head__top d-flex align-items-center">
              <h1>{usersEdit.title} </h1>
              <div className="ml-auto d-flex">
                <button type="submit" className="btn btn-white">
                  Обновить
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="inner-page">
            <div className="row no-gutters">
              <div className="col-md-12">
                <div className="inner-page__item">
                  <div className="inner-item-title">О пользователе</div>
                  <div className="row">
                    <div className="col-6">
                      <label className="field-style">
                        <span>Фамилия, имя</span>
                        <input
                          id="name"
                          type="text"
                          defaultValue={usersEdit.user.name}
                          name="name"
                          required
                          placeholder="Не указан"
                          onChange={handleChange}
                        />

                        <span className="invalid-feedback" role="alert">
                          <strong></strong>
                        </span>
                      </label>
                    </div>
                    <div className="col-6">
                      <label className="switch-radio-wrap mt-11px">
                        <input
                          type="checkbox"
                          name="status"
                          onChange={handleChange}
                          defaultChecked={usersEdit.user.status ? true : false}
                        />
                        <span className="switcher-radio"></span>
                        <span>Активен</span>
                      </label>
                    </div>
                    <div className="col-6 mt-3">
                      <label className="field-style">
                        <span>E-Mail</span>
                        <InputMask
                          maskChar="_"
                          defaultValue={usersEdit.user.email}
                          id="email"
                          name="email"
                          required
                          onChange={handleChange}
                          placeholder="example@example.com"
                        />
                      </label>
                    </div>
                    <div className="col-6 mt-3">
                      <label className="field-style">
                        <span>Тел</span>
                        <InputMask
                          // mask="+7 (999) 999-99-99"
                          maskChar="_"
                          defaultValue={usersEdit.user.phone}
                          id="phone"
                          name="phone"
                          required
                          onChange={handleChange}
                          placeholder="+7 (___) ___-__-__"
                        />
                      </label>
                    </div>

                    <div className="col-6 mt-3">
                      <label className="field-style select-react__style" style={{minWidth:'255px'}}>
                        <span>Роль</span>
                        <Select
                          defaultValue={{
                            value: usersEdit.user.roles[0].name,
                            label: usersEdit.user.roles[0].name,
                            name: "role",
                          }}
                          options={roles}
                          styles={selectStyle}
                          onChange={handleChange}
                          id="status-selection"
                        />
                      </label>
                    </div>
                  </div>
                </div>

                <div className="inner-page__item">
                  <div className="inner-item-title">Доступы</div>
                  <div className="row">
                    <div className="col-6">
                      <label className="field-style">
                        <span>Пароль*</span>
                        <span className="password">
                          <input
                            type={password ? "password" : "text"}
                            name="password"
                            placeholder="Не указан"
                            value={userEdit.password}
                            onChange={handleChange}
                          />
                          <div
                            className={`password-control  ${
                              password ? "" : "view"
                            }`}
                            onClick={handlePasswordVisible}
                          ></div>
                        </span>
                      </label>
                    </div>

                    <div className="col-6">
                      <label className="field-style">
                        <span>Подтверждение пароля*</span>
                        <span className="password">
                          <input
                            type={confirmPassword ? "password" : "text"}
                            value={userEdit.password_confirmation}
                            name="password_confirmation"
                            placeholder="Не указан"
                            onChange={handleChange}
                          />
                          <div
                            className={`password-control  ${
                              confirmPassword ? "" : "view"
                            }`}
                            onClick={handleConfirmPasswordVisible}
                          ></div>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  );
}

export default UserEditPage;
