import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {profileData, profileDataUpdate} from "../../store/profile/profile-get-update/profileGetUpdateSlice";
import ProfileItem from "../../components/profile/profile-item";
import Preloader from "../../components/preloader/preloader";
import swal from "sweetalert";

function Profile() {
    const dispatch = useDispatch()
    const [profileInfoStatus, setProfileInfoStatus] = useState()
    const [profileInfo, setProfileInfo] = useState({
        name: "",
        email: "",
        phone: "",
        moderation: true,
        password: "",
        password_confirmation: "",
        created_at : '',
        otp : '',
        parent_id : '',
        status : '',
        terms_accepted : '',
        updated_at : ''
    })

    useEffect(() => {
        dispatch(profileData()).then((response) => {
            setProfileInfo(response.payload.data.user)
            setProfileInfoStatus(response.payload.status)
        })
    }, [dispatch])

    const handleChange = (e) => {
        const {name, value} = e.target ? e.target : e;
        setProfileInfo({...profileInfo, [name]: value});
    };

    const onSubmit = (e)=>{
        e.preventDefault()
        switch (true){
            case profileInfo.password !== profileInfo.password_confirmation :
                swal("Пароли не совпадают, повторите ввод", {
                    icon: "warning",
                })
            case profileInfo.password === '' || profileInfo.password_confirmation === "" :
                swal("Оба пароля обязательны", {
                    icon: "warning",
                })
            case profileInfo.password === '' && profileInfo.password_confirmation === "" :
                delete profileInfo['password']
                delete profileInfo['password_confirmation']
                delete profileInfo['created_at']
                delete profileInfo['otp']
                delete profileInfo['parent_id']
                delete profileInfo['status']
                delete profileInfo['terms_accepted']
                delete profileInfo['updated_at']
                dispatch(profileDataUpdate(profileInfo)).then((response)=>{
                    swal("Данные обновлены", {
                        icon: "success",
                    })
                })
            default :
                // delete profileInfo['created_at']
                // delete profileInfo['otp']
                // delete profileInfo['parent_id']
                // delete profileInfo['status']
                // delete profileInfo['terms_accepted']
                // delete profileInfo['updated_at']
                dispatch(profileDataUpdate({profileInfo})).then((response)=>{
                    swal("Данные обновлены", {
                        icon: "success",
                    })
                })
        }
    }


    return profileInfoStatus === 200 ? (
        <div className={'container'}>
            <div className="inner_page">
                <form
                    onSubmit={(e)=>onSubmit(e)}
                    style={{background:'#fff'}}
                >
                    <div className=" page-head-wrap">
                        <div className="page-head">
                            <div className="page-head__top d-flex align-items-center">
                                <h1>Профиль</h1>
                                <div className="ml-auto d-flex">
                                    <button type="submit" className="btn btn-white">
                                        Сохранить
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="inner-page__item pt-5 pb-5 d-flex flex-column gap-3">
                        <ProfileItem
                            data={profileInfo?.name}
                            title={'Имя'}
                            name={'name'}
                            handleChange={handleChange}
                        />
                        <ProfileItem
                            data={profileInfo?.email}
                            title={'Email'}
                            name={'email'}
                            handleChange={handleChange}
                        />
                        <ProfileItem
                            data={profileInfo?.phone === null ? '' : profileInfo?.phone}
                            title={'Телефон'}
                            name={'phone'}
                            handleChange={handleChange}
                        />
                        <ProfileItem
                            data={profileInfo?.password === null ? '' : profileInfo?.password}
                            title={'Пароль'}
                            name={'password'}
                            handleChange={handleChange}
                        />
                        <ProfileItem
                            data={profileInfo?.password_confirmation === null ? '' : profileInfo?.password_confirmation}
                            title={'Подтверждение пароля'}
                            name={'password_confirmation'}
                            handleChange={handleChange}
                        />
                    </div>
                </form>
            </div>
        </div>
    ) : (
        <Preloader/>
    )
}

export default Profile;