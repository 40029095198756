import React from "react";
import { Link } from "react-router-dom";

function OfferPage() {
  window.scrollTo(0, 0); // Или document.documentElement.scrollTop = 0;

  return (
    <div className="container">
      <h1>
        Оферта на оказание Услуг по предоставлению доступа к Сервису для
        Исполнителя
      </h1>
      <p>
        Настоящий документ представляет собой предложение Общества с
        ограниченной ответственностью «А-Приори» (далее — MyParking) юридическим
        лицам и индивидуальным предпринимателям заключить Договор на оказание
        Услуг по предоставлению доступа к Сервису на изложенных ниже условиях.
      </p>
      <h2>Термины и определения</h2>
      <p>
        <strong>Оферта</strong> — настоящий документ «Оферта на оказание Услуг
        по предоставлению доступа к Сервису для Исполнителя», размещенный в сети
        Интернет по адресу&nbsp;
        <Link to={"/offer"}> https://myparking.info/offer/</Link>
      </p>
      <p>
        <strong>Сервис</strong> — программный комплекс, предоставляющий
        функциональные возможности для автоматизированного приема и передачи
        Исполнителем заказов по хранению, мойке, химчистке, фотографированию,
        эвакуации автотранспортных средств и иных услуг, позволяющий
        осуществлять удаленное взаимодействие Исполнителя со Службой Сервиса и с
        Заказчиками, а также предоставляющая Исполнителю иные функциональные
        возможности. Логин и пароль для доступа к Сервису для Исполнителя
        предоставляется Исполнителю при условии прохождения Исполнителем
        процедуры регистрации (совокупность действий Исполнителя по
        предоставлению данных Службе Сервиса и формированию уникальных
        авторизационных данных). К указанным логину и паролю применяются все
        требования, установленные Договором.
      </p>
      <p>
        В Сервисах доступна информация о статистике и иная информация в
        отношении Услуг, оказываемых Службой Сервиса в соответствии с Договором,
        а также доступен функционал, обеспечивающий удаленное взаимодействие в
        рамках оказания услуг по Договору.
      </p>
      <p>
        Договор на оказание Услуг по предоставлению доступа к Сервису,
        Договор&nbsp;— возмездный договор между MyParking и Исполнителем на
        оказание Услуг, который заключается посредством Акцепта Оферты.
      </p>
      <p>
        <strong>Акцепт Оферты</strong>&nbsp;— полное и безоговорочное принятие
        Оферты путем осуществления действий, указанных в разделе 7 Оферты.
        Акцептом Оферты заключается Договор.
      </p>
      <p>
        <strong>Исполнитель</strong> – юридическое лицо или индивидуальный
        предприниматель, оказывающее услуги по хранению, мойке, химчистке,
        фотографированию, эвакуации автотранспортных средств, а также иных
        услуг, полный перечень которых указывается Исполнителем в Личном
        кабинете Сервиса, в соответствии с законодательством РФ, заключившее с
        MyParking Договор на оказание услуг по предоставлению доступа к Сервису
        в порядке п. 7.1. Оферты.
      </p>
      <p>
        <strong>Заказчик</strong>&nbsp;— физическое лицо или юридическое лицо,
        использующее Сервис на условиях, изложенных в документе «Оферта на
        оказание Услуг по предоставлению доступа к Сервису для Заказчика»,
        размещенном в сети Интернет по адресу:&nbsp;
        <Link to={"/offer"}> https://myparking.info/offer/</Link>
      </p>
      <p>
        <strong>Заявка</strong>&nbsp;— размещенная Заказчиком с использованием
        Сервисов информация о потенциальном спросе на Услуги на Территории.
      </p>
      <p>
        <strong>Уведомление</strong>&nbsp;— информация о размещенном Заказчиком
        на Сервисе Заявке, направляемая Службой Сервиса Исполнителю.
      </p>
      <p>
        <strong>Подтверждение</strong>&nbsp;— направленное Исполнителем Службе
        Сервиса уведомление о том, что Исполнитель готов оказать перечисленные в
        Заявке услуги.
      </p>
      <p>
        <strong>Тариф Исполнителя</strong> — согласованные Сторонами тарифы на
        услуги, предоставляемые Исполнителем, полный перечень которых указан в
        Личном кабинете Исполнителя и заказываемые Заказчиком через Сервис.
      </p>
      <p>
        <strong>Территория</strong>&nbsp;— территория Российской Федерации, где
        одновременно: а) MyParking имеет техническую возможность оказывать
        Услуги; б) Исполнитель оказывает услуги согласно перечня, указанного в
        Личном кабинете Исполнителя и по Заявкам Заказчиков, размещенным через
        Сервис.
      </p>
      <p>
        <strong>Личный кабинет</strong>&nbsp;— раздел сайта MyParking.info
        который содержит информацию об Исполнителе, данные статистики и иную
        информацию в отношении услуг, оказываемых Исполнителем в соответствии с
        Договором, а также предоставляет возможность удаленного взаимодействия
        Сторон в рамках Договора, доступный Исполнителю после авторизации на
        сайте MyParking.info с использованием логина и пароля Исполнителя.
      </p>
      <p>
        <strong>Отчетный период</strong>&nbsp;— период, равный календарному
        месяцу. При этом первым Отчетным периодом признается период времени с
        момента Акцепта Оферты до окончания соответствующего календарного
        месяца, а последним Отчетным периодом — период времени с даты начала
        соответствующего календарного месяца до даты прекращения (расторжения,
        окончания срока действия) Договора.
      </p>
      <p>
        <strong>Лицевой счет</strong>&nbsp;— уникальный идентификатор
        Исполнителя, который используется для единого суммарного учета оказанных
        MyParking Услуг, информации о платежах за Услуги и о задолженности
        Исполнителя за оказанные, но неоплаченные Услуги по Договору. Лицевой
        счет носит технический характер и не имеет статуса расчетного или
        банковского счета.
      </p>
      <p>
        <strong>Внесение денежных средств на Лицевой счет</strong>&nbsp;—
        авансовый платеж Исполнителя на расчетный счет MyParking по счету,
        выставленному MyParking.
      </p>
      <p>
        <strong>Списание денежных средств с Лицевого счета</strong>&nbsp;—
        списание MyParking денежных средств из авансовых платежей Исполнителя в
        качестве оплаты за Услуги, осуществляемое в текущем режиме.
      </p>
      <p>
        <strong>Баланс Лицевого счета</strong>&nbsp;— разность между двумя
        суммами денежных средств в определенный момент времени. Первая сумма
        состоит из денежных средств, внесенных на Лицевой счет до данного
        момента времени; вторая сумма состоит из денежных средств, списанных с
        Лицевого счета до данного момента времени.
      </p>
      <p>
        В Оферте могут быть использованы термины, не определенные в настоящем
        разделе Оферты. В этом случае толкование такого термина производится в
        соответствии с текстом настоящей Оферты. В случае отсутствия
        однозначного толкования термина в тексте указанных документов следует
        руководствоваться толкованием термина, определенным: в первую очередь —
        законодательством РФ, во вторую очередь — сложившимся (общеупотребимым)
        в сети Интернет.
      </p>
      <h2>1. Предмет Договора</h2>
      <p>
        1.1. Предметом настоящей Оферты является оказание MyParking Исполнителю
        Услуг в порядке и на условиях, указанных в Оферте, а также исполнение
        обязательств, предусмотренных разделом 4 Оферты.
      </p>
      <h2>2. Обязанности Сторон</h2>
      <p>2.1.&nbsp;MyParking обязуется:</p>
      <p>
        2.1.1. Оказывать услуги по предоставлению доступа к Сервису в
        соответствии с условиями Договора.
      </p>
      <p>
        2.1.2. Направлять акт об оказанных услугах и счет-фактуру в порядке,
        предусмотренном в разделе 6 Оферты.
      </p>
      <p>
        2.1.3. Предоставлять доступ к Сервису, обеспечивать исправление ошибок,
        возникших во время их эксплуатации (при наличии возможности), за
        исключением случаев, связанных с неисправностью оборудования Исполнителя
        или Заказчика, ошибками Исполнителя или Заказчика при использовании
        Сервиса или ее собственных технических средств и устройств.
      </p>
      <p>2.2.&nbsp;MyParking имеет право:</p>
      <p>
        2.2.1. Вносить изменения в Оферту без предварительного уведомления
        Исполнителя.
      </p>
      <p>
        2.2.2. Приостановить оказание Услуг по Договору и/или досрочно
        расторгнуть Договор в одностороннем внесудебном порядке путем
        уведомления Исполнителя в случаях, предусмотренных Офертой. При этом
        оказание Услуг по Договору приостанавливается, и/или Договор считается
        расторгнутым с даты уведомления Службой Сервиса Исполнителя.
      </p>
      <p>
        2.2.3. Осуществлять любые действия (контрольные мероприятия) по проверке
        качества оказания Исполнителем услуг по хранению, мойке, химчистке,
        фотографированию, эвакуации автотранспортных средств и иных услуг.
        Данные, полученные в результате осуществления контрольных мероприятий,
        являются достаточным, но не единственным основанием для установления
        несоответствия качества услуг Исполнителя требованиям Договора. При этом
        Служба Сервиса самостоятельно определяет способы, периодичность и
        основания проведения контрольных мероприятий.
      </p>
      <p>
        2.2.4. По своему усмотрению и без согласования с Исполнителем вносить
        изменения в содержание, функциональные возможности и пользовательский
        интерфейс Сервиса, а также в руководства по использованию Сервиса.
      </p>
      <p>
        2.2.5. Проводить профилактические работы на сервере, на котором
        расположен Сервис для Исполнителей, по возможности в период с 19 часов
        вечера до 4 часов утра (время московское).
      </p>
      <p>
        2.2.6. Приостановить оказание Услуг или расторгнуть Договор в случае
        наличия у Исполнителя задолженности перед MyParking по Договору или по
        какому-либо другому договору, заключенному между Исполнителем и
        MyParking.
      </p>
      <p>
        2.2.7. Размещать на Сервисе для доступа неограниченного круга лиц
        информацию о коммерческом обозначении, фирменном наименовании и адресе
        Исполнителя, в целях предоставления Заказчикам информации об Исполнителе
        в соответствии с действующим законодательством.
      </p>
      <p>
        2.2.8. В случае нарушения Исполнителем условий настоящей Оферты
        ограничивать функциональные возможности Сервиса.
      </p>
      <p>
        2.2.9. Вести записи всех обращений и сеансов связи с любыми
        представителями Исполнителя в целях разрешения разногласий, спорных
        ситуаций, осуществления прав и интересов MyParking.
      </p>
      <p>
        2.2.10. Прекратить оказание Услуг Исполнителю без направления
        предварительного уведомления, а также расторгнуть Договор в
        одностороннем внесудебном порядке путем уведомления Исполнителя, если
        Исполнитель перестает удовлетворять критериям добросовестности и
        благонадежности, установленным MyParking. MyParking вправе не раскрывать
        Исполнителю причины, по которым MyParking относит Исполнителя к
        контрагентам, которые не удовлетворяют критериям добросовестности и
        благонадежности.
      </p>
      <p>
        2.2.11. Прекратить оказание Услуг Исполнителю без направления
        предварительного уведомления, а также расторгнуть Договор в
        одностороннем внесудебном порядке путем уведомления Исполнителя, если
        Исполнитель предоставляет MyParking некорректные платежные реквизиты, в
        том числе принадлежащие третьим лицам без предоставления юридических
        оснований, по которым MyParking вправе осуществлять перевод полученных
        денежных средств в рамках исполнения Поручения, предусмотренного
        разделом 4 настоящей Оферты. В случае обнаружения MyParking
        некорректных/не принадлежащих Исполнителю платежных реквизитов,
        MyParking вправе приостановить перечисление денежных средств, полученных
        MyParking в рамках исполнения Поручения, предусмотренного разделом 4
        настоящей Оферты, а также приостановить выплаты по любым иным,
        заключенным между MyParking и Исполнителем договорам, до момента
        предоставления Исполнителем корректных платежных реквизитов.
      </p>
      <p>2.3.&nbsp;Исполнитель обязуется:</p>
      <p>
        2.3.1. Предоставить MyParking контактные данные лиц, ответственных за
        оперативное взаимодействие по вопросам исполнения Договора.
      </p>
      <p>
        2.3.2. Предоставлять MyParking в течение срока действия Договора
        информацию (далее — Информация), фирменное наименование и адрес
        Исполнителя, необходимую для оказания MyParking Исполнителю Услуг и в
        целях реализации прав и законных интересов MyParking, его партнеров и
        Заказчиков. Исполнитель понимает и соглашается с тем, что информация о
        фирменном наименовании и адресе Исполнителя будет общедоступна.
      </p>
      <p>
        2.3.3. Принимать Услуги MyParking в порядке и на условиях,
        предусмотренных Договором, и оплачивать Услуги MyParking в размере и в
        сроки, предусмотренные разделом 6 Оферты.
      </p>
      <p>
        2.3.4. Оказывать Заказчику услуги по хранению, мойке, химчистке,
        фотографированию, эвакуации автотранспортных средств, а также иные
        услуги, полный перечень которых представлен в Личном кабинете
        Исполнителя, в соответствии с критериями, указанными Заказчиком в
        Заявке, а также в соответствии с требованиями настоящего документа и
        действующего законодательства Российской Федерации.
      </p>
      <p>
        2.3.5. Оказывать содействие MyParking при проведении контрольных
        мероприятий согласно п. 2.2.3. Оферты.
      </p>
      <p>
        2.3.6. Использовать Сервисы только в пределах тех прав и теми способами,
        которые предусмотрены Договором и с соблюдением всех условий руководств
        по использованию Сервисами.
      </p>
      <p>
        2.3.7. Самостоятельно нести ответственность за сохранность и
        конфиденциальность регистрационных данных (логин и пароль). Все
        действия, осуществленные с использованием логина и пароля Исполнителя в
        Сервисе или Личном кабинете считаются осуществленным Исполнителем.
        Исполнитель самостоятельно несет ответственность перед третьими лицами
        за все действия, совершенные с использованием логина и пароля
        Исполнителя. MyParking не несет ответственности за несанкционированное
        использование регистрационных данных Исполнителя третьими лицами.
      </p>
      <p>
        2.3.8. Поддерживать в актуальном состоянии адрес электронной почты
        контактного лица, указанный в Личном кабинете, а также гарантировать
        доступ к указанному почтовому ящику только со стороны лиц,
        уполномоченных Исполнителем на взаимодействия с MyParking по всем
        вопросам, связанным с исполнением, изменением, расторжением,
        прекращением Договора.
      </p>
      <p>
        2.3.9. Оплачивать Услуги MyParking на условиях, согласованных в
        настоящем документе.
      </p>
      <p>2.4.&nbsp;Исполнитель имеет право:</p>
      <p>
        2.4.1. На доступ к данным статистики в установленном порядке. При этом
        все действия, осуществленные Исполнителем через Личный кабинет с
        использованием логина и пароля Исполнителя, считаются осуществленными
        Исполнителем.
      </p>
      <p>
        2.4.2. В случае несогласия с изменениями, внесенными MyParking в Оферту,
        отказаться от исполнения Договора в одностороннем порядке, письменно
        уведомив MyParking в срок не позднее 10 (десяти) календарных дней с
        момента публикации указанных изменений в сети Интернет по адресу&nbsp;
        <Link to={"/offer"}> https://myparking.info/offer/</Link>
        &nbsp;и оплатив MyParking стоимость фактически оказанных Услуг. Договор
        считается расторгнутым с момента получения MyParking уведомления
        Исполнителя.
      </p>
      <h2>3. Порядок взаимодействия Сторон при оказании Услуг</h2>
      <p>3.1.&nbsp;Данные, направляемые Исполнителем Службе Сервиса.</p>
      <p>
        Для целей оказания Услуг Стороны согласовали, что Исполнитель&nbsp;
        направляет MyParking следующие соответствующие действительности данные:
      </p>
      <p>
        3.1.1. Контактные данные ответственных лиц (ФИО, телефон, адрес почты,
        сайт, мессенджер и т.п.)
      </p>
      <p>
        3.1.2 Точный адрес фактического местонахождения парковки, телефон
        оперативной связи.
      </p>
      <p>3.1.3 Карточка Компании, банковские реквизиты.</p>
      <p>
        3.1.4 Полный перечень предоставляемых услуг (хранение, мойка, эвакуация,
        персональный менеджер, реализация и оценка ТС, и др.)
      </p>
      <p>
        3.1.5. Документ на право собственности или аренды территории и строений.
      </p>
      <p>
        3.1.6 Согласие об актуализации информации и ее достоверности (наличие
        свободных мест, соблюдение заявленных регламентов и стандартов.
      </p>
      <p>3.2.&nbsp;Формирование, передача и исполнение Заявок Заказчиков.</p>
      <p>
        Стороны согласовали следующий порядок формирования, передачи и
        исполнения Заявок Заказчиков:
      </p>
      <p>3.2.1.&nbsp;Формирование заявки</p>
      <p>
        По умолчанию Заказчику посредством использования Сервиса предлагается
        отправить Заявку всем Исполнителям, которые соответствуют Заявке, при
        этом у Заказчика есть возможность выбрать для поиска определенного
        Исполнителя по своему усмотрению.
      </p>
      <p>
        После размещения Заказчиком Заявки MyParking направляет Исполнителю
        Уведомление.
      </p>
      <p>
        3.2.2. Уведомление, направляемое Исполнителям, может содержать следующую
        информацию:
      </p>
      <ul>
        <li>Тариф на оказание услуг</li>
        <li>Дополнительные требования к автостоянке, если указаны;</li>
        <li>Комментарий, если указан Заказчиком;</li>
        <li>
          Форма оплаты (непосредственно Исполнителю либо безналичная оплата
          MyParking способами, предусмотренными Сервисом).
        </li>
      </ul>
      <p>3.2.3.&nbsp;Действия Исполнителя при получении Уведомления</p>
      <p>
        В случае готовности Исполнителя выполнить Заявку в соответствии с
        указанными в Уведомлении условиями Исполнитель сообщает об этом
        MyParking.
      </p>
      <p>3.2.4.&nbsp;Передача Заявки</p>
      <p>
        Определение MyParking Исполнителя, которому передается Заявка для
        выполнения, осуществляется автоматически в соответствии с алгоритмами
        MyParking, посредством акцепта Заявки.
      </p>
      <p>Выбранный Исполнитель получает полную информацию о Заявке:</p>
      <ul>
        <li>Наименование Заказчика;</li>
        <li>Контактные данные Заказчика.</li>
        <li>Тариф.</li>
        <li>Дополнительные требования, если указаны;</li>
        <li>Комментарий, если указан.</li>
      </ul>
      <p>
        В ответ на получение полной информации о Заявке Исполнитель направляет
        MyParking Подтверждение либо сообщает о том, что не готов оказать
        перечисленные в Заявке услуги. Направление Подтверждения означает, что
        Исполнитель готов оказать Заказчику перечисленные в Заявке услуги в
        полном объеме и на указанных в Заявке условиях. После получения
        MyParking Подтверждения Заявка считается принятой к исполнению
        Исполнителем. В случае отказа Исполнителя от выполнения заявки MyParking
        продолжает поиск другого Исполнителя для выполнения Заявки.
      </p>
      <p>
        После того, как MyParking направил Исполнителю полные данные о Заявке, а
        Исполнитель направил MyParking Подтверждение, взаимодействие с
        Заказчиком производится Исполнителем самостоятельно (без участия
        MyParking) в соответствии с принятыми у Исполнителями правилами оказания
        услуг. Исполнитель обязан оказать перечисленные в Заявке услуги в
        соответствии с требованиями действующего законодательства и настоящего
        документа.
      </p>
      <p>3.2.5.&nbsp;Информация об исполнении Заявки</p>
      <p>
        После получения Заявки Исполнитель осуществляет дальнейшее оказание
        Заказчику перечисленных в Заявке услуг. Исполнитель отправляет MyParking
        следующую информацию:
      </p>
      <ul>
        <li>
          Заказ выполнен. Вместе с этим статусом должна передаваться стоимость
          оказанной услуги,
        </li>
        <li>
          Заказ отменен Исполнителем со ссылкой на отказ Заказчика от Заказа и
          указанием причины отказа.
        </li>
      </ul>
      <p>3.2.6.&nbsp;Отмена Заявки</p>
      <p>Сервис сообщает Исполнителю об отмене Заявки, в случаях если:</p>
      <ul>
        <li>Заявка отменена Заказчиком;</li>
        <li>Заявка передана другому Исполнителю.</li>
      </ul>
      <p>3.3.&nbsp;Требования к Исполнителю.</p>
      <ul>
        <li>Наличие круглосуточной охраны;</li>
        <li>
          Наличие необходимой инфраструктуры, которая обеспечивает должный
          уровень оказания услуг
        </li>
        <li>
          Соответствие иным требованиям, предусмотренным законодательством.
        </li>
        <li>
          Исполнитель обязуется обеспечить соответствие профессиональных качеств
          и квалификации сотрудников Исполнителя требованиям Сервиса и
          действующего законодательства Российской Федерации;
        </li>
        <li>
          Исполнитель обязуется обеспечить оказание Заказчикам услуг по тарифам,
          не превышающим согласованный Сторонами максимальный Тариф.
        </li>
        <li>
          Исполнитель обязуется по электронной почте отвечать на запросы
          MyParking касательно обращений Заказчиков, в течение 24 часов с
          момента их направления в целях урегулирования конфликтных ситуаций и,
          при необходимости, выплаты компенсации за причиненный Заказчику ущерб,
          а также сообщать о принятых мерах MyParking
        </li>
        <li>
          Отменять выполнение Заявки исключительно в форс-мажорных
          обстоятельствах, за исключением отмены по инициативе Заказчика, и
          сообщать Заказчику о такой отмене по факту, но не менее чем за 8 часов
          после случившегося. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </li>
      </ul>
      <p>
        3.4.&nbsp;Приостановление и Прекращение доступа Исполнителя к Сервису.
      </p>
      <p>
        MyParking оставляет за собой право приостановить оказание Услуг
        Исполнителю в следующих случаях:
      </p>
      <ul>
        <li>
          По техническим, технологическим или иным причинам, препятствующим
          корректной работе Сервиса и на время устранения таких причин;
        </li>
        <li>
          При наличии многократных жалоб со стороны Заказчиков на Исполнителя;
        </li>
        <li>
          В случае расчета стоимости оказанных Заказчику услуг по тарифу,
          превышающему максимальный Тариф MyParking, согласованный Сторонами;
        </li>
        <li>
          Нарушение Исполнителем обязанности по выполнению Заявок Заказчиков,
          размещенных в рамках Сервиса, с тем же приоритетом, что и заказы,
          полученные Исполнителем из других источников;
        </li>
        <li>Недобросовестное исполнение Заявок Сервиса;</li>
        <li>
          Осуществление Исполнителем иных недобросовестных действий в отношении
          Сервиса. При этом MyParking вправе по своему усмотрению определять,
          является ли то или иное действие Исполнителя недобросовестным по
          отношению к Сервису и не раскрывать Исполнителю алгоритм выявления
          таких действий. В случае, если недобросовестные действия Исполнителя
          привели к убыткам MyParking, в том числе к упущенной выгоде, MyParking
          имеет право требовать возмещения убытков;
        </li>
        <li>
          Грубое, невежливое, некорректное поведение сотрудников Исполнителя в
          отношении Заказчика/представителя Заказчика, а также представителей
          Сервиса;
        </li>
        <li>Отказа Исполнителя от исполнения 75 % и более Заявок.</li>
        <li>
          В иных случаях нарушения Исполнителем обязательств, предусмотренных
          Договором.
        </li>
      </ul>
      <p>
        В случае отказа Исполнителя от проверки MyParking качества оказания
        Заказчикам услуг, а также в случаях, если MyParking посчитает, в том
        числе по результатам осуществления контрольных мероприятий, качество
        услуг Исполнителя не соответствующим требованиям настоящего документа,
        MyParking вправе направить замечание с требованием устранить допущенные
        нарушения и/или приостановить оказание Услуг Исполнителю и/или
        расторгнуть Договор в одностороннем порядке.
      </p>
      <p>
        3.5. Обмен информацией, указанной в разделе 3 настоящего документа,
        осуществляется посредством интерфейсов Сервиса.
      </p>
      <h2>4. Прием MyParking безналичных денежных средств</h2>
      <p>
        4.1. Не вступая в противоречия с условиями заключенного между Сторонами
        Договора, Стороны зафиксировали условия агентского поручения Исполнителя
        (далее по тексту настоящего раздела — Принципал) и порядок его
        исполнения MyParking (далее по тексту настоящего раздела — Агент).
      </p>
      <p>
        4.2. Агент по поручению Принципала обязуется за вознаграждение совершать
        от своего имени, но за счет Принципала фактические действия по приему
        безналичных платежей Заказчиков за выполнение Подтвержденных Заявок и
        иных безналичных платежей Заказчиков в пользу Принципала, связанных с
        выполнением таких Заявок (далее по тексту — Поручение).
      </p>
      <p>
        4.3. Для целей настоящего раздела Оферты стоимостью выполнения
        Подтвержденной Заявки считается сообщенная Исполнителем стоимость
        услуги, оказанной Исполнителем в соответствии с Заявкой Заказчика.
      </p>
      <p>
        4.4. Оплата Заказчиком стоимости выполнения Подтвержденной Заявки может
        осуществляться по выбору Заказчика:
      </p>
      <p>4.4.1. Непосредственно Принципалу</p>
      <p>Либо</p>
      <p>
        4.4.2. В порядке безналичной оплаты Агенту (способами, предусмотренными
        Сервисом для Заказчика).
      </p>
      <p>
        4.5. Суммы оплаты, полученные Агентом от Заказчиков в соответствии с п.
        4.4.2. Оферты, подлежат перечислению Агентом Принципалу за вычетом сумм,
        полагающихся Агенту в качестве агентского вознаграждения. Перечисление
        указанных денежных средств осуществляется Агентом на счет Принципала,
        указанный Принципалом в Личном кабинете в срок, не превышающий 15
        банковских дней с момента получения Агентом от Заказчиков указанных
        сумм.
      </p>
      <p>
        Перечисление сумм оплаты будет производиться в соответствии с правилами,
        тарифами и ставками, установленными соответствующим оператором платёжной
        системы и (или) кредитной организацией. Агент не несет ответственности в
        случае возникновения у Принципала претензий к действиям и (или)
        бездействию выбранного Принципалом оператора платёжной системы, и (или)
        кредитной организации в отношении заявки Принципала на перечисление сумм
        оплаты или иных операций.
      </p>
      <p>
        Принципал соглашается с тем, что при осуществлении процедуры
        перечисления сумм оплаты выбранный Принципалом оператор платёжной
        системы или кредитная организация могут удерживать свои комиссии из
        перечисляемых сумм и запрашивать дополнительные документы для
        осуществления операции.
      </p>
      <p>
        Агент не обязан участвовать во взаимодействии между Принципалом и
        платёжной системой, или кредитной организацией.
      </p>
      <p>
        4.6. Срок действия Поручения, предусмотренного п. 4.2. Оферты, равен
        сроку действия Договора.
      </p>
      <p>
        4.7. Вознаграждение Агента за совершение фактических действий согласно
        п. 4.2. Оферты включено в общую стоимость Услуг по настоящей Оферте и
        определяется в соответствии с разделом 6 настоящей Оферты.
      </p>
      <p>
        4.8. Агент не является платежным агентом при проведении расчетов в
        соответствии с настоящим разделом Оферты согласно с пп. 1, 4 ч. 2 ст. 1
        Федерального закона от 03.06.2009 N 103-ФЗ "О деятельности по приему
        платежей физических лиц, осуществляемой платежными агентами".
      </p>
      <p>
        4.9. В случае получения MyParking обращений Заказчиков в любой форме об
        их несогласии с фактом и/или суммой безналичной оплаты, произведенной в
        пользу Исполнителя с использованием Сервиса, Исполнитель уполномочивает
        MyParking рассмотреть соответствующее обращение Заказчика и принять
        решение о возврате Заказчику суммы произведенной оплаты.
      </p>
      <p>
        4.10. На основании указанной информации MyParking по своему усмотрению
        принимает решение по обращению Заказчика, в том числе о полном или
        частичном возврате Заказчику суммы произведенной оплаты, отказе от
        возврата и/или иное решение по существу обращения Заказчику.
      </p>
      <p>
        4.11. В случае принятия MyParking решения о полном или частичном
        возврате Заказчику суммы оплаты, произведенной в пользу Исполнителя с
        использованием Сервиса, сумма, подлежащая возврату Заказчику, подлежит
        возмещению MyParking Исполнителем (в частности, может быть удержана
        и/или подлежит оплате по счету в порядке, предусмотренном настоящим
        разделом Оферты).
      </p>
      <p>
        4.12. Осуществление MyParking указанных возвратов денежных средств
        Заказчиков не является основанием для пересмотра (уменьшения) размера
        агентского вознаграждения, подлежащего начислению и оплате MyParking
      </p>
      <p>
        4.13. В случае установления MyParking факта непоступления безналичного
        платежа Заказчика в пользу Исполнителя по выполненной Заявке (в
        частности, получение MyParking соответствующей информации от Исполнителя
        и/или самостоятельное выявление данного факта) MyParking принимает на
        себя ручательство за перечисление денежных средств в размере стоимости
        Заявки, определенной согласно разделу 5 Оферты. Указанное ручательство
        действует в случае обращения Исполнителя за перечислением денежных
        средств не позднее 45 дней с момента выполнения соответствующей Заявки и
        не распространяется на случаи непоступления безналичных платежей,
        связанные с недобросовестными действиями Исполнителя, Заказчика или иных
        лиц. При этом MyParking вправе по своему усмотрению принять решение о
        недобросовестности действий Исполнителя, Заказчика или иных лиц. В
        случае перечисления MyParking не поступившего безналичного платежа и
        последующего поступления в пользу Исполнителя платежа Заказчика, в
        отношении которого MyParking было сделано соответствующее перечисление,
        сумма платежа Заказчика не подлежит перечислению Исполнителю и
        учитывается в счет ранее перечисленных MyParking денежных средств.
      </p>
      <p>
        Вознаграждение за ручательство, указанное в настоящем пункте, включено в
        размер вознаграждения MyParking, рассчитываемое согласно разделу 6
        Оферты.
      </p>
      <p>
        4.14. В случае несогласия Исполнителя с решением, принятым MyParking в
        соответствии с п. 4.10. настоящей Оферты и/или возмещением суммы,
        предусмотренным п. 4.11. настоящей Оферты, а равно с действиями
        MyParking в соответствии с п. 4.13. настоящих Условий, Исполнитель
        уведомляет об этом MyParking. В этом случае MyParking вправе немедленно
        приостановить оказание Услуг и выполнение Поручения по Договору до
        достижения Сторонами согласия по указанному вопросу.
      </p>
      <p>4.15.&nbsp;Принципал обязуется:</p>
      <p>
        4.15.1. Без промедления принять от Агента все исполненное им в
        соответствии с Поручением.
      </p>
      <p>
        4.15.2. Оплатить Агенту вознаграждение в порядке и на условиях
        настоящего раздела Оферты.
      </p>
      <p>
        4.15.3. Предоставить Агенту всю необходимую для исполнения Поручения
        информацию. Принципал подтверждает и гарантирует, что информация о
        стоимости выполнения Подтвержденной Заявки является правильной, точной и
        достоверной.
      </p>
      <p>
        4.15.4. Принципал гарантирует, что исполнение Агентом Поручения на
        условиях настоящего раздела Оферты не нарушает и не влечет нарушение
        каких-либо прав третьих лиц и действующего законодательства РФ.
      </p>
      <p>
        4.15.5. Оформление предусмотренных законодательством РФ документов при
        оказании услуг и осуществлении расчетов с физическими лицами
        осуществляется Принципалом.
      </p>
      <p>4.16.&nbsp;Агент обязуется:</p>
      <p>
        4.16.1. Принятое на себя Поручение исполнить на наиболее выгодных для
        Принципала условиях, добросовестно и разумно, в соответствии с
        указаниями Принципала.
      </p>
      <p>
        4.16.2. Передать Принципалу суммы оплат, полученные Агентом от
        Заказчиков, в соответствии с п. 4.5. настоящего раздела Оферты.
      </p>
      <p>
        4.16.3. Сообщать Принципалу по его требованию все сведения о ходе
        исполнения Поручения.
      </p>
      <p>4.17.&nbsp;Принципал вправе:</p>
      <p>
        4.17.1. Получать от Агента все сведения о ходе исполнения Поручения.
      </p>
      <p>
        4.17.2. В любое время отменить Поручение при условии предварительного
        возмещения Агенту убытков, вызванных отменой Поручения.
      </p>
      <p>4.18.&nbsp;Агент вправе:</p>
      <p>
        4.18.1. Получить агентское вознаграждение в порядке и на условиях,
        предусмотренных настоящим разделом Оферты.
      </p>
      <p>
        4.18.2. Агент вправе привлекать третьих лиц для выполнения своих
        обязательств по настоящему разделу Оферты, оставаясь ответственным за их
        действия перед Принципалом.
      </p>
      <p>4.19.&nbsp;Сдача Отчета Агента</p>
      <p>
        4.19.1. По окончании каждого Отчетного периода (календарный месяц),
        приходящегося на период действия Договора, Агент формирует и направляет
        Принципалу односторонний Отчет об исполнении обязательств Агента в
        отчетном периоде (далее по тексту — Отчет) с указанием следующей
        информации:
      </p>
      <ul>
        <li>
          Общая сумма принятых Агентом безналичных платежей Заказчиков в пользу
          Принципала;
        </li>
        <li>
          таблица с расшифровкой расчетов с Принципалом (в том числе, при
          наличии таковых: общая сумма принятых Агентом безналичных платежей
          Заказчиков; общая сумма возвращенных в отчетном периоде Агентом
          Заказчикам денежных средств; сумма, перечисленная Агентом на счет
          Принципала в отчетном периоде; сумма, подлежащая удержанию Агентом в
          связи с возвратом Заказчикам денежных средств в отчетном периоде;
          сальдо на начало и конец отчетного периода, размер агентского
          вознаграждения и т.д.).
        </li>
      </ul>
      <p>
        Отчет направляется Агентом Принципалу не позднее 7 (семи) рабочих дней
        после окончания Отчетного периода.
      </p>
      <p>
        4.20.1. Отчет направляется Агентом Принципалу по электронной почте, с
        одновременным направлением копии текста Отчета по почте и/или
        посредством Сервиса.
      </p>
      <p>
        4.20.2. Стороны признают, что копия текста Отчета, направленная Агентом
        Принципалу по электронной почте и/или посредством Сервиса, будет
        применяться Принципалом в процессе приемки выполненных Агентом
        обязательств наравне с оригиналом Отчета.
      </p>
      <p>
        4.20.3. Датой получения копии текста Отчета, направленного Агентом
        Принципалу по электронной почте и/или посредством Сервиса считается
        следующий рабочий день за днем отправки.
      </p>
      <p>
        4.20.4. Стороны установили, что в случае, если в течение 5 (пяти)
        календарных дней с даты формирования Агентом Отчета Агент не получил от
        Принципала мотивированных письменных возражений по Отчету, указанный
        Отчет считается утвержденным Принципалом без возражений, а Поручение —
        выполненным Агентом в Отчетном периоде в полном объеме и принятым
        Принципалом.
      </p>
      <p>4.21.&nbsp;Вознаграждение Агента:</p>
      <p>
        4.21.1. За выполнение поручения Принципал начисляет Агенту
        вознаграждение в размере и порядке, определённом настоящим разделом
        Оферты.
      </p>
      <p>
        4.21.2. Вознаграждение Агента исчисляется в рублях Российской Федерации
        и включено в общую стоимость Услуг по настоящей Оферте, установленной
        разделом 6 настоящей Оферты.
      </p>
      <p>
        4.21.3. Стороны определили, что все расходы, которые несет Агент в
        процессе исполнения Поручения, включая, но не ограничиваясь, расходами
        на оплату услуг банков по перечислению денежных средств в адрес
        Принципала, аренду, телефонные переговоры, командировочные, заработную
        плату сотрудникам, оплату услуг привлеченных субагентов и любые иные
        расходы, считаются оплаченными Принципалом Агенту посредством выплаты
        агентского вознаграждения и не подлежат дополнительному возмещению
        Принципалом.
      </p>
      <p>
        4.21.4. В случаях возврата Агентом денежных средств Заказчика сумма,
        подлежащая возврату Заказчику, подлежит возмещению Принципалом Агенту и
        удерживается Агентом при перечислении полученных от Заказчиков денежных
        средств Принципалу (т.е. полученная от Заказчика сумма оплаты
        перечисляется Принципалу за вычетом суммы произведенных Агентом
        возвратов).
      </p>
      <p>
        4.21.5. Агент вправе удержать денежные средства в размере стоимости
        Услуг Агента, рассчитанной согласно правилам, установленным разделом 6
        Оферты, а равно денежные средства в размере задолженности Исполнителя по
        любому иному договору, заключенному с MyParking, из безналичных денежных
        средств, полученных MyParking в соответствии с п. 4.4.2 Оферты (в
        порядке безналичной оплаты MyParking как агенту Исполнителя). Такое
        удержание денежных средств не будет считаться Сторонами нарушением
        Агентом обязательств, принятых на себя в соответствии с Договором.
      </p>
      <p>
        4.21.6. В случае несвоевременного возмещения сумм осуществленных Агентом
        возвратов денежных средств Заказчикам, определенных настоящим разделом
        Оферты, Принципал обязуется выплатить Агенту пени в размере 0,1% (ноль
        целых одна десятая процента) от неоплаченной в срок суммы за каждый день
        просрочки, но не более 10 (десяти) процентов от неоплаченной в срок
        суммы расходов Агента и/или осуществленных возвратов и/или агентского
        вознаграждения.
      </p>
      <h2>5. Тариф Исполнителя</h2>
      <p>
        5.1. Тарифы Исполнителя опубликованы в Личном кабинете и/или в Сервисе в
        сети Интернет по адресу&nbsp;
        <Link to={"/offer"}> https://myparking.info/offer/</Link> и не могут
        быть изменены в одностороннем порядке Исполнителем. Тарифы Исполнителя
        могут быть изменены по соглашению Сторон не чаще чем 1 (один) раз в год.
        Тарифы Исполнителя не могут быть изменены в сторону увеличения в
        отношении тех Заказчиков, Заявки которых исполняются Исполнителем и
        приняты ранее, чем предполагаемое изменение Тарифов.&nbsp;
      </p>
      <h2>6. Стоимость Услуг и порядок расчетов</h2>
      <p>
        6.1. Условия настоящего раздела Оферты, помимо пп. 6.1 — 6.4.2 Оферты,
        опубликованы в Личном&nbsp; кабинете или в Сервисе в сети Интернет по
        адресу&nbsp;
        <Link to={"/offer"}> https://myparking.info/offer/</Link>
        &nbsp;и являются неотъемлемой частью Оферты.
      </p>
      <p>
        6.1.1. MyParking вправе по своему усмотрению предоставлять скидки на
        стоимость услуг. Такие условия могут быть в том числе указаны в Сервисе
        или иным образом доведены до Исполнителей. Итоговая стоимость Услуг
        MyParking за соответствующий Отчетный период (с учетом всех
        предоставленных скидок, если применимо) подтверждается Сторонами в Акте,
        формируемом в соответствии с п. 6.5 настоящей Оферты.
      </p>
      <p>
        В период до 31.01.2024 года включительно скидка на Услуги MyParking по
        предоставлению доступа к Сервису составляет 100 %.
      </p>
      <p>
        6.2. Оплата Услуг осуществляется путем внесения денежных средств на
        Лицевой счет. Взимание оплаты за Услуги производится путем списания
        денежных средств с Лицевого счета в сумме, рассчитанной в соответствии с
        условиями настоящей Оферты.
      </p>
      <p>
        6.3. Оплата Услуг производится в безналичном порядке, в частности
        банковским переводом или иным допустимым законодательством способом из
        числа принимаемых MyParking. Выбор и использование способа оплаты Услуг
        производится Исполнителем по собственному усмотрению.
      </p>
      <p>
        6.4. В случае если в определенный момент времени в течение Отчетного
        периода Баланс Лицевого счета принял отрицательное значение или стал
        равен или менее суммы внесенной предоплаты согласно п. 7.1.2 настоящего
        документа, MyParking без предварительного уведомления вправе
        приостановить оказание Услуг. Возобновление предоставления Услуг
        MyParking в Отчетном периоде, в случае приостановления Услуг MyParking
        по указанному основанию, производится после поступления на расчетный
        счет MyParking платежа, образующего положительный остаток или остаток,
        превышающий сумму ранее внесенной предоплаты, соответственно, на Лицевом
        счете. При этом внесенный Исполнителем авансовый платеж учитывается в
        первую очередь для погашения задолженности за оказанные, но неоплаченные
        Услуги, а после погашения задолженности оставшаяся часть платежа
        используется в счет авансовых платежей за Услуги.
      </p>
      <p>
        6.4.1. MyParking в одностороннем порядке устанавливает максимальную
        стоимость Услуг, которые могут быть заказаны Исполнителем условиях
        отсрочки платежа (далее — Сумма лимита). При этом Стороны договорились в
        целях настоящего пункта под суммарной стоимостью заказанных и оплаченных
        Услуг понимать только сумму заказанных услуг, поступившую на банковский
        расчетный счет MyParking. Сумма лимита определяется и изменяется
        MyParking самостоятельно и указывается в Личном кабинете или в Сервисе.
      </p>
      <p>
        6.5. В течение срока оказания услуг ежемесячно, в течение 7 (семи)
        рабочих дней с момента окончания Отчетного периода, MyParking направляет
        Исполнителю односторонний акт об оказанных услугах (далее — «Акт») в
        соответствии с объемом фактически оказанных в Отчетном периоде услуг и
        счет-фактуру.
      </p>
      <p>
        6.6. Акт направляется MyParking Исполнителю по почте, с одновременным
        направлением копии текста Акта по электронной почте и/или посредством
        Сервиса. Стороны признают, что копия текста Акта, направленная MyParking
        Исполнителю по электронной почте и/или посредством Сервиса, будет
        применяться Исполнителем в процессе приемки Услуг, до момента получения
        оригинала Акта.
      </p>
      <p>
        6.7. Датой получения копии текста Акта, направленного MyParking
        Исполнителю по электронной почте и/или посредством Сервиса, считается
        следующий рабочий день за днем отправки.
      </p>
      <p>
        6.8. Стороны установили, что Услуги считаются оказанными MyParking
        надлежащим образом и принятыми Исполнителем в указанном в Акте объеме,
        если в течение 14 (четырнадцати) дней с даты формирования Акта,
        MyParking не получил от Исполнителя мотивированных письменных
        возражений. По истечении срока, указанного выше, претензии относительно
        недостатков услуг, в том числе по количеству (объему), стоимости и
        качеству не принимаются.
      </p>
      <h2>7. Акцепт Оферты и заключение Договора</h2>
      <p>
        7.1. Для юридических лиц или индивидуальных предпринимателей Акцепт
        Оферты осуществляется путем последовательного совершения действий,
        указанных в п. 7.1. -. 7.1.2. (в совокупности) или совершения действия,
        указанного в п. 7.1.4.:
      </p>
      <p>
        7.1.1. Нажатие на кнопку «Я ознакомился, согласен и полностью принимаю
        условия Оферты» (или аналогичной по смыслу) на сайте MyParking по
        адресам:&nbsp;
        <Link to={"/offer"}> https://myparking.info/offer/</Link>
      </p>
      <p>
        7.1.2. Оплата счета MyParking в размере 1000 рублей: - для юридических
        лиц или индивидуальных предпринимателей, планирующих вести свою
        деятельность в качестве Исполнителей, для подключения личного кабинета в
        Личном кабинете не позднее 5 (пяти) календарных дней с момента,
        указанного в п. 7.1.1 Оферты.
      </p>
      <p>
        7.1.3. Если по истечении 1 (одного) рабочего дня с момента окончания
        срока, указанного в п. 7.1.1 Оферты, MyParking не получил подтверждения
        осуществления действий, указанных в п. 7.1.2 Оферты, MyParking вправе
        приостановить оказание Услуг и (или) расторгнуть Договор в одностороннем
        порядке без предварительного уведомления.
      </p>
      <p>
        Сумма в размере 1000 руб., указанная в настоящем п. 7.1.2, является
        предоплатой стоимости Услуг, внесенной Исполнителем. Указанная сумма
        предоплаты подлежит возврату только в случае расторжения Договора по
        инициативе Исполнителя, а также прекращения Договора по иным основаниям,
        не связанным с нарушением Исполнителем Договора.
      </p>
      <p>
        В случае нарушения Исполнителем условий Договора сумма в размере 1000
        руб. засчитывается в полном объеме в качестве неустойки за такое
        нарушение в Отчетном периоде, в котором произошло нарушение. Если
        условиями Договора предусмотрены санкции в размере, превышающем 1000
        руб., Исполнитель обязуется оплатить недостающую сумму в срок не позднее
        5 календарных дней после окончания Отчетного периода, в котором
        произошло нарушение. MyParking вправе удержать указанные денежные
        средства из безналичных денежных средств, полученных MyParking в
        порядке, установленном разделом 4 Оферты.
      </p>
      <p>
        7.1.4. Подписание заявления (соглашения) об акцепте Оферты по форме,
        которую предложит MyParking.
      </p>
      <p>
        7.1.5. Сумма, внесенная Заказчиком в качестве предоплаты в соответствие
        с пунктом 7.1.2, списывается MyParking по истечению расчетного периода,
        после чего Заказчику необходимо пополнить лицевой счет сервиса. За
        расчетный период принимается количество дней, равное календарному
        месяцу, в котором была подписана Оферта. При этом, первичный счет
        выставляется пропорционально количеству оставшихся дней текущего месяца.
        Допускается пополнение лицевого счета на период, превышающий один
        календарный месяц.
      </p>
      <p>
        7.2. Акцепт Оферты Исполнителем создает Договор (статья 438 Гражданского
        Кодекса РФ) на условиях Оферты.
      </p>
      <p>
        7.3. MyParking вправе отказать Исполнителю в заключении Договора на
        любом этапе переговоров, если Исполнитель не удовлетворяет критериям
        добросовестности и благонадежности, установленным MyParking. MyParking
        вправе не раскрывать Исполнителю причины, по которым MyParking относит
        Исполнителя к контрагентам, которые не удовлетворяют критериям
        добросовестности и благонадежности.
      </p>
      <h2>8. Ответственность Сторон. Гарантии.</h2>
      <p>
        8.1. За нарушение условий Договора Стороны несут ответственность в
        соответствии с условиями Договора и действующим законодательством РФ.
      </p>
      <p>
        8.2. В случае ненадлежащего исполнения Стороной своих обязательств по
        Договору, другая Сторона имеет право в одностороннем порядке отказаться
        от исполнения Договора, направив другой Стороне соответствующее
        письменное уведомление.
      </p>
      <p>
        8.3. Совокупный размер ответственности MyParking по Договору, включая
        размер штрафных санкций (пеней, неустоек) и/или возмещаемых убытков, по
        любому иску или претензии в отношении Договора или его исполнения,
        ограничивается 10 % стоимости Услуг по Договору за календарный месяц, в
        котором произошло нарушение исполнения MyParking Договора.
      </p>
      <p>
        8.4. В случае предоставления Исполнителем недостоверной информации, а
        также нарушения обязанности, предусмотренной в п. 2.3.2. Оферты,
        Исполнитель обязуется по требованию MyParking уплатить неустойку в
        размере 10000 рублей за каждый случай нарушения, а также возместить
        MyParking причиненные таким нарушением убытки в объеме, не покрытом
        неустойкой. MyParking вправе удержать указанные денежные средства из
        безналичных денежных средств, полученных MyParking в порядке,
        установленном разделом 4 Оферты и оставляет за собой право приостановить
        оказание Услуг Исполнителю и/или досрочно расторгнуть Договор в
        одностороннем внесудебном порядке путем уведомления Исполнителя.
      </p>
      <p>
        8.5. В случае нарушения Исполнителем обязанностей, предусмотренных в пп.
        2.3.3., 4.15.3, 4.15.4 Оферты, MyParking вправе приостановить оказание
        Услуг Исполнителю и/или досрочно расторгнуть Договор в одностороннем
        внесудебном порядке путем уведомления Исполнителя.
      </p>
      <p>
        8.6. В случае нарушения Исполнителем сроков оплаты Услуг, MyParking
        вправе потребовать уплаты штрафной неустойки в размере 0,01% от суммы
        просроченного платежа за каждый день просрочки.
      </p>
      <p>
        8.7. Стороны освобождаются от ответственности за неисполнение и/или
        ненадлежащие исполнение обязательств по Договору, если такое
        неисполнение явилось следствием обстоятельств непреодолимой силы
        (форс-мажор), не зависящих от воли Сторон, к которым, среди прочих,
        относятся стихийные бедствия, пожары, техногенные аварии и катастрофы,
        аварии на инженерных сооружениях и коммуникациях, массовые беспорядки,
        военные действия, террористические акты, бунты, гражданские волнения,
        забастовки, нормативные акты органов государственной власти и местного
        самоуправления, препятствующие исполнению Сторонами своих обязательств
        по Договору, то есть чрезвычайные и непреодолимые при данных условиях
        обстоятельства, наступившие после заключения Договора. Сторона,
        ссылающаяся на невозможность выполнения обязательств вследствие
        наступления обстоятельств непреодолимой силы, обязана незамедлительно
        уведомить об этом другую Сторону. Факт наступления обстоятельств
        непреодолимой силы должен быть подтвержден соответствующими
        компетентными организациями.
      </p>
      <p>
        8.8. MyParking гарантирует, что оказание Исполнителю Услуг по Договору
        не противоречит законодательству РФ, обязательствам, взятым на себя
        MyParking перед третьими лицами, и иным образом не нарушает прав и
        законных интересов третьих лиц.
      </p>
      <p>
        8.9. В случае, если исполнение MyParking Договора явилось основанием для
        предъявления к MyParking претензий, исков и/или предписаний по уплате
        штрафных санкций со стороны государственных органов и/или третьих лиц (в
        том числе Заказчиков), Исполнитель обязуется незамедлительно по
        требованию MyParking предоставить ему всю запрашиваемую информацию,
        содействовать MyParking в урегулировании таких претензий и исков, а
        также возместить MyParking документально подтвержденные убытки (включая
        судебные расходы, расходы по уплате штрафов), причиненные MyParking
        вследствие предъявления ему таких претензий, исков, предписаний в связи
        с нарушением прав третьих лиц и/или действующего законодательства РФ в
        результате исполнения MyParking Договора.
      </p>
      <p>
        8.10. За исключением гарантий, прямо указанных в тексте Оферты,
        MyParking не предоставляет никаких иных прямых или подразумеваемых
        гарантий по Договору и прямо отказывается от каких-либо гарантий или
        условий в отношении работоспособности, бесперебойной и безошибочной
        работы и соответствия Сервисов конкретным целям Исполнителя.
      </p>
      <p>
        8.11. MyParking не несет ответственности и не возмещает никакие убытки,
        в том числе никакой ущерб, прямой или косвенный, причиненный Исполнителю
        или третьим лицам в результате использования или невозможности
        использования Сервисов, их отдельных компонентов и/или функций.
      </p>
      <p>
        8.12. В случае несоблюдения MyParking гарантии, предусмотренной п. 8.8.
        Оферты, в результате которого Исполнителю будут предъявлены любыми
        третьими лицами требования, претензии и/или иски, MyParking обязуется
        урегулировать указанные требования, претензии и/или иски своими силами и
        за свой счет, а также возместить Исполнителю понесенные им в связи с
        этим реальный (доказанный) ущерб.
      </p>
      <p>
        8.13. Соглашаясь с условиями и принимая условия настоящей Оферты путем
        Акцепта Оферты, Исполнитель (или представитель Исполнителя, в т.ч.
        физическое лицо, должным образом уполномоченное заключить Договор от
        лица Исполнителя) заверяет MyParking и гарантирует MyParking, что:
      </p>
      <ul>
        <li>
          Исполнитель (представитель Исполнителя) обладает всеми правами и
          полномочиями, необходимыми для заключения и исполнения Договора;
        </li>
        <li>
          Исполнитель заключает Договор добровольно, при этом Исполнитель
          (представитель Исполнителя): а) полностью ознакомился с условиями
          Оферты, б) полностью понимает предмет Оферты и Договора, в) полностью
          понимает значение и последствия своих действий в отношении заключения
          и исполнения Договора;
        </li>
        <li>
          Исполнитель (представитель Исполнителя) указал достоверные данные
          Исполнителя в Личном кабинете или в Сервисе и достоверные данные
          Исполнителя при оформлении платежных документов по оплате Услуг;
        </li>
        <li>
          Исполнитель не находится в процессе банкротства, в процессе ликвидации
          или прекращения деятельности, деятельность юридического лица не
          приостановлена, а в случае статуса индивидуального предпринимателя –
          индивидуальный предприниматель не прекратил деятельность.
        </li>
      </ul>
      <p>
        8.14. Каждая Сторона утверждает и гарантирует другой Стороне, что для
        исполнения условий Договора:
      </p>
      <ul>
        <li>
          (у нее есть полные права и полномочия юридического лица или
          индивидуального предпринимателя, а также&nbsp;полная
          дееспособность,&nbsp;правоспособность физического лица, и/или
          специальный статус, позволяющий осуществлять предпринимательскую
          деятельность без регистрации в качестве индивидуального
          предпринимателя для заключения и выполнения условий Договора;
        </li>
        <li>
          она приняла корпоративное решение, необходимое для разрешения
          исполнения Договора;
        </li>
      </ul>
      <p>
        8.15. В случае расторжения Договора в связи с нарушением Исполнителем
        условий Оферты MyParking вправе в дальнейшем не заключать Договор с
        таким Исполнителем.
      </p>
      <h2>9. Срок действия и изменения условий Оферты</h2>
      <p>
        9.1. Оферта вступает в силу с момента размещения в сети Интернет по
        адресу&nbsp;
        <Link to={"/offer"}> https://myparking.info/offer/</Link> и действует до
        момента отзыва Оферты.
      </p>
      <p>
        9.2. MyParking оставляет за собой право внести изменения в условия
        Оферты, и/или отозвать Оферту в любой момент по своему усмотрению. В
        случае внесения MyParking изменений в Оферту и/или Стоимость Услуг,
        такие изменения вступают в силу с момента размещения измененного текста
        документа в сети Интернет по адресу&nbsp;
        <Link to={"/offer"}> https://myparking.info/offer/</Link>, если иной
        срок вступления изменений в силу не определен дополнительно при таком
        размещении.
      </p>
      <p>
        9.3. Исполнитель соглашается и признает, что внесение изменений в Оферту
        влечет за собой внесение этих изменений в заключенный и действующий
        между Исполнителем и MyParking Договор, и эти изменения в Договор
        вступают в силу одновременно с такими изменениями в Оферту.
      </p>
      <p>
        9.4. В случае отзыва Оферты MyParking в течение срока действия Договора,
        Договор считается прекращенным с момента отзыва, если иное не оговорено
        MyParking при отзыве Оферты.
      </p>
      <h2>10. Срок действия, изменения и расторжения Договора</h2>
      <p>
        10.1. Договор вступает в силу с момента Акцепта Оферты Исполнителем и
        действует до момента расторжения Договора.
      </p>
      <p>10.2. Договор может быть расторгнут:</p>
      <p>10.2.1. По соглашению Сторон в любое время.</p>
      <p>
        10.2.2. По инициативе любой из Сторон в одностороннем порядке с
        обязательным предварительным письменным уведомлением другой Стороны не
        менее чем за 10 календарных дней до даты расторжения, при этом
        исполнитель обязуется урегулировать/исполнить свои обязательства по всем
        принятым заявкам, а также исполнить обязательства по оплате услуг
        сервиса.
      </p>
      <p>
        10.2.3. По инициативе любой из Сторон в случае нарушения другой Стороной
        условий Договора с письменным уведомлением другой Стороны.
      </p>
      <p>
        10.2.4. По инициативе MyParking в случае возврата Исполнителю суммы
        предоплаты, указанной в п. 7.1.2 настоящего документа.
      </p>
      <p>10.2.5. По иным основаниям, предусмотренным настоящей Офертой.</p>
      <p>
        10.3. В случае расторжения Договора по инициативе MyParking, оригинал
        уведомление о расторжении в порядке 10.2.2. направляется по запросу
        уведомляемой Стороны.
      </p>
      <p>
        10.4. В случае расторжения Договора по любому из оснований, Исполнитель
        исполняет принятые на себя обязательства по принятым Заявкам.
      </p>
      <h2>11. Прочие условия</h2>
      <p>
        11.1. Договор и его исполнение регулируется в соответствии с
        законодательством Российской Федерации.
      </p>
      <p>
        11.2. Любые уведомления по Договору направляются уведомляющей Стороной в
        адрес уведомляемой Стороны в Сервисах, по электронной почте, факсом,
        курьером. При этом копии документов, доставленные по электронной почте,
        по факсу или посредством Сервиса признаются Сторонами имеющими
        юридическую силу.
      </p>
      <p>
        11.3. В случае если одно или более положений Договора будут признаны
        недействительными, такая недействительность не оказывает влияния на
        действительность любого другого положения Договора и Договора в целом.
      </p>
      <p>
        11.4. Не вступая в противоречие с условиями Оферты, Исполнитель и
        MyParking вправе в любое время оформить Договор на оказание Услуг в
        форме письменного двухстороннего документа.
      </p>
      <p>
        11.5. Все споры и разногласия между Сторонами по Договору, в связи с
        Договором и/или его исполнением Стороны будут стремиться урегулировать
        путем переговоров. Применение обязательного досудебного (претензионного)
        порядка разрешения споров обязательно. Сторона, права которой нарушены,
        до обращения в суд обязана предъявить другой Стороне письменную
        претензию с изложением своих требований. Срок рассмотрения претензии —
        30 (тридцать) календарных дней со дня ее получения. Если в указанный
        срок требования не удовлетворены, спор подлежит разрешению в Арбитражном
        суде г. Москвы.
      </p>
      <p>
        11.6. Содержание Договора и Оферты, а также вся иная информация,
        относящаяся к исполнению Договора и ставшая известной Сторонам в связи с
        исполнением Договора, признается Сторонами конфиденциальной информацией
        Сторон. Такая информация не подлежит раскрытию Сторонами за исключением
        случаев, прямо предусмотренных Офертой или случаев, когда раскрытие
        такой информации необходимо для поддержания качества предоставляемого
        пользователям Сервиса, в том числе в связи с передачей, продажей,
        объединения, присоединения бизнеса, а также при проведении экспериментов
        по улучшению качества предоставляемого пользователям Сервиса как
        самостоятельно MyParking, так и с привлечением или с участием третьих
        лиц.
      </p>
      <h2>12. Реквизиты MyParking</h2>
      <p>
        <strong>
          ООО «А-Приори»
          <br />
        </strong>
        109004, г. Москва, ул. Николоямская, д. 52, стр.2, эт. Чердак ком 9
        <br />
        Телефон: +7(495) 128-50-80
        <br />
        ИНН/ КПП: 9721068026/770901001
        <br />
        ОГРН: 1187746707246
        <br />
        р/с: 40702810502410002447
        <br />
        АО «АЛЬФА-БАНК»
        <br />
        к/с: 30101810200000000593
        <br />
        БИК:044525593
      </p>
      <p>
        <strong>Адрес электронной почты для официальных уведомлений:</strong>
        &nbsp;&nbsp;&nbsp;{" "}
        <a href="mailto:info@a-piory.ru">
          info@a-priory.ru
          <br />
        </a>
        <strong>Адрес для размещения в сети Интернет:</strong>{" "}
        <Link to={"/offer"}> https://myparking.info/offer/</Link>
        &nbsp;
      </p>
    </div>
  );
}

export default OfferPage;
