import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { Gallery, Item } from "react-photoswipe-gallery";
import {editParkingReset, editParkings} from "../../../store/parkings/parkings-edit-slice/parkingsEditSlice";
import {uploadImageEditParkingSlice} from "../../../store/image/image-upload-edit-parking/uploadImageEditParkingSlice";
import {imageDeleteParking} from "../../../store/image/image-temporary-parking/imageTemporaryParkingSlice";

const ImageApplicationShow = ({ id, oldimage }) => {
    const [imageState, setImagesState] = useState([]);
    const dispatch = useDispatch();
    const onDrop = (acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onload = () => {
                const base64Data = reader.result;
                const newImage = {
                    id: file.name,
                    data: base64Data,
                };
                setImagesState((prevImages) => [...prevImages, newImage]);
                dispatch(
                    uploadImageEditParkingSlice({
                        id: id,
                        data: {
                            file: base64Data,
                            name: file.name,
                        },
                    })
                ).then((res) => {
                    if (res.meta.requestStatus === "fulfilled") {
                        dispatch(editParkings(id)).then((resp)=>{
                        })
                    }
                });
            };
            reader.readAsDataURL(file);
        });
    };
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const items = Array.from(imageState);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        // setImagesState(items);
    };
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const handleDeleteImage = (id) => {
        const updatedImages = oldimage.filter((item) => item.id !== id);
        setImagesState(updatedImages);
        dispatch(imageDeleteParking(id)).then((resp) =>{
            if (resp.meta.requestStatus === "fulfilled"){
            }
        })
    };

    useEffect(() => {
        if (imageState.length !== 0) {
            dispatch(editParkings(id)).then((resp) =>{
            })
        }
    }, [imageState]);

    return (
        <>
            <DragDropContext  onDragEnd={onDragEnd}>
                <div className="d-flex flex-column">
                    <div className="inner-item-title">Фотографии</div>
                    <Droppable droppableId="droppable">
                        {(provided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className="d-flex flex-wrap flex-row"
                            >
                                <Gallery>
                                    {oldimage &&
                                    oldimage.length !== 0 &&
                                    oldimage.map((image, index) => {
                                        return (
                                            <Item
                                                original={image.url}
                                                thumbnail={image.thumbnail_url}
                                                width="1024"
                                                height="768"
                                                key={index}
                                            >
                                                {({ ref, open }) => (
                                                    <Draggable
                                                        key={image.id}
                                                        draggableId={image.url}
                                                        index={index}
                                                    >
                                                        {(provided) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className="page-file-item application-show"
                                                            >
                                                                <img
                                                                    src={image.url}
                                                                    alt={`Image ${index + 1}`}
                                                                    className="image-preview"
                                                                    ref={ref}
                                                                />
                                                                <div className="page-file__option">
                                                                    <button
                                                                        type="button"
                                                                        className="page-file__zoom"
                                                                        onClick={open}
                                                                    ></button>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )}
                                            </Item>
                                        );
                                    })}
                                </Gallery>
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </div>
            </DragDropContext>
        </>
    );
};

export default ImageApplicationShow;
