import React from 'react';
import ImageUploaderEdit from "../../../../image-upload/image-edit-upload";
import ApplicationsDocument from "./applications-document/applications-document";

function ApplicationPhotoDocuments({single}) {
    return (
        <div>
            {/*    PHOTO */}
            <span className="application-modal__title">Фото</span>
            <ImageUploaderEdit
                id={single?.application?.id}
                oldimage={single?.application?.images}
            />
            <hr />
            {/*    DOCUMENTS */}
            <span className="application-modal__title">Документы</span>
            <ApplicationsDocument single={single} />
            <hr />
        </div>
    );
}

export default ApplicationPhotoDocuments;