export const roles = {
  superAdmin: "SuperAdmin",
  admin: "Admin",
  manager: "Manager",
  operator: "Operator",
  partner: "Partner",
  parterOperator: "PartnerOperator",
  moderator: "Moderator",
  contractor: "Contractor",
  contractorManager : "ContractorManager",
  contractorOperator : "ContractorOperator"

};
