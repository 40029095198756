import axios from "axios";

const API_URL = "/v1/";

// Заявка на выдачу данные

const issueRequestCreate = async (id) => {
  const response = await axios.get(
    API_URL + "applications/" + id + "/issue-requests/create"
  );
  return response.data;
};
//

// Заявка на выдачу отправляем данные
const issueRequestPost = async (id, data) => {
  // Собираем элементы appData
  const {
    arriving_at,
    arriving_interval,
    legal_type_id,
    organization_name,
    inn,
    fio,
    phone,
  } = data;

  let issue_request = {
    arriving_at,
    arriving_interval,
  };
  //

  // Собираем элементы CarData

  let client = {
    legal_type_id,
    organization_name,
    inn,
    fio,
    phone,
  };
  //

  let q = {
    issue_request,
    client,
  };

  const response = await axios.post(
    API_URL + "applications/" + id + "/issue-requests",
    q
  );
  return response.data;
};
//

// Получаем данные заявка на выдачу для редактирование Оператором
const issueRequestEdit = async (id) => {
  const response = await axios.get(API_URL + "issue-requests/" + id + "/edit");
  return response.data;
};
//

// Заявка на выдачу отправляем данные
const issueRequestUpdate = async (id, data) => {
  // Собираем элементы appData
  const {
    arriving_at,
    arriving_interval,
    legal_type_id,
    organization_name,
    inn,
    fio,
    phone,
  } = data;

  let issue_request = {
    arriving_at,
    arriving_interval,
  };
  //

  // Собираем элементы CarData

  let client = {
    legal_type_id,
    organization_name,
    inn,
    fio,
    phone,
  };
  //

  let q = {
    _method: "PUT",
    issue_request,
    client,
  };
  const response = await axios.post(API_URL + "issue-requests/" + id, q);
  return response.data;
};
//

// Заявка отправляется менеджером на выдано
const issuanceUpdate = async (id, data) => {
  // Собираем элементы appData
  const { issued_at, legal_type_id, organization_name, inn, fio, phone } = data;
  let app_data = {
    issued_at,
  };
  //

  // Собираем элементы CarData

  let client = {
    legal_type_id,
    organization_name,
    inn,
    fio,
    phone,
  };
  //

  let q = {
    app_data,
    client,
  };

  const response = await axios.post(
    API_URL + "application/" + id + "/issuance",
    q
  );
  return response.data;
};
//

const issueRequestService = {
  issueRequestCreate,
  issueRequestPost,
  issueRequestEdit,
  issueRequestUpdate,
  issuanceUpdate,
};

export default issueRequestService;
