import axios from "axios";

// Application dublicate vin
let API_URL = "/v1/application/";

const applicationPhotoArchive = async (id) => {
  const response = await axios.get(API_URL + "download-photos/" + id);
  return { data: response.data, status: response.status };
};

const applicationPhotoService = {
  applicationPhotoArchive,
};

export default applicationPhotoService;
