import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { applicationFilterData } from "../../../../store/applications/application-filter/applicationFilterSlice";
import { applicationsAll } from "../../../../store/applications/applicationsSlice";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import selectStyle from "../../../../utils/select-styles";
import {
  selectArray,
  selectArrayParking,
} from "../../../../utils/select-array";
import { useCallback } from "react";

function ApplicationSearch({
  sessionFilter,
  setFilterHome,
  filterHome,
  currentPage,
}) {
  const [partnerValue, setPartnerValue] = useState(null);
  const [parkingValue, setParkingValue] = useState(null);
  const { filter } = useSelector((state) => state.applicationFilter);

  // Пока что так нужно будет исправить
  const partnerSelect = selectArray(filter?.partners, "partner");
  const customPartnerOption = {
    value: "",
    label: "Выберите заказчика",
    name: "partner",
  };
  const updatedPartnerSelect = [customPartnerOption, ...partnerSelect];
  //

  const parkingsSelect = selectArrayParking(filter?.parkings, "parking");
  const customParkingOption = {
    value: "",
    label: "Выберите парковку",
    name: "parking",
  };
  const updatedparkingsSelect = [customParkingOption, ...parkingsSelect];

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchRef = useRef(null);
  const partnerRef = useRef(null);
  const parkingRef = useRef(null);

  useEffect(() => {
    dispatch(applicationFilterData()).then((response) => {
      if (response.payload === undefined) {
        localStorage.removeItem("web_vinz");
      }
    });
  }, []);

  useEffect(() => {
    // dispatch(
    //   applicationsAll({ 6584
    //     id: id,
    //     paginate: currentPage,
    //     query:
    //       sessionFilter?.length !== 0 ? JSON.parse(sessionFilter) : filterHome,
    //   })
    // );
    if (filterHome.length !== 0) {
      localStorage.setItem("filter", JSON.stringify(filterHome));
    }
  }, [filterHome, sessionFilter]);
  // Функции для фильтрации



  const filterFunc = (e) => {
    const { name, value, label, type, checked } = e.target ? e.target : e;
    let newValue = value;

    if (type === "checkbox") {
      newValue = checked ? 1 : 0;
    }
    setFilterHome((prevFilterHome) => ({
      ...prevFilterHome,
      [name]: newValue,
    }));

    // Обновите значения partnerValue и parkingValue
    if (name === "partner") {
      setPartnerValue({
        value: newValue,
        label: label,
        name: "partner",
      });
    }
    if (name === "parking") {
      setParkingValue({
        value: newValue,
        label: label,
        name: "parking",
      });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      // filterFunc(e);
      handleSubmit(e)
    }
  };

  const handleFilter = (e) => {
    filterFunc(e);
  };
  //

  // Очистить фильтр
  const resetFilter = useCallback(async () => {
    navigate("/");
    await dispatch(
      applicationsAll({
        paginate: currentPage,
      })
    );

    localStorage.removeItem("filter");
    searchRef.current.value = "";
    setPartnerValue(null); // Сбросить значение partnerValue
    setParkingValue(null); // Сбросить значение parkingValue
    setFilterHome([]);
  }, [
    // currentPage,
    // dispatch,
    // navigate,
    // searchRef,
    // partnerRef,
    // parkingRef,
    // setFilterHome,
  ]);
  //

  // Option по умолчанию в заказчиках
  // PARTNER
  let defaultPartner = {
    value: "",
    label: "Выберите заказчика",
    name: "partner",
  };

  if (partnerValue !== null) {
    defaultPartner = partnerValue;
  } else if (sessionFilter && sessionFilter.length !== 0) {
    defaultPartner = partnerSelect.find(
      (item) => item.value === JSON.parse(sessionFilter).partner
    );
  }
  //

  // PARKING
  let defaultParking = {
    value: "",
    label: "Выберите стоянку",
    name: "parking",
  };

  if (parkingValue !== null) {
    defaultParking = parkingValue;
  } else if (sessionFilter && sessionFilter.length !== 0) {
    defaultParking = parkingsSelect.find(
      (item) => item.value === JSON.parse(sessionFilter).parking
    );
  }

  //
  //

  const handleSubmit = (e) => {
    e.preventDefault()
    const collectData = {}
    if (searchRef.current.value !== undefined){
      collectData.search = searchRef.current.value
    }
    if (parkingRef.current.value){
        collectData.parking = parkingRef.current.value

    }else if (defaultParking?.value){
      collectData.parking = defaultParking.value
    }

    setFilterHome(
        collectData
    //     {
    //   search: searchRef.current.value ? searchRef.current.value : null, 6988
    //   partner: partnerRef.current.value ? partnerRef.current.value : null,
    //   parking: parkingRef.current.value ? parkingRef.current.value : defaultParking.value,
    //
    // }

    )
    // filterFunc(searchRef.current);
  }

  return (
    <form
        onSubmit={handleSubmit}
        className="filter filter-mob-style d-flex gap-3">
      {/* Поиск по ID */}
      <label className="field-style">
        <span> Поиск по тексту</span>
        <input
          type="text"
          defaultValue={sessionFilter && JSON.parse(sessionFilter).search}
          ref={searchRef}
          name="search"
          className={'input-cst-height'}
          onKeyPress={handleKeyPress}
        />
        <button className={'search-in-filter'}
          onClick={(e)=>{handleSubmit(e)}}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 50 50"><g fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="#344054" stroke-width="2" d="M41.667 41.667L31.146 31.146"/><path stroke="#344054" stroke-width="3" d="m42.708 42.708l-7.291-7.291"/><path stroke="#306CFE" stroke-width="2" d="M20.833 35.417c8.055 0 14.584-6.53 14.584-14.584S28.887 6.25 20.833 6.25S6.25 12.78 6.25 20.833c0 8.055 6.53 14.584 14.583 14.584"/></g></svg>
        </button>
      </label>
      {/*  */}

      {/* Фильтр заказчика */}
      <label className="field-style select-react__style ">
        <span>Заказчик </span>
        <Select
          value={defaultPartner}
          name="partner"
          options={updatedPartnerSelect}
          ref={partnerRef}
          styles={selectStyle}
          onChange={handleFilter}
        />
      </label>
      {/*  */}

      {/* фильтр по парковкам */}
      <label className="field-style mr-0 select-react__style ">
        <span>Стоянка</span>
        <Select
          value={defaultParking}
          name="parking"
          options={updatedparkingsSelect}
          ref={parkingRef}
          styles={selectStyle}
          onChange={handleFilter}
        />
      </label>

      {/*  */}

      {/* Фильтр по фаворитам */}
      <label className="chosen-post">
        <input
          type="checkbox"
          name="favorite"
          className="checkbox-chosen"
          value={1}
          onChange={handleFilter}
        />
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 2.5C12.3788 2.5 12.7251 2.714 12.8945 3.05279L15.4734 8.2106L21.144 9.03541C21.5206 9.0902 21.8335 9.35402 21.9511 9.71599C22.0687 10.078 21.9706 10.4753 21.6981 10.741L17.571 14.7649L18.4994 20.4385C18.5608 20.8135 18.4043 21.1908 18.0957 21.4124C17.787 21.6339 17.3794 21.6614 17.0438 21.4834L12 18.8071L6.95624 21.4834C6.62062 21.6614 6.21306 21.6339 5.9044 21.4124C5.59573 21.1908 5.4393 20.8135 5.50065 20.4385L6.42906 14.7649L2.30193 10.741C2.02942 10.4753 1.93136 10.078 2.04897 9.71599C2.16658 9.35402 2.47946 9.0902 2.85609 9.03541L8.5267 8.2106L11.1056 3.05279C11.275 2.714 11.6213 2.5 12 2.5ZM12 5.73607L10.082 9.57221C9.93561 9.86491 9.65531 10.0675 9.33147 10.1146L5.14842 10.723L8.19813 13.6965C8.43182 13.9243 8.53961 14.2519 8.4869 14.574L7.80004 18.7715L11.5313 16.7917C11.8244 16.6361 12.1756 16.6361 12.4687 16.7917L16.2 18.7715L15.5132 14.574C15.4604 14.2519 15.5682 13.9243 15.8019 13.6965L18.8516 10.723L14.6686 10.1146C14.3448 10.0675 14.0645 9.86491 13.9181 9.57221L12 5.73607Z"
            fill="#536E9B"
          ></path>
          <path
            d="M9 9L3.5 10L7.5 14.5L7 20.5L12 18L17.5 20.5L16.5 14L21 10L15 9L12 4L9 9Z"
            fill="transparent"
          ></path>
        </svg>
      </label>
      {/*  */}

      {/*reset*/}
      <label className="custom-input-wrap pl-2" onClick={resetFilter}>
        <input type="checkbox" name="uncheckFilters" />
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.29289 5.29289C5.68342 4.90237 6.31658 4.90237 6.70711 5.29289L12 10.5858L17.2929 5.29289C17.6834 4.90237 18.3166 4.90237 18.7071 5.29289C19.0976 5.68342 19.0976 6.31658 18.7071 6.70711L13.4142 12L18.7071 17.2929C19.0976 17.6834 19.0976 18.3166 18.7071 18.7071C18.3166 19.0976 17.6834 19.0976 17.2929 18.7071L12 13.4142L6.70711 18.7071C6.31658 19.0976 5.68342 19.0976 5.29289 18.7071C4.90237 18.3166 4.90237 17.6834 5.29289 17.2929L10.5858 12L5.29289 6.70711C4.90237 6.31658 4.90237 5.68342 5.29289 5.29289Z"
            fill="#536E9B"
          ></path>
        </svg>
      </label>

      {/*search*/}

      {/*<label className="custom-input-wrap pl-2 search-btn-application">*/}
      {/*  <button*/}
      {/*      type={"submit"}*/}
      {/*  >*/}
      {/*    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 1024 1024">*/}
      {/*      <path fill="currentColor" d="m795.904 750.72l124.992 124.928a32 32 0 0 1-45.248 45.248L750.656 795.904a416 416 0 1 1 45.248-45.248zM480 832a352 352 0 1 0 0-704a352 352 0 0 0 0 704"/>*/}
      {/*    </svg>*/}

      {/*  </button>*/}

      {/*</label>*/}
    </form>
  );
}

export default ApplicationSearch;
