
function ProfileItem({data, title,name,handleChange}) {

    return (
        <div className="row w-100 d-flex flex-column justify-content-center align-items-center">
            <div className="col-9">
                <label className="field-style w-100 ">
                    <span>{title}</span>
                    <input
                        id={name}
                        type={ name === 'password' || name === 'password_confirmation' ? 'password' : 'text'}
                        value={data}
                        name={name}
                        className={'w-100'}
                        placeholder="Не указан"
                        onChange={handleChange}
                    />
                </label>
            </div>
        </div>
    );
}

export default ProfileItem;