import React from 'react';

function ApplicationLogsItem(
    {
        id,
        date,
        action
    }
    ) {
    return (
        <tr>
            {/*<td>{id}</td>*/}
            <td scope="row">{date}</td>
            <td>{action}</td>
        </tr>
    );
}

export default ApplicationLogsItem;