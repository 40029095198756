import { createAsyncThunk } from "@reduxjs/toolkit";
import applicationQuickEditService from "./applicationModalQuickEditService";

// Vin dublicate
export const appQuickEdit = createAsyncThunk(
  "applications/applicationQuickEdit",
  async (data, thunkAPI) => {
    try {
      return await applicationQuickEditService.applicationQuickEdit(data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
