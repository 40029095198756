import React, {useCallback, useEffect, useState} from 'react';
import Preloader from "../../../../../preloader/preloader";
import ApplicationCurrentService from "./application-current-service";
import {currentApplicationServiceById} from "../../../../../../store/applications/application-service-order/applicationServiceOrderSlice";
import {useDispatch, useSelector} from "react-redux";
import ApplicationAddService from "./application-add-service/application-add-service";
import ApplicationCertainServiceItem from "./application-certain-service-item/application-certain-service-item";

//страница заказ в зависимости от роли и статуса

export default AplicationServicesItem;

function AplicationServicesItem(
    {
        role,
        setApprovedPrice,
        toggleCompleteApplicationService,
        orderServices,
        setOrderServices,
        handleDeleteService,
        services,
        currentApplicationId,
        orderID,
        showAddServices,
        getApplicationSelectedServices,
        getSelectedServicesForFooterInfo,
        single,
        setOrderStatus
    }) {
    const currentServices = useSelector((state) => state.applicationService);
    const dispatch = useDispatch()
    const [checkedItems, setCheckedItems] = useState({})
    const [prices, setPrices] = useState({})
    const [checkedServices, setCheckedServices] = useState({});

    const partnerApproveHandle = (id, isChecked) => {
        if (isChecked) {
            // Если чекбокс выбран, меняем status_id на 3
            const updatedServices = orderServices.orderServices.map(service =>
                service.id === id ? { ...service, status_id: 3 } : service
            );
            setOrderServices({orderServices: updatedServices});
        } else {
            // Если чекбокс отменен, меняем status_id на 1
            const updatedServices = orderServices.orderServices.map(service =>
                service.id === id ? { ...service, status_id: 1 } : service
            );
            setOrderServices({orderServices: updatedServices});
        }
        setCheckedServices((prevCheckedServices) => ({
            ...prevCheckedServices,
            [id]: isChecked,
        }));
    }
    useEffect(()=>{
        const tempServicesArr = []
        currentServices.currentService.orderServices && currentServices.currentService.orderServices.map((currentService) => {
            if (currentService.status_id === 9 || currentService.status_id === 2){
                tempServicesArr.push({id: currentService.id, status_id: 1})
            }
        } )
        setOrderServices({orderServices: tempServicesArr});
    },[currentServices.currentService.orderServices])

    const handleDefaultCheckedState = (serviceId, serviceCompleted) => {
        return serviceCompleted === 1;
    };
    const checkStatus = (serviceId, serviceCompleted) => {
        toggleCompleteApplicationService(serviceId);
        setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [serviceId]: !prevCheckedItems[serviceId],
        }));
    };

    // Set default checked state when component mounts
    useEffect(() => {
        const defaultCheckedState = {}
        currentServices.currentService.orderServices?.map((service) => {
            defaultCheckedState[service.id] = handleDefaultCheckedState(service.id, service.completed)
        });
        setCheckedItems(defaultCheckedState)
    }, [currentServices.currentService.orderServices])

    useEffect(() => {
        if (services.currentService && services.currentService.orderServices) {
            const extractedPrices = services.currentService.orderServices.map(service => ({
                id: service.id,
                price_for_us: service.price_for_us,
                approved_price: service.approved_price,
            }));
            setPrices(extractedPrices);
        }
    }, [services.currentService.orderServices])

    const handleInputChange = (e, id) => {
        const {value, name} = e.target
        setPrices(prevPrices =>
            prevPrices.map(price =>
                price.id === id ? {...price, [name]: value} : price
            )
        )
    }

    // useEffect(() => {
    //     if (serviceStatuses.some(status => status.statusId === 1)) {
    //         setShowApproveBtn(1); // If any status === 1
    //     } else if (serviceStatuses.some(status => status.statusId === 2)) {
    //         setShowApproveBtn(2); // If any status === 2, but none are 1
    //     } else if (serviceStatuses.every(status => status.statusId === 3)) {
    //         setShowApproveBtn(3); // If all statuses === 3
    //     }
    // }, [serviceStatuses]);

    // const updateServiceStatus = (serviceId, statusId) => {
    //     setServiceStatuses(prevStatuses => {
    //         const updatedStatuses = prevStatuses.map(status =>
    //             status.serviceId === serviceId ? { ...status, statusId } : status
    //         );
    //         // If the service doesn't exist, add it
    //         if (!updatedStatuses.find(status => status.serviceId === serviceId)) {
    //             updatedStatuses.push({ serviceId, statusId });
    //         }
    //         return updatedStatuses;
    //     });
    // };
    const selectServiceStatusColorByStatusId = (statusId) => {
        switch (statusId) {
            case 3:
                return 'green'
            case 5:
                return 'green'
            case 7:
                return 'red'
            case 8:
                return 'green'
            default:
                return '#536E9B'
        }
    }
    switch (role) {
        case 'Partner' :
        case 'Operator' :
            return currentServices && (
                <div className="tab-pane fade show" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                    {!showAddServices ?
                        <table className="price-list-table table-orders">
                            <thead
                                style={{width: '100%', borderBottom: '1px solid #e7e7e7'}}
                            >
                            <tr>
                                <th style={{paddingLeft: 0}}></th>
                                <th>Наименование</th>
                                <th className={'text-center'}>Цена</th>
                                <th></th>
                            </tr>

                            </thead>
                            <tbody className={'modal-services__list'}>
                            {currentServices.currentService.orderServices?.map((service, index) => {
                                const isChecked = checkedServices[service.id] || false;
                                return (
                                    <tr>
                                        <td
                                            onClick={() => {
                                                handleDeleteService(service.id)
                                            }}
                                            className={'delete_service'}>
                                            {service.status_id !== 7 && service.status_id !== 3 && service.status_id !== 5 ?
                                                <span className="">
                                                    <svg width="10" height="11" viewBox="0 0 10 11" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M0.52827 1.02729C0.788619 0.766944 1.21073 0.766944 1.47108 1.02729L4.99967 4.55589L8.52827 1.02729C8.78862 0.766944 9.21073 0.766944 9.47108 1.02729C9.73143 1.28764 9.73143 1.70975 9.47108 1.9701L5.94248 5.4987L9.47108 9.02729C9.73143 9.28764 9.73143 9.70975 9.47108 9.9701C9.21073 10.2305 8.78862 10.2305 8.52827 9.9701L4.99967 6.44151L1.47108 9.9701C1.21073 10.2305 0.788619 10.2305 0.52827 9.9701C0.26792 9.70975 0.26792 9.28764 0.52827 9.02729L4.05687 5.4987L0.52827 1.9701C0.26792 1.70975 0.26792 1.28764 0.52827 1.02729Z"
                                                            fill="#EB5757"/>
                                                    </svg>
                                                </span> : ''
                                            }

                                        </td>
                                        <td>
                                            <div className="table-service-title"
                                                 style={{color: selectServiceStatusColorByStatusId(service.status_id)}}
                                            >{service.parking_has_service !== null ? service.parking_has_service.parking_service.name : service.custom_service}</div>
                                        </td>

                                        <td className={`text-center price_service ${service.approved_price === 0 ? 'text-right' : ''}`}>
                                            {service.approved_price === 0 ?
                                                <>
                                                    <span className={'tip-service'}>?</span>
                                                    <span className={'tip-content'}>
                                                        {service.parking_has_service !== null ? service.parking_has_service.comment : ''}
                                                    </span>
                                                </>
                                                : ''
                                            }
                                            {service.status !== 'Удалена' && service.approved_price}
                                        </td>
                                        <td className="text-right">
                                            {
                                                isChecked && (
                                                    <span style={{color: 'green'}}>
                                                        Cогласована
                                                    </span>
                                                )
                                            }

                                            {!isChecked &&
                                                <span style={{color: selectServiceStatusColorByStatusId(service.status_id)}}>
                                                    {service.status === 'Не согласована' ? 'На согласовании' : service.status}
                                                </span>
                                            }
                                        </td>
                                        <td className="text-right d-flex justify-content-end align-items-center">
                                            {currentServices?.currentService?.order?.status_code ==='toPartner' && (service.status_id !== 3 && service.status_id !== 5 && service.status_id !== 8 && service.status_id !== 7) &&
                                            <label className="switch-radio-wrap d-flex flex-row-reverse">
                                                <input
                                                    type="checkbox"
                                                    onChange={(e) => {
                                                        partnerApproveHandle(service.id, e.target.checked)
                                                    }}
                                                />
                                                <span className="switcher-radio ml-2 mr-0"></span>
                                            </label>
                                            }

                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                        : <ApplicationAddService
                            getApplicationSelectedServices={getApplicationSelectedServices}
                            getSelectedServicesForFooterInfo={getSelectedServicesForFooterInfo}
                            single={single}
                        />
                    }
                </div>
            );
            break;
        case 'Manager' :
        case  'SuperAdmin' :
        case 'Admin' :
            if (currentServices?.currentService?.order?.status_code === 'toManager') {
                return currentServices && (
                    <div className="tab-pane fade show" id="nav-profile" role="tabpanel"
                         aria-labelledby="nav-profile-tab">
                        {!showAddServices ?
                            <table className="price-list-table table-orders">
                                <thead
                                    style={{width: '100%', borderBottom: '1px solid #e7e7e7'}}
                                >
                                <tr>
                                    <th style={{paddingLeft: 0}}></th>
                                    <th>Наименование</th>
                                    <th>Цена для заказчика</th>
                                    <th>Наша цена</th>
                                    <th></th>
                                </tr>

                                </thead>
                                <tbody className={'modal-services__list'}>
                                {currentServices.currentService.orderServices ? currentServices.currentService.orderServices.map((service, index) => {
                                    return (
                                        <ApplicationCurrentService
                                            key={orderID + index}
                                            service={service}
                                            setApprovedPrice={setApprovedPrice}
                                            handleDeleteService={handleDeleteService}
                                            currentApplicationId={currentApplicationId}
                                            orderID={orderID}
                                            setOrderStatus={setOrderStatus}
                                        />
                                    )
                                }) : <Preloader/>}
                                </tbody>
                            </table>
                            : <ApplicationAddService
                                getApplicationSelectedServices={getApplicationSelectedServices}
                                getSelectedServicesForFooterInfo={getSelectedServicesForFooterInfo}
                                single={single}
                            />
                        }
                    </div>
                );
            }
            if (currentServices?.currentService?.order?.status_code === 'toPartner') {
                return currentServices && (
                    <div className="tab-pane fade show" id="nav-profile" role="tabpanel"
                         aria-labelledby="nav-profile-tab">
                        {!showAddServices ?
                            <table className="price-list-table table-orders">
                                <thead
                                    style={{width: '100%', borderBottom: '1px solid #e7e7e7'}}
                                >
                                <tr>
                                    <th style={{paddingLeft: 0}}></th>
                                    <th>Наименование</th>
                                    <th>Цена для заказчика</th>
                                    <th>Наша цена</th>
                                    <th></th>
                                </tr>

                                </thead>
                                <tbody className={'modal-services__list'}>
                                {currentServices.currentService.orderServices?.map((service, index) => {
                                    return (
                                        <tr>

                                            <td
                                                onClick={() => {
                                                    handleDeleteService(service.id)
                                                }}
                                                className={'delete_service'}
                                            >
                                            </td>
                                            <td>
                                                <div
                                                    className="table-service-title"
                                                    style={service.status === 'Удалена' ?
                                                        {color: 'red'} : {color: '#000'}}
                                                >{service.parking_has_service !== null ? service.parking_has_service.parking_service.name : service.custom_service}</div>
                                            </td>

                                            <td className="text-center">
                                                {service.status !== 'Удалена' ? service.approved_price : ''}
                                            </td>
                                            <td className="text-center">
                                                {service.status !== 'Удалена' ? service.price_for_us : ''}
                                            </td>
                                            <td className="text-right">
                                                <label className="switch-radio-wrap d-flex flex-row-reverse">
                                                    <span
                                                        style={service.status === 'Удалена' ?
                                                            {color: 'red'} : service.status_id === 8 ? {color: 'green'} : {color: '#000'}}
                                                        className="condition">
                                                        {service.status === 'Не согласована' ? 'На согласовании' : service.status}
                                                    </span>
                                                </label>
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                            : <ApplicationAddService
                                getApplicationSelectedServices={getApplicationSelectedServices}
                                getSelectedServicesForFooterInfo={getSelectedServicesForFooterInfo}
                                single={single}
                            />
                        }
                    </div>
                );
            }
            if (currentServices?.currentService?.order?.status_code === 'approved' ||
                currentServices?.currentService?.order?.status_code === 'in-progress' ||
                currentServices?.currentService?.order?.status_code === 'ready' ||
                currentServices?.currentService?.order?.status_code === 'partly-completed') {
                return currentServices && (
                    <div className="tab-pane fade show" id="nav-profile" role="tabpanel"
                         aria-labelledby="nav-profile-tab">
                        {!showAddServices ?
                            <table className="price-list-table table-orders">
                                <thead
                                    style={{width: '100%'}}
                                >
                                <tr>
                                    <th style={{paddingLeft: 0}}></th>
                                    <th>Наименование</th>
                                    <th>Цена для заказчика</th>
                                    <th>Наша цена</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody className={'modal-services__list'}>
                                <tr>
                                    <td style={{paddingLef: '0', width: '10px'}}></td>
                                    <td></td>
                                    <td></td>
                                    <td className={'d-flex justify-content-end'}>

                                    </td>

                                </tr>
                                {currentServices.currentService.orderServices?.map((service, index) => {
                                    const isChecked = checkedItems[service.id];
                                    return (
                                        <>
                                            <ApplicationCertainServiceItem
                                                handleDeleteService={handleDeleteService}
                                                service={service}
                                                handleInputChange={handleInputChange}
                                                isChecked={isChecked}
                                                checkStatus={checkStatus}
                                                services={services}
                                                prices={prices}
                                                orderStatusCode={currentServices?.currentService?.order?.status_code}
                                            />
                                        </>
                                    )
                                })}
                                </tbody>
                            </table>
                            : <ApplicationAddService
                                getApplicationSelectedServices={getApplicationSelectedServices}
                                getSelectedServicesForFooterInfo={getSelectedServicesForFooterInfo}
                                single={single}
                            />
                        }
                    </div>
                );
            }
            if (currentServices?.currentService?.order?.status_code === 'cancelled' || currentServices?.currentService?.order?.status_code === 'completed') {
                return (
                    <div className="tab-pane fade show" id="nav-profile" role="tabpanel"
                         aria-labelledby="nav-profile-tab">
                        {!showAddServices ?
                            <table className="price-list-table table-orders">
                                <thead
                                    style={{width: '100%'}}
                                >
                                <tr>
                                    <th>Наименование</th>
                                    <th className={'text-center'}>Цена для заказчика</th>
                                    <th className={'text-center'}>Наша цена</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {currentServices.currentService.orderServices?.map((service, index) => {
                                    return (
                                        <tr>
                                            <td>
                                                <div
                                                    style={service.status_id === 7 ? {color: 'red'} : {color: '#000'}}
                                                    className="table-service-title"
                                                >
                                                    {service.parking_has_service !== null ?
                                                        service.parking_has_service.parking_service.name
                                                        : service.custom_service
                                                    }
                                                </div>
                                            </td>

                                            <td className="text-center">
                                                {service.status_id !== 7 && service.approved_price}
                                            </td>
                                            <td className="text-center">
                                                {service.status_id !== 7 && service.price_for_us}
                                            </td>
                                            <td className="text-right">
                                                {currentServices?.currentService?.order?.status_code === 'cancelled' ?
                                                    <span style={{color: 'red'}}> Отменен </span>
                                                    : <span style={{color: 'green'}}>Выполнен </span>
                                                }


                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                            : <ApplicationAddService
                                getApplicationSelectedServices={getApplicationSelectedServices}
                                getSelectedServicesForFooterInfo={getSelectedServicesForFooterInfo}
                                single={single}
                            />
                        }
                    </div>
                )
            }
        default :
            return !currentServices && (
                <Preloader/>
            );
    }
}
