import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import applicationTypeService from "../applicationSelectService";

const initialState = {
  modifications: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Type cars
export const applicationsModifications = createAsyncThunk(
  "applications/applicationsModifications",
  async ({ modelId, seriesId, yearId }, thunkAPI) => {
    try {
      return await applicationTypeService.appModification(
        modelId,
        seriesId,
        yearId
      );
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const applicationModificationsSlice = createSlice({
  name: "applicationsModifications",
  initialState,
  reducers: {
    modificationsReset: (state) => {
      state.modifications = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(applicationsModifications.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(applicationsModifications.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.modifications = action.payload;
      })
      .addCase(applicationsModifications.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.modifications = null;
      });
  },
});
export const { modificationsReset } = applicationModificationsSlice.actions;

export default applicationModificationsSlice.reducer;
