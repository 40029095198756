import axios from "axios";

// Application dublicate vin
let API_URL = "/v1/application/";

const applicationAct = async (id) => {
  const response = await axios.get(API_URL + "generate-act/" + id);
  return { data: response.data, status: response.status };
};

const applicationActService = {
  applicationAct,
};

export default applicationActService;
