import React from "react";

function ParkingServive() {
  return (
    <div className="row no-gutters">
      <div className="col-md-12">
        <div className="inner-page__item pb-0">
          <div className="inner-item-title">Услуги</div>
        </div>
      </div>
      <div className="d-flex w-100">
        <table className="price-list-table price-table">
          <thead>
            <tr>
              <th className="price-list-head">Хранение</th>
              <th className="text-center">Активна</th>
              <th>Стоимость, руб</th>
              <th>Контакты</th>
              <th>Комментарий</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Парковка легкового авто</td>
              <td className="text-center">
                <label className="check-box-style">
                  <input type="checkbox" checked />
                  <span></span>
                </label>
              </td>
              <td>
                <div className="field-style">
                  <input className="m-0" type="text" value="300" />
                </div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="field-style mr-0">
                    <input type="text" className="ml-0" placeholder="Имя" />
                  </div>
                  <div className="field-style">
                    <input type="phone" placeholder="+7 (___) ___-__-__" />
                  </div>
                </div>
              </td>
              <td>
                <textarea
                  className="table-textarea"
                  placeholder="Не указано"
                  name=""
                  id=""
                >
                  Бронирование с предоплатой
                </textarea>
              </td>
            </tr>
            <tr className="disabled-field">
              <td>Парковка фургона</td>
              <td className="text-center">
                <label className="check-box-style">
                  <input type="checkbox" />
                  <span></span>
                </label>
              </td>
              <td>
                <div className="field-style">
                  <input className="m-0" type="text" placeholder="Не указано" />
                </div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="field-style mr-0">
                    <input type="text" className="ml-0" placeholder="Имя" />
                  </div>
                  <div className="field-style">
                    <input type="phone" placeholder="+7 (___) ___-__-__" />
                  </div>
                </div>
              </td>
              <td>
                <textarea
                  className="table-textarea"
                  placeholder="Не указано"
                  name=""
                  id=""
                ></textarea>
              </td>
            </tr>
            <tr>
              <td>Парковка легкового авто</td>
              <td className="text-center">
                <label className="check-box-style">
                  <input type="checkbox" checked />
                  <span></span>
                </label>
              </td>
              <td>
                <div className="field-style">
                  <input className="m-0" type="text" value="300" />
                </div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="field-style mr-0">
                    <input type="text" className="ml-0" placeholder="Имя" />
                  </div>
                  <div className="field-style">
                    <input type="phone" placeholder="+7 (___) ___-__-__" />
                  </div>
                </div>
              </td>
              <td>
                <textarea
                  className="table-textarea"
                  placeholder="Не указано"
                  name=""
                  id=""
                >
                  Бронирование с предоплатой
                </textarea>
              </td>
            </tr>
            <tr className="disabled-field">
              <td>Парковка фургона</td>
              <td className="text-center">
                <label className="check-box-style">
                  <input type="checkbox" />
                  <span></span>
                </label>
              </td>
              <td>
                <div className="field-style">
                  <input className="m-0" type="text" placeholder="Не указано" />
                </div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="field-style mr-0">
                    <input type="text" className="ml-0" placeholder="Имя" />
                  </div>
                  <div className="field-style">
                    <input type="phone" placeholder="+7 (___) ___-__-__" />
                  </div>
                </div>
              </td>
              <td>
                <textarea
                  className="table-textarea"
                  placeholder="Не указано"
                  name=""
                  id=""
                ></textarea>
              </td>
            </tr>
            <tr>
              <td>Парковка легкового авто</td>
              <td className="text-center">
                <label className="check-box-style">
                  <input type="checkbox" checked />
                  <span></span>
                </label>
              </td>
              <td>
                <div className="field-style">
                  <input className="m-0" type="text" value="300" />
                </div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="field-style mr-0">
                    <input type="text" className="ml-0" placeholder="Имя" />
                  </div>
                  <div className="field-style">
                    <input type="phone" placeholder="+7 (___) ___-__-__" />
                  </div>
                </div>
              </td>
              <td>
                <textarea
                  className="table-textarea"
                  placeholder="Не указано"
                  name=""
                  id=""
                >
                  Бронирование с предоплатой
                </textarea>
              </td>
            </tr>
            <tr className="disabled-field">
              <td>Парковка фургона</td>
              <td className="text-center">
                <label className="check-box-style">
                  <input type="checkbox" />
                  <span></span>
                </label>
              </td>
              <td>
                <div className="field-style">
                  <input className="m-0" type="text" placeholder="Не указано" />
                </div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="field-style mr-0">
                    <input type="text" className="ml-0" placeholder="Имя" />
                  </div>
                  <div className="field-style">
                    <input type="phone" placeholder="+7 (___) ___-__-__" />
                  </div>
                </div>
              </td>
              <td>
                <textarea
                  className="table-textarea"
                  placeholder="Не указано"
                  name=""
                  id=""
                ></textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="d-flex w-100 mt-5">
        <table className="price-list-table price-table">
          <thead>
            <tr>
              <th className="price-list-head">Доставка</th>
              <th className="text-center">Активна</th>
              <th>Стоимость, руб</th>
              <th>Контакты</th>
              <th>Комментарий</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Парковка легкового авто</td>
              <td className="text-center">
                <label className="check-box-style">
                  <input type="checkbox" checked />
                  <span></span>
                </label>
              </td>
              <td>
                <div className="field-style">
                  <input className="m-0" type="text" value="300" />
                </div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="field-style mr-0">
                    <input type="text" className="ml-0" placeholder="Имя" />
                  </div>
                  <div className="field-style">
                    <input type="phone" placeholder="+7 (___) ___-__-__" />
                  </div>
                </div>
              </td>
              <td>
                <textarea
                  className="table-textarea"
                  placeholder="Не указано"
                  name=""
                  id=""
                >
                  Бронирование с предоплатой
                </textarea>
              </td>
            </tr>
            <tr className="disabled-field">
              <td>Парковка фургона</td>
              <td className="text-center">
                <label className="check-box-style">
                  <input type="checkbox" />
                  <span></span>
                </label>
              </td>
              <td>
                <div className="field-style">
                  <input className="m-0" type="text" placeholder="Не указано" />
                </div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="field-style mr-0">
                    <input type="text" className="ml-0" placeholder="Имя" />
                  </div>
                  <div className="field-style">
                    <input type="phone" placeholder="+7 (___) ___-__-__" />
                  </div>
                </div>
              </td>
              <td>
                <textarea
                  className="table-textarea"
                  placeholder="Не указано"
                  name=""
                  id=""
                ></textarea>
              </td>
            </tr>
            <tr>
              <td>Парковка легкового авто</td>
              <td className="text-center">
                <label className="check-box-style">
                  <input type="checkbox" checked />
                  <span></span>
                </label>
              </td>
              <td>
                <div className="field-style">
                  <input className="m-0" type="text" value="300" />
                </div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="field-style mr-0">
                    <input type="text" className="ml-0" placeholder="Имя" />
                  </div>
                  <div className="field-style">
                    <input type="phone" placeholder="+7 (___) ___-__-__" />
                  </div>
                </div>
              </td>
              <td>
                <textarea
                  className="table-textarea"
                  placeholder="Не указано"
                  name=""
                  id=""
                >
                  Бронирование с предоплатой
                </textarea>
              </td>
            </tr>
            <tr className="disabled-field">
              <td>Парковка фургона</td>
              <td className="text-center">
                <label className="check-box-style">
                  <input type="checkbox" />
                  <span></span>
                </label>
              </td>
              <td>
                <div className="field-style">
                  <input className="m-0" type="text" placeholder="Не указано" />
                </div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="field-style mr-0">
                    <input type="text" className="ml-0" placeholder="Имя" />
                  </div>
                  <div className="field-style">
                    <input type="phone" placeholder="+7 (___) ___-__-__" />
                  </div>
                </div>
              </td>
              <td>
                <textarea
                  className="table-textarea"
                  placeholder="Не указано"
                  name=""
                  id=""
                ></textarea>
              </td>
            </tr>
            <tr>
              <td>Парковка легкового авто</td>
              <td className="text-center">
                <label className="check-box-style">
                  <input type="checkbox" checked />
                  <span></span>
                </label>
              </td>
              <td>
                <div className="field-style">
                  <input className="m-0" type="text" value="300" />
                </div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="field-style mr-0">
                    <input type="text" className="ml-0" placeholder="Имя" />
                  </div>
                  <div className="field-style">
                    <input type="phone" placeholder="+7 (___) ___-__-__" />
                  </div>
                </div>
              </td>
              <td>
                <textarea
                  className="table-textarea"
                  placeholder="Не указано"
                  name=""
                  id=""
                >
                  Бронирование с предоплатой
                </textarea>
              </td>
            </tr>
            <tr className="disabled-field">
              <td>Парковка фургона</td>
              <td className="text-center">
                <label className="check-box-style">
                  <input type="checkbox" />
                  <span></span>
                </label>
              </td>
              <td>
                <div className="field-style">
                  <input className="m-0" type="text" placeholder="Не указано" />
                </div>
              </td>
              <td>
                <div className="d-flex">
                  <div className="field-style mr-0">
                    <input type="text" className="ml-0" placeholder="Имя" />
                  </div>
                  <div className="field-style">
                    <input type="phone" placeholder="+7 (___) ___-__-__" />
                  </div>
                </div>
              </td>
              <td>
                <textarea
                  className="table-textarea"
                  placeholder="Не указано"
                  name=""
                  id=""
                ></textarea>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ParkingServive;
