import React from "react";

function ApplicationsAdministrativeData({ single }) {
  return (
    <div className="row no-gutters">
      <div className="col-6">
        <div className="info-item">
          <span>ФИО доставщика</span>
            <span>_</span>
        </div>
        <div className="info-item">
          <span>Телефон доставщика</span>
            <span>_</span>
        </div>
      </div>
      <div className="col-6">
        <div className="info-item">
          <span>ФИО собственника</span>
            {
                single.application.courier_fullname !== undefined ? single.application.courier_fullname : '_'
            }

        </div>
        <div className="info-item">
          <span>Телефон собственника</span>
            {single.application.courier_phone !== undefined ? single.application.courier_phone : '_'}
        </div>
      </div>
    </div>
  );
}

export default ApplicationsAdministrativeData;
