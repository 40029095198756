import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import {
    createParking,
    parkingCreatePost,
} from "../../../store/parkings/parkings-create-slice/parkingsCreateSlice";
import {selectArray} from "../../../utils/select-array";
import swal from "sweetalert";
import {useNavigate} from "react-router-dom";
import {Tab, Tabs} from "react-bootstrap";
import ParkingApplication
    from "../../../components/parkings/parkings-create-update/parking-application/parking-application";
import ParkingPrice from "../../../components/parkings/parkings-create-update/parking-price/parking-price";
import ParkingServive from "../../../components/parkings/parkings-create-update/parking-service/parking-sevice";
import {getAllFence} from "../../../store/parkings/create-fence/createFenceSlice";
import {getAllCoverage} from "../../../store/parkings/create-coverage/coverageCreateSlice";

function ParkingsCreatePage() {
    const {createNewParking} = useSelector((state) => state.parkingsCreate);
    const navigate = useNavigate();
    const [parkingCreateForm, setParkingCreateForm] = useState({
        _token : "",
        title: "",
        status: 0,
        code: "",
        address: "",
        legals: [],
        users: [],
        contractors: [],
        timezone: "UTC+06:00",
        coordinates: "",
        map_url: "",
        working_hours: "",
        nav_to_trans: "",
        qty_parking: 0,
        parking_surface: 0,
        fencing: null,
        covered_parking: 0,
        heated_parking: 0,
        cctv: 0,
        client_zones: 0,
    });


    const [key, setKey] = useState("parking-tab-1");

    //записываем токен стоянки для картинок
    if (
        (createNewParking &&
            createNewParking.length !== 0 &&
            localStorage.getItem("attachment_token_parking") === null) ||
            localStorage.getItem("attachment_token_parking") === "undefined"
    ) {
        localStorage.setItem(
            "attachment_token_parking",
            JSON.stringify(createNewParking.token)
        );
    }

    // useEffect(()=>{
    //
    // },[parkingCreateForm])

    // Функция для отправки формы

    const handleChange = (e) => {
        if (e.target) {
            const {name, value, type, checked} = e.target;
            let newValue = value;
            if (type === "checkbox") {
                newValue = checked ? 1 : 0;
                if (name === 'status') {
                    newValue = checked ? '1' : '0';
                }
            }
            if (name === 'coverage' || name === 'fencing') {
                newValue = parseInt(newValue);
            }
            if (name === 'qty_parking') {
                newValue = parseInt(newValue);
            }

            setParkingCreateForm({...parkingCreateForm, [name]: newValue});
        } else if (Array.isArray(e)) {
            const arrayLegals = e;
            const arrayValue = [];
            arrayLegals.map((item) => {
                arrayValue.push(item.value);
                setParkingCreateForm({...parkingCreateForm, [item.name]: arrayValue});
            });
        }
    };

    const updateToken = (newToken) => {
        setParkingCreateForm(prevState => ({
            ...prevState,
            _token: newToken
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(parkingCreatePost(parkingCreateForm)).then((response) => {
            if (response.payload === 201) {
                swal("таб Заявка заполнен корректно", {
                    icon: "success",
                })
                navigate("/parkings");
                localStorage.removeItem("attachment_token_parking");
            } else {
                let errorMessage = "Ошибка при создании парковки на табе Заявка!";
                if (response.payload && response.payload.errors) {
                    const errorMessages = Object.values(response.payload.errors).reduce(
                        (acc, val) => acc.concat(val),
                        []
                    );
                    errorMessage = errorMessages.join("\n");
                }
                swal(errorMessage, {
                    icon: "error",
                });
            }
        });
    };
    //

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(createParking());
    }, [dispatch]);

    const legals = Object.keys(createNewParking)[2];
    const users = Object.keys(createNewParking)[0];
    const fence = Object.keys(createNewParking)[5];
    const coverage = Object.keys(createNewParking)[6];

    const individualsOptions = selectArray(createNewParking.legals, legals);
    const usersOptions = selectArray(createNewParking.users, users);
    const constractorsOptions = selectArray(
        createNewParking.contractors,
        "contractors"
    );
    const fenceList = selectArray(createNewParking.parking_fences, fence);
    const coverageList = selectArray(createNewParking.parking_surfaces, coverage);

    const renderContent = () => {
        switch (key) {
            case "parking-tab-1":
                return (
                    <ParkingApplication
                        key={key}
                        parkingCreateForm={parkingCreateForm}
                        handleChange={handleChange}
                        individualsOptions={individualsOptions}
                        usersOptions={usersOptions}
                        constractorsOptions={constractorsOptions}
                        fenceList={fenceList}
                        coverageList={coverageList}
                        updateToken={updateToken}
                    />
                );
            default:
                return null;
        }
    };
    const handleSelect = (k) => {
        setKey(k);
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="container page-head-wrap">
                <div className="page-head">
                    <div className="page-head__top d-flex align-items-center">
                        <h1>{createNewParking.title}</h1>
                        <div className="ml-auto d-flex">
                            <button type="submit" className="btn btn-white">
                                Сохранить
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="inner-page">
                    <div className="row no-gutters position-relative">
                        <div className="col-md-8 block-parking">
                            <div
                                className="nav block-nav__item "
                                id="v-pills-tab"
                                role="tablist"
                                aria-orientation="vertical"
                            >
                                <Tabs activeKey={key} onSelect={handleSelect}>
                                    <Tab eventKey="parking-tab-1" title="Заявка"/>
                                    {/*<Tab eventKey="parking-tab-2" title="Прайс-лист" />*/}
                                    {/*<Tab eventKey="parking-tab-3" title="Услуги" />*/}
                                </Tabs>
                            </div>
                        </div>
                        <div className="tab-content tab-content-main col-md-12">
                            {renderContent()}
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}

export default ParkingsCreatePage;
