import axios from "axios";

// Application dublicate vin
let API_URL = "/v1/applications/check-duplicate";

const applicationLicensePlateDublicate = async (license_plate) => {
  const response = await axios.get(API_URL, {
    params: {
      license_plate: license_plate,
    },
  });
  return response.data;
};

const applicationLicensePlateDublicateService = {
  applicationLicensePlateDublicate,
};

export default applicationLicensePlateDublicateService;
