import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { moderatorApprove } from "../../../../../../../store/applications/application-moderator/applicationModeratorSlice";
import { useState } from "react";
import { useDispatch } from "react-redux";
import swal from "sweetalert";

function ApplicationFooterModeratorModal({ isModalOpen, closeModal, id }) {
  const dispatch = useDispatch();
  const [moderatorDate, setModeratorDate] = useState(true);
  const [moderatorData, setModeratorData] = useState({
    appId: id,
    notChangeDate: moderatorDate ? 1 : 0,
  });

  const hadleApprove = () => {
    dispatch(moderatorApprove(moderatorData)).then((response) => {
      if (response.payload.status === 200) {
        swal("Успешно одобрено!", {
          icon: "success",
        }).then(() => {
          window.location.replace("/applications/9");
        });
      } else {
        let errorMessage = "При одобрение произошла ошибка!";
        swal(errorMessage, {
          icon: "error",
        });
      }
    });
  };

  const handleChange = () => {
    setModeratorDate(!moderatorDate);
  };

  return (
    <Modal
      show={isModalOpen}
      onHide={closeModal}
      backdrop="static"
      keyboard={false}
      className="custom-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Вы точно хотите одобрить?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label className="switch-radio-wrap mt-2 justify-content-end pr-3">
          <input
            name="notChangeDate"
            type="checkbox"
            value="1"
            checked={moderatorDate}
            onChange={handleChange}
          />
          <span className="switcher-radio"></span>
          <span>Hе менять дату</span>
        </label>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Нет
        </Button>
        <Button variant="primary" onClick={hadleApprove}>
          Да
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ApplicationFooterModeratorModal;
