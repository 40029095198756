import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import applicationLicensePlateDublicateService from "./applicationLicenseDublicateService";

const initialState = {
  license_plate_dublicate: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Vin dublicate
export const license_plateDublicateData = createAsyncThunk(
  "applications/license_plateDublicateData",
  async (license_plate, thunkAPI) => {
    try {
      return await applicationLicensePlateDublicateService.applicationLicensePlateDublicate(
        license_plate
      );
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const licensePlateDublicateSlice = createSlice({
  name: "licensePlateDublicate",
  initialState,
  reducers: {
    licensePlateReset: (state) => {
      state.license_plate_dublicate = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(license_plateDublicateData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(license_plateDublicateData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.license_plate_dublicate = action.payload;
      })
      .addCase(license_plateDublicateData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.license_plate_dublicate = null;
      });
  },
});
export const { licensePlateReset } = licensePlateDublicateSlice.actions;

export default licensePlateDublicateSlice.reducer;
