import React, { useState } from "react";
import Slider from "react-slick";
import noimage from "../../../../../images/no-image.png";
import "photoswipe/dist/photoswipe.css";

import { Gallery, Item } from "react-photoswipe-gallery";
import { useDispatch } from "react-redux";
import { applicationFavority } from "../../../../../store/applications/application-favorite/applicationFavoritySlice";
import { toast } from "react-toastify";

function ApplicationSliderImage({ item, setFavorite }) {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setactiveSlide(next + 1),
  };
  const [activeSlide, setactiveSlide] = useState(1);
  const dispatch = useDispatch();


  const onClickFavorite = () => {
    if (item.id) {
      dispatch(applicationFavority(item.id)).then((response) => {
        if (response.payload.status === 200) {
          if (response.payload.data.favorite) {
            toast.success("Добавлено в избранное!");
          } else {
            toast.warning("Удалил из избранного!");
          }
        }
        setFavorite(new Date());
      });
    }
  };
  return (
    <>
      {item && (
        <div
          className={`car-slide-wrap ${item.favorite ? "select-favorite" : ""}`}
        >
          {/* Same as */}
          <span className="pagingInfo">
            <span>{activeSlide} </span>
            <span className="delimiter">/</span>
            {item.images
              ? item.images.length !== 0
                ? item.images.length
                : 1
              : 1}
          </span>
          <div className="favorite" onClick={onClickFavorite}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 2.5C12.3788 2.5 12.7251 2.714 12.8945 3.05279L15.4734 8.2106L21.144 9.03541C21.5206 9.0902 21.8335 9.35402 21.9511 9.71599C22.0687 10.078 21.9706 10.4753 21.6981 10.741L17.571 14.7649L18.4994 20.4385C18.5608 20.8135 18.4043 21.1908 18.0957 21.4124C17.787 21.6339 17.3794 21.6614 17.0438 21.4834L12 18.8071L6.95624 21.4834C6.62062 21.6614 6.21306 21.6339 5.9044 21.4124C5.59573 21.1908 5.4393 20.8135 5.50065 20.4385L6.42906 14.7649L2.30193 10.741C2.02942 10.4753 1.93136 10.078 2.04897 9.71599C2.16658 9.35402 2.47946 9.0902 2.85609 9.03541L8.5267 8.2106L11.1056 3.05279C11.275 2.714 11.6213 2.5 12 2.5ZM12 5.73607L10.082 9.57221C9.93561 9.86491 9.65531 10.0675 9.33147 10.1146L5.14842 10.723L8.19813 13.6965C8.43182 13.9243 8.53961 14.2519 8.4869 14.574L7.80004 18.7715L11.5313 16.7917C11.8244 16.6361 12.1756 16.6361 12.4687 16.7917L16.2 18.7715L15.5132 14.574C15.4604 14.2519 15.5682 13.9243 15.8019 13.6965L18.8516 10.723L14.6686 10.1146C14.3448 10.0675 14.0645 9.86491 13.9181 9.57221L12 5.73607Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="car-slide">
            {item.images && item.images.length !== 0 ? (
              <Gallery>
                <Slider {...settings}>
                  {item.images.map((attachment, index) => {
                    return (
                      <Item
                        original={attachment.url}
                        thumbnail={attachment.thumbnail_url}
                        width="1024"
                        height="768"
                        key={index}
                      >
                        {({ ref, open }) => (
                          <div
                            className="car-slide__image-wrapper"
                            onClick={open}
                          >
                            <img
                              ref={ref}
                              src={attachment.url}
                              className="car-row__image"
                            />
                          </div>
                        )}
                      </Item>
                    );
                  })}
                </Slider>
              </Gallery>
            ) : (
              <div className="car-slide__no-image">
                <img src={noimage} className="car-row__image" />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default ApplicationSliderImage;
