import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import applicationTypeService from "../applicationSelectService";

const initialState = {
  appType: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Type cars
export const applicationsType = createAsyncThunk(
  "applications/applicationType",
  async (thunkAPI) => {
    try {
      return await applicationTypeService.appType();
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const applicationTypeSlice = createSlice({
  name: "applicationsType",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(applicationsType.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(applicationsType.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.appType = action.payload;
      })
      .addCase(applicationsType.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.appType = null;
      });
  },
});

export default applicationTypeSlice.reducer;
