import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import applicationServiceOrderService from "./applicationServiceOrderService";

const initialState = {
    services: [],
    currentService:{},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};

// Vin dublicate
export const applicationServiceSend = createAsyncThunk(
    "applications/service_send",
    async ({currentApplicationId, data}, thunkAPI) => {
        try {
            return await applicationServiceOrderService.applicationOrderServiceByCustomer({currentApplicationId, data}  );
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const applicationServicesGetById = createAsyncThunk(
    "applications/services_get",
    async ({currentApplicationId}, thunkAPI) => {
        try {
            return await applicationServiceOrderService.createApplicationOrderServiceById({currentApplicationId}  );
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const applicationServicesAddToOrder = createAsyncThunk(
    "applications/services_add_to_order",
    async ({currentApplicationId,currentApplicationOrderId,data}, thunkAPI) => {
        try {
            return await applicationServiceOrderService.applicationOrderServiceAddToOrder({currentApplicationId,currentApplicationOrderId,data}  );
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const applicationServicesDeleteOrder = createAsyncThunk(
    "applications/services_delete_from_order",
    async ({currentApplicationId,currentApplicationOrderId,data}, thunkAPI) => {
        try {
            return await applicationServiceOrderService.applicationOrderServiceDeleteFromOrder({currentApplicationId,currentApplicationOrderId,data}  );
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const currentApplicationServiceById = createAsyncThunk(
    "applications/current_services_get",
    async ({currentApplicationId, orderID}, thunkAPI) => {
        try {
            return await applicationServiceOrderService.getApplicationOrderServiceById({currentApplicationId, orderID});
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
const applicationServiceSlice = createSlice({
    name: "applicationService",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(applicationServiceSend.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(applicationServiceSend.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.message = action.payload.status;
            })
            .addCase(applicationServiceSend.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(applicationServicesGetById.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(applicationServicesGetById.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isError = false;
                state.message = action.payload.status;
                state.services = action.payload;
            })
            .addCase(applicationServicesGetById.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
            .addCase(currentApplicationServiceById.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(currentApplicationServiceById.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.isError = false;
                state.message = action.payload.status;
                state.currentService = action.payload;
            })
            .addCase(currentApplicationServiceById.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
    },
});

export default applicationServiceSlice.reducer;