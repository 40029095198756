import React, {useEffect, useRef, useState} from 'react';
import ApplicationImportHeader from "../../../components/applications/application-import/application-import-header/application-import-header";
import ApplicationImportSubHeader
    from "../../../components/applications/application-import/application-import-header/application-import-subheader";
import ApplicationImportItem from "../../../components/applications/application-import/application-import-item";
import {useDispatch, useSelector} from "react-redux";
import {
    addNewRow,
    cancelUploadedApplication,
    deleteRowById,
    getDataFromExcelFile, importApplicationsToApp,
    importExcelFile
} from "../../../store/applications/application-import/applicationImportSlice";
import swal from "sweetalert";
import {selectArrayCarTypes} from "../../../utils/select-array";

function MassImport() {
    const dispatch = useDispatch()
    const [files, setFiles] = useState([]);
    const [previewUrls, setPreviewUrls] = useState([]);
    const fileInputRef = useRef(null);
    const [applicationWithoutAttach, setApplicationWithoutAttach] = useState({})
    const massImportData = useSelector((state)=>state.applicationMassImportData.applicationsMassImportData)
    const [lastApplicationVersionAfterChanging, setLastApplicationVersionAfterChanging] = useState()


    useEffect(()=>{
       setApplicationWithoutAttach(massImportData)
        setLastApplicationVersionAfterChanging(massImportData.applications)
    },[massImportData])

    useEffect(()=>{
        dispatch(getDataFromExcelFile())
    },[dispatch])

    const cancelCurrentUploadedCars =(e)=>{
        e.preventDefault()
        dispatch(cancelUploadedApplication())
            .then((response)=>{
                if (response.payload === 200){
                    swal("Загрузка отменена", {
                        icon: 'success',
                    }).then(() => {
                        dispatch(getDataFromExcelFile()).then((response)=>{

                        })
                    });
                }
            })
    }
    const addNewRowToApplicationList = ()=>{
        dispatch(addNewRow()).then((resp)=>{
            dispatch(getDataFromExcelFile())
        })
    }
    const handleFileMassUpload = (event) => {
        const selectedFiles = event.target.files;
        const selectedFilesArray = Array.from(selectedFiles);
        setFiles((prevFiles) => prevFiles.concat(selectedFilesArray));

        const fileReaders = selectedFilesArray.map((file) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            return fileReader;
        });

        Promise.all(fileReaders).then((results) => {
            const previewUrls = results.map((result) => result.result);
            setPreviewUrls((prevUrls) => [...prevUrls, ...previewUrls]);
        });
        const data = { file: selectedFilesArray }
        dispatch( importExcelFile({data})).then((response) => {
            if (response.meta.requestStatus === "fulfilled"){
                dispatch(getDataFromExcelFile()).then((resp)=>{
                  if (resp.meta.requestStatus = "fulfilled"){

                  }else{
                      swal("Ошибка при загрузке машин", {
                          icon: "error",
                      });
                  }
                })
            }else{
            }
        });
    }
    function handleClick() {
        fileInputRef.current.click();
    }
    const deleteCurrentRow = (id )=>{
        dispatch(deleteRowById(id))
            .then((response)=>{
               if (response.payload.status === 200){
                   swal("Машина удалена", {
                       icon: 'success',
                   }).then(() => {
                       dispatch(getDataFromExcelFile()).then((response)=>{
                           // if (response.payload?.applications !== undefined){
                           // }
                       })
                   });
               }
            })
    }
    const handleSubmit =(e)=>{
        e.preventDefault()

        // setApplicationWithoutAttach(newArrayOfApplication)

        const joinedData = lastApplicationVersionAfterChanging?.map(item1 => {
            // Find the corresponding object in the second array by ID
            const correspondingItem2Index = applicationWithoutAttach.applications.findIndex(item2 => item2.id === item1.id);

            // If the corresponding object is found
            if (correspondingItem2Index !== -1) {
                const correspondingItem2 = { ...applicationWithoutAttach.applications[correspondingItem2Index] };
                // Update fields in the object from the second array with values from the first array
                Object.keys(item1).forEach(key => {
                    if (key === 'arriving_at' || key === 'courier_fullname' || key === 'courier_phone'
                        || key === 'external_id' || key === 'license_plate' || key === 'parking_id' || key === 'vin') {
                        correspondingItem2[key] = item1[key];
                    }
                });

                const updatedApplications = [...applicationWithoutAttach.applications];
                updatedApplications[correspondingItem2Index] = correspondingItem2;

                // Update the state with the modified array
                setApplicationWithoutAttach(prevState => ({
                    ...prevState,
                    applications: updatedApplications
                }));

                return correspondingItem2;
            }
            return null;
        });

        const newArrayOfApplication = joinedData.map(obj => {
            // Create a new object without the attachments field
            const newObj = { ...obj };
            delete newObj.attachments;
            return newObj;
        });
        const data = newArrayOfApplication
        dispatch(importApplicationsToApp(data)).then((response)=>{
            if (response.payload === 200){
                swal('Авто в обработке',{
                    icon : 'success',
                }).then(()=>{
                    window.location.replace("/applications");
                })
            }
        })
    }

    return (
        <>
        <form action="">
            <ApplicationImportHeader
                handleSubmit={handleSubmit}
                cancelCurrentUploadedCars={cancelCurrentUploadedCars}
            />
            <ApplicationImportSubHeader
                handleFileMassUpload={handleFileMassUpload}
                handleClick={handleClick}
                fileInputRef={fileInputRef}
                addNewRowToApplicationList={addNewRowToApplicationList}
            />
            <div className={'container'}>
                <div className="inner-page">
                    {massImportData.applications !== undefined ?
                        <table className={'table fs-13 sortable horizontal-scrollable vertical-scrollable mass-import-table'}>
                        <thead>
                            <tr>
                                <th>№</th>
                                <th>Фото</th>
                                <th>Тип авто</th>
                                <th>Марка</th>
                                <th>Модель</th>
                                <th>VIN</th>
                                <th>Гос.номер</th>
                                <th>Наим.собственника</th>
                                <th>Тел.собственника</th>
                                <th>№ убытка/договора</th>
                                <th>Предполагаемая <br/> дата постановки</th>
                                <th>Город/Адрес <br/> стоянки</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                massImportData && <ApplicationImportItem
                                massImportData={massImportData}
                                deleteCurrentRow={deleteCurrentRow}
                                applicationWithoutAttach={applicationWithoutAttach}
                                setApplicationWithoutAttach={setApplicationWithoutAttach}
                                setLastApplicationVersionAfterChanging={setLastApplicationVersionAfterChanging}
                                lastApplicationVersionAfterChanging={lastApplicationVersionAfterChanging}

                            /> }
                        </tbody>
                    </table>
                    :
                            <h5 className={'massImportNotification'} >{massImportData.message}</h5>
                       }
                </div>
            </div>
        </form>
        </>
    );
}

export default MassImport;