import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import applicationFilterService from "./applicationFilterService";

const initialState = {
  filter: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Application filter
export const applicationFilterData = createAsyncThunk(
  "applications/applicationFilterData",
  async (thunkAPI) => {
    try {
      return await applicationFilterService.applicationFilter();
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const applicationFilterSlice = createSlice({
  name: "applicationFilter",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(applicationFilterData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(applicationFilterData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.filter = action.payload;
      })
      .addCase(applicationFilterData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.filter = null;
      });
  },
});

export default applicationFilterSlice.reducer;
