const modalSelectStyle = {
  option: (provided, state) => ({
    ...provided,
    fontWeight: state.isSelected ? "bold" : "normal",
    color: state.isSelected ? "white" : "#536E9B",
    backgroundColor: state.isSelected ? "#536E9B" : "white",
    zIndex: 9999,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#536E9B",
    fontSize: "16px",
    padding: "8px ",
    zIndex: 0,
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  control: (provided) => ({
    ...provided,
    border: "1px solid #ced4da", // Добавляем бордер
    width: "100%", // Увеличиваем ширину на 100%
  }),
};

export default modalSelectStyle;
