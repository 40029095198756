import React from "react";
import { Link } from "react-router-dom";

function UserItemParking({ item }) {
  return (
    <Link to={`/parkings/${item.id}/edit`} key={item.id}>
      {item.title}
    </Link>
  );
}

export default UserItemParking;
