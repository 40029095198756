import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import parkingsService from "../parkingsService";

const initialState = {
  createNewParking: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

//  создание новой parking
export const createParking = createAsyncThunk(
  "parkings/parkingCreate",
  async (thunkAPI) => {
    try {
      return await parkingsService.parkignsCreate();
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

// parking Create Post Data
export const parkingCreatePost = createAsyncThunk(
  "parkings/parkingCreatePost",
  async (data, thunkAPI) => {
    try {
      return await parkingsService.parkignsCreateData(data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//

const parkingCreateSlice = createSlice({
  name: "parkingCreate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createParking.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createParking.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.createNewParking = action.payload;
      })
      .addCase(createParking.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.createNewParking = null;
      });
  },
});

export default parkingCreateSlice.reducer;
