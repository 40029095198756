import axios from "axios";

// Application dublicate vin
let API_URL = "/v1/application/change-system-data";

const applicationQuickEdit = async (data) => {
  const response = await axios.post(API_URL, data);
  return { data: response.data, status: response.status };
};

const applicationQuickEditService = {
  applicationQuickEdit,
};

export default applicationQuickEditService;
