import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import applicationServiceOrderPartnerApproveService from "./applicationServiceOrderPartnerApproveService";

const initialState = {
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};


export const applicationServiceApprovePartner = createAsyncThunk(
    "applications/service_approve_partner",
    async ({currentApplicationId,currentApplicationOrderId,orderServices}, thunkAPI) => {
        try {
            return await applicationServiceOrderPartnerApproveService.approveApplicationOrderServiceByPartner({currentApplicationId,currentApplicationOrderId,orderServices} );
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

const applicationServiceApprovePartnerSlice = createSlice({
    name: "applicationServiceApprovePartner",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(applicationServiceApprovePartner.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(applicationServiceApprovePartner.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.message = action.payload.status;
            })
            .addCase(applicationServiceApprovePartner.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
    },
});

export default applicationServiceApprovePartnerSlice.reducer;
