import axios from "axios";

// Application All LiST
let API_URL = "/v1/application/deny/";

const applicationDeny = async (id, text) => {
  const response = await axios.post(API_URL + id, {
    _method: "POST",
    car_additional: text,
  });
  return response.data;
};

const applicationDenyService = {
  applicationDeny,
};

export default applicationDenyService;
