import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { singleLegal } from "../../../store/legal/legal-show/legalShowSlice";

function LegalShowPage() {
  const { id } = useParams();
  const { legalInfo } = useSelector((state) => state.legalsShow);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(singleLegal(id));
  }, [dispatch, navigate]);
  return (
    <>
      {legalInfo && (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="card">
                <div className="card-header">{legalInfo.title}</div>

                <div className="card-body">
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <th scope="row">Имя</th>
                        <td>{legalInfo?.legal?.name}</td>
                      </tr>
                      <tr>
                        <th scope="row">ОГРН</th>
                        <td>{legalInfo?.legal?.reg_number}</td>
                      </tr>
                      <tr>
                        <th scope="row">ИНН</th>
                        <td>{legalInfo?.legal?.inn}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default LegalShowPage;
