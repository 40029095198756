import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import issueRequestService from "../issueRequestService";

// issueRequest get data
export const issuanceUpdate = createAsyncThunk(
  "application/issuanceUpdate",
  async ({ id, data }, thunkAPI) => {
    try {
      return await issueRequestService.issuanceUpdate(id, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
