const selectStyle = {
  option: (provided, state) => ({
    ...provided,
    fontWeight: state.isSelected ? "bold" : "normal",
    color: state.isSelected ? "white" : "#536E9B",
    backgroundColor: state.isSelected ? "#536E9B" : "white",
    zIndex: 999,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#536E9B",
    fontSize: "16px",
    padding: "8px ",
    zIndex: 999,
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
};

export default selectStyle;
