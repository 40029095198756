import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import logsService from "../logs/logsService";

const initialState = {
    logs : [],
    isError : false,
    isSuccess : false,
    isLoading : false,
    message : '',
}

export const logsData  = createAsyncThunk(
    'logs/logsData',
    async  (paginationPage, thunkAPI)=>{
        try {
            return await logsService.getAllLogs(paginationPage)
        }catch (error){
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
)
const logsSlice = createSlice({
    name: 'logs',
    initialState,
    reducers :{},
    extraReducers: (builder)=>{
        builder
            .addCase(logsData.pending, (state)=>{
                state.isLoading = true;
            })
            .addCase(logsData.fulfilled, (state, action)=>{
                state.isLoading= false;
                state.isError = false;
                state.isSuccess = true;
                state.logs = action.payload

            })
            .addCase(logsData.rejected, (state, action)=>{
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
    }
})

export default logsSlice.reducer;