import axios from "axios";

// Application dublicate vin
let API_URL = "/v1/application/";

const applicationModeratrorApprove = async (data) => {
  const response = await axios.post(API_URL + "approved", data);
  return { data: response.data, status: response.status };
};

const applicationModeratrorReject = async (data, id) => {
  const response = await axios.post(API_URL + `send-chat-message/${id}`, data);
  return { data: response.data, status: response.status };
};

const applicationModeratorService = {
  applicationModeratrorApprove,
  applicationModeratrorReject,
};

export default applicationModeratorService;
