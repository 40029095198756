import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import permissionsData from "./permissionsService";

const initialState = {
    permissions: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
}

export const permissionsGet = createAsyncThunk(
    "permissions/get", async (thunkAPI) => {
    try {
        return await permissionsData.permissions()
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data) ||
            error.message ||
            error.toSrting()
        return thunkAPI.rejectWithValue(message)
    }
})
export const permissionsUpdate = createAsyncThunk(
    "permissions/update", async ({data},thunkAPI) => {
        try {
            return await permissionsData.permissionsUpdate({data})
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting()
            return thunkAPI.rejectWithValue(message)
        }
    })

const permissionsSlice = createSlice({
    name: "permissions",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(permissionsGet.pending, (state) => {
                state.isLoading = true
            })
            .addCase(permissionsGet.fulfilled, (state, action) => {
                state.isLoading = false
                state.isSuccess = true
                state.permissions = action.payload
            })
            .addCase(permissionsGet.rejected, (state, action) => {
                state.isLoading = false
                state.isError = true
                state.message = action.payload
            })
    },
})

export default permissionsSlice.reducer