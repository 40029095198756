import {initializeApp, } from 'firebase/app';
import {getMessaging, getToken, onMessage} from 'firebase/messaging';
import { getDatabase } from 'firebase/database';

//development
// const firebaseConfig = {
//   apiKey: "AIzaSyCSs4NA6AgLGT_tt1eBiw9EiIpua_BwMug",
//   authDomain: "mypark-dd5a4.firebaseapp.com",
//   projectId: "mypark-dd5a4",
//   storageBucket: "mypark-dd5a4.appspot.com",
//   messagingSenderId: "256597169374",
//   appId: "1:256597169374:web:b7933638419c3d2c605e4f",
//   measurementId: "G-6YMZ0XCHK6"
// };

//production
const firebaseConfig = {
    apiKey: "AIzaSyDtPt79Lqb8KEw07pf7rATOfOIsXBYcqiE",
    authDomain: "mypark-prod.firebaseapp.com",
    databaseURL: "https://mypark-prod-default-rtdb.firebaseio.com",
    projectId: "mypark-prod",
    storageBucket: "mypark-prod.appspot.com",
    messagingSenderId: "749356640895",
    appId: "1:749356640895:web:69a26ebd6e18212eeeed4a",
    measurementId: "G-H3TTRYBH6W"
};


// Initialize Firebase app
const app = initializeApp(firebaseConfig);
// Get the messaging instance
export const messaging = getMessaging(app);

export const db = getDatabase(app);

// Define the function to request FCM token
export const requestForToken = () => {
    return getToken(messaging, { vapidKey: `BM6eWh22H8k_cslGjKiE0mR2Wdr2PUhzQBsOP8oq6SxDMJK3IJcpi3oDwnsuUaQi8ZnB0hMJsJ-LXtRZ9hD82bE` })
        .then((token) => {

            return token;
        })
        .catch((err) => {
            console.log('Unable to get FCM token.', err);
            return null;
        });
};

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);

        });
    });
