import React, {useEffect, useState} from 'react';
import Select from "react-select";
import selectStyle from "../../../../utils/select-styles";
import {selectArray, selectArrayParking} from "../../../../utils/select-array";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";

function ReportHeader(
    {
        reportsByPartner,
        showReportByOrder,
        total,
        linkToExcel,
        getFilteredExcel,
        forFilter
    }) {
    const partnersSelect = selectArray(forFilter?.partners, "partner_id[]");
    const parkingSelect = selectArrayParking(forFilter?.parkings,"parking_id[]");
    const [selectedPartner, setSelectedPartner] = useState(0);
    const [selectedParking, setSelectedParking] = useState(0);
    const [completed, setCompleted] = useState(0);
    const [dateStatus, setDateStatus] = useState();

    useEffect(()=>{
        const data = {
            partner_id: selectedPartner,
            parking_id: selectedParking,
            completed: completed,
        }
        showReportByOrder(data)
        getFilteredExcel(data)
    },[])

    const handleSelectChange = (selected, setSelectedOptions) => {
        setSelectedOptions(selected.value);
        const data = {
            partner_id: selectedPartner,
            parking_id: selectedParking,
            completed: completed,
        }
        getFilteredExcel(data)
    };
    const handleCompletedChange =(e)=>{
        setCompleted(e.target.value)
    }
    const handleSubmit = (e)=>{
        e.preventDefault();
            const data = {
                partner_id: selectedPartner,
                parking_id: selectedParking,
                completed: completed,
                dates: dateStatus?.dates,
            }
            showReportByOrder(data)
            getFilteredExcel(data)
    }
    const hadleClick = (e) => {
        e.preventDefault();
        window.location.replace(linkToExcel);
    };
    const handleChange = (e) => {
        if (Array.isArray(e)) {
            let formattedDates = e;
            formattedDates = formattedDates
                .map((item) =>
                    new Date(item)
                        .toLocaleDateString("ru-RU", {
                            day: "numeric",
                            month: "numeric",
                            year: "numeric",
                        })
                        .replace(/\./g, "-")
                )

                .join(" — ");
            setDateStatus({ ...dateStatus, dates: formattedDates });
        }
    };
    return (
        <div className="container page-head-wrap">
            <div className="page-head">
                <div className="page-head__top d-flex align-items-center">
                    <h1>Отчёт по Заказам</h1>
                </div>
            </div>
            <form onSubmit={handleSubmit} className="filter">
                <div className="d-flex align-items-center mb-3">
                    {/* заказчик */}
                    <label className="field-style  select-react__style">
                        <span>Заказчик</span>
                        <Select
                            options={partnersSelect}
                            styles={selectStyle}
                            onChange={(selected) =>
                                handleSelectChange(selected, setSelectedPartner)
                            }
                            id="status-selection"
                            name="partner_id[]"
                        />
                    </label>
                    {/*  */}

                    {/* Стоянка */}
                    <label className="field-style select-react__style">
                        <span>Стоянка</span>
                        <Select
                            // isMulti
                            options={parkingSelect}
                            styles={selectStyle}
                            onChange={(selected) =>
                                handleSelectChange(selected, setSelectedParking)
                            }
                            name="parking_id[]"
                        />
                    </label>
                    {/*  */}

                    {/* Выполненные  */}
                    <label className="field-style select-react__style">
                        <span>Выполненные</span>
                        <select
                            onChange={handleCompletedChange}
                        >
                            <option key={0} value="0">Все</option>
                            <option key={1} value="1">Выполненые</option>
                        </select>
                    </label>
                    {/*    */}
                    <label className="field-style">
                        <span>Даты отчёта</span>
                        <Flatpickr
                            options={{
                                mode: "range",
                                dateFormat: "d-m-Y",
                                locale: {
                                    ...Russian,
                                    months: {
                                        ...Russian.months,
                                        longhand: [
                                            "Января",
                                            "Февраля",
                                            "Марта",
                                            "Апреля",
                                            "Мая",
                                            "Июня",
                                            "Июля",
                                            "Августа",
                                            "Сентября",
                                            "Октября",
                                            "Ноября",
                                            "Декабря",
                                        ],
                                    },
                                    rangeSeparator: "—",
                                },
                            }}
                            onChange={handleChange}
                            name="dates"
                        />
                    </label>
                </div>
                <button type="submit" className="btn btn-primary ml-auto">
                    Показать
                </button>
                <div className="btn btn-dowenload" onClick={hadleClick}></div>
                <label className="field-style pl-5">
                    Всего:
                    {total}
                </label>
            </form>
        </div>
    );
}

export default ReportHeader;