import styles from '../permission.module.css'
import PermissionByRole from "./permission-by-role/permissionByRole";
function PermissionItem({permission, permissionsRoleList, togglePermission}) {
    return (
        <tr>
            <td className={styles['permission-actions']}>
                <b>{permission.ru}</b>
                <span>{permission.name}</span>
            </td>
            <PermissionByRole
                permissions = {permissionsRoleList.SuperAdmin}
                role={'SuperAdmin'}
                permissionName = {permission.name}
                togglePermission={togglePermission}
            />
            <PermissionByRole
                permissions = {permissionsRoleList.Admin}
                role={'Admin'}
                permissionName = {permission.name}
                togglePermission={togglePermission}
            />
            <PermissionByRole
                permissions = {permissionsRoleList.Manager}
                role={'Manager'}
                permissionName = {permission.name}
                togglePermission={togglePermission}
            />
            <PermissionByRole
                permissions = {permissionsRoleList.Operator}
                role={'Operator'}
                permissionName = {permission.name}
                togglePermission={togglePermission}
            />
            <PermissionByRole
                permissions = {permissionsRoleList.Partner}
                role={'Partner'}
                permissionName = {permission.name}
                togglePermission={togglePermission}
            />
            <PermissionByRole
                permissions = {permissionsRoleList.PartnerOperator}
                role={'PartnerOperator'}
                permissionName = {permission.name}
                togglePermission={togglePermission}
            />
            <PermissionByRole
                permissions = {permissionsRoleList.Moderator}
                role={'Moderator'}
                permissionName = {permission.name}
                togglePermission={togglePermission}
            />
            <PermissionByRole
                permissions = {permissionsRoleList.Contractor}
                role={'Contractor'}
                permissionName = {permission.name}
                togglePermission={togglePermission}
            />
            <PermissionByRole
                permissions = {permissionsRoleList.ContractorManager}
                role={'ContractorManager'}
                permissionName = {permission.name}
                togglePermission={togglePermission}
            />
            <PermissionByRole
                permissions = {permissionsRoleList.ContractorOperator}
                role={'ContractorOperator'}
                permissionName = {permission.name}
                togglePermission={togglePermission}
            />

        </tr>
    )
}
export default PermissionItem;