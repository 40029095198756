import {createAsyncThunk} from "@reduxjs/toolkit";
import uploadImageService from "../uploadImageService";

export const uploadImageParkingSlice = createAsyncThunk(
    "parking/uploadImage",
    async (data, thunkAPI) => {
        try {
            return await uploadImageService.uploadApiForParking(data);
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
