import { createAsyncThunk } from "@reduxjs/toolkit";
import uploadImageService from "../uploadImageService";

export const uploadImageSlice = createAsyncThunk(
  "application/uploadImage",
  async ({data}, thunkAPI) => {
    try {
      return await uploadImageService.uploadApi({data});
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
