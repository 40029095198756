import axios from "axios";

// Application All LiST

const application = async (id = null, page, query) => {
  let API_URL = "/v1/applications";

  if (id !== null) {
    API_URL = "/v1/applications/" + id;
  }
  const response = await axios.get(API_URL + `?page=${page}`, {
    params: query,
  });
  return response.data;
};

const applicationDublicate = async (page) => {
  const response = await axios.get(
    "/v1/applications/duplicate" + `?page=${page}`
  );
  return response.data;
};

const applicationService = {
  application,
  applicationDublicate,
};

export default applicationService;
