import Modal from "react-modal";
import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {customStyles } from "../../../utils/partners-modal";
import {partnerToggleActive} from '../../../store/partners/partner-toggle-active/partnerToggleActiveSlice'
import {partnerAdd} from '../../../store/partners/partners-edit-slice/partnersEditSlice'
import {partnerModalInfo} from "../../../store/partners/partner-get-modal-content-slice/partnersGetModalContentSlice";

function PartnerUserModal({isModalOpen, closeModal, currentUser}) {
    const dispatch = useDispatch();
    const [localSearchField, setLocalSearchField] = useState('show');
    const [localSelectUserField, setLocalSelectUserField] = useState('hide');

    const [selectedUserId, setSelectedUserId] = useState();
    const modalData  = useSelector((state) => state.partnerModalInfo);

    //change active status by current user
    const toggleActive = (userId, partnerId) => {
        dispatch(partnerToggleActive({userId:userId,  partnerId: partnerId })).then(response => {
            if(response.payload.status === 200){
                dispatch(partnerModalInfo(partnerId));
            }
        })
    }
    //
    //  searching in modal among users by email
    const handleSearch = (event) => {
        if (event.target.value.length){
            modalData.partnerModal.partnerUsers && modalData.partnerModal.partnerUsers.filter((item) => item.email.includes(event.target.value))
        }
    };
    //
    const handleAddUser = () => {
      dispatch(partnerAdd({id: currentUser.id, user_id:selectedUserId })).then(response => {
          if(response.payload.status === 200){
              dispatch(partnerModalInfo(currentUser.id));
          }
      })
    }
    // change forms search by email and select absent users
    const handleSearchAbsentUsers = () => {
        setLocalSearchField('hide')
        setLocalSelectUserField('show')
    }
    const handleSearchAbsentUsersBack = ()=>{
        setLocalSearchField('show')
        setLocalSelectUserField('hide')
    }
    //
    return (
        <div>
            {/*{modalData.isLoading && <h2>Идет загрузка</h2> }*/}
            {/*{modalData.isError && <h2>Server error ...</h2> }*/}

                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal}
                    contentLabel="Модальное окно"
                    ariaHideApp={false}
                    style={customStyles}
                >
                    <div className="table-modal">
                        <div className="modal-header">
                            <h5 className="modal-title" id="modal-tableLabel">
                                Управление Заказчиком {currentUser.shortname}
                            </h5>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={closeModal}
                            >
                                <svg
                                    width="15"
                                    height="14"
                                    viewBox="0 0 15 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1.07268 0.851982C1.44693 0.494001 2.05372 0.494001 2.42797 0.851982L7.50033 5.7038L12.5727 0.851982C12.9469 0.494001 13.5537 0.494001 13.928 0.851982C14.3022 1.20996 14.3022 1.79036 13.928 2.14834L8.85561 7.00016L13.928 11.852C14.3022 12.21 14.3022 12.7904 13.928 13.1483C13.5537 13.5063 12.9469 13.5063 12.5727 13.1483L7.50033 8.29653L2.42797 13.1483C2.05372 13.5063 1.44693 13.5063 1.07268 13.1483C0.698429 12.7904 0.698429 12.21 1.07268 11.852L6.14504 7.00016L1.07268 2.14834C0.698429 1.79036 0.698429 1.20996 1.07268 0.851982Z"
                                        fill="#D0D0D0"
                                    ></path>
                                </svg>
                            </button>
                        </div>
                        <div className="scroll-modal">
                            <div className="table-modal-head">
                                <div className={`d-flex modal-default-head ${localSearchField}`}>
                                    <label className="field-style">
                                        <span>Поиск пользователей</span>
                                        <input type="text" onChange={handleSearch} placeholder="E-mail"/> <br/>
                                        <span>Добавить</span>
                                    </label>
                                    <button type="button" onClick={handleSearchAbsentUsers}
                                            className={`addUserOfPartner d-flex align-items-start`}>
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M9.99967 3.3335C10.4599 3.3335 10.833 3.70659 10.833 4.16683V9.16683H15.833C16.2932 9.16683 16.6663 9.53993 16.6663 10.0002C16.6663 10.4604 16.2932 10.8335 15.833 10.8335H10.833V15.8335C10.833 16.2937 10.4599 16.6668 9.99967 16.6668C9.53944 16.6668 9.16634 16.2937 9.16634 15.8335V10.8335H4.16634C3.7061 10.8335 3.33301 10.4604 3.33301 10.0002C3.33301 9.53993 3.7061 9.16683 4.16634 9.16683H9.16634V4.16683C9.16634 3.70659 9.53944 3.3335 9.99967 3.3335Z"
                                                fill="#536E9B"
                                            ></path>
                                        </svg>
                                        Добавить <br/> пользователя
                                    </button>
                                </div>

                                <div className={`d-flex modal-add-user ${localSelectUserField}`}>
                                    <div className="d-down-field field-style ">
                                        <span>Выберите пользователя</span>
                                        <select
                                            name="user_id"
                                            id="partner_user"
                                            className="partnerAdmin"
                                            onChange={(e) => setSelectedUserId(e.target.value)}
                                        >
                                            <option key={'1'} value={'empty'} disabled={'disabled'}
                                                    selected={'selected'}>Выбрать пользователя
                                            </option>
                                            {modalData.partnerModal.absentUsers && modalData.partnerModal.absentUsers.map((item, index) =>
                                                <option key={item.id} value={item.id}>{item.email}</option>
                                            )}

                                        </select>
                                    </div>
                                    <button
                                        type="button"
                                        className="addPartnerUser"
                                        data-user-partner-id="76"
                                        onClick={() => handleAddUser()}
                                    >
                                        <svg
                                            width="20"
                                            height="20"
                                            viewBox="0 0 20 20"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M9.99967 3.3335C10.4599 3.3335 10.833 3.70659 10.833 4.16683V9.16683H15.833C16.2932 9.16683 16.6663 9.53993 16.6663 10.0002C16.6663 10.4604 16.2932 10.8335 15.833 10.8335H10.833V15.8335C10.833 16.2937 10.4599 16.6668 9.99967 16.6668C9.53944 16.6668 9.16634 16.2937 9.16634 15.8335V10.8335H4.16634C3.7061 10.8335 3.33301 10.4604 3.33301 10.0002C3.33301 9.53993 3.7061 9.16683 4.16634 9.16683H9.16634V4.16683C9.16634 3.70659 9.53944 3.3335 9.99967 3.3335Z"
                                                fill="#536E9B"
                                            ></path>
                                        </svg>
                                        Добавить
                                    </button>
                                    <button type="button" onClick={handleSearchAbsentUsersBack} className="close-add">
                                        <svg
                                            width="15"
                                            height="14"
                                            viewBox="0 0 15 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M1.07268 0.851982C1.44693 0.494001 2.05372 0.494001 2.42797 0.851982L7.50033 5.7038L12.5727 0.851982C12.9469 0.494001 13.5537 0.494001 13.928 0.851982C14.3022 1.20996 14.3022 1.79036 13.928 2.14834L8.85561 7.00016L13.928 11.852C14.3022 12.21 14.3022 12.7904 13.928 13.1483C13.5537 13.5063 12.9469 13.5063 12.5727 13.1483L7.50033 8.29653L2.42797 13.1483C2.05372 13.5063 1.44693 13.5063 1.07268 13.1483C0.698429 12.7904 0.698429 12.21 1.07268 11.852L6.14504 7.00016L1.07268 2.14834C0.698429 1.79036 0.698429 1.20996 1.07268 0.851982Z"
                                                fill="#D0D0D0"
                                            ></path>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <table className="table table_sort adminsEmail">
                                <thead>
                                <tr>
                                    <th></th>
                                    <th className="sortable">Пользователь</th>
                                    <th className="sortable">Статус</th>
                                </tr>
                                </thead>
                                <tbody>

                                {modalData.partnerModal.partnerUsers != null && modalData.partnerModal.partnerUsers.length !== 0
                                    ? modalData.partnerModal.partnerUsers.map((item, index) => (

                                        <tr className="tr">
                                            <td className="tr-id">{index + 1}</td>
                                            <td>
                                                <div className="first-info d-flex align-items-center">
                                                </div>
                                                <div>{item.email}</div>
                                            </td>
                                            <td>
                                                <label className="switch-radio-wrap">
                                            <span className="d-none check-st">
                                              2<span></span>
                                            </span>
                                                    <input
                                                        type="checkbox"
                                                        name="switch-radio"
                                                        className="partner-user-activate"
                                                        data-partner-id="76"
                                                        data-user-id="17"
                                                        defaultChecked={item.active === 1 ? 'checked' : ''}
                                                        onChange={() => toggleActive(item.user_id, item.partner_id)}
                                                    />
                                                    <span className="switcher-radio"></span>
                                                    <span className="table-modal-status-active">Активен</span>
                                                    <span className="table-modal-status-disabled">
                                              Не активен
                                            </span>
                                                </label>
                                            </td>
                                        </tr>
                                    ))
                                    : ""}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Modal>


        </div>
    );
}

export default PartnerUserModal;
