import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import parkingsService from "../parkingsService";
import {editParkingPrice} from "../parking-edit-prices-slice/parkingEditPricesSlice";

const initialState={
    parkingServices: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
}
export const editParkingService = createAsyncThunk(
    'editParking/editParkingService',
    async ({id, partner_id}, thunkAPI)=>{
        try {
            return await parkingsService.parkingEditService({id, partner_id});
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
)
export const updateParkingService = createAsyncThunk(
    'updateParking/editParkingService',
    async ({id, newData, selectedOption}, thunkAPI)=>{
        try {
            return await parkingsService.parkingUpdateService({id, newData, selectedOption});
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
)

const ParkingEditServicesSlice = createSlice({
    name: 'ParkingEditServicesSlice',
    initialState,
    reducers:{},
    extraReducers: (builder) => {
        builder
            .addCase(editParkingService.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(editParkingService.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.parkingServices = action.payload;
            })
            .addCase(editParkingService.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.parkingServices = null;
            });
    },
})

export default ParkingEditServicesSlice.reducer