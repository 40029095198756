import axios from "axios";

const API_URL = "/v1/applications";
// Загрузка excel для массовой загрузки авто
const applicationExcelFileUpload = async ({data}) => {
    const dataFile = {file:data.file[0]}
    const response = await axios.post(API_URL + "/mass-upload", dataFile, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    });

    return response.data;
};
//Получение и редактировани данных после загрузки excel
const applicationGetDataFromExcelFile = async () => {
    const response = await axios.get(API_URL + "/edit/mass-upload");

    return response.data;
};
//импорт данных в myparking
const importToMyParking = async (data) =>{

    const formatedData = {
        applications:data
    }
    const responses = await axios.put(API_URL + `/update/mass-upload`, formatedData)
    return responses.status
}
const deleteRowByApplicationId = async (id) => {
    const response = await axios.delete(API_URL + `/delete/mass-upload/${id}`)
    return response
}

const cancelUploadedApplication = async ()=>{
    const response = await axios.delete(API_URL + `/delete/mass-upload`)
    return response.status
}
const applicationAddNewRow = async () =>{
    const response = await axios.post(API_URL + `/update/mass-upload/add-row`)
    return response
}

const applicationImportService = {
    applicationExcelFileUpload,
    applicationGetDataFromExcelFile,
    importToMyParking,
    deleteRowByApplicationId,
    cancelUploadedApplication,
    applicationAddNewRow
}

export default applicationImportService

