import React from "react";
import newLogo from "../../../images/Logo.svg"

const Logo = () => {
  return (
    <>
      <a href="/">
        <img style={{maxWidth:'100px'}} src={newLogo} alt="" className="logo" />
      </a>
    </>
  );
};

export default Logo;
