import React from 'react';
import Sidebar from "../../components/guide/sidebar/sidebar";

function Guide() {
    return (
        <>
          <Sidebar  />
        </>
    );
}

export default Guide;