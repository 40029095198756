import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import applicationTypeService from "../applicationSelectService";

const initialState = {
  engines: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Type cars
export const applicationsEngines = createAsyncThunk(
  "applications/applicationsEngines",
  async (id, thunkAPI) => {
    try {
      return await applicationTypeService.appEngines(id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const applicationEnginesSlice = createSlice({
  name: "applicationsEngines",
  initialState,
  reducers: {
    enginesReset: (state) => {
      state.engines = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(applicationsEngines.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(applicationsEngines.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.engines = action.payload;
      })
      .addCase(applicationsEngines.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.engines = null;
      });
  },
});
export const { enginesReset } = applicationEnginesSlice.actions;

export default applicationEnginesSlice.reducer;
