import React, {useEffect, useState} from 'react';
import AplicationServices from "../aplication-services";
import ApplicationAddCustomService from "./application-add-custom-service/application-add-custom-service";
import ApplicationAddServiceHeader from "./application-add-service-header/application-add-service-header";
import {useDispatch, useSelector} from "react-redux";
import ApplicationAddExistingService from "./application-add-existing-service/application-add-existing-service";

function ApplicationAddService(
    {
        getApplicationSelectedServices,
        getSelectedServicesForFooterInfo,
        single,
    }) {
    const [servicesType, setServicesType] = useState(false)
    const toggleServicesType = ()=>{
        setServicesType(prevState => !prevState)
    }

    const [selectedServices, setSelectedServices] = useState([]);
    const [parkingId, setParkingId] = useState(null)
    const [categories, setCategories] = useState([])
    const [groupedData, setGroupedData] = useState([])
    const [filterId, setFilterId] = useState(null)
    const [rows, setRows] = useState([]);


    const collectDataAboutSelectedServices = (e, parkingId, serviceId, cost, cost_for_us, name) => {
        setParkingId(parkingId);
        setSelectedServices((prevServices) => {
            if (e.target.checked) {
                return [
                    ...prevServices,
                    {
                        parking_has_service_id: serviceId,
                        price: cost,
                        price_for_us: cost_for_us,
                        name: name,
                    },
                ];
            } else {
                return prevServices.filter(
                    (service) => service.parking_has_service_id !== serviceId
                );
            }
        });
    };
    useEffect(() => {
        getApplicationSelectedServices(single.application.id, parkingId, selectedServices)
    }, [selectedServices])
    useEffect(() => {

    }, [rows])
    useEffect(() => {
        getSelectedServicesForFooterInfo(selectedServices)
    }, [getApplicationSelectedServices])
    useEffect(() => {
        const categoriesList = []
        single.parkingHasServices?.forEach(item => {
            const categoryItem = {
                catId: item.parking_service.service_category?.id,
                catName: item.parking_service.service_category?.name
            }
            const isItemUnique = !categoriesList.some(
                (category) => category.catId === categoryItem.catId
            );
            if (isItemUnique) {
                categoriesList.push(categoryItem);
                setCategories([...categoriesList]);
            }
        });
        setGroupedData(single.parkingHasServices);
    }, [single.parkingHasServices])
    useEffect(() => {
        if (single.parkingHasServices && single.parkingHasServices.length > 0) {
            setFilterId(single.parkingHasServices[0].parking_service.service_category?.id)
        }
    }, [single.parkingHasServices])
    useEffect(()=>{
        setServicesType(false)
    },[])
    // Function to add a new row
    const addRow = () => {
        const rowId = Date.now().toString(36) + Math.random().toString(36).substr(2)
        setRows([...rows, {rowId : rowId, custom_service : '', price: '', price_for_us: '' }])
    };
    const handleInputChange = (index, event) => {
        const {name, value} = event.target;
        const newRows = [...rows];
        newRows[index][name] = value;
        setRows(newRows);

        // Get a copy of selectedServices
        const updatedServices = [...selectedServices];

        // Check if the row at the current index already exists in selectedServices
        const existingServiceIndex = updatedServices.findIndex(service => service.rowId === newRows[index].rowId);

        if (existingServiceIndex !== -1) {
            // Update the existing service
            updatedServices[existingServiceIndex] = newRows[index];
        } else {
            // Add the new row if it doesn't exist in selectedServices
            updatedServices.push(newRows[index]);
        }

        setSelectedServices(updatedServices);
    }
    const deleteRow = (index) => {
        const newRows = rows.filter((row, i) => i !== index); // Remove row by index
        setRows(newRows);
    };
    return (
        <>
            <ApplicationAddServiceHeader
                servicesType={servicesType}
                toggleServicesType={toggleServicesType}
            />
            {!servicesType ?
               <ApplicationAddExistingService
                single={single}
                collectDataAboutSelectedServices={collectDataAboutSelectedServices}
                groupedData={groupedData}
                selectedServices={selectedServices}
                filterId={filterId}
                setFilterId={setFilterId}
                categories={categories}
               />
                :
                <ApplicationAddCustomService
                    addRow={addRow}
                    handleInputChange={handleInputChange}
                    deleteRow={deleteRow}
                    rows={rows}
                />
            }


        </>
    );
}

export default ApplicationAddService;