import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {createFence, getAllFence} from "../../store/parkings/create-fence/createFenceSlice";
import swal from "sweetalert";
import Sidebar from "../../components/guide/sidebar/sidebar";
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';
import {
    changeParkingServiceName,
    changeParkingServicesSeq,
    changeParkingServicesSequence,
    createParkingServices,
    getParkingCategory,
    getParkingServices
} from "../../store/parkings/parking-services-create-slice/parkingServicesСreateSlice";
import ServiceItem from "../../components/guide/service-item";
import {deleteService} from "../../store/parkings/parking-services-delete-slice/parkingServicesDeleteSlice";

function Services() {
    const dispatch = useDispatch();
    const allServices = useSelector((state) => state.parkingAllServices.parkingServices);
    const allCategories = useSelector((state) => state.parkingAllServices.parkingCategories);
    const [serviceObjectToCreate, setServiceObjectToCreate] = useState({
        service_category_id: 0,
        name: "",
        cost: 0,
        contact_name: "",
        contact_phone: "",
        comment: ""
    })

    const [users, setUsers] = useState(allServices.parkingServices);
    const [orderServices, setOrderServices] = useState([]);
    const [orderServicesForBackend, setOrderServicesForBackend] = useState({});

    //drag and drop sorting
    const onDragEnd = result => {
        if (!result.destination) {
            return;
        }
        const updatedItems = [...orderServices];
        const [removed] = updatedItems.splice(result.source.index, 1);
        updatedItems.splice(result.destination.index, 0, removed);

        const reorderedItems = updatedItems.map((item, index) => ({
            ...item,
            sequence: index
        }));

        const data = reorderedItems.map((item)=>{
            const {id, sequence } = item
            return {id, sequence }
        })

        setOrderServices(reorderedItems);
        setOrderServicesForBackend({parking_services: data})

        dispatch(changeParkingServicesSeq(data)).then((response)=>{
        })
    };

    useEffect(() => {
        const tempSerList = allServices?.parkingServices?.map((service, index) => {
            return {
                ...service,
                sequence: index
            }
        })
        setOrderServices(tempSerList)
    }, [allServices])

    const handleDeleteService = (id) => {
        swal({
            title: "Вы уверены что хотите удалить услугу?",
            icon: "warning",
            buttons: ["Отменить", "Да"],
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                dispatch(deleteService(id)).then((response) => {
                    if (response.payload === 200) {
                        dispatch(getParkingServices())
                        swal("Услуга удалено успешно", {
                            icon: "success",
                        })
                    } else {
                        swal("Ошибка при удалении услуги", {
                            icon: "error",
                        });
                    }
                })
            } else {
                swal("Ошибка при удалении услуги", {
                    icon: "error",
                });
            }
        })


    }

    useEffect(() => {
        dispatch(getParkingCategory())
        dispatch(getParkingServices())
    }, [dispatch])


    const handleSetNewServiceName = (e) => {
        const newName = e.target.value;
        setServiceObjectToCreate({
            ...serviceObjectToCreate,
            name: newName
        });
    }
    const handleSelectNewService = (e) => {
        if (e.target.name === "selectCategory"){
            const newCategoryId = parseInt(e.target.value, 10);
            setServiceObjectToCreate({
                ...serviceObjectToCreate,
                service_category_id: newCategoryId
            });
        }if (e.target.name === "carType" && parseInt(e.target.value) !== 4){
            const newCarType = parseInt(e.target.value, 10);
            if (serviceObjectToCreate.car_subtype_id) {
                delete serviceObjectToCreate.car_subtype_id;
            }
            setServiceObjectToCreate({
               ...serviceObjectToCreate,
                car_type_id: newCarType
            });
        }if(e.target.name === "carType" && parseInt(e.target.value) === 4){
            const carSubtypeId = e.target.selectedOptions[0].getAttribute('data-car-subtype-id');
            if (serviceObjectToCreate.car_type_id) {
                delete serviceObjectToCreate.car_type_id;
            }
            setServiceObjectToCreate({
                ...serviceObjectToCreate,
                car_subtype_id: parseInt(carSubtypeId)
            });
        }
    }

    const renameServiceItem = (parkingservice_id, name , service_category_id, newServiceCarType, newServiceCarSubType) =>{
        const data = {
            service_category_id:service_category_id,
            name: name,
        }

        if (newServiceCarType !== null) {
            data.car_type_id = newServiceCarType;
            delete data.car_subtype_id; // Ensure car_subtype_id is not included
        } else {
            data.car_subtype_id = newServiceCarSubType;
            delete data.car_type_id; // Ensure car_type_id is not included
        }
        dispatch(changeParkingServiceName({data, parkingservice_id})).then((resp)=>{
            if (resp.payload.status === 200) {
                dispatch(getParkingServices())
                swal("Услуга успешна обнавлена", {
                    icon: "success",
                })
            } else {
                swal("Ошибка при обнавлении услуги", {
                    icon: "error",
                });
            }
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        if (serviceObjectToCreate?.name?.length > 0 || serviceObjectToCreate.id !== undefined) {
            dispatch(createParkingServices(serviceObjectToCreate)).then((response) => {
                if (response.payload.status === 201) {
                    dispatch(getParkingServices())
                    swal("Услуга создана успешно", {
                        icon: "success",
                    })
                } else {
                    swal(`${response.payload}`, {
                        icon: "error",
                    });
                }
            })
        } else {
            swal("Название услуги и категория не могут быть пустыми", {
                icon: "error",
            });
        }
    }
    return (
        <div className={'container'}>
            <div className="inner-page">
                <div className="page-head-wrap">
                    <div className="page-head">
                        <div className="page-head__top d-flex align-items-center">
                            <h1>Услуги</h1>
                        </div>
                    </div>
                </div>
                <div className="d-flex">
                    <Sidebar/>
                    <div className="col-md-8 p-3 border-left">
                        <form onSubmit={handleSubmit}>
                            <div className="inner-item-title">Создать услуги</div>
                            <table className={''}>
                                <thead>
                                <tr >
                                    <th> Наименование услуги </th>
                                    <th></th>
                                    <th>Категория</th>
                                    <th>Тип авто</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr >
                                    {/* Service name */}
                                    <th className="pe-auto " colSpan="2">
                                        <label className="field-style flex-grow-1 mb-0">
                                            <input onChange={handleSetNewServiceName} type="text"
                                                   className="w-100 pt-0 h-44" placeholder="Наименование услуги"/>
                                        </label>
                                    </th>
                                    {/*service category*/}
                                    <th>
                                        <label className="field-style w-auto mb-0">
                                            <select name="selectCategory" id="" className="page-select"
                                                    onChange={handleSelectNewService}>
                                                <option key={'empty'} selected={true} disabled={true}>Не указано</option>
                                                {allCategories.map((item,index) => {
                                                    return (
                                                        <option key={index} value={item.id}>{item.name}</option>
                                                    )
                                                })}
                                            </select>
                                        </label>
                                    </th>
                                    {/* car type*/}
                                    {serviceObjectToCreate.service_category_id === 1 ?
                                        <th>
                                            <label className="field-style w-auto mb-0">
                                                <select  name="carType" id="" className="page-select"
                                                        onChange={handleSelectNewService}>
                                                    <option key={'empty2'} selected={true} disabled={true}>Не указано</option>
                                                    {allServices?.carTypes?.map((item, index) => {
                                                        return (
                                                            <option
                                                                key={index}
                                                                value={item.id}
                                                                data-car-subtype-id={item.id === 4 ? item.car_subtype_id : ''}
                                                            >
                                                                {item.name}
                                                            </option>
                                                        )
                                                    })}
                                                </select>
                                            </label>
                                        </th> : <th>
                                            <label className="field-style w-auto mb-0">
                                                <select name="carType" id="" className="page-select"
                                                        onChange={handleSelectNewService}>
                                                    <option selected={true} disabled={true}>Не указано</option>
                                                </select>
                                            </label>
                                        </th>
                                    }

                                    <th>
                                        <button
                                            style={{minHeight:'45px'}}
                                            className="btn btn-dark-blue">Добавить</button>
                                    </th>
                                </tr>
                                </tbody>
                            </table>
                        </form>
                        <br/>
                        <br/>
                        <br/>
                        <div>
                            <h3>Список имеющихся услуг</h3>
                            <br/>
                            <div className="inner-page">
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {(provided, snapshot) => (
                                            <table
                                                className="table"
                                                {...provided.droppableProps}
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                            >
                                                <thead>
                                                <tr>
                                                    <th>Наименование услуги</th>
                                                    <th>Категория услуги</th>
                                                    <th></th>
                                                    <th>Удаить</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {orderServices?.map((item, index) => {
                                                    return (
                                                        <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                                                            {(provided, snapshot) => (
                                                                <tr
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                >
                                                                    <ServiceItem
                                                                        key={item.id}
                                                                        allServices={item}
                                                                        handleDelete={handleDeleteService}
                                                                        allCategories={allCategories}
                                                                        renameServiceItem={renameServiceItem}
                                                                        carTypes={allServices.carTypes}
                                                                    />
                                                                </tr>
                                                            )}
                                                        </Draggable>
                                                    )
                                                })}
                                                {provided.placeholder}
                                                </tbody>
                                            </table>
                                        )}
                                    </Droppable>

                                </DragDropContext>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
);
}

export default Services;