import React from 'react';
import Select from "react-select";
import selectStyle from "../../../../utils/select-styles";
import ImageUploaderEditParking from "../../../image-upload/parking/image-edit-upload-parking";

function ParkingEditApplication(
    {
        parkingsEdit,
        handleChange,
        defaultIndividualOptions,
        individualsOptions,
        handleChangeLegals,
        usersSelectedOptions,
        handleChangeUsers,
        usersOptions,
        constractorsSelectedOptions,
        constractorsOptions,
    }
) {

    return (
        <div className="row no-gutters">
            <div className="col-md-12">
                {/* О стоянке */}
                <div className="inner-page__item">
                    <div className="inner-item-title">О стоянке</div>
                    <div className="row">
                        {/* Название  */}
                        <div className="col-6 mt-3">
                            <label className="field-style">
                                <span>Название</span>
                                <input
                                    type="text"
                                    defaultValue={parkingsEdit.parking?.title}
                                    name="title"
                                    required
                                    placeholder="Не указан"
                                    onChange={handleChange}
                                />
                            </label>
                        </div>
                        {/*  */}
                        {/* Активен */}
                        <div className="col-6">
                            <label className="switch-radio-wrap mt-11px">
                                <input
                                    type="checkbox"
                                    name="status"
                                    onChange={handleChange}
                                    defaultChecked={parkingsEdit.parking?.status === 1 ? 'checked' : ''}
                                />
                                <span className="switcher-radio"></span>
                                <span>Активен</span>
                            </label>
                        </div>
                        {/*  */}
                        {/* Регион */}
                        <div className="col-6 mt-3">
                            <label className="field-style">
                                <span>Регион</span>
                                <input
                                    id="code"
                                    type="text"
                                    defaultValue={parkingsEdit.parking?.code}
                                    name="code"
                                    required
                                    placeholder="Не указан"
                                    onChange={handleChange}
                                />
                            </label>
                        </div>
                        {/*  */}
                        {/* Адрес */}
                        <div className="col-6 mt-3">
                            <label className="field-style">
                                <span>Адрес</span>
                                <input
                                    id="address"
                                    type="text"
                                    defaultValue={parkingsEdit.parking?.address}
                                    name="address"
                                    required
                                    placeholder="Не указан"
                                    onChange={handleChange}
                                />
                            </label>
                        </div>
                        {/*  */}

                        {/* Юридические лица */}
                        <div className="col-6 mt-3">
                            <label className="field-style select-react__style" style={{minWidth:'255px'}}>
                                <span>Юр.лица</span>
                                <Select
                                    styles={selectStyle}
                                    defaultValue={defaultIndividualOptions}
                                    options={individualsOptions}
                                    name="legals"
                                    isMulti
                                    onChange={handleChangeLegals}
                                />
                            </label>
                        </div>
                        {/*  */}

                        {/* Пользователи */}
                        <div className="col-6 mt-3">
                            <label className="field-style select-react__style" style={{minWidth:'255px'}}>
                                <span>Менеджеры</span>
                                <Select
                                    isMulti
                                    defaultValue={usersSelectedOptions}
                                    options={usersOptions}
                                    name="users"
                                    styles={selectStyle}
                                    onChange={(e) => {
                                        handleChangeUsers(e, "users");
                                    }}
                                />
                            </label>
                        </div>
                        {/*  */}

                        {/* Констрактор */}
                        <div className="col-6 mt-3">
                            <label className="field-style select-react__style" style={{minWidth:'255px'}}>
                                <span>Исполнители</span>
                                <Select
                                    isMulti
                                    defaultValue={constractorsSelectedOptions}
                                    options={constractorsOptions}
                                    name="contractors"
                                    styles={selectStyle}
                                    onChange={(e) => {
                                        handleChangeUsers(e, "contractors");
                                    }}
                                />
                            </label>
                        </div>
                        {/*  */}
                    </div>
                </div>
                {/*  */}

                {/* Контактная информация */}
                <div className="inner-page__item">
                    <div className="inner-item-title">
                        Контактная информация
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <label className="field-style">
                                <span>Адрес</span>
                                <input
                                    name="address"
                                    type="text"
                                    placeholder="Не указано"
                                    defaultValue={parkingsEdit.parking?.address}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>
                        <div className="col-6">
                            <label className="field-style">
                                <span>Координаты на карте</span>
                                <input
                                    name="coordinates"
                                    type="text"
                                    placeholder="Не указано"
                                    defaultValue={parkingsEdit.parking?.coordinates}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>
                        <div className="col-6 mt-3">
                            <label className="field-style">
                                <span>Ссылка на навигатор</span>
                                <input
                                    name="map_url"
                                    type="text w-100"
                                    placeholder="Не указано"
                                    defaultValue={parkingsEdit.parking?.map_url}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>
                        <div className="col-6 mt-3">
                            <label className="field-style">
                                <span>Время работы</span>
                                <input
                                    name="working_hours"
                                    type="text"
                                    placeholder="Не указано"
                                    defaultValue={parkingsEdit.parking?.working_hours}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>
                        <div className="field-style col-12 mt-3">
                            <span>Схема проезда, общественный транспорт и пр.</span>
                            <textarea
                                name="qty_parking"
                                id="car_additional"
                                placeholder="Не указан"
                                className="mw-100"
                                defaultValue={parkingsEdit.parking?.nav_to_trans}
                                onChange={handleChange}
                            ></textarea>
                        </div>
                    </div>
                </div>
                {/*  */}

                {/* Фотографии */}
                <div className="inner-page__item">
                    {/*<ImageUploader/>*/}
                    <ImageUploaderEditParking
                        id={parkingsEdit.parking?.id}
                        oldimage={parkingsEdit.parking?.attachments}
                    />
                </div>
                {/*  */}

                {/* Параметры стоянки */}
                <div className="inner-page__item">
                    <div className="inner-item-title">Параметры стоянки</div>
                    <div className="row">
                        <div className="col-6">
                            <label className="field-style">
                                <span>Кол-во машиномест</span>
                                <input
                                    type="text"
                                    placeholder="Не указано"
                                    name={'qty_parking'}
                                    defaultValue={parkingsEdit.parking?.qty_parking}
                                    onChange={handleChange}
                                />
                            </label>
                        </div>
                        <div className="col-6">
                            <label className="field-style">
                                <span>Покрытие стоянки</span>
                                <select
                                    name="parking_surface"
                                    id=""
                                    className="page-select"
                                    defaultValue={parkingsEdit.parking?.parking_surface}
                                    onChange={handleChange}
                                >
                                    {parkingsEdit?.parking_surfaces.map((item, index) => {
                                        return (
                                            <option
                                                key={item.id}
                                                value={item.id}
                                                // selected={parkingsEdit.parking?.parking_surface === item.id ? 'selected' : ''}
                                            >
                                                {item.name}
                                            </option>

                                        );
                                    })}
                                </select>
                            </label>

                        </div>
                        <div className="col-6">
                            <label className="switch-radio-wrap d-flex mt-3">
                                <input
                                    type="checkbox"
                                    name="covered_parking"
                                    defaultChecked={parkingsEdit.parking?.covered_parking === 1 ? 'checked' : ''}
                                    onChange={handleChange}
                                />
                                <span className="switcher-radio ml-auto"></span>
                                <span className="check-box-text">
                            Наличие крытых машиномест
                          </span>
                            </label>
                            <label className="switch-radio-wrap d-flex mt-3">
                                <input
                                    type="checkbox"
                                    name="heated_parking"
                                    defaultChecked={parkingsEdit.parking?.heated_parking === 1 ? 'checked' : ''}
                                    onChange={handleChange}
                                />
                                <span className="switcher-radio ml-auto"></span>
                                <span className="check-box-text">
                            Наличие отапливаемых машиномест
                          </span>
                            </label>
                        </div>
                        <div className="col-6">
                            <label className="field-style mt-3">
                                <span>Ограждение</span>
                                <select
                                    name="fencing"
                                    id=""
                                    className="page-select"
                                    defaultValue={parkingsEdit.parking?.fencing}
                                    onChange={handleChange}
                                >
                                    {parkingsEdit?.parking_fences.map((item, index) => {
                                        return (
                                            <option
                                                key={item.id}
                                                value={item.id}
                                                // selected={parkingsEdit.parking?.fencing === item.id ? 'selected' : ''}
                                            >
                                                {item.name}
                                            </option>

                                        );
                                    })}
                                </select>
                            </label>
                        </div>
                        <div className="col-12">
                            <div className="d-flex mt-4">
                                <label className="switch-radio-wrap bold mr-5">
                                    <input
                                        type="checkbox"
                                        name="cctv"
                                        defaultChecked={parkingsEdit.parking?.cctv === 1 ? 'checked' : ''}
                                        className="chech-dd"
                                        onChange={handleChange}
                                    />
                                    <span className="switcher-radio"></span>
                                    <span>Видеонаблюдение</span>
                                </label>
                                <label className="switch-radio-wrap bold mr-5">
                                    <input
                                        type="checkbox"
                                        name="client_zones"
                                        defaultChecked={parkingsEdit.parking?.client_zones === 1 ? 'checked' : ''}
                                        className="chech-dd"
                                        onChange={handleChange}
                                    />
                                    <span className="switcher-radio"></span>
                                    <span>Клиентская зона (кулер, Wi-Fi и пр.)</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                {/*  */}
                {/*<div className=" d-flex justify-content-end mt-3 mb-3 pr-4">*/}
                {/*    <button type="submit" name={'application'} className="btn btn-dark-blue">*/}
                {/*        Сохранить*/}
                {/*    </button>*/}
                {/*</div>*/}
            </div>
        </div>
    );
}

export default ParkingEditApplication;