import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import applicationTypeService from "../applicationSelectService";

const initialState = {
  brand: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Type cars
export const applicationsBrand = createAsyncThunk(
  "applications/applicationsBrand",
  async (id, thunkAPI) => {
    try {
      return await applicationTypeService.appBrand(id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const applicationBrandSlice = createSlice({
  name: "applicationsBrand",
  initialState,
  reducers: {
    brandReset: (state) => {
      state.brand = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(applicationsBrand.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(applicationsBrand.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.brand = action.payload;
      })
      .addCase(applicationsBrand.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.brand = null;
      });
  },
});
export const { brandReset } = applicationBrandSlice.actions;

export default applicationBrandSlice.reducer;
