import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { reset, login } from "../../store/auth/authSlice";
import { toast } from "react-toastify";
import AuthForm from "../../components/auth/auth-form";

const Login = () => {
  // DATA
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showPass, setShowPass] = useState(false)

  useEffect(()=>{
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Уведомления разрешены');

      } else if (permission === 'denied') {
        console.log('Уведомления отклонены');
        alert('Для корректной работы, пожалуйста разрешите этому сайту показывать уведомления')
        // Обработка сценария, когда пользователь отклонил уведомления
      }
    });

  },[])

  const { email, password } = formData;
  //

  // STYLE CLASS
  let isActive = "";
  //

  // HOOKS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //

  // USESELECTOR
  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  );
  const stateData = useSelector((state)=>state.auth)
  //


  // USEEFFECT
  useEffect(() => {
    if (isError) {
      toast.error(message.error);
      isActive = "invalid";
    }
    if (isSuccess) {
      navigate("/applications");
    }
    dispatch(reset());
  }, [user, isError, isSuccess, message, navigate, dispatch]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  //

  const togglePasswordView = () => {
    setShowPass(prevState => !prevState)
  }

  // ONSUBMIT
  const onSubmit = (e) => {
    e.preventDefault();
    const userData = {
      email,
      password
    };
    dispatch(login(userData)).then((res)=>{
    })
  };
  //

  return (
    <AuthForm title="Авторизация" onSubmit={onSubmit}>
      <div className="form-group row mb-4">
        <div className={`${isActive} col-md-12`}>
          <input
            placeholder="Email"
            id="email"
            type="email"
            className="form-control"
            name="email"
            value={email}
            onChange={onChange}
            required
          />
        </div>
      </div>

      <div className="form-group row mb-4">
        <div className={`${isActive} col-md-12`}>
          <input
            placeholder="Пароль"
            id="password"
            type={showPass ? "text" : 'password'}
            className="form-control"
            name="password"
            onChange={onChange}
            value={password}
            required
          />
          <div
              className={`password-control login ${
                  password ? "" : "view"
              }`}
              onClick={togglePasswordView}
          ></div>
        </div>
      </div>

      <div className="form-group row ">
        <div className="col-md-12 text-center">
          {isLoading ? (
            <button className="btn-login" type="button" disabled>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Войти...
            </button>
          ) : (
            <button type="submit" className="btn-login">
              Войти
            </button>
          )}

          <Link to="/password-reset" className="text-left d-block">
            Забыли пароль ?
          </Link>
        </div>
      </div>
    </AuthForm>
  );
};

export default Login;
