import React from 'react';
import {useSelector} from "react-redux";

function ApplicationAddServiceHeader({servicesType,toggleServicesType}) {
    const { user } = useSelector((state) => state.auth);
    return (
        <div className="top-add-service d-flex justify-content-between align-items-start">
            {!servicesType &&
            <h4>Добавление услуг</h4>
            }
            {user.role !=='Partner' &&
                <label className={`switch-radio-wrap d-flex gap-1 ${servicesType ? 'mb-4' : ''}`}>
                    <span className="condition" style={{minWidth: '100px'}}>Готовые услуги</span>
                    <input
                    type="checkbox"
                    onChange={()=>{
                    toggleServicesType()
                }}
                />
                    <span className="switcher-radio ml-2 mr-0"></span>
                    <span className="condition" style={{minWidth: '80px'}}>Свои услуги</span>
                </label>
            }

        </div>
    );
}

export default ApplicationAddServiceHeader;