import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {createFence, getAllFence} from "../../store/parkings/create-fence/createFenceSlice";
import {deleteFence} from "../../store/parkings/delete-fence/deleteFenceSlice";
import swal from "sweetalert";
import {editFence} from "../../store/parkings/edit-fence/editFenceSlice";
import GuideItem from "../../components/guide/guide-Item";
import Sidebar from "../../components/guide/sidebar/sidebar";

function Fence() {
    const dispatch = useDispatch();
    const allFence = useSelector((state) => state.parkingFence.allFence);
    const [fenceName, setFenceName] = useState('name')

    const changeFenceName = (e) => {
        setFenceName(e.target.value)
    }
    const handleDeleteFence = (id) => {
      dispatch(deleteFence(id)).then((response)=>{
          if(response.payload === 200 ){
              dispatch(getAllFence())
              swal("Покрытие удалено успешно", {
                  icon: "success",
              })
          }else{
              swal("Ошибка при удалении покрытия", {
                  icon: "error",
              });
          }
      })
    }

    useEffect(()=>{
       dispatch(getAllFence())
    }, [dispatch])

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(createFence(fenceName)).then((response)=>{
            if(response.payload.status === 201 ){
                dispatch(getAllFence())
                swal("Покрытие создано успешно", {
                    icon: "success",
                })
            }else{
                swal("Ошибка при создании покрытия", {
                    icon: "error",
                });
            }
        })
    }
    return (
        <div className={'container'}>
            <div className="inner-page">
                <div className="page-head-wrap">
                    <div className="page-head">
                        <div className="page-head__top d-flex align-items-center">
                            <h1>Ограждения</h1>
                        </div>
                    </div>
                </div>
                    <div className="d-flex">
                        <Sidebar/>
                        <div className="col-md-8 p-3 border-left">
                            <form onSubmit={handleSubmit}>
                                <div className="inner-item-title">Создать ограждение</div>
                                <div className=" mt-3 d-flex  justify-content-start">
                                    <label className="field-style">
                                        <span>Тип ограждения</span>
                                        <input id="title"
                                               type="text"
                                               name="title"
                                               required=""
                                               className={'w-100'}
                                               placeholder="Деревянное ограждение"
                                               onChange={changeFenceName}
                                        />
                                    </label>
                                    <button
                                        className={'btn btn-dark-blue'}
                                        type={"submit"}
                                        style={{maxHeight:'45px'}}
                                    >
                                        Добавить
                                    </button>
                                </div>
                            </form>
                            <br/>
                            <br/>
                            <br/>

                            <div >
                                <h3>Список имеющихся ограждений</h3>
                                <br/>
                                <div className="inner-page">

                                    <table className="table">
                                        <thead>
                                        <tr>
                                            <th>id</th>
                                            <th>Название</th>
                                            <th>Редактировать</th>
                                            <th>Удаить</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {allFence?.map((item, index) => {
                                            return (
                                                <GuideItem
                                                    key={item.id}
                                                    item={item}
                                                    handleDelete={handleDeleteFence}
                                                    getAll={getAllFence}
                                                    editItem={editFence}
                                                />
                                            );
                                        })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>

        </div>
    );
}

export default Fence;