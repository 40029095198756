import React, {useEffect, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import LegalsItem from "../../../components/legals/legals-item";
import { legalData } from "../../../store/legal/legalSlice";
import { useNavigate } from "react-router-dom";

function LeagalIndex() {
  const { legals, title } = useSelector((state) => state.legal);
  const [filteredLegals, setFilteredLegals] = useState([])
  useEffect(()=>{
    setFilteredLegals(legals)
  },[legals])
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(legalData());
  }, [dispatch, navigate]);

  const filterLegals = (event) => {
    const keyword = event.target.value.toLowerCase();
    const filteredLegals = legals.filter((legal) =>
      legal.name.toLowerCase().includes(keyword) ||
      legal.inn.toLowerCase().includes(keyword) ||
      legal.reg_number.toLowerCase().includes(keyword)
    );
    setFilteredLegals(filteredLegals);
  }

  return (
    <>
      <div className="container page-head-wrap">
        <div className="page-head">
          <div className="page-head__top d-flex align-items-center">
            <h1>{title}</h1>
            <label className="field-style blue">
              <span>Поиск</span>
              <input type="text" id="keyword" onChange={filterLegals} defaultValue={''} placeholder="Поиск по столбцам" />
            </label>
            <div className="ml-auto d-flex">
              <Link to="/legals/create" className="btn btn-white">
                Добавить
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container" id="searchable">
        <div className="inner-page">
          <table className="table">
            <thead className={'sticky-thead'}>
              <tr>
                <th></th>
                <th>Название</th>
                <th>ИНН</th>
                <th>ОГРН</th>
                <th>КПП</th>
                <th>
                  Стоянки <span>новый блок</span>
                </th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {legals.length !== 0 &&
              filteredLegals.map((item, index) => {
                  return <LegalsItem item={item} index={index} key={index} />;
                })}
            </tbody>
          </table>
          <p></p>
        </div>
      </div>
    </>
  );
}

export default LeagalIndex;
