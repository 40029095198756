import React from "react";
import { Link } from "react-router-dom";

function ApplicationFooterIssueBtn({ item }) {
  const isStatus2 = item.status.id === 2;

  const isEditable = isStatus2;
  return (
    isEditable && (
      <div className="d-flex">
        <Link
          to={
            item.issuance
              ? `/applications/issue-requests/${item.issuance.id}/edit`
              : `/applications/${item.id}/issue-requests/create`
          }
          className="btn btn-success"
          style={{ minWidth:'200px'}}
        >
          Заявка на выдачу
        </Link>
      </div>
    )
  );
}

export default ApplicationFooterIssueBtn;
