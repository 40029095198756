import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import issueRequestService from "../issueRequestService";

const initialState = {
  issueCreateGet: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// issueRequest get data
export const issueRequestCreate = createAsyncThunk(
  "application/issueRequestCreate",
  async (id, thunkAPI) => {
    try {
      return await issueRequestService.issueRequestCreate(id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

// issueRequest store data
export const issueRequestPost = createAsyncThunk(
  "application/issueRequestPost",
  async ({ id, data }, thunkAPI) => {
    try {
      return await issueRequestService.issueRequestPost(id, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

const issueRequestCreateSlice = createSlice({
  name: "issueRequestCreateSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(issueRequestCreate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(issueRequestCreate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.issueCreateGet = action.payload;
      })
      .addCase(issueRequestCreate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.issueCreateGet = null;
      });
  },
});

export default issueRequestCreateSlice.reducer;
