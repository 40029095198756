import React, { useEffect } from "react";
import Select from "react-select";
import { selectArray } from "../../../../utils/select-array";
import { useSelector } from "react-redux";
function ApplicatonCrtUptHeader({
  title,
  tab,
  tabfunc,
  status,
  styles,
  setUpdateAppData,
  updateAppData,
  disabledEditBtn,
  statuscurrent,
}) {
  const { user } = useSelector((state) => state.auth);
  // Проверка является ли машина на Выдачу
  const statusSelect = selectArray(status, "status_id");
  const statusSelectOnlyTwoVariation = [];

  statusSelect.forEach((element) => {
    if (element.value === 6 || element.value === 2) {
      statusSelectOnlyTwoVariation.push(element);
    }
  });
  // Статус по умолчанию в зависимости от роли пользователя
  let defaultStatusValue = "";
  if (user.role === "Operator") {
    defaultStatusValue = statusSelect[0];
  } else if (user.role === "Manager") {
    if (statuscurrent === 3) {
      defaultStatusValue = statusSelect[0];
    } else {
      defaultStatusValue = statusSelect[6];
    }
  } else {
    defaultStatusValue = statusSelect[7];
  }
  //

  const handleInputChange = (event) => {
    const { name, value } = event.target ? event.target : event;
    setUpdateAppData({
      ...updateAppData,
      [name]: value,
    });
  };
  return (
    <div className="container page-head-wrap">
      <div className="page-head">
        <div className="page-head__top d-flex align-items-center">
          <h1>{title}</h1>
          <div className="ms-auto d-flex">
            <label className="field-style select-react__style">
              <span className="field-style-title">Статус</span>
              <Select
                defaultValue={defaultStatusValue}
                options={
                  statuscurrent === 7 && user.role === "Manager"
                    ? statusSelectOnlyTwoVariation
                    : statusSelect
                }
                styles={styles}
                onChange={handleInputChange}
              />
            </label>

            {tab === 1 && (
              <>
                <button
                  className="btn btn-white mr-2"
                  type="button"
                  id="tabPrev"
                  onClick={() => tabfunc((prev) => prev - 1)}
                >
                  Назад
                </button>

                <button className="btn btn-white" disabled={disabledEditBtn}>
                  {title}
                </button>
              </>
            )}

            {tab === 0 && (
              <button
                className="btn btn-white"
                type="button"
                id="tabNext"
                disabled={tab === 1}
                onClick={() => tabfunc((prev) => prev + 1)}
              >
                Далее
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ApplicatonCrtUptHeader;
