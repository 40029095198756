import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import reportsService from "../reportService";

const initialState = {
  reportsByPartner: [],
  link: "",
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Получить данные  отчета по заказчикам
export const reportsByPartnerData = createAsyncThunk(
  "application/reportsByPartnerData",
  async (data, thunkAPI) => {
    try {
      return await reportsService.reportByPartner(data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

// Получить ссылку на скачивание отчета по заказчикам
export const reportsByPartnerLink = createAsyncThunk(
  "application/reportsByPartnerLink",
  async (data, thunkAPI) => {
    try {
      return await reportsService.reportByPartnerLink(data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

// SLICE
const reportsByPartnerSlice = createSlice({
  name: "reportsByPartnerSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(reportsByPartnerData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(reportsByPartnerData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.reportsByPartner = action.payload;
      })
      .addCase(reportsByPartnerData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.reportsByPartner = null;
      })
      .addCase(reportsByPartnerLink.fulfilled, (state, action) => {
        state.link = action.payload;
      });
  },
});

export default reportsByPartnerSlice.reducer;
//
