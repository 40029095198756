import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const applicationDataSlice = createSlice({
  name: "appliation/formData",
  initialState: {
    car_key_quantity: 0,
    arriving_at: moment().format("YYYY-MM-DD"),
  },
  reducers: {
    setFormDataReset: (state, action) => {
      return {
        car_key_quantity: 0,
        arriving_at: moment().format("YYYY-MM-DD"),
      };
    },
    setApplicationDataSlice: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setApplicationDataSlice, setFormDataReset } =
  applicationDataSlice.actions;
export default applicationDataSlice.reducer;
