import { createAsyncThunk } from "@reduxjs/toolkit";
import applicationDenyService from "./applicationDenyService";

// Application put list
export const applicationsDeny = createAsyncThunk(
  "applications/applicationsDeny",
  async ({ id, text }, thunkAPI) => {
    try {
      return await applicationDenyService.applicationDeny(id, text);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
