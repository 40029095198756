import { createAsyncThunk } from "@reduxjs/toolkit";
import uploadFileService from "./uploadFileService";

export const uploadFileSlice = createAsyncThunk(
  "application/uploadFileModal",
  async ({ id, data }, thunkAPI) => {
    try {
      return await uploadFileService.uploadFile(id, data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
