import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import legalsService from "./legalService";

const initialState = {
  legals: [],
  title: "",
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Legal
export const legalData = createAsyncThunk("legal/all", async (thunkAPI) => {
  try {
    return await legalsService.legals();
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data) ||
      error.message ||
      error.toSrting();
    return thunkAPI.rejectWithValue(message);
  }
});
//

  // Удаление legal
  export const legalDelete = createAsyncThunk(
    "legal/legalDelete",
    async (id, thunkAPI) => {
      try {
        return await legalsService.legalsDelete(id);
      } catch (error) {
        const message =
          (error.response && error.response.data && error.response.data) ||
          error.message ||
          error.toSrting();
        return thunkAPI.rejectWithValue(message);
      }
    }
  );
  //

const legalsSlice = createSlice({
  name: "legals",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(legalData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(legalData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.legals = action.payload.legals;
        state.title = action.payload.title;
      })
      .addCase(legalData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(legalDelete.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(legalDelete.fulfilled, (state, action) => {
        state.legals = state.legals.filter((item) => item.id !== action.payload);
      })
      .addCase(legalDelete.rejected, (state, action) => {
        state.message = action.payload;
      });
  },
});

export default legalsSlice.reducer;
