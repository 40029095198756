import style from "../../../parking.module.css";

function ParkingEditServiceItem({service, handleServiceChange, item, inputDisable}) {
    return (
        <tr key={`${service.id }${service.parking_service_name}`}>
            <td key={'service_name'}>{service.parking_service_name}</td>
            <td key={'is_active'} className="text-center">
                <label className="check-box-style">
                    <input
                        type="checkbox"
                        name="is_active"
                        defaultChecked={service.is_active === 1 ? 'checked' : ''}
                        className={`chech-dd ${service.is_active}`}
                        onChange={(e) => {
                            handleServiceChange(e, service.parking_service_id, item.category)
                        }}
                    />
                    <span className={`${service.is_active === 1 ? 'checked' : ''}`}></span>
                </label>
            </td>
            <td key={'min-price'} className={'price-min-width'}>
                <div className={`${style['field-style']}`}>
                    <input
                        className="m-0"
                        type="text"
                        name={'cost'}
                        value={service.cost === null ? 0 : service.cost}
                        onChange={(e) => {
                            handleServiceChange(e, service.parking_service_id, item.category)
                        }}
                    />
                </div>
            </td>
            <td key={'price-min-2'} className={'price-min-width'}>
                <div className={`${style['field-style']}`}>
                    <input
                        className={`cost_for_us m-0 ${inputDisable ? 'disabled' : ''}`}
                        type="text"
                        name={'cost_for_us'}
                        disabled={`${inputDisable ? 'disabled' : ''}`}
                        value={service.cost_for_us === null ? 0 : service.cost_for_us}
                        onChange={(e) => {
                            handleServiceChange(e, service.parking_service_id, item.category)
                        }}
                    />
                </div>
            </td>
            <td key={'field_style'}>
                <div className={`${style['field-style']} mr-0`}>
                    <input
                        type="text"
                        className="ml-0"
                        placeholder="Имя"
                        name={'contact_name'}
                        defaultValue={service.contact_name}
                        onChange={(e) => {
                            handleServiceChange(e, service.parking_service_id, item.category)
                        }}
                    />
                </div>
            </td>
            <td  key={'phone_key'}>
                <div className={`${style['field-style']}`}>
                    <input
                        type="phone"
                        name={'contact_phone'}
                        placeholder="+7 (___) ___-__-__"
                        defaultValue={service.contact_phone}
                        onChange={(e) => {
                            handleServiceChange(e, service.parking_service_id, item.category)
                        }}
                    />
                </div>
            </td>
            <td key={'text_area-key'}>
                                            <textarea
                                                className="table-textarea"
                                                placeholder="Не указано"
                                                name="comment"
                                                id=""
                                                value={service.comment ? service.comment : ''}
                                                onChange={(e) => {
                                                    handleServiceChange(e, service.parking_service_id, item.category)
                                                }}>
                                            </textarea>
            </td>
        </tr>
    );
}
export default ParkingEditServiceItem;