import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import applicationServiceOrderMangerApproveService from "./applicationServiceOrderMangerApproveService";

const initialState = {
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};


export const applicationServiceApproveManager = createAsyncThunk(
    "applications/service_approve_manager",
    async ({currentApplicationId,currentApplicationOrderId,data}, thunkAPI) => {
        try {
            return await applicationServiceOrderMangerApproveService.approveApplicationOrderServiceByManager({currentApplicationId,currentApplicationOrderId,data} );
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
)
export const applicationServiceSendToWorkManager = createAsyncThunk(
    "applications/service_send_to_work_manager",
    async ({currentApplicationId,currentApplicationOrderId}, thunkAPI) => {
        try {
            return await applicationServiceOrderMangerApproveService.sendApplicationOrderServiceToWork({currentApplicationId,currentApplicationOrderId} );
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
)
export const applicationServicePriceChangeByManager = createAsyncThunk(
    "applications/service_price_change_by_manager",
    async ({currentApplicationId, currentApplicationOrderId, currentApplicationOrderServiceId, data}, thunkAPI) => {
        try {
            return await applicationServiceOrderMangerApproveService.changePriceInServiceByManager({currentApplicationId, currentApplicationOrderId, currentApplicationOrderServiceId, data} );
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const toggleServiceComplete = createAsyncThunk(
    "applications/service_complete_toggle",
    async ({currentApplicationId,currentApplicationOrderId,currentApplicationOrderServiceId}, thunkAPI) => {
        try {
            return await applicationServiceOrderMangerApproveService.toggleCompleteApplicationService({currentApplicationId,currentApplicationOrderId,currentApplicationOrderServiceId} );
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

const applicationServiceApproveSlice = createSlice({
    name: "applicationServiceApprove",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(applicationServiceApproveManager.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(applicationServiceApproveManager.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.message = action.payload.status;
            })
            .addCase(applicationServiceApproveManager.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })
    },
});

export default applicationServiceApproveSlice.reducer;
