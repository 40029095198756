import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import parkingsService from "../parkingsService";
import parkignsService from "../parkingsService";

const initialState =  {
    createNewCoverage : [],
    allCoverage : [],
    isError : false,
    isSuccess : false,
    isLoading : false,
    message : '',
}
// creating new coverage

export const createCoverage = createAsyncThunk(
    'coverage/coverageCreate',
    async (name ,thunkAPI) =>{
      try {
        return await parkingsService.coverageCreate(name)
      } catch (error){
          const message = (error.response && error.response.data && error.response.data) ||
              error.message ||
              error.toSrting();
          return thunkAPI.rejectWithValue(message);
      }
    }
)
export const getAllCoverage = createAsyncThunk(
    'coverage/coverageGetAll',
    async (thunkAPI)=>{
        try {
            return await parkignsService.getAllCoverage()
        } catch (error){
            const message = (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
)

const coverageCreateSlice = createSlice({
    name: "coverageCreate",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createCoverage.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(createCoverage.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.createNewCoverage = action.payload;
            })
            .addCase(createCoverage.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.createNewCoverage = null;
            })

            .addCase(getAllCoverage.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getAllCoverage.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.allCoverage = action.payload;
            })
            .addCase(getAllCoverage.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.allCoverage = null;
            });
    },
});
export  default coverageCreateSlice.reducer