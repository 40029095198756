import HeaderNotificationItem from "./item/header-notification-item";
import {useEffect, useState} from "react";
import {notificationData, notificationReadeAll} from "../../../../store/notofocations/headerNotificationSlice";
import {useDispatch} from "react-redux";

const HeaderNotification = (
    {
      notifications,
      openModal,
      setActiveTabKey,
      setNotifications
    }) => {
    const dispatch = useDispatch()
    const [newNotificationsCount, setNewNotificationsCount] = useState(0)

    useEffect(() => {
        const count = notifications?.filter((item) => {
            return item && (item.type === 'application' || item.type === 'order');
        }).length;
        setNewNotificationsCount(count);
    }, [notifications]);

    const readAllNotification = (e)=>{
        e.preventDefault()
        const chat = false;
        dispatch(notificationReadeAll({chat})).then((response)=>{
            dispatch(notificationData()).then((resp) => {
                setNotifications(resp.payload.notifications)
            })
        })
    }


  return (
      <div className="notification">
        <svg
            width="24"
            height="28"
            viewBox="0 0 24 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
          <path
              d="M9.52819 2.33066C9.92441 1.35472 10.8818 0.666504 12 0.666504C13.1182 0.666504 14.0756 1.35472 14.4718 2.33066C18.4269 3.41413 21.3333 7.03423 21.3333 11.3332V17.5961L23.7761 21.2602C24.0488 21.6694 24.0743 22.1954 23.8422 22.629C23.6102 23.0625 23.1584 23.3332 22.6667 23.3332H16.6194C16.2959 25.5947 14.351 27.3332 12 27.3332C9.64902 27.3332 7.70408 25.5947 7.38059 23.3332H1.33334C0.841608 23.3332 0.389795 23.0625 0.157769 22.629C-0.0742561 22.1954 -0.0488261 21.6694 0.223935 21.2602L2.66667 17.5961V11.3332C2.66667 7.03423 5.57311 3.41413 9.52819 2.33066ZM10.1138 23.3332C10.3884 24.11 11.1292 24.6665 12 24.6665C12.8708 24.6665 13.6116 24.11 13.8862 23.3332H10.1138ZM12 4.6665C8.3181 4.6665 5.33334 7.65127 5.33334 11.3332V17.9998C5.33334 18.2631 5.25542 18.5204 5.1094 18.7394L3.82469 20.6665H20.1753L18.8906 18.7394C18.7446 18.5204 18.6667 18.2631 18.6667 17.9998V11.3332C18.6667 7.65127 15.6819 4.6665 12 4.6665Z"
              fill="#011A3F"
          ></path>
        </svg>
          { newNotificationsCount !== 0 &&
          <div className="bell notification__count">
              { newNotificationsCount }
          </div>
          }
          {newNotificationsCount && newNotificationsCount > 0 &&
             <ul className="notification__dd-list">
              <div className="notif_head">
                <span onClick={(e)=>{readAllNotification(e)}}>
                    Очистить все ({newNotificationsCount})
                </span>
              </div>
              {notifications && notifications?.map((item)=>{
                  if(item && item.type === 'application' || item && item.type === 'order') {
                      return (
                          <HeaderNotificationItem
                              item={item}
                              key={item.notification_id}
                              openModal={openModal}
                              setActiveTabKey={setActiveTabKey}
                              setNotifications={setNotifications}
                          />
                      )
                  }

              })}

          </ul>
          }
      </div>
  );
};

export default HeaderNotification;
