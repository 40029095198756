import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import reportsService from "../reportService";
import {reportsByPartnerData, reportsByPartnerLink} from "../reportsByPartnerSlice/reportsByPartnerSlice";

const initialState = {
    reportsByOrder: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};

// Получить данные  отчета по заказчикам
export const reportsByOrderData = createAsyncThunk(
    "application/reportsByOrderData",
    async ({data}, thunkAPI) => {
        try {
            return await reportsService.reportByOrder({data});
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const reportsByOrderDataExcel = createAsyncThunk(
    "application/reportsByExcelData",
    async (data, thunkAPI) => {
        try {
            return await reportsService.reportByOrderExcel(data);
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

const reportsByOrderSlice = createSlice({
    name: "reportsByOrderSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(reportsByOrderData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(reportsByOrderData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.reportsByOrder = action.payload;
            })
            .addCase(reportsByOrderData.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.reportsByOrder = null;
            })
    },
});

export default reportsByOrderSlice.reducer;