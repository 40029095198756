import axios from "axios";

let API_URL = "/v1/applications/";

const approveApplicationOrderServiceByPartner = async ({currentApplicationId,currentApplicationOrderId,orderServices}) => {
    const response = await axios.put(API_URL + `${currentApplicationId}/orders/${currentApplicationOrderId}/approve`, orderServices );
    return response.status;
};
const applicationServiceOrderPartnerApproveService = {
    approveApplicationOrderServiceByPartner
}
export default applicationServiceOrderPartnerApproveService

