import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import applicationCreateService from "./applicationCreateService";

const initialState = {
  applications: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  carTypes: []
};

// Login user
export const applicationsCreate = createAsyncThunk(
  "applications/applicationsCreate",
  async (thunkAPI) => {
    try {
      return await applicationCreateService.application();
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const applicationCarTypes = createAsyncThunk(
  "applications/getApplicationsCarTYpe",
  async (data,thunkAPI) => {
    try {
      return await applicationCreateService.getApplicationCarTypes(data);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const applicationCreateSlice = createSlice({
  name: "applicationsCreate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(applicationsCreate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(applicationsCreate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.applications = action.payload;
      })
      .addCase(applicationsCreate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.applications = null;
      })
        .addCase(applicationCarTypes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(applicationCarTypes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.carTypes = action.payload;
      })
      .addCase(applicationCarTypes.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.carTypes = null;
      });
  },
});

export default applicationCreateSlice.reducer;
