import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import profileServices from "./profileGetUpdateService";

const initialState = {
    profileInfo: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};

// get profile
export const profileData = createAsyncThunk(
    "profile/get-info", async ( thunkAPI) => {
    try {
        return await profileServices.getProfileInfo()
    } catch (error) {
        const message =
            (error.response && error.response.data && error.response.data) ||
            error.message ||
            error.toSrting();
        return thunkAPI.rejectWithValue(message);
    }
});
//update profile
export const profileDataUpdate = createAsyncThunk(
    "profile/update-info", async ({profileInfo}, thunkAPI) => {
        try {
            return await profileServices.updateProfileInfo({profileInfo})
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    });


const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(profileData.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(profileData.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.profileInfo = action.payload;
            })
            .addCase(profileData.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            })

    },
});
export default profileSlice.reducer