import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import usersService from "../usersService";

const initialState = {
    userChildEdit: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};
export const userChildEdit = createAsyncThunk(
    'userChild/userChildEdit',
    async ({id, childId},thunkAPI)=>{
        try {
            return await usersService.userChildEdit({id, childId})
        }catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
)
export const userChildEditPut = createAsyncThunk(
    'userChild/userChildEditPut',
    async ({id:id,childId: child,data :data}, thunkAPI) =>{
        try {
            return await usersService.usersChildEditPut({id:id,childId: child,data:data});
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
)

const childUserEditSlice = createSlice({
    name: "childUserEditSlice",
    initialState,
    reducers: {
        editChildUserReset: (state) => {
            state.usersEdit = [];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(userChildEdit.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(userChildEdit.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.usersEdit = action.payload;
            })
            .addCase(userChildEdit.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.usersEdit = null;
            });
    },
});

export const { editChildUserReset } = childUserEditSlice.actions;
export default childUserEditSlice.reducer;