import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import applicationVinDublicateService from "./applicationVinDublicateService";

const initialState = {
  serial_number: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Vin dublicate
export const vinDublicateData = createAsyncThunk(
  "applications/vinDublicateData",
  async (vin, thunkAPI) => {
    try {
      return await applicationVinDublicateService.applicationVinDublicate(vin);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const vinDublicateSlice = createSlice({
  name: "vinDublicate",
  initialState,
  reducers: {
    serialNumberReset: (state) => {
      state.serial_number = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(vinDublicateData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(vinDublicateData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.serial_number = action.payload;
      })
      .addCase(vinDublicateData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.serial_number = null;
      });
  },
});
export const { serialNumberReset } = vinDublicateSlice.actions;

export default vinDublicateSlice.reducer;
