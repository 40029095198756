import React, { useState } from "react";
import Select from "react-select";
import { Russian } from "flatpickr/dist/l10n/ru.js";

import selectStyle from "../../../../utils/select-styles";
import Flatpickr from "react-flatpickr";
import { selectArrayParking } from "../../../../utils/select-array";

function PartnerAllForm({
  reportsAllPartner,
  handleChangeAllPartnerData,
  link,
}) {
  const [selectedParking, setSelectedParking] = useState([]);
  const [dateStatus, setDateStatus] = useState();

  const parkingSelect = selectArrayParking(
    reportsAllPartner.parking,
    "parking_id[]"
  );
  const handleChange = (e) => {
    if (Array.isArray(e)) {
      let formattedDates = e;
      formattedDates = formattedDates
        .map((item) =>
          new Date(item)
            .toLocaleDateString("ru-RU", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
            })
            .replace(/\./g, "-")
        )

        .join(" — ");
      setDateStatus({ ...dateStatus, dates: formattedDates });
    }
  };
  const handleSelectChange = (selected, setSelectedOptions) => {
    const { value, name } = selected;
    setSelectedOptions(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      parking_id: selectedParking,
      dates: dateStatus?.dates,
    };
    handleChangeAllPartnerData(data);
  };

  const hadleClick = (e) => {
    e.preventDefault();
    window.location.replace(link.url);
  };

  return (
    <div className="container page-head-wrap">
      <div className="page-head">
        <div className="page-head__top d-flex align-items-center">
          <h1>{reportsAllPartner.title}</h1>
        </div>
      </div>
      <form
        onSubmit={handleSubmit}
        className="filter d-flex align-items-center"
      >
        <label className="field-style">
          <span>Стоянка</span>
          {/* Стоянка */}
          <label className="field-style select-react__style">
            <span>Стоянка</span>
            <Select
              options={parkingSelect}
              styles={selectStyle}
              onChange={(selected) =>
                handleSelectChange(selected, setSelectedParking)
              }
              name="parking_id"
            />
          </label>
          {/*  */}
        </label>
        <label className="field-style">
          <span>Даты отчёта</span>
          <Flatpickr
            options={{
              mode: "range",
              dateFormat: "d-m-Y",
              locale: {
                ...Russian,
                months: {
                  ...Russian.months,
                  longhand: [
                    "Января",
                    "Февраля",
                    "Марта",
                    "Апреля",
                    "Мая",
                    "Июня",
                    "Июля",
                    "Августа",
                    "Сентября",
                    "Октября",
                    "Ноября",
                    "Декабря",
                  ],
                },
                rangeSeparator: "—",
              },
            }}
            onChange={handleChange}
            name="dates"
          />
        </label>
        <button type="submit" className="btn btn-primary ml-auto">
          Показать
        </button>
        <div className="btn btn-dowenload" onClick={hadleClick}></div>
      </form>
    </div>
  );
}

export default PartnerAllForm;
