import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import applicationImportService from "./applicationImportService";

const initialState = {
    applicationsMassImportData: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};

export const importExcelFile = createAsyncThunk(
    "application/massImportFileExcel",
    async ({data}, thunkAPI) => {
        try {
            return await applicationImportService.applicationExcelFileUpload({data});
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const getDataFromExcelFile = createAsyncThunk(
    "application/getDataFromExcel",
    async (thunkAPI) => {
        try {
            return await applicationImportService.applicationGetDataFromExcelFile();
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const importApplicationsToApp = createAsyncThunk(
    "application/importFromExcelToMyParking",
    async (data,thunkAPI) => {
        try {
            return await applicationImportService.importToMyParking(data);
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const deleteRowById = createAsyncThunk(
    "application/deleteRowByIdInMassUpload",
    async (id,thunkAPI) => {
        try {
            return await applicationImportService.deleteRowByApplicationId(id);
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const cancelUploadedApplication = createAsyncThunk(
    "application/cancelUploadApplication",
    async (thunkAPI) => {
        try {
            return await applicationImportService.cancelUploadedApplication();
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const addNewRow = createAsyncThunk(
    "application/addNewRow",
    async (thunkAPI) => {
        try {
            return await applicationImportService.applicationAddNewRow();
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

const applicationMassImportSlice = createSlice({
    name: "applicationMassImport",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDataFromExcelFile.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getDataFromExcelFile.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.applicationsMassImportData = action.payload;
            })
            .addCase(getDataFromExcelFile.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.applicationsMassImportData = null;
            });
    },
});

export default applicationMassImportSlice.reducer
