import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { appGenerateAct } from "../../../../../../../store/applications/application-generate-act/applicationActSlice";

function ApplicationGenereteAct({ item, role, text }) {
  const isStatus2 = item.status.id === 2;
  const isStatus3 = item.status.id === 3;
  const isManager = role === "Manager";
  const isAdmin = role === "Admin";

  const isManagerAndStatus2 = (isManager || isAdmin) && isStatus2;
  const isManagerAndStatus3 = (isManager || isAdmin) && isStatus3;

  let isEditable = null;
  if (isManagerAndStatus2){
      isEditable = isManagerAndStatus2
  }if (isManagerAndStatus3){
        isEditable = isManagerAndStatus3
    }

  return (
    isEditable && (
      <a href={item?.act_url} className="link">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 2C12.5523 2 13 2.44772 13 3V13.5858L15.2929 11.2929C15.6834 10.9024 16.3166 10.9024 16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L12.7071 16.7071C12.3166 17.0976 11.6834 17.0976 11.2929 16.7071L7.29289 12.7071C6.90237 12.3166 6.90237 11.6834 7.29289 11.2929C7.68342 10.9024 8.31658 10.9024 8.70711 11.2929L11 13.5858V3C11 2.44772 11.4477 2 12 2ZM5 17C5.55228 17 6 17.4477 6 18V20H18V18C18 17.4477 18.4477 17 19 17C19.5523 17 20 17.4477 20 18V20C20 21.1046 19.1046 22 18 22H6C4.89543 22 4 21.1046 4 20V18C4 17.4477 4.44772 17 5 17Z"
            fill="#536E9B"
          />
        </svg>
        {text}
      </a>
    )
  );
}

export default ApplicationGenereteAct;
