import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import applicationActService from "./applicationActService";

const initialState = {
  link: "",
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Generate Act
export const appGenerateAct = createAsyncThunk(
  "applications/appGenerateAct",
  async (id, thunkAPI) => {
    try {
      return await applicationActService.applicationAct(id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const applicationActSlice = createSlice({
  name: "applicationActGenerate",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(appGenerateAct.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(appGenerateAct.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.link = action.payload;
      })
      .addCase(appGenerateAct.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.link = null;
      });
  },
});

export default applicationActSlice.reducer;
