import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { editPartners } from "../../../../store/partners/partners-edit-slice/partnersEditSlice";
import Select from "react-select";
import { useState } from "react";
import selectStyle from "../../../../utils/select-styles";
import { partnerUserSelect } from "../../../../utils/select-array";
import swal from "sweetalert";
import { partnerUserCreatePost } from "../../../../store/partners/partners-user-slice/partnerUserCreateSlice";

function PartnerUserCreatePage() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { partnersEdit } = useSelector((state) => state.partnersEdit);
  const [partnerUserCreate, setPartnerUserCreate] = useState({});
  useEffect(() => {
    dispatch(editPartners(id));
  }, [dispatch]);

  useEffect(() => {
    if (partnersEdit.length !== 0) {
      setPartnerUserCreate({
        ...partnerUserCreate,
        name: partnersEdit?.partner?.name,
      });
    }
  }, [partnersEdit]);

  const handleChange = (e) => {
    const { name, value } = e.target ? e.target : e;
    setPartnerUserCreate({ ...partnerUserCreate, [name]: value });
  };

  const handleSubmitPartnerUser = (e) => {
    e.preventDefault();
    dispatch(partnerUserCreatePost({ id: id, data: partnerUserCreate })).then(
      (response) => {
        if (response.payload.status === 200) {
          swal("Пользователь успешно создан!", {
            icon: "success",
          }).then(() => {
            navigate("/partners");
          });
        } else {
          let errorMessage = "Ошибка при создании пользователя!";
          if (response.payload && response.payload.errors) {
            const errorMessages = Object.values(response.payload.errors).reduce(
              (acc, val) => acc.concat(val),
              []
            );
            errorMessage = errorMessages.join("\n");
          }
          swal(errorMessage, {
            icon: "error",
          });
        }
      }
    );
    //
  };
  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-md-8">
          <div className="card">
            <div className="card-header">
              Создать пользователя для заказчика
            </div>

            <div className="card-body">
              <form onSubmit={handleSubmitPartnerUser}>
                <div className="form-group row">
                  <label
                    for="name"
                    className="col-md-4 col-form-label text-md-right"
                  >
                    Имя
                  </label>

                  <div className="col-md-6">
                    {partnersEdit.length !== 0 && (
                      <input
                        id="name"
                        type="text"
                        className="form-control"
                        name="name"
                        defaultValue={partnersEdit.partner.name}
                        required
                        onChange={handleChange}
                      />
                    )}
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    for="email"
                    className="col-md-4 col-form-label text-md-right"
                  >
                    Email
                  </label>

                  <div className="col-md-6">
                    <input
                      id="email"
                      type="email"
                      className="form-control "
                      name="email"
                      onChange={handleChange}
                    />
                  </div>
                </div>


                <div className="form-group row">
                  <label
                      htmlFor="email"
                      className="col-md-4 col-form-label text-md-right"
                  >
                    Телефон
                  </label>

                  <div className="col-md-6">
                    <input
                        id="phone"
                        type="text"
                        className="form-control "
                        name="phone"
                        onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    for="password"
                    className="col-md-4 col-form-label text-md-right"
                  >
                    Пароль
                  </label>

                  <div className="col-md-6">
                    <input
                      id="password"
                      type="password"
                      className="form-control"
                      onChange={handleChange}
                      name="password"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    for="password-confirm"
                    className="col-md-4 col-form-label text-md-right"
                  >
                    Подтверждение пароля
                  </label>

                  <div className="col-md-6">
                    <input
                      id="password-confirm"
                      type="password"
                      className="form-control"
                      onChange={handleChange}
                      name="password_confirmation"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label
                    for="status"
                    className="col-md-4 col-form-label text-md-right"
                  >
                    Статус
                  </label>

                  <div className="col-md-6">
                    <label className="field-style select-react__style">
                      <Select
                        options={partnerUserSelect}
                        styles={selectStyle}
                        name="status"
                        onChange={handleChange}
                      />
                    </label>
                  </div>
                </div>

                <div className="form-group row mb-0">
                  <div className="col-md-6 offset-md-4">
                    <button type="submit" className="btn btn-primary">
                      Создать
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnerUserCreatePage;
