import React, {useState} from 'react';
import {
    applicationServiceApproveManager,
    applicationServicePriceChangeByManager
} from "../../../../../../../store/applications/application-service-order-manger-approve/applicationServiceOrderMangerApproveSlice";
import {
    applicationServicesGetById,
    currentApplicationServiceById
} from "../../../../../../../store/applications/application-service-order/applicationServiceOrderSlice";
import swal from "sweetalert";
import {useDispatch} from "react-redux";

function ApplicationCertainServiceItem(
    {
        handleDeleteService,
        service,
        handleInputChange,
        isChecked,
        checkStatus,
        services,
        prices,
        orderStatusCode
    }) {

    const dispatch = useDispatch();
    const [pricesEditable, setPricesEditable] = useState(false)
    const saveNewPrices = () => {
        const currentApplicationId = services?.currentService?.order?.application_id
        const currentApplicationOrderId = services?.currentService?.order?.id
        const currentApplicationOrderServiceId = service?.id
        const dataArr = prices.filter(price => (price.id === service?.id))
        const data = {
            price: dataArr[0].approved_price,
            price_for_us: dataArr[0].price_for_us
        }
        dispatch(applicationServicePriceChangeByManager({
            currentApplicationId,
            currentApplicationOrderId,
            currentApplicationOrderServiceId,
            data
        }))
            .then((response) => {
                if (response.meta.requestStatus === "fulfilled") {
                    const orderID = currentApplicationOrderId;
                    dispatch(currentApplicationServiceById({currentApplicationId, orderID})).then((response) => {
                    })
                    swal("цены успешно изменены", {
                        icon: "success",
                    }).then(() => {
                        dispatch(applicationServicesGetById({currentApplicationId}))

                        setPricesEditable(prevState => !prevState)
                    });
                } else {
                    swal("Ошибка при изменении цен", {
                        icon: "error",
                    }).then(() => {
                    });
                }
            })
    }
    return (
        <tr>
            <td
                onClick={() => {
                    handleDeleteService(service.id)
                }}
                className={'delete_service'}>
                {service.status !== 'Удалена' &&
                <span className="">
                        <svg width="10" height="11" viewBox="0 0 10 11" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M0.52827 1.02729C0.788619 0.766944 1.21073 0.766944 1.47108 1.02729L4.99967 4.55589L8.52827 1.02729C8.78862 0.766944 9.21073 0.766944 9.47108 1.02729C9.73143 1.28764 9.73143 1.70975 9.47108 1.9701L5.94248 5.4987L9.47108 9.02729C9.73143 9.28764 9.73143 9.70975 9.47108 9.9701C9.21073 10.2305 8.78862 10.2305 8.52827 9.9701L4.99967 6.44151L1.47108 9.9701C1.21073 10.2305 0.788619 10.2305 0.52827 9.9701C0.26792 9.70975 0.26792 9.28764 0.52827 9.02729L4.05687 5.4987L0.52827 1.9701C0.26792 1.70975 0.26792 1.28764 0.52827 1.02729Z"
                                fill="#EB5757"/>
                        </svg>
                    </span>
                }

            </td>
            <td>
                <div
                    className="table-service-title"
                    style={service.status === 'Удалена' ? {color: 'red'} : {color: '#000'}}>
                    {service.parking_has_service !== null ? service.parking_has_service.parking_service.name : service.custom_service}
                </div>
            </td>

            <td className="text-center">
                {service.status !== 'Удалена' && pricesEditable ?
                    <input
                        type="text"
                        name={'approved_price'}
                        defaultValue={service.approved_price}
                        style={{maxWidth: '100px'}}
                        onChange={(e) => {
                            handleInputChange(e, service.id)
                        }}
                    /> :
                    service.status !== 'Удалена' && service.approved_price
                }
            </td>
            <td className="text-center position-relative service-price__td">
                {service.status !== 'Удалена' && pricesEditable ?
                    <input
                        type="text"
                        name={'price_for_us'}
                        defaultValue={service.price_for_us}
                        style={{maxWidth: '100px'}}
                        onChange={(e) => {
                            handleInputChange(e, service.id)
                        }}
                    /> :
                    service.status !== 'Удалена' && service.price_for_us
                }
                {service.status !== 'Удалена' ?
                    (!pricesEditable ?
                        <span
                            className={'edit-prise_for_us cursor-pointer'}
                            onClick={() => {
                                setPricesEditable(prevState => !prevState)
                            }}
                        >
                        <svg width="15" height="15" viewBox="0 0 20 20" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M13.5774 1.91058C13.9028 1.58514 14.4305 1.58514 14.7559 1.91058L18.0893 5.24391C18.4147
                                  5.56935 18.4147 6.09699 18.0893 6.42243L7.25592 17.2558C7.09964 17.412 6.88768 17.4998 6.66667
                                  17.4998H3.33333C2.8731 17.4998 2.5 17.1267 2.5 16.6665V13.3332C2.5 13.1122 2.5878 12.9002
                                  2.74408 12.7439L11.0772 4.41075L13.5774 1.91058ZM11.6667 6.17835L4.16667
                                  13.6783V15.8332H6.32149L13.8215 8.33317L11.6667 6.17835ZM15
                                  7.15466L16.3215 5.83317L14.1667 3.67835L12.8452 4.99984L15 7.15466Z"
                                  fill="#536E9B">
                            </path>
                        </svg>
                    </span> :
                        <span
                            className={'save-price_for_us cursor-pointer'}
                            onClick={() => {
                                saveNewPrices()
                            }}
                        >
                       <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M11.5917 1.00822C11.5142 0.93011 11.4221 0.868115 11.3205 0.825808C11.219 0.7835 11.11 0.761719 11 0.761719C10.89 0.761719 10.7811 0.7835 10.6796 0.825808C10.578 0.868115 10.4858 0.93011 10.4084 1.00822L4.20004 7.22488L1.59171 4.60822C1.51127 4.53052 1.41632 4.46942 1.31227 4.42842C1.20823 4.38742 1.09713 4.36731 0.985308 4.36924C0.873491 4.37118 0.76315 4.39512 0.660584 4.4397C0.558019 4.48427 0.465238 4.54862 0.387539 4.62905C0.309841 4.70949 0.248746 4.80444 0.207742 4.90848C0.166739 5.01253 0.14663 5.12363 0.148565 5.23545C0.150499 5.34727 0.174439 5.45761 0.219017 5.56017C0.263595 5.66274 0.327938 5.75552 0.408373 5.83322L3.60837 9.03322C3.68584 9.11133 3.77801 9.17332 3.87956 9.21563C3.98111 9.25793 4.09003 9.27972 4.20004 9.27972C4.31005 9.27972 4.41897 9.25793 4.52052 9.21563C4.62207 9.17332 4.71424 9.11133 4.79171 9.03322L11.5917 2.23322C11.6763 2.15518 11.7438 2.06047 11.79 1.95506C11.8361 1.84964 11.86 1.7358 11.86 1.62072C11.86 1.50563 11.8361 1.3918 11.79 1.28638C11.7438 1.18096 11.6763 1.08625 11.5917 1.00822Z"
                                fill="#27AE60"/>
                       </svg>

                    </span>) : ''
                }

            </td>
            <td className="text-right d-flex justify-content-end align-items-center">
                <label className="switch-radio-wrap d-flex flex-row-reverse">
                    {service.status !== 'Удалена' && orderStatusCode !== 'approved' && orderStatusCode !== 'ready' ?
                        <>
                            <input
                                type="checkbox"
                                checked={isChecked}
                                onChange={() => {
                                    checkStatus(service.id, service.completed)
                                }}
                            />
                            <span className="switcher-radio ml-2 mr-0"></span>
                        </> : ''
                    }
                    <span
                        className="condition min-w__100"
                        style={service.status === 'Удалена' ?
                            {color: 'red'} : isChecked ? {color: 'green'} : {color: '#000'}
                        }
                    >
                            {
                                isChecked ? 'Выполнено' : service.status
                            }
                        </span>
                </label>
            </td>
        </tr>
    );
}

export default ApplicationCertainServiceItem;