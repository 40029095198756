import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
import { useState } from "react";
import selectStyle from "../../../../utils/select-styles";
import { partnerUserSelect } from "../../../../utils/select-array";
import swal from "sweetalert";
import { partnerUserCreatePost } from "../../../../store/partners/partners-user-slice/partnerUserCreateSlice";
import {
  partnerUserEditData,
  partnerUserEditPut,
} from "../../../../store/partners/partners-user-slice/partnerUserEditSlice";

function PartnerUserEditPage() {
  const dispatch = useDispatch();
  const { id, user_id } = useParams();
  const navigate = useNavigate();
  const { partnersUserEdit } = useSelector((state) => state.partnerUserEdit);
  const [partnerUserEdit, setPartnerUserEdit] = useState({});
  useEffect(() => {
    dispatch(partnerUserEditData({ id: id, user_id: user_id }));
  }, [dispatch]);

  useEffect(() => {
    if (partnersUserEdit.length !== 0) {
      setPartnerUserEdit({
        ...partnerUserEdit,
        name: partnersUserEdit.partner_user.name,
        email: partnersUserEdit.partner_user.email,
        phone: partnersUserEdit.partner_user.phone,
        status: partnersUserEdit.partner_user.status,
      });
    }
  }, [partnersUserEdit]);

  const handleChange = (e) => {
    const { name, value } = e.target ? e.target : e;
    setPartnerUserEdit({ ...partnerUserEdit, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      partnerUserEditPut({ id: id, user_id: user_id, data: partnerUserEdit })
    ).then((response) => {
      if (response.payload === 200) {
        swal("Пользователь успешно обновлен!", {
          icon: "success",
        }).then(() => {
          navigate("/partners");
        });
      } else {
        let errorMessage = "Ошибка при обновлении!";
        if (response.payload && response.payload.errors) {
          const errorMessages = Object.values(response.payload.errors).reduce(
            (acc, val) => acc.concat(val),
            []
          );
          errorMessage = errorMessages.join("\n");
        }
        swal(errorMessage, {
          icon: "error",
        });
      }
    });
  };
  //   Собираем данные для Select по умолчанию
  let defaultPartnerUserSelectStatus = "";
  if (partnersUserEdit.length !== 0) {
    for (let i = 0; i < partnerUserSelect.length; i++) {
      if (
        Number(partnerUserSelect[i].value) ===
        partnersUserEdit.partner_user.status
      ) {
        defaultPartnerUserSelectStatus = partnerUserSelect[i];
      }
    }
  }
  //

  return (
    partnersUserEdit.length !== 0 && (
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card">
              <div className="card-header">Обновить пользователя заказчика</div>

              <div className="card-body">
                <form onSubmit={handleSubmit}>
                  {/* Имя */}

                  <div className="form-group row">
                    <label
                      htmlFor="name"
                      className="col-md-4 col-form-label text-md-right"
                    >
                      Имя
                    </label>

                    <div className="col-md-6">
                      <input
                        id="name"
                        type="text"
                        className="form-control"
                        name="name"
                        defaultValue={partnersUserEdit.partner_user.name}
                        required
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  {/*  */}

                  {/* EMAIL */}
                  <div className="form-group row">
                    <label
                      htmlFor="email"
                      className="col-md-4 col-form-label text-md-right"
                    >
                      Email
                    </label>

                    <div className="col-md-6">
                      <input
                        id="email"
                        type="email"
                        className="form-control "
                        defaultValue={partnersUserEdit.partner_user.email}
                        name="email"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {/*  */}

                  {/* Phone */}
                  <div className="form-group row">
                    <label
                        htmlFor="email"
                        className="col-md-4 col-form-label text-md-right"
                    >
                      Телефон
                    </label>

                    <div className="col-md-6">
                      <input
                          id="phone"
                          type="tel"
                          className="form-control "
                          defaultValue={partnersUserEdit.partner_user.phone}
                          name="phone"
                          onChange={handleChange}
                      />
                    </div>
                  </div>
                  {/*  */}

                  {/* ПАРОЛЬ */}
                  <div className="form-group row">
                    <label
                      htmlFor="password"
                      className="col-md-4 col-form-label text-md-right"
                    >
                      Пароль
                    </label>

                    <div className="col-md-6">
                      <input
                        id="password"
                        type="password"
                        className="form-control"
                        onChange={handleChange}
                        name="password"
                      />
                    </div>
                  </div>
                  {/*  */}

                  {/* ПОДТВЕРЖДЕНИЕ ПАРОЛЯ */}
                  <div className="form-group row">
                    <label
                      htmlFor="password-confirm"
                      className="col-md-4 col-form-label text-md-right"
                    >
                      Подтверждение пароля
                    </label>

                    <div className="col-md-6">
                      <input
                        id="password-confirm"
                        type="password"
                        className="form-control"
                        onChange={handleChange}
                        name="password_confirmation"
                      />
                    </div>
                  </div>
                  {/*  */}

                  {/* СТАТУС */}
                  <div className="form-group row">
                    <label
                      htmlFor="status"
                      className="col-md-4 col-form-label text-md-right"
                    >
                      Статус
                    </label>

                    <div className="col-md-6">
                      <label className="field-style select-react__style">
                        <Select
                          defaultValue={defaultPartnerUserSelectStatus}
                          options={partnerUserSelect}
                          styles={selectStyle}
                          name="status"
                          onChange={handleChange}
                        />
                      </label>
                    </div>
                  </div>
                  {/*  */}

                  <div className="form-group row mb-0">
                    <div className="col-md-6 offset-md-4">
                      <button type="submit" className="btn btn-primary">
                        Обновить
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default PartnerUserEditPage;
