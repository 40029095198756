import axios from "axios";

const API_URL = "/v1/";

// Login user

const login = async (userData) => {
  const response = await axios.post(API_URL + "login", userData)
  if (response.data) {
    localStorage.setItem("web_vinz", JSON.stringify(response.data));
  }
  return response.data;
};
//

// Logout  user

const logout = async (data) => {
  const response = await axios.post(API_URL + "logout");

  if (response.data) {
    localStorage.removeItem("web_vinz");
  }
  return response.data
};

//

const authService = {
  login,
  logout,
};

export default authService;
