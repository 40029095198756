import axios from "axios";

// Users All LiST
let API_URL = "/v1/legals";

// Список USer/
const legals = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};
//

// Редактирование данных legals
const legalsEditData = async (id) => {
  const response = await axios.get(API_URL + "/" + id + "/edit");
  return response.data;
};

const legalsEditPut = async (id, data) => {
  const response = await axios.put(API_URL + "/" + id, data);
  return response.status;
};
//

// Удаление legals
const legalsDelete = async (id) => {
  const response = await axios.delete(API_URL + "/" + id);
  if (response) {
    return id;
  } else {
    return { data: response.data, status: response.status };
  }
};
//

// Создание legals
const legalsCreate = async () => {
  const response = await axios.get(API_URL + "/create");
  return response.data;
};

const legalsCreateData = async (data) => {
  const response = await axios.post(API_URL, data);
  return response.status;
};
//

// Просмотр Legals
const legalsSingle = async (id) => {
  const response = await axios.get(API_URL + "/" + id);
  return response.data;
};
//

const legalsService = {
  legals,
  legalsEditData,
  legalsEditPut,
  legalsDelete,
  legalsCreate,
  legalsCreateData,
  legalsSingle,
};

export default legalsService;
