import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { usersData } from "../../store/users/usersSlice";
import UserItem from "./user-item/user-item";

function Users() {
  const { users, title } = useSelector((state) => state.users);
  const { user } = useSelector((state) => state.auth);

  const [sortParams , setSortParams] = useState('team')
  const [sortedUsers , setSortedUsers] = useState([])
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(usersData());
  }, []);

  const sortByParams = (params)=>{
    setSortParams(params)

    switch (params){
      case 'team' :
        if (user.role === 'SuperAdmin') {
          const filteredUsers = users.filter((user) =>
              user.roles[0]?.name === 'Admin' || user.roles[0]?.name === 'SuperAdmin'
          );
          setSortedUsers(filteredUsers);
        }
        else if (user.role === 'Admin') {
          const filteredUsers = users.filter((user) =>
              user.roles[0]?.name === 'Manager' || user.roles[0]?.name === 'Operator'
          );
          setSortedUsers(filteredUsers);
        }
        break;
      case 'partners' :
        const filteredUsersPartners = users.filter((user) =>
            user.roles[0]?.name === 'Partner'
        );
        setSortedUsers(filteredUsersPartners);
        break;

      case 'contractors' :
        const childrenWithDesiredRoles = users.flatMap((user) =>
            user.children.filter((child) =>
                    Array.isArray(child.roles) && child.roles.some((role) =>
                        role.name === 'Contractor' ||
                        role.name === 'ContractorOperator' ||
                        role.name === 'ContractorManager'
                    )
            )
        );
        setSortedUsers(childrenWithDesiredRoles);
        if (user.role === 'Admin') {
          setSortedUsers(prevUsers => [
            ...prevUsers,
            ...users.filter((user) =>
                user.roles[0]?.name === 'Contractor'
            )
          ]);
        }

        break;
    }
  }
  useEffect(()=>{
    let filteredUsers = []
    if (user.role === 'SuperAdmin') {
       filteredUsers = users.filter((user) =>
          user.roles[0]?.name === 'Admin' || user.roles[0]?.name === 'SuperAdmin'
      );
    }
    else if (user.role === 'Admin') {
       filteredUsers = users.filter((user) =>
          user.roles[0]?.name === 'Manager' || user.roles[0]?.name === 'Operator'
      );
    }
      setSortedUsers(filteredUsers);
  }, [users, dispatch])


  return (
    <>
      <div className="container page-head-wrap">
        <div className="page-head">
          <div className="page-head__top d-flex align-items-center">
            <h1>{title}</h1>
            <label className="field-style blue">
              <span>Поиск</span>
              <input type="text" id="keyword" placeholder="Поиск по столбцам" />
            </label>
            <div className="ml-auto d-flex">
              <Link to="/users/create" className="btn btn-white">
                Добавить
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        {/*tabs*/}
        <div className="col-md-8 block-parking">
          <div className="nav block-nav__item " id="v-pills-tab" role="tablist" aria-orientation="vertical">
            <ul className="nav nav-tabs" role="tablist">
              {user.role !== 'Partner' &&
              <li className="nav-item" role="presentation">
                <button
                    className={`nav-link  ${sortParams === 'team' ? 'active' : ''}`}
                    onClick={() => {
                      sortByParams('team')
                    }}
                > Команды
                </button>
              </li>
              }
              {user.role === 'SuperAdmin' &&
              <li className="nav-item" role="presentation">
                <button
                    className={`nav-link  ${sortParams === 'partners' ? 'active' : ''}`}
                    onClick={()=>{sortByParams('partners')}}
                >Заказчики
                </button>
              </li>}
              <li className="nav-item" role="presentation">
                <button
                    className={`nav-link  ${sortParams === 'contractors' ? 'active' : ''}`}
                    onClick={()=>{sortByParams('contractors')}}
                >Исполнители
                </button>
              </li>
            </ul>
          </div>
        </div>
        {/*tabs end */}
        <div className="inner-page">
          <table className="table" id="searchable">
            <thead className="users__table">
              <tr>
                <th></th>
                <th>Имя</th>
                <th>Роль</th>
                <th>E-Mail </th>
                <th>Телефон </th>
                {/*<th>Юр.лица </th>*/}
                {sortParams !== 'partners'
                 ? <th>Стоянки</th> : ''
                }
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                sortedUsers && sortedUsers?.length !== 0 ? sortedUsers?.map((item, index) => (
                    <UserItem item={item} key={item.id} index={index} sortParams={sortParams} />
                  ))
                : ""
              }
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default Users;
