import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import usersService from "./usersService";

const initialState = {
  users: [],
  title: "",
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Login user
export const usersData = createAsyncThunk("users/all", async (thunkAPI) => {
  try {
    return await usersService.users();
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data) ||
      error.message ||
      error.toSrting();
    return thunkAPI.rejectWithValue(message);
  }
});
//

// Удаление юзера
export const userDelete = createAsyncThunk(
  "users/userDelete",
  async (id, thunkAPI) => {
    try {
      return await usersService.usersDelete(id);
    } catch (error) {
      const message =
        (error.response && error.response.data && error.response.data) ||
        error.message ||
        error.toSrting();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(usersData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(usersData.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.users = action.payload.users;
        state.title = action.payload.title;
      })
      .addCase(usersData.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(userDelete.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(userDelete.fulfilled, (state, action) => {
        state.users = state.users.filter((item) => item.id !== action.payload);
      })
      .addCase(userDelete.rejected, (state, action) => {
        state.message = action.payload;
      });
  },
});

export default usersSlice.reducer;
