import axios from "axios";

// Application dublicate vin
let API_URL = "/v1/applications/check-duplicate";

const applicationVinDublicate = async (vin) => {
  const response = await axios.get(API_URL, {
    params: {
      vin: vin,
    },
  });
  return response.data;
};

const applicationVinDublicateService = {
  applicationVinDublicate,
};

export default applicationVinDublicateService;
