import React from "react";
import Select from "react-select";
import {
  selectArray,
  selectArrayPartner,
} from "../../../../../../utils/select-array";
import modalSelectStyle from "../../../../../../utils/select-modal-style";
import { selectArrayParking } from "../../../../../../utils/select-array";
import Flatpickr from "react-flatpickr";
import { Russian } from "flatpickr/dist/l10n/ru.js";

function ApplicationsItemSystemData({
  single,
  quickEdit,
  setDataQuickEdit,
  dataQuickEdit,
}) {
  const partnerSelect = selectArray(single.partners, "partner_id");
  const parkingSelect = selectArrayParking(single.parkings, "parking_id");
  const acceptedSelect = selectArray(single.users, "accepted_by");
  const issuedSelect = selectArray(single.users, "issued_by");


  const handleChange = (e) => {
    const { name, value, type, checked } = e.target ? e.target : e;

    let newValue = value;
    if (type === "checkbox") {
      newValue = checked ? 1 : 0;
    }

    setDataQuickEdit({ ...dataQuickEdit, [name]: newValue });
  };
  const handleChangeDate = (e, name) => {
    if (Array.isArray(e)) {
      let issueDate = e;
      issueDate = issueDate
        .map((item) =>
          new Date(item)
            .toLocaleDateString("ru-RU", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
            })
            .replace(/\./g, ".")
        )
        .join(".");
      setDataQuickEdit({ ...dataQuickEdit, [name]: issueDate });
    }
  };
  return (
    <div className="row custom-height-items">
      {quickEdit ? (
        <>
          {/* Первая колонка */}
          <div className="col-4">
            {/* Редактирование VIN Номера */}
            <div className="info-item pseudo-field1 active">
              <span>VIN</span>
              <input
                type="text"
                className="w-100"
                name="vin"
                defaultValue={single.application.vin}
                onChange={handleChange}
              />
            </div>
            {/*  */}

            {/* Редактирование гос.номера */}
            <div className="info-item pseudo-field1 active">
              <span>Гос. номер</span>
              <input
                type="text"
                className="w-100"
                name="license_plate"
                defaultValue={single.application.license_plate}
                onChange={handleChange}
              />
            </div>
            {/*  */}

            {/* Редактирование заказчика  */}
            <div className="info-item mb-0">
              <span>Заказчик</span>
              <Select
                defaultValue={{
                  value: single.application?.partner?.id,
                  label: single.application?.partner?.name,
                  name: "partner_id",
                }}
                className="client-select-options"
                name="partner_id"
                options={partnerSelect}
                styles={modalSelectStyle}
                onChange={handleChange}
              />
            </div>
            {/*  */}

            {/* Добавление повтора */}
            <div className="info-item pseudo-field1 repeat-checkbox ">
              <label className="switch-radio-wrap mt-2">
                <input
                  type="checkbox"
                  name="returned"
                  onChange={handleChange}
                  defaultChecked={single.application?.returned}
                />
                <span className="switcher-radio"></span>
                <span>Повтор</span>
              </label>
            </div>
            {/*  */}
          </div>
          {/*  */}

          {/* Вторая колонка */}
          <div className="col-4">
            {/* Дата постановки */}
            <div className="info-item pseudo-field1 active">
              <span>Дата постановки</span>
              <Flatpickr
                options={{
                  dateFormat: "d-m-Y",
                  locale: {
                    ...Russian,
                    months: {
                      ...Russian.months,
                      longhand: [
                        "Января",
                        "Февраля",
                        "Марта",
                        "Апреля",
                        "Мая",
                        "Июня",
                        "Июля",
                        "Августа",
                        "Сентября",
                        "Октября",
                        "Ноября",
                        "Декабря",
                      ],
                    },
                  },
                }}
                onChange={(e) => {
                  handleChangeDate(e, "arrived_at");
                }}
                defaultValue={single.application.formated_arrived_at}
                name="arrived_at"
                style={{ width: "100%" }}
              />
            </div>
            {/*  */}

            {/* Принял */}
            <div className="info-item mb-0">
              <span>Принял</span>
              <Select
                defaultValue={{
                  value: single.application?.acceptedBy?.id,
                  label: single.application?.acceptedBy?.name,
                  name: "accepted_by",
                }}
                name="accepted_by"
                options={acceptedSelect}
                styles={modalSelectStyle}
                onChange={handleChange}
              />
            </div>
            {/*  */}

            {/* Редактирование стоянки */}
            <div className="info-item mb-0">
              <span>Стоянка</span>
              <Select
                  defaultValue={{
                    value: single.application?.parking?.id,
                    label: single.application?.parking?.title,
                    name: "parking_id",
                  }}
                  name="parking_id"
                  options={parkingSelect}
                  styles={modalSelectStyle}
                  onChange={handleChange}
              />
            </div>
            {/*  */}

          </div>

          <div className="col-4">
            {/* Дата выдачи */}
            <div className="info-item pseudo-field1 active">
              <span>Дата выдачи</span>
              <Flatpickr
                  options={{
                    dateFormat: "d-m-Y",
                    locale: {
                      ...Russian,
                      months: {
                        ...Russian.months,
                        longhand: [
                          "Января",
                          "Февраля",
                          "Марта",
                          "Апреля",
                          "Мая",
                          "Июня",
                          "Июля",
                          "Августа",
                          "Сентября",
                          "Октября",
                          "Ноября",
                          "Декабря",
                        ],
                      },
                    },
                  }}
                  onChange={(e) => {
                    handleChangeDate(e, "issued_at");
                  }}
                  defaultValue={single.application.issuance_date}
                  name="issued_at"
                  style={{ width: "100%" }}
              />
            </div>
            {/*  */}

            {/* Выдал  */}
            <div className="info-item mb-0">
              <span>Выдал</span>
              <Select
                  defaultValue={{
                    value: single.application?.issuedBy?.id,
                    label: single.application?.issuedBy?.name,
                    name: "issued_by",
                  }}
                  name="issued_by"
                  options={issuedSelect}
                  styles={modalSelectStyle}
                  onChange={handleChange}
              />
            </div>
            {/*  */}

            {/* Бесплатное хранение */}
            <div className="info-item pseudo-field1 repeat-checkbox">
              <label className="switch-radio-wrap mt-2">
                <input
                    type="checkbox"
                    name="free_parking"
                    onChange={handleChange}
                    defaultChecked={single.application?.free_parking}
                />
                <span className="switcher-radio"></span>
                <span>Бесплатное хранение</span>
              </label>
            </div>
            {/*  */}
          </div>
          {/*  */}
        </>
      ) : (
        <>
          <div className="col-4">
            <div className="info-item pseudo-field1">
              <span>VIN</span>
              <div id="vinnumber">{single.application.vin && single.application.vin === 'не указан' ? '_' : single.application.vin}</div>
            </div>
            <div className="info-item pseudo-field1">
              <span>Гос. номер</span>
              <div id="licenceplate">{single.application.license_plate && single.application.license_plate === 'не указан' ? '_' : single.application.license_plate}</div>
            </div>

            <div className="info-item pseudo-field">
              <span>Заказчик</span>
              <div className="dropdownEditible partner pt-0">
                <div>
                  {single?.application?.partner === null
                    ? '_'
                    : single?.application?.partner.name}
                </div>
              </div>
            </div>


          </div>

          <div className="col-4">
            <div className="info-item pseudo-field1">
              <span>Дата постановки</span>
              <input
                type="text"
                id="arriving_at_modal"
                className="custom-select date-select d-none"
                placeholder="Не указан"
              />
              <div className=" pt-0">
                <div id="arriving_at_div">
                  {single.application.formated_arrived_at}
                </div>
              </div>
            </div>

            <div className="info-item pseudo-field1 acc">
              <span>Принял</span>

              <div className=" accepted pt-0">
                <div className="">
                  {single.application.acceptedBy
                    ? single.application?.acceptedBy?.name
                    : '_'}
                </div>
              </div>
            </div>
            <div className="info-item pseudo-field1">
              <span>Стоянка</span>

              <div className="dropdownEditible parking pt-0">
                <div>
                  {single?.application?.parking === null
                      ? '_'
                      : single?.application?.parking.title}
                </div>
              </div>
            </div>


          </div>

          <div className="col-4">
            <div className="info-item pseudo-field1">
              <span>Дата выдачи</span>
              <input
                  type="text"
                  id="issued_at_modal"
                  className="custom-select date-select d-none"
                  placeholder="Не указан"
              />
              <div className="dropdownEditible pt-0">
                <div contentEditable="false" id="issued_at_div">
                  {single.application.issuance_date}
                </div>
              </div>
            </div>

            <div className="info-item pseudo-field1">
              <span>Выдал</span>
              <div className="dropdownEditible issued pt-0">
                <div contentEditable="false">
                  {" "}
                  <div className="">
                    {single.application.issued_by
                        ? single.application?.issued_by?.name
                        : '_'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ApplicationsItemSystemData;
