import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { applicationsEditGet } from "../../store/applications/applications-edit/applications-edit-get/applicationsEditSlice";
import { applicationsSingle } from "../../store/applications/applications-single/applicationsSingleSlice";
import { uploadFileSlice } from "../../store/file/uploadFileSlice";
import { imageDelete } from "../../store/image/image-temperaty/imageTemperarySlice";
import { Gallery, Item } from "react-photoswipe-gallery";

const FileUploadModal = ({ applicationId, docs }) => {
  const [files, setFiles] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);
  const [allDocs, setAllDocs] = useState([]);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();

  function handleClick() {
    fileInputRef.current.click();
  }
  const handleFileInputChange = (event) => {
    const selectedFiles = event.target.files;
    const selectedFilesArray = Array.from(selectedFiles);
    setFiles((prevFiles) => prevFiles.concat(selectedFilesArray));

    const fileReaders = selectedFilesArray.map((file) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      return fileReader;
    });

    Promise.all(fileReaders).then((results) => {
      const previewUrls = results.map((result) => result.result);
      setPreviewUrls((prevUrls) => [...prevUrls, ...previewUrls]);
    });
    dispatch(
      uploadFileSlice({ id: applicationId, data: { docs: selectedFilesArray } })
    ).then(() => {
      dispatch(applicationsSingle(applicationId));
    });
  };

  const failDeleteDB = (id) => {
    dispatch(imageDelete(id));
    dispatch(applicationsSingle(applicationId)).then((resp)=>{
      setAllDocs(resp.payload.application.docs)
    });
  };

  useEffect(()=>{
    dispatch(applicationsSingle(applicationId)).then((resp)=>{
      setAllDocs(resp.payload.application.docs)
    });
  },[docs, dispatch, files])
  return (
    <>
      {/*<div className="inner-item-title">Паспорт, доверенность и прочее</div>*/}
      <div>
        <input
          type="file"
          multiple
          onChange={handleFileInputChange}
          ref={fileInputRef}
          className="d-none"
        />
        <div className="page-file-list">
          <div
            className="page-add-file no-ajax upload-file"
            onClick={handleClick}
          >
            <svg
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.6"
                d="M20.0013 6.6665C20.9218 6.6665 21.668 7.4127 21.668 8.33317V18.3332H31.668C32.5884 18.3332 33.3346 19.0794 33.3346 19.9998C33.3346 20.9203 32.5884 21.6665 31.668 21.6665H21.668V31.6665C21.668 32.587 20.9218 33.3332 20.0013 33.3332C19.0808 33.3332 18.3346 32.587 18.3346 31.6665V21.6665H8.33464C7.41416 21.6665 6.66797 20.9203 6.66797 19.9998C6.66797 19.0794 7.41416 18.3332 8.33464 18.3332H18.3346V8.33317C18.3346 7.4127 19.0808 6.6665 20.0013 6.6665Z"
                fill="#536E9B"
              />
            </svg>
          </div>

          <div className="page-file-list">
            <Gallery>
              {allDocs &&
              allDocs.length !== 0 &&
              allDocs.map((previewUrl, index) => (
                  <div className="page-file-item " key={index}>
                    <div className="file-icon pdf-icon"></div>
                    <span>{previewUrl?.name}</span>
                    <div className="page-file__option">
                      {previewUrl.thumbnail_url ? (
                        <Item
                          original={previewUrl?.url}
                          thumbnail={previewUrl?.thumbnail_url}
                          width="1024"
                          height="768"
                          key={index}
                        >
                          {({ ref, open }) => (
                            <button
                              type="button"
                              className="page-file__zoom"
                              onClick={open}
                              ref={ref}
                            ></button>
                          )}
                        </Item>
                      ) : (
                        <>
                          <a
                            href={previewUrl?.url}
                            type="button"
                            className="page-file__download"
                            target="_blank"
                          ></a>
                        </>
                      )}
                      <button
                        type="button"
                        className="page-file__delete"
                        onClick={(e) => failDeleteDB(previewUrl?.id)}
                      ></button>
                    </div>
                  </div>
                ))}
            </Gallery>
          </div>
        </div>
      </div>
    </>
  );
};

export default FileUploadModal;
