import React, {useEffect, useState} from "react";
import LogsItem from "../../components/logs-item/logs-item";
import {useDispatch, useSelector} from "react-redux";
import {logsData} from '../../store/logs/logsSliece'
import ReactPaginate from "react-paginate";

function Logs() {
    const logs = useSelector((state)=>state.logs)
    const [pageCount, setPageCount] = useState();

    const [currentPage, setCurrentPage] = useState(1);
    const dispatch = useDispatch();


    useEffect(()=>{
        dispatch(logsData(currentPage))
    },[dispatch])

    useEffect(()=>{
        setPageCount(logs.logs?.pagination?.totalPages);
    },[logs])

    const handlePageChange = (selectedObject)=>{
        window.scroll(0,0)
        setCurrentPage(selectedObject + 1)
        dispatch(logsData(selectedObject.selected+1))
    }
    return (
        <>
            <div className="container page-head-wrap">
                <div className="page-head">
                    <div className="page-head__top d-flex align-items-center">
                        <h1>Логи</h1>

                    </div>
                </div>
            </div>
            <div className="container">
                <div className="inner-page">
                    <table className="table">
                        <thead>
                        <tr>
                            <th className="sortable" scope="col">
                                id
                            </th>
                            <th className="sortable" scope="col">
                                Дата
                            </th>
                            <th className="sortable" scope="col">
                                Действие
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        { logs.isSuccess  ?
                            logs.logs.data && logs.logs.data.map((item) =>  <LogsItem key={item.id} item={item} />)
                            : ''
                        }
                        </tbody>
                    </table>
                    <div className="container">
                        <ReactPaginate
                            pageCount={pageCount}
                            onPageChange={handlePageChange}
                            containerClassName={"pagination"}
                            previousLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            nextLabel={`>`}
                            previousLabel={`<`}
                            pageClassName={"page-item"}
                            disabledClassName={"disabled"}
                            activeClassName={"active"}
                        />
                    </div>
                </div>
            </div>

        </>
    )
}
export default Logs