//1 заказ в списке заказов
function ApplicationServiceListItem(
    {
        item,
        openCurrentOrders,
        handleSelect,
        formatDate,
        getStatusColor,
        progressBar,
    }
) {
    return (
        <a href="#" key={item?.id} className={'mb-3 col-md-4'} onClick={(e) => {
            openCurrentOrders(item.id, item.application_id)
            handleSelect('tabCurrentServices')
        }}>
            {/*{showPoint &&*/}
            {/*    <div className={'point_order_status'} >*/}
            {/*        <div className="dot" style={{backgroundColor: getStatusColor(item.status_code)}}></div>*/}
            {/*    </div>*/}
            {/*}*/}
            <span
                style={{fontSize: '14px'}}
            >
                {`Заказ № ${item?.id}`}
            </span>
            <span
                style={{fontSize: '12px'}}
            >
                {` от ${formatDate(item.created_at)}`}
            </span>
            <br/>
            <span
                className={'status_info_owner'}
                style={{
                    fontSize: '11px',
                    color: getStatusColor(item.status_code),
                    textAlign: 'left',
                }}
            >
                {item.status_name}
                {item.status_code === 'toPartner' ?  <p className={'small_text'}>(у Заказчика)</p> : ''}
                {item.status_code === 'toManager' ?  <p className={'small_text'}>(у Менеджера)</p> : ''}
            </span>
            {item.status_code === 'in-progress' || item.status_code === 'partly-completed' ?
                <div className={'order-bar__info'}>
                    <div className="progress">
                        <div
                            className="progress-bar bg-success"
                            role="progressbar"
                            style={{width: progressBar(item.service_count, item.completed_service_count)}}
                            aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                        </div>
                    </div>
                    <span
                        className={'order__completed-count'}>{item.completed_service_count}/{item.service_count}</span>
                </div>
                : ''
            }

        </a>
    );
}

export default ApplicationServiceListItem;