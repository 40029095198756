import React, {useEffect, useState} from 'react';
import ApplicationLogsItem from "./application-logs-item/application-logs-item";

function ApplicationLogs({logs}) {
    const [logsList, setLogsList] = useState([])
    const [filter, setFilter] = useState('all')

    useEffect(() => {
        setLogsList(logs)
    }, [logs])

    const filterLogs = (filterBy) =>{
        setFilter(filterBy)
        if(filterBy === 'all'){
            setLogsList(logs)
        }
        if(filterBy === 'orders') {
            setLogsList(logs.filter(item=>item?.log_type === 'order'))
        }
        if(filterBy === 'status') {
            setLogsList(logs.filter(item=>item?.log_type === null))
        }
    }
    return (
        <div>
            <div className="log-filters">
                <div className={`log-filters__item ${filter === 'all' && 'active'}`}>
                    <span
                        onClick={()=>{filterLogs('all')}}
                    >Все логи</span>
                </div>
                <div className={`log-filters__item ${filter === 'orders' && 'active'}`}>
                    <span
                        onClick={()=>{filterLogs('orders')}}
                    >Заказы</span>
                </div>
                <div className={`log-filters__item ${filter === 'status' && 'active'}`}>
                    <span
                        onClick={()=>{filterLogs('status')}}
                    >Статусы</span>
                </div>

            </div>
            <table className="table logs-table">
                <thead>
                <tr>
                    {/*<th>ID</th>*/}
                    <th>Дата</th>
                    <th>Действие</th>
                </tr>
                </thead>
                <tbody>
                {logsList.map((item) => {
                    return (
                        <ApplicationLogsItem
                            id={item.id}
                            key={item.id}
                            date={item.date}
                            action={item.action}
                        />
                    )
                })}
                </tbody>
            </table>

        </div>
    );
}

export default ApplicationLogs;