import axios from "axios";

const API_URL = '/v1/profile'
const getProfileInfo = async () => {
    const response =  await axios.get(API_URL);
    return {data : response.data, status : response.status};
};

const updateProfileInfo = async (profileInfo) => {
    const response =  await axios.put(API_URL+'/update', profileInfo.profileInfo);
    return {status : response.status};
};

const profileServices = {
    getProfileInfo,
    updateProfileInfo
}

export default profileServices
