import React from 'react';
import {Link} from "react-router-dom";

function Sidebar() {

    return (
        <div className="col-md-3 sidebar bg-white">
            {/* Tabs */}
            <div style={{display: 'flex', flexDirection: "column", gap: "6px"}} className={`container`}>
                <div>
                    <Link className={'nav__link'} to="/guide/coverage">Покрытия</Link>
                </div>
                <div>
                    <Link className={'nav__link'} to="/guide/fence">Ограждения</Link>
                </div>
                <div>
                    <Link className={'nav__link'} to={'/guide/services'} >Услуги</Link>
                </div>
                <div>
                    <Link className={'nav__link'} to={'/guide/services-category'} >Категории услуг</Link>
                </div>
            </div>

        </div>

    );
}

export default Sidebar;