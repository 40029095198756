import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import partnersService from "../partnersService";


const initialState = {
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};

// delete partner
export const partnersDelete = createAsyncThunk(
    "partners/delete",
    async (id, thunkAPI) => {
        try {
            return await partnersService.partnerDelete(id);
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
//

const partnerDeleteSlice = createSlice({
    name: "partners/delete",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(partnersDelete.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(partnersDelete.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.message = action.payload;
            })
            .addCase(partnersDelete.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
            });
    },
});

export default partnerDeleteSlice.reducer;
