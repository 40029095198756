import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import parkingsService from "../parkingsService";

const initialState = {
    parkingCategories: [],
    parkingServices: [],
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
};

export const getParkingCategory = createAsyncThunk(
    "parkings/getCategory",
    async ( thunkAPI) => {
        try {
            return await parkingsService.getAllParkingServiceCategories();
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const getParkingServices = createAsyncThunk(
    "parkings/getServices",
    async ( thunkAPI) => {
        try {
            return await parkingsService.getAllParkingServices();
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const createParkingServices = createAsyncThunk(
    "parkings/createServices",
    async (data, thunkAPI) => {
        try {
            return await parkingsService.createNewService(data);
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
);
export const changeParkingServicesSeq = createAsyncThunk(
    "parkings/changeParkingServicesSequence",
    async (data, thunkAPI) => {
        try {
            return await parkingsService.changeParkingServicesSequence(data);
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const changeParkingServiceName = createAsyncThunk(
    "parkings/changeParkingServicesName",
    async ({data, parkingservice_id}, thunkAPI) => {
        try {
            return await parkingsService.changeServiceName({data, parkingservice_id});
        } catch (error) {
            const message =
                (error.response && error.response.data && error.response.data) ||
                error.message ||
                error.toSrting();
            return thunkAPI.rejectWithValue(message);
        }
    }
);




const getParkingServicesSlice = createSlice({
    name : 'ParkingCategoryGet',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getParkingServices.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getParkingServices.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.parkingServices = action.payload;
            })
            .addCase(getParkingServices.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.parkingServices = null;
            })
            .addCase(getParkingCategory.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getParkingCategory.fulfilled, (state, action) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.parkingCategories = action.payload;
            })
            .addCase(getParkingCategory.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.message = action.payload;
                state.parkingCategories = null;
            })
    },
})

export default getParkingServicesSlice.reducer;