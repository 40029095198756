import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {deleteFence} from "../../store/parkings/delete-fence/deleteFenceSlice";
import {createFence, getAllFence} from "../../store/parkings/create-fence/createFenceSlice";
import swal from "sweetalert";
import Sidebar from "../../components/guide/sidebar/sidebar";
import GuideItem from "../../components/guide/guide-Item";
import {
    createParkingCategories,
    editParkingCategories
} from "../../store/parkings/parking-services-category-create-slice/parkingServicesCategoryCreateSlice";
import {getParkingCategory} from "../../store/parkings/parking-services-create-slice/parkingServicesСreateSlice";
import {deleteServiceCategory} from "../../store/parkings/parking-services-category-delete-slice/parkingServicesCategoryDeleteSlice";

function ServicesCategory() {
    const dispatch = useDispatch();
    const allCategories = useSelector((state) => state.parkingAllServices.parkingCategories);
    const [serviceCategoryName, setServiceCategoryName] = useState('name')

    const changeServiceCategoryName = (e) => {
        setServiceCategoryName(e.target.value)
    }
    const handleDelete = (id) => {
        dispatch(deleteServiceCategory(id)).then((response)=>{
            if(response.payload === 200 ){
                dispatch(getParkingCategory())
                swal("Услуга удалена успешно", {
                    icon: "success",
                })
            }else{
                swal("Ошибка при удалении услуги", {
                    icon: "error",
                });
            }
        })
    }

    useEffect(()=>{
        dispatch(getAllFence())
    }, [dispatch])

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(createParkingCategories(serviceCategoryName)).then((response)=>{
            if(response.payload.status === 201 ){
                dispatch(getParkingCategory())
                swal("Услуга создана успешно", {
                    icon: "success",
                })
            }else{
                swal("Ошибка при создании услуги", {
                    icon: "error",
                });
            }
        })
    }
    return (
        <div className={'container'}>
            <div className="inner-page">
                <div className="page-head-wrap">
                    <div className="page-head">
                        <div className="page-head__top d-flex align-items-center">
                            <h1>Категории услуг</h1>
                        </div>
                    </div>
                </div>
                <div className="d-flex">
                    <Sidebar/>
                    <div className="col-md-8 p-3 border-left">
                        <form onSubmit={handleSubmit}>
                            <div className="inner-item-title">Создать услугу</div>
                            <div className=" mt-3 d-flex  justify-content-start">
                                <label className="field-style">
                                    <span>Название категории</span>
                                    <input id="title"
                                           type="text"
                                           name="title"
                                           required=""
                                           className={'w-100'}
                                           placeholder="Мойка легковых машин"
                                           onChange={changeServiceCategoryName}
                                    />
                                </label>
                                <button
                                    className={'btn btn-dark-blue'}
                                    type={"submit"}
                                    style={{maxHeight:'45px'}}
                                >
                                    Добавить
                                </button>
                            </div>
                        </form>
                        <br/>
                        <br/>
                        <br/>

                        <div >
                            <h3>Список имеющихся категорий</h3>
                            <br/>
                            <div className="inner-page">

                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th>id</th>
                                        <th>Название</th>
                                        <th>Редактировать</th>
                                        <th>Удаить</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {allCategories?.map((item, index) => {
                                        return (
                                            <GuideItem
                                                key={item.id}
                                                item={item}
                                                handleDelete={handleDelete}
                                                getAll={getParkingCategory}
                                                editItem={editParkingCategories}
                                            />
                                        );
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default ServicesCategory;