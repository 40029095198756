import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Flatpickr from "react-flatpickr";
import Select from "react-select";
import selectStyle from "../../../utils/select-styles";
import { selectArrayInterval } from "../../../utils/select-array";
import { useState } from "react";
import swal from "sweetalert";
import {
  editIssueReset,
  issueEditData,
  issueRequestUpdate,
} from "../../../store/issueRequest/issue-request-edit/IssueRequestEditSlice";
import { issuanceUpdate } from "../../../store/issueRequest/issuance/issuanceSlice";
import { Russian } from "flatpickr/dist/l10n/ru.js";

function IssueRequestEdit() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { issueEdit } = useSelector((state) => state.issueRequestEdit);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(editIssueReset());
    dispatch(issueEditData(id));
  }, [dispatch, navigate]);
  const [issueEditPost, setIssueEditPost] = useState({});

  useEffect(() => {
    if (issueEdit) {
      user.role === "Manager"
        ? setIssueEditPost({
            issued_at: issueEdit?.issueRequest?.arriving_at,
            fio: `${issueEdit?.client?.lastname} ${issueEdit?.client?.firstname} ${issueEdit?.client?.middlename}`,
            inn: issueEdit?.client?.inn,
            legal_type_id: issueEdit.client?.legal_type_id,
            organization_name: issueEdit?.client?.organization_name,
            phone: issueEdit?.client?.phone,
          })
        : setIssueEditPost({
            arriving_at: issueEdit?.issueRequest?.arriving_at,
            arriving_interval: issueEdit?.issueRequest?.arriving_interval,
            fio: `${issueEdit?.client?.lastname} ${issueEdit?.client?.firstname} ${issueEdit?.client?.middlename}`,
            inn: issueEdit?.client?.inn,
            legal_type_id: issueEdit.client?.legal_type_id,
            organization_name: issueEdit?.client?.organization_name,
            phone: issueEdit?.client?.phone,
          });
    }
  }, [issueEdit]);
  // Собираем данные и отправляем их
  const onEditIssue = (e) => {
    e.preventDefault();
    if (user.role === "Manager") {
      dispatch(
        issuanceUpdate({
          id: issueEdit.issueRequest.application.id,
          data: issueEditPost,
        })
      ).then((response) => {
        if (response.payload.message === "Issued.") {
          swal("Машина успешно обновлена", {
            icon: "success",
          }).then(() => {
            navigate("/applications");
          });
        } else {
          let errorMessage = "Ошибка!";
          if (response.payload && response.payload.errors) {
            const errorMessages = Object.values(response.payload.errors).reduce(
              (acc, val) => acc.concat(val),
              []
            );
            errorMessage = errorMessages.join("\n");
          }
          swal(errorMessage, {
            icon: "error",
          });
        }
      });
    } else {
      dispatch(issueRequestUpdate({ id: id, data: issueEditPost })).then(
        (response) => {
          if (response.payload.success === "Сохранено.") {
            swal("Машина успешно обновлена", {
              icon: "success",
            }).then(() => {
              navigate("/applications");
            });
          } else {
            let errorMessage = "Ошибка!";
            if (response.payload && response.payload.errors) {
              const errorMessages = Object.values(
                response.payload.errors
              ).reduce((acc, val) => acc.concat(val), []);
              errorMessage = errorMessages.join("\n");
            }
            swal(errorMessage, {
              icon: "error",
            });
          }
        }
      );
    }
  };

  const handleChangeDate = (e) => {
    if (Array.isArray(e)) {
      let issueDate = e;
      issueDate = issueDate
        .map((item) =>
          new Date(item)
            .toLocaleDateString("ru-RU", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
            })
            .replace(/\./g, "-")
        )
        .join("");
      setIssueEditPost({ ...issueEditPost, arriving_at: issueDate });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target ? e.target : e;
    setIssueEditPost({ ...issueEditPost, [name]: value });
  };
  //
  // Проверяем данные в промежуток времени
  const selectedOption = selectArrayInterval.find(
    (option) => option.value === issueEdit?.issueRequest?.arriving_interval
  );
  //
  return (
    issueEdit &&
    issueEdit.length !== 0 && (
      <form onSubmit={onEditIssue}>
        {/* Заголовок  */}
        <div className="container page-head-wrap">
          <div className="page-head">
            <div className="page-head__top d-flex align-items-center">
              <h1>
                {user.role === "Manager" ? "Выдать авто" : issueEdit.title}
              </h1>
              <div className="ml-auto d-flex">
                <button className="btn btn-white">
                  {user.role === "Manager" ? "Выдать" : "Обновить заявку"}
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*  */}

        <div className="container">
          <div className="inner-page">
            <div className="row no-gutters">
              <div className="col-md-12">
                {/* Информация об авто */}
                <div className="inner-page__item">
                  <div className="inner-item-title">Информация об авто</div>
                  <div className="row no-gutters">
                    <div className="col-6">
                      <div className="info-item">
                        <span>VIN</span>
                        {issueEdit.issueRequest?.application
                          ? issueEdit.issueRequest?.application.vin
                          : "не указан"}
                      </div>
                      <div className="info-item">
                        <span>Гос. номер</span>
                        {issueEdit.issueRequest?.application
                          ? issueEdit.issueRequest?.application.license_plate
                          : "не указан"}
                      </div>
                      <div className="info-item">
                        <span>ФИО собственника</span>
                        {issueEdit.issueRequest.application
                          ? issueEdit.issueRequest.application.courier_fullname
                          : "не указан"}
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="info-item">
                        <span>Заказчик</span>
                        {issueEdit.issueRequest.application
                          ? issueEdit.issueRequest.application.partner.name
                          : "не указан"}
                      </div>
                      <div className="info-item">
                        <span>Стоянка</span>
                        {issueEdit.issueRequest.application
                          ? issueEdit.issueRequest.application.parking.title
                          : "не указан"}
                      </div>
                      <div className="info-item">
                        <span>Номер убытка / договора</span>
                        {issueEdit.issueRequest.application
                          ? issueEdit.issueRequest.application.external_id
                          : "не указан"}
                      </div>
                    </div>
                  </div>
                </div>
                {/*  */}

                {/* Дата */}
                <div className="inner-page__item">
                  <div className="inner-item-title">Дата</div>
                  <div className="row">
                    <div className="col-6">
                      <label className="field-style ">
                        <span>Дата выдачи</span>
                        <Flatpickr
                          options={{
                            dateFormat: "d-m-Y",
                            minDate: user.role === "Operator" ? new Date() : "",
                            locale: {
                              ...Russian,
                              months: {
                                ...Russian.months,
                                longhand: [
                                  "Января",
                                  "Февраля",
                                  "Марта",
                                  "Апреля",
                                  "Мая",
                                  "Июня",
                                  "Июля",
                                  "Августа",
                                  "Сентября",
                                  "Октября",
                                  "Ноября",
                                  "Декабря",
                                ],
                              },
                            },
                          }}
                          onChange={handleChangeDate}
                          defaultValue={issueEdit.issueRequest.arriving_at}
                          name="arriving_at"
                        />
                      </label>
                    </div>
                    {user.role === "Manager" ? (
                      ""
                    ) : (
                      <div className="col-6">
                        <label className="field-style select-react__style ">
                          <span>Промежуток времени</span>
                          <Select
                            defaultValue={selectedOption}
                            options={selectArrayInterval}
                            styles={selectStyle}
                            onChange={handleChange}
                          />
                        </label>
                      </div>
                    )}
                  </div>
                </div>
                {/*  */}

                {/* Информация о выдаче */}
                <div className="inner-page__item">
                  <div className="inner-item-title">Информация о выдаче</div>
                  <div className="row">
                    {/* ФИзические лица */}
                    <div className="col-6 mt-2 mb-5">
                      <label className="switch-radio-wrap">
                        <input
                          type="radio"
                          name="legal_type_id"
                          defaultValue={1}
                          onChange={handleChange}
                          defaultChecked={
                            issueEdit.client?.legal_type_id === 1 ? true : false
                          }
                        />
                        <span className="switcher-radio"></span>
                        <span>{issueEdit.individualLegalOptions["1"]}</span>
                      </label>
                    </div>
                    {/*  */}
                    {/* Юридические лица */}
                    <div className="col-6 mt-2 mb-5">
                      <label className="switch-radio-wrap">
                        <input
                          type="radio"
                          name="legal_type_id"
                          defaultValue={2}
                          defaultChecked={
                            issueEdit.client?.legal_type_id === 2 ? true : false
                          }
                          onChange={handleChange}
                        />
                        <span className="switcher-radio"></span>

                        <span>{issueEdit.individualLegalOptions["2"]}</span>
                      </label>
                    </div>
                    {/*  */}
                    {/* Название организации */}
                    <div className="col-6">
                      <label className="field-style">
                        <span>Название организации</span>
                        <input
                          type="text"
                          defaultValue={issueEdit.client.organization_name}
                          name="organization_name"
                          placeholder="Не указан"
                          onChange={handleChange}
                        />
                      </label>
                    </div>
                    {/*  */}

                    {/* ИНН */}
                    <div className="col-6">
                      <label className="field-style">
                        <span>ИНН</span>
                        <input
                          type="text"
                          defaultValue={issueEdit.client.inn}
                          name="inn"
                          placeholder="Не указан"
                          onChange={handleChange}
                        />
                      </label>
                    </div>
                    {/*  */}
                    {/* ФИО доверенного лица */}
                    <div className="col-6 mt-3">
                      <label className="field-style">
                        <span>ФИО доверенного лица</span>
                        <input
                          type="text"
                          defaultValue={`${
                            issueEdit.client.lastname !== null &&
                            issueEdit.client.lastname !== "null"
                              ? issueEdit.client.lastname
                              : ""
                          } ${
                            issueEdit.client.firstname !== null &&
                            issueEdit.client.firstname !== "null"
                              ? issueEdit.client.firstname
                              : ""
                          } ${
                            issueEdit.client.middlename !== null &&
                            issueEdit.client.middlename !== "null"
                              ? issueEdit.client.middlename
                              : ""
                          }`}
                          name="fio"
                          placeholder="Не указан"
                          onChange={handleChange}
                        />
                      </label>
                    </div>
                    {/*  */}

                    {/* Телефон доверенного лица */}
                    <div className="col-6 mt-3">
                      <label className="field-style">
                        <span>Телефон доверенного лица</span>
                        <input
                          type="text"
                          defaultValue={issueEdit.client.phone}
                          name="phone"
                          placeholder="Не указан"
                          onChange={handleChange}
                        />
                      </label>
                    </div>
                    {/*  */}
                  </div>
                </div>
                {/*  */}
                {/* <div className="inner-page__item">
                  <div className="inner-item-title">Документы</div>
                  Паспорт, доверенность и прочее
                  <div className="page-file-list" id="images">
                    <div className="page-add-file no-ajax upload-file">
                      <svg
                        width="40"
                        height="40"
                        viewBox="0 0 40 40"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.6"
                          d="M20.0013 6.6665C20.9218 6.6665 21.668 7.4127 21.668 8.33317V18.3332H31.668C32.5884 18.3332 33.3346 19.0794 33.3346 19.9998C33.3346 20.9203 32.5884 21.6665 31.668 21.6665H21.668V31.6665C21.668 32.587 20.9218 33.3332 20.0013 33.3332C19.0808 33.3332 18.3346 32.587 18.3346 31.6665V21.6665H8.33464C7.41416 21.6665 6.66797 20.9203 6.66797 19.9998C6.66797 19.0794 7.41416 18.3332 8.33464 18.3332H18.3346V8.33317C18.3346 7.4127 19.0808 6.6665 20.0013 6.6665Z"
                          fill="#536E9B"
                        />
                      </svg>
                    </div>
                    <div
                      className="page-file-item"
                      data-src="{{ $attachment->url }}"
                    >
                      <img src="{{ $attachment->thumbnail_url }}" alt="" />
                      <div className="page-file__option">
                        <button
                          type="button"
                          className="page-file__zoom"
                        ></button>
                        <button
                          type="button"
                          className="page-file__delete"
                          data-img-id="{{ $attachment->id }}"
                        ></button>
                      </div>
                    </div>
                  </div>
                </div>
                <input
                  type="file"
                  id="noAjaxFileUploader"
                  name="docs[]"
                  className="d-none"
                  multiple
                /> */}
              </div>
            </div>
          </div>
        </div>
      </form>
    )
  );
}

export default IssueRequestEdit;
