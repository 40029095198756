import React from 'react';
import ImageUploaderEdit from "../../../image-upload/image-edit-upload";
import MyModal from "../../../modal/modal";

function ApplicationImportImageUploadModal({carId,currentCarAttach, isOpen,closeModal}) {
    return (
        <MyModal isOpen={isOpen} onClose={closeModal} ariaHideApp={false}>
            <div class="mass-import--image-modal">
                <ImageUploaderEdit id={carId} oldimage={currentCarAttach}  />
            </div>
        </MyModal>
    );
}

export default ApplicationImportImageUploadModal;