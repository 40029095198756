import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSearchPartner,
  partnerSearch,
} from "../../../store/partners/partners-search-slice/partnerSearchSlice";
import { Link, useMatch, useNavigate, useParams } from "react-router-dom";
import { partnerCreatePost } from "../../../store/partners/partners-create-slice/partnersCreateSlice";
import swal from "sweetalert";
import { partnersEditPut } from "../../../store/partners/partners-edit-slice/partnersEditSlice";

function PartnerSearchPage({ children, data, disabledButton }) {
  const createPartnerRoute = useMatch("/partners/add-new");
  const editPartnerRoute = useMatch("/partners/:id/edit");
  const { searchPartner } = useSelector((state) => state.partnerSearch);
  const { id } = useParams();

  // Функция для отправки формы
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(partnerSearch({ [name]: value }));
  };

  //

  const handleClickAddNew = () => {
    dispatch(clearSearchPartner());
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Создание заказчика
    createPartnerRoute &&
      dispatch(partnerCreatePost(data)).then((response) => {
        if (response.payload.status === 201) {
          swal("заказчик успешно создана!", {
            icon: "success",
          }).then(() => {
            navigate("/partners");
          });
        } else {
          let errorMessage = "Ошибка при создании заказчика!";
          if (response.payload && response.payload.errors) {
            const errorMessages = Object.values(response.payload.errors).reduce(
              (acc, val) => acc.concat(val),
              []
            );
            errorMessage = errorMessages.join("\n");
          }
          swal(errorMessage, {
            icon: "error",
          });
        }
      });
    //

    editPartnerRoute &&
      dispatch(partnersEditPut({ id: id, data: data })).then((response) => {
        if (response.payload.status === 200) {
          swal("заказчик успешно обновлен!", {
            icon: "success",
          }).then(() => {
            navigate("/partners");
          });
        } else {
          let errorMessage = "Ошибка при обновление заказчика!";
          if (response.payload && response.payload.errors) {
            const errorMessages = Object.values(response.payload.errors).reduce(
              (acc, val) => acc.concat(val),
              []
            );
            errorMessage = errorMessages.join("\n");
          }
          swal(errorMessage, {
            icon: "error",
          });
        }
      });
  };
  return (
    <form onSubmit={handleSubmit}>
      <div className="container page-head-wrap">
        <div className="page-head">
          <div className="page-head__top d-flex align-items-center">
            <h1>Создать нового заказчика</h1>
            <div className="ml-auto d-flex">
              {createPartnerRoute && (
                <button className="btn btn-white">Добавить</button>
              )}
              {editPartnerRoute && !disabledButton && (
                <button className="btn btn-white">Обновить</button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="inner-page add-partners">
          <div className="row no-gutters">
            <div className="inner-page__item">
              <div className="inner-item-title">Поиск по базе</div>
              <div className="d-down-field">
                <label className="field-style">
                  <span>Поиск</span>
                  <input
                    type="text"
                    name="vin"
                    className="inner-page-search"
                    placeholder="Название или юр. данные"
                    onChange={handleChange}
                  />
                </label>
                <div className="d-dowen-body active">
                  <ul className="d-dowen-select">
                    {searchPartner.length !== 0 &&
                      searchPartner.data.map((item) => {
                        return (
                          <Link to={`/partners/${item.id}/edit`}>
                            <li>
                              <span>{item.name}</span>
                              <div>
                                <span>ИНН: {item.inn}</span>
                                <span>КПП: {item.kpp}</span>
                              </div>
                            </li>
                          </Link>
                        );
                      })}
                  </ul>
                  <Link
                    to="/partners/add-new"
                    className="btn-partner-search"
                    onClick={handleClickAddNew}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.6"
                        d="M8 0C8.55228 0 9 0.447715 9 1V7H15C15.5523 7 16 7.44772 16 8C16 8.55229 15.5523 9 15 9H9V15C9 15.5523 8.55228 16 8 16C7.44772 16 7 15.5523 7 15V9H1C0.447715 9 0 8.55229 0 8C0 7.44772 0.447715 7 1 7H7V1C7 0.447715 7.44772 0 8 0Z"
                        fill="#536E9B"
                      ></path>
                    </svg>
                    Добавить своего
                  </Link>
                </div>
              </div>
            </div>
            {children}
          </div>
        </div>
      </div>
    </form>
  );
}

export default PartnerSearchPage;
