import React from "react";
import FileUploadModal from "../../../../../file-upload/file-upload-modal";

function ApplicationsDocument({ single }) {
  return (
    <FileUploadModal
      docs={single?.application?.docs}
      applicationId={single?.application?.id}
    />
  );
}

export default ApplicationsDocument;
