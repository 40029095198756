import React, {useEffect, useState} from 'react';
import PermissionsHeader from "./permissionsHeader/permissionsHeader";
import PermissionItem from "./permissionItem/permissionItem";
import styles from './permission.module.css'
import {useDispatch, useSelector} from "react-redux";
import {permissionsGet, permissionsUpdate} from "../../store/permissions/permissionsSlice";
import swal from "sweetalert";

function PermissionsList() {
    const {permissions} = useSelector((state)=>state.permissions)
    const [permissionsRoleList, setPermissionsRoleList] = useState([])
    const [permissionsData, setPermissionsData] = useState({})

    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(permissionsGet())
    }, [])
    useEffect(()=>{
        if (permissions?.roles) {
            permissions && setPermissionsRoleList(Object.entries(permissions?.roles))
            const formatted = Object.keys(permissions?.roles).reduce((acc, roleKey) => {
                const role = permissions?.roles[roleKey]
                acc[role.name] = role.permissions
                return acc
            }, {})
            setPermissionsData(formatted)
        }
    }, [permissions])
    const togglePermission = (role, permissionName) =>{
        setPermissionsData(prevPermissionsData => {
            const rolePermissions = prevPermissionsData[role] || []
            const updatedPermissions = rolePermissions.includes(permissionName)
                ? rolePermissions.filter(permission => permission !== permissionName)
                : [...rolePermissions, permissionName];

            return {
                ...prevPermissionsData,
                [role]: updatedPermissions,
            };
        });
    }
    const submitForm = ()=> {
        const data = permissionsData
       dispatch(permissionsUpdate({data})).then((resp)=>{
           if (resp.meta.requestStatus === 'fulfilled'){
               swal(resp.payload.success, {
                   icon: "success",
               })
           }else{
               swal(resp.payload.success, {
                   icon: "error",
               })
           }
       })
    }
    return (
        <>
            <PermissionsHeader
                submitForm={submitForm}
            />
            <div className="container">
                <table>
                    <thead className={styles['permission-table-header']}>
                        <tr>
                            <th key={'doing'}>Действие</th>
                            {permissionsRoleList?.map(prRole => (<th key={prRole[1].id}>{prRole[1].name}</th> ))}
                        </tr>
                    </thead>
                    <tbody className={styles['permission-table-body']}>
                        {permissions?.permissions?.map(permission => (
                           <PermissionItem
                               key={permission.name}
                               permission={permission}
                               permissionsRoleList={permissionsData}
                               togglePermission={togglePermission}
                           />
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}
export default PermissionsList;